var routes = {}
if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
 routes = require("./routes.bookingwidget")
}

if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget2'){
 routes = require("./routes.bookingwidget2")
}


if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget3'){
routes = require("./routes.bookingwidget3")
}


if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget4'){
 routes = require("./routes.bookingwidget4")
}

if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget5'){
 routes = require("./routes.bookingwidget5")
}

if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget6'){
 routes = require("./routes.bookingwidget6")
}

if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget7'){
 routes = require("./routes.bookingwidget7")
}

if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget8'){
 routes = require("./routes.bookingwidget8")
}

module.exports = routes;