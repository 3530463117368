import React,{Component} from "react";
import TourAvailability from "../components/component.tour.availability";
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}
const $ = window.$;
class ToursAvailability extends Global {
  constructor(props) {
    super(props);

    this.state = {
      tourinfo:{}
    };
  }

  componentDidMount() {
    this.scrolltop();
  }

  componentWillUnmount() {}

  onload(data){
     this.state.tourinfo = data.tourinfo;
     this.forceUpdate();
  }

  render() {

    var tourinfo = this.state.tourinfo;
    if (this.ismobile())
      return (
        <div>
          <section
            className="parallax-window"
            data-parallax="scroll"
            style={{ background: "url(img/cart_back.jpg)",backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center center' }}
          >
            <div className="parallax-content-1">
              <div className="animated fadeInDown">
                <h1>{tourinfo.TourName}</h1>
                <p>{this.t('from')} {tourinfo.DestinationName}</p>
              </div>
            </div>
          </section>

          <main>
            <div id="position">
              <div className="container">
                <ul>
                  <li>
                    <NavLink to="/tours/1">Tours</NavLink>
                  </li>
                  <li>Availability</li>
                </ul>
              </div>
            </div>
            <TourAvailability match={this.props.match} onload={this.onload.bind(this)} />;
          </main>
        </div>
      );

    return (
      <div
        className="touravailability"
        style={{ height: "600px", background: "#245b98" }}
      >
        <div id="cart" className="zoom-anim-dialog mfp-hide">
          <div className="small-dialog-header">
            <h3>{this.translate('Availability')}</h3>
          </div>
          <TourAvailability match={this.props.match} {...this.props} />
        </div>
      </div>
    );
  }
}

export default ToursAvailability
