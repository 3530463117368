import React,{Component} from "react";
import ToursList from "../components/component.tours.list";
import { NavLink,withRouter } from "react-router-dom";
import Section from '../components/component.section';
import DestinationSelector from "../components/component.destination.selector";
import Pagination from "../components/component.pagination";
import Global from '../globals/global';
const $ = window.$;

class wishlist extends Global{
  constructor(props) {
    super(props);

    
    var languageid = this.getglobaldata('Id_Language');
    var currencyid = this.getglobaldata('Id_Currency');
    var destinationid = this.getglobaldata('Id_Destination');

    if (this.props.match) {
      if (this.props.match.params) {
       
        if (this.props.match.params.language)
          languageid =
            this.props.match.params.language.toLowerCase() == "en" ? 1 : 2;
        if (this.props.match.params.destinationid)
          destinationid = this.props.match.params.destinationid;

        if (this.props.match.params.currency) {
          if (this.props.match.params.currency.toLowerCase() == "mxn")
            currencyid = 2;
          if (this.props.match.params.currency.toLowerCase() == "cad")
            currencyid = 3;
        }
      }
    }

    this.state = {
      tours: [],
      languageid: languageid,
      destinationid: destinationid,
      currencyid: currencyid,
      totaltours: 0,
      limit:9,
      searching:false,
      page: 1,
      pages: 1,
      localwishlist:false
    };
  }

  componentDidMount() {
    //this.starttours();
  }

  componentWillMount() {
    if(!this.getglobaldata('wishlist')) this.setglobaldata('wishlist',[]);

    if(!this.getglobaldata('user')) {
      this.state.tours = this.getglobaldata('wishlist');
      this.state.totaltours = this.state.tours.length;
      this.state.localwishlist = true;
    }
    else this.gettours();
  }

  gettours() {

    this.state.searching = true;
    this.forceUpdate();


    var options = {
      arraydata: "tours", 
      method: "GetTours",
      query: {
        pagination: this.state.page-1,
        Id_Language: this.state.languageid,
        destination: this.state.destinationid,
        Id_Currency: this.state.currencyid,
        InWishlist:-1,
        
      },
      callback: function(tours, jsonresponse) {
        
        this.state.totaltours = jsonresponse.TotalTours;
        this.state.pages = Math.ceil(
          jsonresponse.TotalTours / this.state.limit
        );
         this.state.searching = false;
       
      }.bind(this)
    };


    this.all(options);
  }


  changepage(options_) {
    this.state.page = options_.page; 

    this.gettours();
    
  }


  render() {
    
    var tours = this.state.tours;

     var paginationoptions = {
      page: this.state.page,
      pages: this.state.pages,
      sidePages: 3,
      beginPages: 2, // optional
      endPages: 2 // optional
    };
    

    return (

        <div>
        <Section origin='wishlist' />
        {/* End section */}
        <main>
          <div id="position">
            <div className="container">
              <ul>
                <li><NavLink to="/tours/1">{this.t("Tours")}</NavLink>
                </li>
                <li>{this.t("Wishlist")}</li>
              </ul>
            </div>
          </div>
          {/* End Position */}
          <div className="container margin_60">
            <div className="card">
              <div className="card-body bor_t">
                {/*TOUR & ACTIVITIES */}
                <div className="row">
                  <div className="col-lg-12">
                    
                     {this.state.searching ? (
                      <b className="found_s">{`(${this.t('Searching')}....)`}</b>
                    ) : (
                      <span className="found_s">
                        ({this.state.totaltours} {this.t('founds')})
                      </span>
                    )}   

                     {this.state.localwishlist?<h3 className="blue_text lista_fav_city">{this.t('My Wish List')}</h3>:<DestinationSelector origin='page' text={this.t('My Wish List in')} />}

                  </div>
                  <div className="col-lg-9">
                    <p className="color_red" dangerouslySetInnerHTML={{__html: this.t("Tap Hearts <i class='icon-heart' /> on Tours & Special Offers throughout the app to build your wish list --we'll look for deals for you! We'll look for specials & enter you to win make it in the app.")}}></p>
                  </div>
                  <div className="col-lg-3">
                   {/*  <a href="populartours.html" className="wish_btn">{this.t('SEE THE BEST THING TO DO')} <i className="icon-heart" /> </a> */}
                  </div>
                </div>
              </div>{/*end card-body*/}
            </div>{/*end card*/}


              {this.state.totaltours > 1?
              <Pagination
              options={paginationoptions}
              onChange={this.changepage.bind(this)}
            />:''}
           
             <ToursList tours={tours} removetours={true} />


             {this.state.totaltours > 1?
              <Pagination
              options={paginationoptions}
              onChange={this.changepage.bind(this)}
            />:''}

            {/* End row */}
          </div>
          {/*End container */}
          <div id="overlay" />
          {/* Mask on input focus */}
        </main>
        {/* End main */}
      </div>
      
    );
  }
}

export default wishlist


