import React, { Component } from "react";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import InputSearchHotel from "../components/component.input.search.hotel";
import AirportSelector from "../components/component.airport.selector";
import TransferCard from "../components/component.transfer.card";
import Section from "../components/component.section";
import Global from "../globals/global";
//if (typeof window === "undefined")  var window={}
const $ = window.$;
class Transfers extends Global {
    constructor(props) {
        super(props);

        this.handleHotel = this.handleHotel.bind(this);
        this.handleAirportArrival = this.handleAirportArrival.bind(this);
        this.handleAirportADeparture = this.handleAirportADeparture.bind(this);

        this.state = {
            languageid: null,
            currencyid: null,
            destinationid: null,
            locationid: null,

            Id_Hotel: null,
            Id_Airport_Arrival: null,
            Id_Airport_Departure: null,
            transfers: null,
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({
            languageid: this.getglobaldata("Id_Language"),
            currencyid: this.getglobaldata("Id_Currency"),
            destinationid: this.getglobaldata("Id_Destination"),
            locationid: this.getglobaldata("Id_Location"),
            loading: false,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.Id_Airport_Arrival !== this.state.Id_Airport_Arrival ||
                prevState.Id_Airport_Departure !==
                    this.state.Id_Airport_Departure) &&
            this.state.Id_Hotel !== null
        ) {
            this.getTransfers();
        }

        if (
            prevState.Id_Hotel !== this.state.Id_Hotel &&
            this.state.Id_Airport_Arrival !== null &&
            this.state.Id_Airport_Departure !== null && 
            this.state.Id_Airport_Departure !== -1 &&
            this.state.Id_Airport_Arrival !== -1
        ) {
            this.getTransfers();
        } /*
        if (prevProps.match !== this.props.match) {
            console.log('this.props.match', this.props.match);
            if (prevProps.match.url !== this.props.match.url) {
                const Id_Destination = this.getglobaldata('Id_Destination');
                this.setState({
                    destination: Id_Destination,
                });
            }
        }*/
    }

    getTransfers() {
        this.setState({ loading: true });

        var data = {
                Id_Destination: this.state.destinationid,
                Id_Location:
                    this.state.locationid !== null
                        ? Number(this.state.locationid) === -1
                            ? ""
                            : this.state.locationid
                        : "",
                Id_Hotel:
                    this.state.Id_Hotel !== null ? this.state.Id_Hotel : "",
                Id_Airport_Arrival:
                    this.state.Id_Airport_Arrival !== null
                        ? this.state.Id_Airport_Arrival
                        : "",
                Id_Airport_Departure:
                    this.state.Id_Airport_Departure !== null
                        ? this.state.Id_Airport_Departure
                        : "",
                Id_Language: this.state.languageid,
                Id_Currency: this.state.currencyid
            }

            if(this.config().sbiid == 1 || this.config().brand == 'pricetravel' || this.config().hascustomtransfers == -1) data.Id_SBI = this.config().sbiid;

            else data.Id_SBI = -1;  

        var options = {
            arraydata: "transfers",
            method: "GetTransfers",
            query: data,
            callback: function (data) {
                this.setState({
                    transfers: data,
                    loading: false,
                });
            }.bind(this),
            error: function (data) {}.bind(this),
        };
        this.all(options);
    }

    handleHotel(data) {
        console.log("hotel data", data);
        this.setState({
            Id_Hotel: data.data,
            locationid: data.hotel
                ? data.hotel.Id_Location
                : this.state.locationid,
        });
    }

    handleAirportArrival(data) {
        this.setState({
            Id_Airport_Arrival: data,
        });
    }

    handleAirportADeparture(data) {
        this.setState({
            Id_Airport_Departure: data,
        });
    }

    render() {
        if (!this.config().transfers)
            return <Redirect to="/tours/1"></Redirect>;

        return (
            <div>
                <Section origin="transfers" />

                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li>
                                    <a href="#">{this.t("Home")}</a>
                                </li>
                                <li>{this.t("Transfers")}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position  */}

                    <div className="container margin_60">
                    
                        {Number(this.state.destinationid) == 1 || Number(this.state.destinationid) == 4 || Number(this.state.destinationid) == 5? (
                            <div className="card warning_msg_c warning_none_msg">
                                <div className="card-body card_tour">
                                    <div className="row">
                                        <div className="col-2">
                                            <img
                                                src={this.image(
                                                    "img/warning_c.png"
                                                )}
                                                className="img-fluid mx-auto d-block"
                                            />
                                        </div>
                                        <div className="col-10">

                                            <p>
                                                {this.t(
                                                    "Because the current global health situation our government implemented a security and sanitize mesures. The STC demands that vehicles must operate at 50% of the maximum capacity. Please take this into consideration when making your reservation."
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="card">
                            <div className="card-body bor_t">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            {this.t(
                                                "Get to your hotel with style, select or search your hotel to get our best selection of airport-hotel transfer services. Air conditioned comfortable coaches, minivans or private vehicles will be waiting to take you to your destination."
                                            )}
                                        </p>
                                    </div>

                                    <div className="col-md-4">
                                        <h4 className="blue_text">
                                            <span className="blue_b">1</span>
                                            {this.t("Select your hotel")}
                                        </h4>
                                        <p>
                                            {this.t(
                                                "Please select your hotel from the list of available options so we can provide an accurate list of transfer options for your location."
                                            )}
                                            <br />
                                        </p>
                                        <InputSearchHotel
                                            identifierSearch={"_3"}
                                            handle={this.handleHotel}
                                            match={this.props.match}
                                            reUpdate={{}}
                                            identifierSearch={"_3"}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <h4 className="blue_text">
                                            <span className="blue_b">2</span>
                                            {this.t("Select Airport Option")}
                                        </h4>
                                        <label>
                                            {this.t("Arrival Airport")}
                                        </label>
                                        <AirportSelector
                                            handle={this.handleAirportArrival}
                                            locationid={this.state.locationid}
                                        />
                                        <br />
                                        <label>
                                            {this.t("Departure Airport")}
                                        </label>
                                        <AirportSelector
                                            handle={
                                                this.handleAirportADeparture
                                            }
                                            locationid={this.state.locationid}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <h4 className="blue_text">
                                            <span className="blue_b">3</span>
                                            {this.t("Select Below")}
                                        </h4>
                                        <p>
                                            <strong>{this.t("Note:")}</strong>{" "}
                                            {this.t(
                                                "If the system shows arrival & departure but round trip is not available, it means that they have to be booked separately."
                                            )}
                                        </p>
                                        <span className="found_l blue_text">
                                            {this.t("TRANSFERS FOUND:")}
                                            {!this.state.loading
                                                ? this.state.transfers !== null
                                                    ? ` ${this.state.transfers.length}`
                                                    : " 0"
                                                : this.spinner()}
                                        </span>
                                    </div>
                                </div>
                                {/* end row */}
                            </div>
                            {/* end card-body */}
                        </div>
                        {/* end card */}

                        <div className="row">
                            {!this.state.loading ? (
                                this.state.transfers !== null ? (
                                    this.state.transfers.length > 0 ? (
                                        this.state.transfers.map(
                                            (transfer, index) => {
                                                return (
                                                    <TransferCard
                                                        key={`TransferCard-${index}`}
                                                        data={transfer}
                                                        Id_Airport_Arrival={
                                                            this.state
                                                                .Id_Airport_Arrival
                                                        }
                                                        Id_Airport_Departure={
                                                            this.state
                                                                .Id_Airport_Departure
                                                        }
                                                    />
                                                );
                                            }
                                        )
                                    ) : (
                                        <div className="col-md-12">
                                            {this.t("No results found")}
                                        </div>
                                    )
                                ) : null
                            ) : (
                                <div className="col-md-12">
                                    {this.spinner()}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* End container  */}

                    <div id="overlay"></div>
                </main>
                {/* End main  */}
            </div>
        );
    }
}

export default Transfers;
