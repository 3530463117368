import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class sunexprivacy extends Global {
    render() {
        return (
            
            <div className="row">

            <div className="col-lg-12">

                <h5 className="terms_title mt-15 blue_text">Mobile Sail Technologies, S.A. de C.V.</h5>

                <p>Mobile Sail Technologies, S.A. de C.V. (operando también bajo los nombres de “Mobile Sail”, “Totoura”, “Toorzy”, y a quien se hace referencia en estos términos y condiciones como “Mobile”, “Sitios Web”, “nosotros”, “nuestro” y “nos”), es una persona jurídica, con domicilio en Paseo Cocoteros S/N, interior local 170, Hotel Marival Emotions Resort & Suites, Nuevo Vallarta, Nayarit, México, Código Postal 63732, página de internet: toorzy.com, mobilesail.com y totoura.com</p>

               <p>Totoura.com y toorzy.com, son los sitios web insignia, y, gestionan un servicio que permite a los usuarios buscar, encontrar y reservar experiencias de viaje alrededor del mundo.
                Totoura y Toorzy, trabajan, además, con afiliados: agencias y/o agentes de viaje, embajadores de destino y comisionistas que distribuyen sus productos y servicios, a través de sus sitios web individuales o bien, desde un widget de Totoura o Toorzy, incorporado en sitios web de terceros. Toorzy y Totoura, normalmente alojan la información sobre los afiliados, y se gestiona la información de tarjetas de crédito, tarjetas de crédito y pagos.
                De igual forma, también trabajamos con distribuidores que gestionan el alojamiento, la compra y el pago de los productos y servicios de nuestros proveedores.
                Los servicios se ponen a disposición de los sitios web totoura.com, toorzy.com y mobilesail.com (y/o dominios relacionados), aplicaciones y servicios telefónicos.
                </p>

                <p>Mobile Sail Technologies, S.A. de C.V. (en lo consecutivo “Mobile Sail”),  es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</p>

            </div>


            <div className="col-sm-12 text-justify">

                 <h5 className="terms_title mt-15 blue_text">1. ¿Para qué fines utilizaremos sus datos personales?</h5>

                 <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>

                <p className="font-weight-bold">a) En su calidad de Cliente, Proveedor, Embajador o Empleado o usuario de nuestro sitio web, Mobile Sail utilizará sus datos personales para las siguientes finalidades primarias:</p>

                <ol type="I">
                <li>Para identificación, verificación y contacto.</li>
                <li>Contratación, procesamiento, administración, operación y reservación de servicios contratados con nosotros que pueden referirse a tours, excursiones, coordinación de transporte terrestre y servicios turísticos complementarios.</li>
                <li>Para gestionar su registro, su cuenta, como cliente o embajador de destino, incluyendo el acceso y el uso que hace de nuestros sitios web  <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> y <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a> publicar sus reseñas, valoraciones y fotografías, ponernos en contacto con usted sobre su cuenta o sobre el uso que hace de nuestro sitio y nuestros servicios, responder o dar seguimiento a sus comentarios y preguntas.</li>
                <li>Ofrecerle un servicio de atención al cliente, notificarle sobre cambios en las condiciones de los productos y/o servicios o en nuestra política de privacidad.</li>
                <li>La facturación y cobranza del precio pactado por los servicios prestados, y otras contraprestaciones que resulten a su cargo.</li>
                <li>Para su seguridad durante su permanencia en las oficinas de Mobile Sail, establecemos medidas de seguridad y vigilancia de las instalaciones, entre ellas el monitoreo, videocámaras, control y vigilancia de acceso físico y lógico.</li>
                <li>Para su atención en caso de incidentes de salud o de seguridad.</li>
                <li>Cualquier otra forma de tratamiento de datos derivado de su calidad cliente, usuario de nuestras páginas web, o proveedor, tales como facturación, procesamiento de pedidos e integración de expedientes administrativos como soporte documental o de otra índole respecto de la relación comercial existente con usted.</li>


                </ol>
    
                <p className="font-weight-bold">b) Particularmente en su calidad de empleado o candidato a obtener un empleo con Mobile Sail  aplicarán además las siguientes finalidades primarias:</p>
    
                <ul>
                    <li>Integración de su expediente de empleado.</li>
                    <li>Para procesos de contratación y/o promoción laboral.</li>
                    <li>Para la realización de estudios socioeconómicos y psicométricos.</li>
                    <li>Para el otorgamiento de prestaciones laborales.</li>
                </ul>

                <p className="font-weight-bold">c) Particularmente, en su calidad de Proveedor de Mobile Sail, aplicarán además las siguientes finalidades primarias:</p>
    
                <ul>
                    <li>Para la elaboración de contratos de prestación de servicios.</li>
                    <li>Para el cumplimiento de obligaciones contractuales.</li>
                    <li>Para los procesos de pago, recepción y/o devolución de bienes y servicios adquiridos.</li>
                    <li>Para el procesamiento de pedidos e integración de expedientes administrativos como soporte documental o de otra índole respecto de la relación comercial existente con usted.</li>
                </ul>

                <p>De manera adicional, utilizaremos sus datos personales para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:</p>
    
                <ul>
                    <li>Mercadotecnia o publicidad.</li>
                    <li>Prospección comercial.</li>
                    <li>Control de calidad.</li>
                    <li> Definición de patrones de consumo.</li>
                    <li> Realización de campañas de retroalimentación y actualización de datos.</li>
                </ul>

                <p className="font-weight-bold">En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, le pedimos que nos lo haga saber utilizando la opción que resulte aplicable:</p>

                <ul>
                    <li>En caso de que proporcione sus datos de forma directa, hágalo saber en ese mismo momento a nuestro colaborador y solicite que le proporcione el formato correspondiente para documentar su negativa.</li>
                    <li>En caso de que proporcione sus datos utilizando nuestra página de internet, una aplicación móvil o agente/agencia de viajes, deberá enviar un mensaje de correo electrónico a la dirección  <a href="mailto:privacy@mobilesail.com" className="blue_text">privacy@mobilesail.com.</a></li>
                </ul>

                <p>La negativa para el uso de sus datos personales para estas finalidades secundarias no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
            
            
                <h5 className="terms_title mt-15 blue_text">2. ¿Qué datos personales utilizaremos para estos fines y cómo los obtenemos?</h5>
            
                <ul>
                    <li>Datos de identificación: Nombre, apellidos, firma, identificación con fotografía, Clave Única del Registro de Población (CURP).</li>
                    <li>Datos generales: Domicilio completo, sea particular o de trabajo, dirección de correo electrónico personal o de trabajo; número telefónico particular o del trabajo; número de teléfono celular, ID Nextel (en su caso) y número de fax; fecha de nacimiento, ocupación, idioma, edad, estado civil y nacionalidad.</li>
                    <li>Datos fiscales: Clave de Registro Federal de Contribuyentes (RFC), así como domicilio fiscal (dirección, código postal, ciudad, estado), y régimen fiscal (en su caso).</li>
                    <li>Datos de cuentas bancarias: Números vinculados con cuentas bancarias, tarjetas de crédito y/o débito, nombre del titular de la cuenta o tarjeta, número de cuenta y banco de origen, CLABE (en su caso), número de la tarjeta de crédito o débito; fecha de vencimiento de la tarjeta, tipo de tarjeta y código de seguridad. Todos estos datos son almacenados con su consentimiento expreso y en caso de nos haga saber su oposición podríamos vernos impedidos para proveer los servicios contratados en virtud de que podría no ser posible procesar el pago correspondiente.</li>
                    <li>Datos personales sensibles: Salvo para los servicios médicos y expedientes laborales, Mobile Sail no recolecta ni trata datos personales sensibles. Sin embargo, debe tener en cuenta que algunos proveedores de tours o productos y algunos de nuestros afiliados y distribuidores podrían necesitar datos personales adicionales u otro tipo de información (como información sobre la actividad física o de salud) para determinar si puede participar en una actividad o no. En dado caso, esta información quedará sujeta al Aviso de Privacidad del proveedor o distribuidor, y no será recabada por Mobile Sail.</li>
                    <li>Datos personales de fuentes de acceso público y recibido vía transferencias consentidas: Provenientes de registros públicos, directorios, redes sociales y otras fuentes lícitas disponibles en el mercado, así como datos personales que nos hayan sido compartidos por terceros a los cuales hubiese autorizado previamente para transferirlos.</li>
                    <li>Datos personales provenientes de redes sociales: Mobile Sail podrá recolectar y procesar datos personales e información que haya compartido en las redes sociales de totoura.com, toorzy.com o mobilesail.com, (tales como Facebook®, Instagram ®, Pinterest®, Foursquare®, Google® y Twitter®, entre otras), particularmente aquella que haya compartido en los perfiles de totoura.com, toorzy.com, en dichas redes sociales o en el perfil de otras empresas relacionadas a Mobile Sail, incluyendo la información que hayas compartido con carácter de “público/a”, según lo dispuesto por los términos y condiciones de uso y las políticas y declaraciones de los mismos.</li>
                    <li>Datos personales provenientes de agencias de viajes: Mobile Sail podría recibir sus datos personales cuando usted realice su reservación de excursiones, traslados terrestres, así como la contratación de otros servicios complementarios que de tiempo en tiempo contrate por nuestro conducto; ya sea a través de alguna agencia de viajes minorista o mayorista con domicilio en territorio nacional o en el extranjero, como también otras plataformas que comercializan los servicios de Mobile Sail (páginas de internet, programas vacacionales de aerolíneas, club de viajes, programas de beneficios y recompensas, y otras similares conocidas o que se conozcan).</li>
                    <li>Información sobre otras personas o referidos: En caso de que utilice la opción “Recomendar a un amigo” o compra productos o servicios para otra persona a través de <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> y/o <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a>, tendrá que enviar los datos personales de esa persona y deberá obtener el consentimiento de esas otras personas, antes de proporcionarnos información personal sobre ellas.</li>
                    <li>Datos personales provenientes de empresas filiales o subsidiarias con las que Usted ya tenga una relación contractual y a la cual haya manifestado su consentimiento para que fuesen transferidas a Mobile Sail.</li>
                    <li>Datos personales provenientes de clientes afiliados: Aquellos que distribuyen nuestros productos y servicios a los consumidores a través de sus sitios web individuales y de un widget de Totoura o Toorzy, colocado en sus sitios web.</li>
                    <li>Datos personales provenientes de distribuidores:  Aquellos que gestionan el alojamiento, la compra y el pago de los productos y servicios de nuestros proveedores. </li>
                    <li>Información de ubicación y otros datos de los dispositivos: Cuando utilice la aplicación de totoura.com o toorzy.com, en un dispositivo móvil, recabaremos y utilizaremos la información sobre usted, en general, de la misma manera y para los mismos propósitos que cuando utiliza el sitio web de totoura.com o toorzy.com. Además, si contamos con su consentimiento, podemos recopilar información sobre su ubicación si ha configurado su dispositivo para enviar dicha información a la aplicación mediante los ajustes de privacidad del dispositivo, o si ha subido fotos etiquetadas con información de ubicación. Por ejemplo, podremos utilizar su ubicación para mostrarle tours o excursiones que se organicen cerca de usted cuando viaje. Usted puede cambiar los ajustes de privacidad de su dispositivo en cualquier momento con el fin de desactivar la funcionalidad para compartir información de ubicación con la aplicación y/o la funcionalidad de etiquetar sus fotos con información de ubicación. Tenga en cuenta que desactivar el uso compartido de la ubicación puede afectar a determinadas características de nuestra aplicación. Si tiene alguna duda acerca de la configuración de privacidad de su dispositivo, le sugerimos que se ponga en contacto con el fabricante de su ordenador o dispositivo móvil,  o con su proveedor de servicios móviles para obtener ayuda.</li>
                    <li> Recopilación automática de información: Recabamos automáticamente cierta información de su ordenador o dispositivo móvil cuando visita totoura.com, toorzy.com, mobilesail.com, o cualquiera de las páginas web de nuestros afiliados alojadas por nosotros. Por ejemplo, recabamos su dirección IP, el software de su navegador web y el sitio web de referencia. Para ayudarnos a analizar el uso de nuestros sitios web y servicios relacionados, y para enviarle comunicaciones más pertinentes, también recopilamos y mantenemos un seguimiento de su actividad en línea, tales como el contenido que consulta, las páginas que visita y las búsquedas y reservas que le proporcionamos y realiza.</li>
                </ul>

                <p>Los datos personales descritos en los incisos precitados del presente apartado, se recolectan mediante: i) el uso de formatos o documentos impresos; ii) el uso de correos electrónicos y/o, iii) el suministro voluntario de información y datos personales cuando usted se registra en nuestros sitios web, adquiere nuestros servicios, se presenta en nuestras instalaciones, cuando se comunica vía telefónica con nosotros o con nuestros colaboradores (incluyendo el área de atención a clientes); iv) vía nuestros sitios web totoura.com, toorzy.com y mobilesail.com, aplicaciones de chat, así como, v) la utilización de fuentes de acceso público y de otras fuentes disponibles en el mercado, vi) vía nuestros clientes afiliados, embajadores o distribuidores: Si le proporciona datos personales directamente a nuestros afiliados, distribuidores o proveedores, serán las condiciones de sus respectivas políticas de privacidad las que se apliquen a tales divulgaciones. Es importante que revise atentamente las políticas de privacidad de los socios afiliados, distribuidores o proveedores antes de proporcionar sus datos personales a dichos terceros.</p>

                <h5 className="terms_title mt-15 blue_text">3. ¿Cómo protegemos sus datos personales?</h5>

                <p>Los datos personales recolectados por Mobile Sail se encuentran protegidos por medidas de seguridad, administrativas y físicas adecuadas contra el daño, pérdida, alteración, destrucción, uso, acceso o tratamiento no autorizado o ilegal, la divulgación, y contra pérdidas, daños o alteraciones o destrucciones accidentales, de conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (“LFPDPPP”) y su Reglamento. Para conocer más sobre nuestras medidas de seguridad le pedimos nos contacte vía correo electrónico a <a href="mailto:privacy@mobilesail.com" className="blue_text font-weight-bold">privacy@mobilesail.com</a></p>

                <p>Nos esforzamos por almacenar sus datos personales en entornos operativos seguros que no están accesibles al público en general, y por aplicar medidas de seguridad en nuestras instalaciones físicas para proteger contra la pérdida, el uso indebido o la alteración de sus datos personales por parte de nuestros empleados o de terceros.</p>

                <p>Sin embargo, no es posible garantizar al 100 % la seguridad de ninguna transmisión de datos a través de Internet ni de ningún entorno de almacenamiento de datos, de manera que no podemos asegurarle en términos absolutos que los datos personales que nos proporciona estén protegidos en todo momento. Mobile Sail solicita que nos informe si experimenta cualquier hecho inusual que pueda indicar una vulneración de la seguridad de su información; del mismo modo, usted será notificado en caso de algún incidente o vulnerabilidad detectado por Mobile Sail, en los plazos y medios establecidos, que la normatividad aplicable designe. En ese caso, nos esforzaremos por investigar si este hecho estaba relacionado con la transmisión de datos cuyo origen o destino sea <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> o <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a>, y le informaremos sobre las medidas que puede adoptar y que se han adoptado para ayudar a corregir el problema.</p>
               
               
                <h5 className="terms_title mt-15 blue_text">4. Información relativa a menores de edad:</h5>

                <p><a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> y/o <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a>, son sitios para el público en general, y no ofrece servicios dirigidos a menores.</p>

                <p>En caso de que Mobile Sail sea informado, de que una persona menor de 13 años nos ha enviado información personal, eliminaremos o destruiremos dicha información tan pronto como sea razonablemente posible.</p>
               
                <p>Si usted conoce a un menor que está inscrito al sitio web de <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> y/o <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a>, por favor infórmenos vía correo electrónico a <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">privacy@mobilesail.com</a>, a fin de eliminar su información.</p>

                <h5 className="terms_title mt-15 blue_text">5. ¿Con quién compartimos su información personal y para qué fines?</h5>

                <ul>

                        <li>Le informamos que sus datos personales pueden ser transferidos a terceros dentro y fuera del territorio nacional en caso de que sea necesario para llevar a cabo las finalidades primarias y secundarias consignadas en el presente aviso de privacidad.</li>
                        <li>También le informamos que Mobile Sail es parte de un grupo empresarial conocido comercialmente como “MARIVAL® GROUP” y puede que transfiera sus datos personales con otras compañías del mismo grupo, en términos de lo establecido en el Artículo 37 de la LFPDPPP, vigente en México, respecto a las partes relacionadas. Las compañías que integran el grupo son:</li>


                     <li className="list_none">
                        <ul>
                            <li>Rinoval de México, S.A. de C.V.,</li>
                            <li>Administraciones Profesionales de Vallarta, S.A. de C.V.,</li>
                            <li>Vacation Club Services, S.A. de C.V.,</li>
                            <li>Operadora y Comercializadora Turística, S.A. de C.V.,</li>
                            <li>Destiladeras Resort Operations, S.A. de C.V.,</li>
                            <li> Desarrollo Transimar, S.A. de C.V.,</li>
                            <li>Constructores y Desarrolladores Inmobiliarios de la Bahía, S.A. de C.V.,</li>
                            <li>Comercializadora Access del Puerto, S.A. de C.V.,</li>
                            <li>Serviventas de México, S.A. de C.V.,</li>
                            <li>Servicios Terrestres del Nayar, S.A. de C.V.,</li>
                            <li>DMC Leisure, S.A. de C.V.,</li>
                            <li>Travel Marketing 500, S. de R.L. de C.V., y</li>
                            <li>Trafictours de México, S.A. de C.V.  </li>
                        </ul>
                    </li>
                </ul>

                <p>De conformidad con el Artículo 37 de la LFPDPPP, hacemos de su conocimiento que transferimos información internacionalmente, a empresas subsidiarias o afiliadas, bajo el control común, del mismo grupo empresarial Marival Group, que opera bajo nuestros mismos procesos y políticas internas, y que asumen, las mismas obligaciones que corresponden a Mobile Sail. Dichas empresas son:</p>

                <ul className="list_circ_b">
                       <li>Turissimo Caribe & Excursiones Dominican Republic, S.A, ubicada en República Dominicana</li>
                </ul>

                <ul>
                    <li>Turissimo Jamaica Limited, ubicada en Jamaica. También compartiremos sus datos personales con terceros independientes con los que requerimos interactuar para llevar a cabo las finalidades aquí establecidas, por ejemplo:</li>
                    
                     <li className="list_none">
                        <ul>
                            <li>Proveedores de servicios: Algunos de los datos personales que recopilamos se comparten con nuestros proveedores de servicios (por ejemplo, operadores de tours o servicios de transportación), quienes están implicados directamente en llevar a cabo las compras que realiza a través de nosotros. Solo le proporcionamos a nuestros proveedores de servicios la información necesaria (por ejemplo, el nombre del hotel para que le recojan o le lleven, su número de teléfono local, su número de teléfono móvil, su dirección de correo electrónico, fecha de llegada y fecha de salida). Le recomendamos que lea la política de privacidad del proveedor de servicios para determinar cómo este podrá usar o divulgar sus datos personales.</li>
                            <li>Sitios web remitentes: Si se le remitió a <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> y/o <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a>, desde otro sitio web, podemos compartir con dicho sitio web remitente su información de registro, como por ejemplo su nombre, su dirección de correo electrónico, su dirección postal, su número de teléfono y sus preferencias de viaje. No hemos limitado el uso que los sitios web remitentes hacen de sus datos personales, y le recomendamos que revise las políticas de privacidad de cualquier sitio web que le haya remitido a <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> y/o <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a></li>
                            <li>Proveedores terceros: También compartimos sus datos personales con proveedores terceros que ofrecen servicios o funciones en nuestro nombre, como por ejemplo el análisis comercial, el procesamiento de pagos, el servicio de atención al cliente, el marketing, las relaciones públicas, la distribución de encuestas o programas de sorteos y la prevención del fraude. Además, es posible que autoricemos a proveedores terceros a recopilar datos personales en nuestro nombre, lo que incluye, por ejemplo, aquellos que sean necesarios para manejar las funciones de nuestros sitios web o Aplicaciones, o para proporcionar la distribución de publicidad en línea adaptada a sus intereses. Los proveedores terceros tienen acceso y pueden recopilar datos personales únicamente cuando sea necesario para llevar a cabo sus funciones, y no pueden compartir o usar datos personales con ningún otro propósito. Actualmente, contratamos a diferentes socios terceros online para que nos ayuden a gestionar y mejorar nuestras comunicaciones y nuestra actividad de Internet. Utilizamos los servicios de empresas de marketing terceras para ayudarnos a medir la eficacia de nuestra publicidad y saber cómo utilizan nuestros visitantes nuestro sitio web.</li>
                        </ul>
                    </li>
                </ul>

                <p><strong>Instituciones bancarias, que procesan sus pagos.</strong> Ésta transferencia siempre será opcional y usted podrá oponerse en cualquier momento. Los terceros con los que compartiremos sus datos personales pueden ser:</p>

                <div className="table-responsive">
                    <table className="table table-striped">
                    <thead className="table_privacy_title">
                        <tr>
                            <th scope="col">Tercero</th>
                            <th scope="col">País</th>
                            <th scope="col">Finalidad</th>
                            <th scope="col" className="text-left">Tipo de Transferencia</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="align-middle">Instituciones bancarias</td>
                            <td className="align-middle">México</td>
                            <td className="align-middle">Procesamiento de Pagos</td>
                            <td>
                                Voluntaria
                                <br />
                                Autorizo
                                <br />
                                Si___ <br />
                                No___

                            </td>
                        </tr>


                        <tr>
                            <td className="align-middle">Servicio de Administración Tributaria</td>
                            <td className="align-middle">México</td>
                            <td className="align-middle">Dar cumplimiento a las disposiciones fiscales vigentes mediante la presentación de declaraciones periódicas.</td>
                            <td>
                                Obligatoria
                            </td>
                        </tr>

                        <tr>
                            <td className="align-middle">Procuraduría Federal del Consumidor</td>
                            <td className="align-middle">México</td>
                            <td className="align-middle">La atención de procedimientos previstos en la Ley Federal de Protección al Consumidor.</td>
                            <td>
                                Obligatoria
                            </td>
                        </tr>


                        <tr>
                            <td className="align-middle">Proveedores de tours y excursiones</td>
                            <td className="align-middle">Internacional</td>
                            <td className="align-middle">Proveedores de Tours y excursiones.</td>
                            <td>
                                 Voluntaria
                            </td>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                Autorizo
                                <br />
                                Si___ <br />
                                No___

                            </td>
                        </tr>

                        <tr>
                            <td className="align-middle">Proveedores de servicios de Traslados terrestres</td>
                            <td className="align-middle">Internacional</td>
                            <td className="align-middle">Proveedores de servicios de Traslados terrestre</td>
                            <td>
                                Voluntaria
                                <br />
                                Autorizo
                                <br />
                                Si___ <br />
                                No___

                            </td>
                        </tr>

                    </tbody>
                    </table>
                </div>

                <p>Finalmente señalamos que transferiremos sus datos nacional o internacionalmente, en los supuestos contemplados por el Artículo 37 de la  LFPDPPP, vigente en México, que establece expresamente la posibilidad de compartir sus datos con autoridades competentes y sociedades controladoras, subsidiarias o afiliadas bajo el control común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo, en los siguientes supuestos:  </p>


                <ol type="I">
                    <li>Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte; </li>
                    <li>Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios; </li>
                    <li>Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo del responsable que opere bajo los mismos procesos y políticas internas; IV. Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, por el responsable y un tercero; </li>
                    <li>Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia; </li>
                    <li>Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y </li>
                    <li>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular</li>
                </ol>

                <h5 className="terms_title mt-15 blue_text">6. Plazos de conservación:</h5>

                <p>Mobile Sail Technologies, S.A. de C.V., conservará los datos personales por el tiempo que sea necesario para cumplir con los fines por los que haya recopilado la información, incluidos las finalidades para satisfacer cualquier requisito legal, contable y de prestaciones de informes, de acuerdo a lo establecido en el Artículo 37, del Reglamento de la LFPDPPP, vigente en México. </p>
                
                <p>Con el propósito de determinar el periodo de retención apropiado, tenemos en cuenta la cantidad, la naturaleza y el grado de confidencialidad de los datos personales, el riesgo potencial de daños originados por el uso o divulgación no autorizados de sus datos personales, los fines por los que procesamos dichos datos y si podemos alcanzar dichos fines mediante otros medios, así como las disposiciones legales vigentes.</p>


                <h5 className="terms_title mt-15 blue_text">7. ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</h5>

                <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Derecho de Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Derecho de Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Derecho de Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Derecho de Oposición). Estos derechos se conocen como derechos ARCO.</p>

                <p>En relación con el procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:</p>

                <p className="font-weight-bold">a) ¿Qué información, documentación y requisitos deberá contener la solicitud?</p>

                <p>En cuanto a los requisitos generales que debe cubrir la solicitud, están los siguientes:</p>

                <ul>
                    <li> Nombre del titular de los datos personales.</li>
                    <li>Documentos que acrediten la identidad del titular (pasaporte, credencial para votar expedida por el Instituto Nacional Electoral, cédula profesional estatal o federal o licencia de manejo).</li>
                    <li>En su caso, nombre del representante del titular y documentos para acreditar su identidad y personalidad. Tratándose de apoderados de personas físicas, la representación se acreditará mediante cualquiera de las siguientes 3 (tres) opciones: a) instrumento público (documento suscrito por parte de un Notario Público), b) carta poder firmada ante 2 (dos) testigos (anexando copia simple de sus identificaciones oficiales), c) acudiendo usted y su representante a declarar en comparecencia del responsable presentando su identificación oficial vigente (pasaporte, credencial para votar expedida por el Instituto Nacional Electoral, cédula profesional estatal o federal o licencia de manejo).</li>
                    <li>Domicilio o cualquier medio para recibir notificaciones.</li>
                    <li>Descripción clara y precisa de los datos personales y del derecho que se quiere ejercer o de lo que se solicita.</li>
                    <li> Los datos o documentos que faciliten identificar la información materia de la solicitud (por ejemplo, número de membresía, fecha de estancia, número de reservación, fechas de hospedaje, etc.).</li>
                </ul>
                
                <p>Así mismo, en relación al tipo de solicitud, requerimos por favor nos indique la siguiente información adicional, a fin de que su solicitud pueda ser atendida de forma más eficiente:</p>

                <ul>
                <li><b>Derecho de Acceso</b></li>
                     <li className="list_none">
                        <ul>
                            <li>Indicar la modalidad en la que usted prefiere que se reproduzcan los datos personales solicitados.</li>
                        </ul>
                    </li>
                    <li><b>Derecho de Rectificación</b></li>
                    <li className="list_none">
                        <ul>
                            <li>Especificar las modificaciones que se solicitan a los datos personales, así como aportar los documentos que sustenten la solicitud.</li>
                        </ul>
                    </li>
                    <li><b>Derecho de Cancelación</b></li>
                    <li className="list_none">
                        <ul>
                            <li>Señalar las causas que motivan la petición de que se eliminen los datos de los archivos, registros o bases de datos del responsable.</li>
                        </ul>
                    </li>
                    <li><b>Derecho de Oposición</b></li>
                    <li className="list_none">
                        <ul>
                            <li>En el caso de que la solicitud se refiera a un tratamiento en lo particular, se deben indicar las finalidades específicas respecto de las cuales se solicita el ejercicio del derecho.</li>
                        </ul>
                    </li>
                    <li><b>Revocación del consentimiento</b></li>
                    <li className="list_none">
                        <ul>
                            <li>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</li>
                        </ul>
                    </li>
                </ul>

                <p className="font-weight-bold">b) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?</p>

                <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través de los siguientes medios:</p>

                <p>Por correo electrónico a la dirección privacy@mobilesail.com o también podrá contactar a nuestro Departamento de Datos Personales para que le dé trámite a su solicitud, le proporcione orientación y le facilite el formato correspondiente. El domicilio de nuestro Departamento de Datos Personales es Paseo de los Cocoteros s/n, hotel Marival® Emotions Resort & Suites, fraccionamiento Nuevo Vallarta, municipio de Bahía de Banderas, Nayarit, código postal 63732, número de teléfono 322-2268200 (extensión 4493).</p>

                <p>EFavor de solicitar el formato oficial de atención a solicitudes ARCO para Mobile Sail a través de la dirección de correo electrónico  <a href="mailto:privacy@mobilesail.com" className="blue_text font-weight-bold">privacy@mobilesail.com</a>.</p>

                <p className="font-weight-bold">c) ¿En cuántos días le daremos respuesta a su solicitud?</p>
                <p>20 días hábiles.</p>
                
                <p className="font-weight-bold">d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?</p>
                <p>Por correo electrónico o por el servicio postal.</p>
                
                <p className="font-weight-bold">e) ¿En qué medios se pueden reproducir los datos personales que, en su caso, solicite?</p>
                <p>En la misma forma que hayan sido proporcionados por el cliente</p>
                
                <p className="font-weight-bold">f) Ponemos a sus órdenes los siguientes formularios o sistemas para facilitar el ejercicio de derechos ARCO:</p>
                <p>Los formularios o sistemas correspondientes y orientación sobre el ejercicio de los derechos ARCO se pueden obtener contactando a nuestro Departamento de Datos Personales en la dirección de correo electrónico: <a href="mailto:privacy@mobilesail.com" className="blue_text font-weight-bold">privacy@mobilesail.com</a>, o en su domicilio físico ubicado en Paseo de los Cocoteros s/n, hotel Marival® Emotions Resort & Suites, fraccionamiento Nuevo Vallarta, municipio de Bahía de Banderas, Nayarit, código postal 63732, número de teléfono 322-2268200 (extensión 4493). También podrá descargar el formato directamente desde <a href="https://www.marivalarmony.com/mediafiles/pages/2019/05/90/formato-arco-marival-armony.pdf" className="blue_text font-weight-bold" target="_blank">aquí</a>.</p>

                <p className="font-weight-bold">g) ¿Se aplica algún costo por el ejercicio de mis derechos ARCO?</p>
                <p>El ejercicio de los derechos ARCO es gratuito. Sin embargo, usted puede ser requerido a cubrir únicamente los gastos justificados de envío o el costo de reproducción en copias u otros formatos. Del mismo modo, si la misma persona reitera su solicitud en un periodo menor a 12 (doce) meses, los costos no serán mayores a 3 (tres) días de Salario Mínimo General Vigente en la Ciudad de México, a menos que existan modificaciones sustanciales al aviso de privacidad que motiven nuevas consultas.</p>
                
                <p className="font-weight-bold">h) ¿Qué pasa si una solicitud es incompleta o no se anexan todos los datos que incluye el procedimiento?</p>
                <p>En caso de que su solicitud no cuente con la información descrita en el apartado 4 Requisitos (incisos a y b), o bien, no es clara, es errónea o está incompleta, Mobile Sail podrá solicitar al titular que proporcione la información faltante, para atender su petición dentro de los 5 (cinco) días hábiles siguientes a la recepción de la solicitud, de no dar respuesta la solicitud se tendrá como no presentada.</p>
                <p>El titular contará con 10 (diez) días hábiles, para atender el requerimiento de Mobile Sail contados a partir del día siguiente en que lo haya recibido. En caso de que el titular atienda el requerimiento de información, el plazo para que el responsable dé respuesta, empezará a correr al día siguiente de que el titular, haya atendido el requerimiento.</p>


                <p className="font-weight-bold">Para mayor información sobre el procedimiento, ponemos a disposición los siguientes medios:</p>
                
                <ul>
                    <li>Correo electrónico: <a href="mailto:privacy@mobilesail.com" className="blue_text font-weight-bold">privacy@mobilesail.com</a></li>
                    <li>Contactando a nuestro Departamento de Datos Personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</li>
                    <li className="list_none">
                        <ul>
                        <li>Nombre de la persona o Departamento de Datos Personales: Departamento de Protección de Datos Grupo Marival.</li>
                        <li>Domicilio: Paseo de los Cocoteros s/n, interior Marival® Emotions Resort & Suites, en el fraccionamiento Nuevo Vallarta, municipio de Bahía de Banderas, Nayarit, México, 63732.</li>
                        <li>Correo electrónico: <a href="mailto:privacy@mobilesail.com" className="blue_text font-weight-bold">privacy@mobilesail.com</a></li>
                        <li>Número telefónico: 322-2268200</li>
                        </ul>
                    </li>
                

                </ul>

                <h5 className="terms_title mt-15 blue_text">8. ¿Cómo puede limitar el uso o divulgación de su información personal?</h5>

                <p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios: el cliente puede limitar el uso o divulgación de sus datos personales enviando un correo donde haga el planteamiento concreto a la dirección de correo electrónico <a href="mailto:privacy@mobilesail.com" className="blue_text font-weight-bold">privacy@mobilesail.com</a></p>
                <p>De manera adicional, le informamos que contamos con los siguientes listados de exclusión, en los cuales podrá registrarse o bien, des inscribirse en nuestras plataformas de envíos, para que sus datos personales no sean tratados para ciertos fines (principalmente secundarios):</p>

                <div className="table-responsive">
                    <table className="table table-striped">
                    <thead className="table_privacy_title">
                        <tr>
                            <th scope="col">Nombre del Listado</th>
                            <th scope="col">Finalidad para las que aplica</th>
                            <th scope="col">Medio para obtener mayor información</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td className="align-middle">Info</td>
                            <td className="align-middle">El procesamiento y la administración en general de sus reservaciones de excursiones, traslados terrestres, así como la contratación de otros servicios complementarios.</td>
                            <td className="align-middle"><a href="mailto:info@totoura.com" className="blue_text font-weight-bold">info@totoura.com</a></td>
                        </tr>

                    </tbody>
                    </table>
                </div>

                <h5 className="terms_title mt-15 blue_text">9. El uso de tecnologías de rastreo en nuestro portal de internet</h5>

                <p>Le informamos que en nuestra página de internet utilizamos cookies, u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines:</p>

                <p>Para monitorear y prevenir el uso indebido de las plataformas digitales; para mejorar nuestras herramientas digitales y actualizarlas conforme evoluciona la tecnología; y para ofrecer contenido personalizado.</p>

                <p>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>

                <ul>
                    <li>Identificadores, nombre de usuario y contraseñas de una sesión.</li>
                    <li>Idioma preferido por el usuario.</li>
                    <li>Región en la que se encuentra el usuario.</li>
                    <li>Tipo de navegador del usuario.</li>
                    <li>Tipo de sistema operativo del usuario.</li>
                    <li>Páginas web visitadas por un usuario.</li>
                    <li>Búsquedas realizadas por un usuario.</li>
                    <li>Publicidad revisada por un usuario.</li>
                </ul>

                <p>Para más información se sugiere consultar nuestra <a href="/cookies" className="blue_text font-weight-bold">Política de Cookies:</a> </p>

                <h5 className="terms_title mt-15 blue_text">10. ¿Cómo puede conocer los cambios en este aviso de privacidad?</h5>

    
                <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: envío de un correo electrónico y en la página de internet <a href="https://www.totoura.com" className="blue_text font-weight-bold" target="_blank">totoura.com</a>, <a href="https://www.toorzy.com" className="blue_text font-weight-bold" target="_blank">toorzy.com</a> y/o <a href="https://www.mobilesail.com" className="blue_text font-weight-bold" target="_blank">mobilesail.com</a>.</p>
                
                <p>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:</p>

                <p>Los cambios o actualizaciones al aviso de privacidad se darán a conocer por los medios que se describen en esta política y surtirán efectos 1 (una) semana después. En caso de dudas o comentarios los clientes deberán dirigirse a <a href="mailto:privacy@mobilesail.com" className="blue_text font-weight-bold">privacy@mobilesail.com</a>.</p>
            
                <p>Última actualización: 27/08/2021</p>
            </div>


        </div>


        )
    }
}

export default withRouter(sunexprivacy)