import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class AgeSelector extends Global {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.buildMaxAgeChild = this.buildMaxAgeChild.bind(this);

        this.state = {
            done: false,
            number: null,
            value: null,
            MinAge: null,
            MaxAgeChild: null,
        };
    }

    componentDidMount() {
        this.setState({
            done: true,
            number: this.props.number,
            value: this.props.value === undefined ? 0 : this.props.value,
            MinAge: this.props.MinAge,
            MaxAgeChild: this.props.MaxAgeChild,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.handle(this.state.number, this.state.value);
        }
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
        });
    }

    buildMaxAgeChild() {
        var options = [];

        for (var i = this.state.MinAge; i <= this.state.MaxAgeChild; i++) {
            options.push(
                <option key={i} value={i}>
                    {`${this.t('Child')} ${this.state.number}: ${i}`}
                </option>
            );
        }
        return options;
    }

    render() {
        return (
            this.state.done ? (
                <select className="form-control" value={this.state.value} onChange={this.handleChange} >
                    <option value={-1} disabled>{`${this.t('Child')} ${this.state.number}`}</option>
                    {
                        this.buildMaxAgeChild()
                    }
                </select>
            ) : null
        )
    }
}

export default withRouter(AgeSelector)