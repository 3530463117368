
import React from "react";
import i18n_en from '../languages/language.en.js';
import i18n_es from '../languages/language.es.js';
import i18n_fr from '../languages/language.fr.js';

import Gtm from './global.gtm'

//if (typeof window === "undefined")  var window={}

const $ = window.$;
var _lang = {1: i18n_en, 2: i18n_es, 3: i18n_fr};

export default class i18n extends Gtm{

gettranlation(){


    

    this.state.tranlation = true;
    this.forceUpdate();

    this.delay({
      callback:function(){
        window.loadmenuevents();
      }.bind(this),    //loadmenuevents is a function in functions.js
      duration:3
    })
    
    

    /* $.ajax({
        url:'https://api-totoura-sandbox.mobilesail.com/GetSiteTranslateTextLang',
        xhrFields: {withCredentials: true},
        dataType: "json",
        data: {Id_Language:(this.getglobaldata('Id_Language') || 1)},
        success: function(data) {
           
           this.state.tranlation = data;
           this.setglobaldata('tranlation',data);


           this.forceUpdate();

           loadmenuevents()  //importante para cargar menu en mobile, no quitar esta funcion

        }.bind(this),
        error: function(error, data) {
           // alert(error);
        }
    });*/



  }

settranslationtext(text_){

    text_ = text_.replace(new RegExp("\\btours\\b"),'experiences'); 
    text_ = text_.replace(new RegExp("\\btour\\b"),'experience'); 
    text_ = text_.replace(new RegExp("\\bTOURS\\b"),'EXPERIENCES'); 
    text_ = text_.replace(new RegExp("\\bTOUR\\b"),'EXPERIENCE');
    text_ = text_.replace(new RegExp("\\bTours\\b"),'Experiences'); 
    text_ = text_.replace(new RegExp("\\bTour\\b"),'Experience');
 

  return text_;
}

escapadasreplace(text_){

   var Id_Language = Number(this.getglobaldata('Id_Language'));



    //if(this.config().brand == 'viajeypunto' && Id_Language == 2){
    text_ = text_.replace(new RegExp("\\bexperiences\\b"),'escapadas'); 
    text_ = text_.replace(new RegExp("\\bexperience\\b"),'escapada'); 
    text_ = text_.replace(new RegExp("\\bEXPERIENCES\\b"),'ESCAPADAS'); 
    text_ = text_.replace(new RegExp("\\bEXPERIENCE\\b"),'ESCAPADA');
    text_ = text_.replace(new RegExp("\\bExperiences\\b"),'Escapadas'); 
    text_ = text_.replace(new RegExp("\\bExperience\\b"),'Escapada');

    console.log('text_',text_)
    //}
  return text_
}

temporarsetappname(text_){

  if(this.config().appname){
    let appname = this.config().appname
    
   // var regexp = new RegExp(replace,"g");

  //  text_ = text_.replace(new RegExp('TOTOURA','INDESTINATION.STORE'); 
   // text_ = text_.replace(new RegExp("\\bTotoura\\b"),appname); 
   // text_ = text_.replace(new RegExp("\\bTOTOURA\\b"),appname); 

  }

 /* if(this.config().domain){
    let domain = this.config().domain

    text_ = text_.replace(new RegExp("\\btotoura.com\\b"),domain); 
    text_ = text_.replace(new RegExp("\\bTotoura.com\\b"),domain); 
    text_ = text_.replace(new RegExp("\\bTOTOURA.COM\\b"),domain); 

  }
*/
  return text_;

}


customreplace(text){

  if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget2'){

  var regexp = new RegExp('\\bwww.totoura.com\\b',"gi");
  var regexp_ = new RegExp('\\bconcierge@totoura.com\\b',"gi");
  var regexp__ = new RegExp('\\bTOTOURA\\b',"gi");
  var regexp___ = new RegExp('\\bLLC\\b',"gi");

/*  var regexp = new RegExp('www.totoura.com',"gi");
  var regexp_ = new RegExp('concierge@totoura.com',"gi");
  var regexp__ = new RegExp('TOTOURA',"gi");
  var regexp___ = new RegExp('LLC',"gi");*/

  if(this.config().brand == 'toorzy'){
     text = text.replace(regexp,'www.toorzy.com');
     text = text.replace(regexp_,'info@toorzy.com');
     text = text.replace(regexp__,'TOORZY');
     text = text.replace(regexp___,'');
  }else if(this.config().brand == 'pricetravel'){
     text = text.replace(regexp,'www.pricetravel.com');
     text = text.replace(regexp_,'info@pricetravel.com');
     text = text.replace(regexp__,'PRICE TRAVEL');
     text = text.replace(regexp___,'');
  }else{

  text = text.replace(regexp,'www.indestination.store');
  text = text.replace(regexp_,'contactus@InDestination.Store');
  text = text.replace(regexp__,'INDESTINATION.STORE');
  text = text.replace(regexp___,'');
}

 }

  return text;

}

//Translation
translate(text_,variables_) {


    if(this.config().experiences)  text_ = this.settranslationtext(text_);
    
   // text_ = this.temporarsetappname(text_);


   text_ = text_.replace(/className/g,'class');
   //console.log('t: ' + text_);
  
   
   var Id_Language = Number(this.getglobaldata('Id_Language'));

   var tranlation =  _lang[Id_Language]; // this.getglobaldata('tranlation') || {};

  // console.log('this.tranlatio',tranlation);

   if(tranlation[text_] === undefined){
      
     // if(!(_lang['en'][text_] || false)){
      //  console.log('lang not found: ' + text_);
        // @ ///////ifdef DEBUG
        ///*
       
           $.ajax({
                url : 'https://api-totoura-sandbox.mobilesail.com/SetSiteTranslateTextLang',
                dataType: 'json',
                data: {
                    'Text': text_
                }
            });   
       
        //*/
        // @ //// endif
        
      }


      var text = (tranlation[text_] || text_);

      text = this.customreplace(text);
     // text = this.escapadasreplace(text);



      if(variables_){
        Object.keys(variables_).map(function(key,index){

          if(text.search("{{"+key+"}}") != -1) {
            
            var replace = "{{"+key+"}}";
            var regexp = new RegExp(replace,"g");

            text = text.replace(regexp,variables_[key]);

          }

        })
      }


      return text;
      
  
}

t(text_,variables_){
  return this.translate(text_,variables_);
}

}


