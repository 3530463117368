import React,{Component} from "react";
import ErrorMessages from "../components/component.error.messages";
import { NavLink, withRouter } from "react-router-dom";
import Language from "../components/component.language";
import Global from '../globals/global';
const $ = window.$;

class login extends Global {
  constructor(props) {
    super(props);


    this.state = {
      user:{
        username:'',
        LoginType:'tagent',
      },
      messages:[],
      validemail:false,
      loading:false,
    };
  }

  componentDidMount() {

    console.log('this.props',this.props)

   this.scrolltop();
    
    $('.jquery-background-video').bgVideo({fadeIn: 2000});
  }

  componentWillMount() {
   
  }

  changeemail(event){

    var target = $(event.target);
    var email = target.val().trim();

    event.target.value = email;

    this.state.messages = [];

    if(!this.isemail(email)) this.state.validemail = false;
    else this.state.validemail = true;
    
    this.changeinput(event);
  }

  login() {

    if(!this.state.validemail) {
      this.state.validemail = false;
     
      return;
    }


    this.state.loading = true;
    this.state.messages = [];
     this.forceUpdate();

    var options = {
      method: "UserLogin",
      query: this.state.user,
      callback: function(user) {
        
        if(user.Destination) {
          if(user.Destination != '') {
            this.setglobaldata('Id_Destination', user.Destination || 1);

            if(user.Location){
                   if(user.Location != '') this.setglobaldata('Id_Location', user.Location || 1);

            }
           }
        }

        
        this.setglobaldata('Id_Language',user.Id_Language || 1);
        this.setuserinfo(user);

        if(user.bTAAppInstalled == -1) this.pushnavigate('/home');
        else {

            if(process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget'){
          this.pushnavigate('/travelagentmessage');
          }

         if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
          this.pushnavigate('/home');
          }

        }


       
      }.bind(this),
      error: function(jsonresponse) {
        
        this.state.loading = false;
        this.state.messages.push(jsonresponse.errorMessage);
        this.forceUpdate();
       
      }.bind(this),

    };


    this.single(options);
  }


  render() {

    var user = this.state.user;
    var messages = this.state.messages;
    
    return (

       <section>
        {/* Start video hero */}
        <div className="video-hero jquery-background-video-wrapper demo-video-wrapper">
          <video className="jquery-background-video" autoPlay muted loop poster={this.image("video/totourav.jpg")}>
            <source src={this.image("video/totoura_v.mp4")} type="video/mp4" />
          </video>
          <div className="video-overlay" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                <div id="login">
                  <div className="text-center"><img src={this.image("img/logo_login_"+this.config().logo+".png")} alt="Image" data-retina="true" /></div>

                  
                  {process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget2' && process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget5'?
                  <p dangerouslySetInnerHTML={{__html:this.t("YOUR TRAVEL AGENT &<br /> {{brandname}} WELCOME YOU",{brandname:this.config().brandname.toUpperCase()})}} ></p>
                  :''}
                  

                    <div className="divider" />
                    <div className="form-group">
                      <label>{this.t("Language")}</label>
                      <Language className='form-control' header={false} />
                    </div>
                    <div className="form-group">
                      <label>{this.t("Email")}</label>
                      <input type="mail" autoComplete="off" className="form-control" placeholder={this.t("Enter Your Email")} onChange={this.changeemail.bind(this)} name='user.username' />
                     
                     <div className="text-center">
                     {!this.state.validemail && user.username != ''?<span>{this.t("Invalid email")}</span>:''}

                     {this.state.loading?<b>{this.t("Accessing...please wait")}</b>:''}
                     </div>
                     
                    </div>
                     
                     {/* <ErrorMessages messages={messages} /> */}

                    <button onClick={this.login.bind(this)} className="btn_logT">{this.t("Go")}</button>

                   

                    {this.state.messages.length>0?
                      <div>
                <div className="mt-3 text-warning">
                  <h6>{this.t("OOPS!")}</h6>



                  {process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget2' && process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget5'?
                  <p>
                  {this.t("That email is not in our system. Please try again or contact your travel agent to register you... No Agent? contact the concierge to get you one. In the meantime, you can browse our destinations.")}
                  </p>
                  :''}


                 {process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget2'?
                 <p>
                  {this.t("That email is not in our system. Please try again or contact  for help. In the meantime, you can browse our destinations.")}
                  </p>
                 :''}

                 {process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget5'?
                 <p>
                  {this.t("That email is not in our system. Please try again or contact  for help. In the meantime, you can browse our destinations.")}
                  </p>
                 :''}

                  

                </div>
                
                  <br/><hr/><br/>
                  <div className="text-center">
                   {this.t("Not registered?")}
                  <NavLink to="/home" className="btn_logT">{this.t("CLICK TO JUST BROWSE")}</NavLink>
                  </div>
               </div>
                  : ''
              }

                </div>

              

           {/*}     <div className="text_login">
                  <h6>{this.t("Mobile Application available!")}</h6>
                  <p>
                  {this.t("Don't miss our mobile app -- it's the way to get exclusives, even free calls home while vacationing.  Install the TOTOURA app from this site on your favorite mobile device and get your free features now!")}
                  </p>
                </div>
              */}


              {process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget2' && process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget5'?
                <a href="https://mobile-tagentpro.mobilesail.com/totouralogin.html" className="btn btn_bluelog">
                  {this.t("Professional Travel Agents")}
                  </a>
              :''}

              </div>
            </div>
          </div>
        </div>
        {/* End video hero */}


                 {/*  chat icon --*/}
                <a href="javascript:void(0)" className="chat_icon" onClick={this.openchat.bind(this)}>
                    <img src={this.image("img/chat_icon.png")} />
                </a>

      </section>

      
    );
  }
}

export default withRouter(login);


