import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import InputSearchHotel from './component.input.search.hotel';
import SearchTransfersResult from './component.search.transfers.result';
import Select from 'react-select';
import Global from '../globals/global';
import SearchDestinationGlobal from './component.search.destination';
import SearchHotelGlobal from './component.search.hotel';
//if (typeof window === "undefined")  var window={}
import autocomplete from 'autocompleter';

const $ = window.$;
//const autocomplete = window.autocomplete;

class SearchTransfers extends Global {

    constructor(props) {
        super(props);

        this.changeDestination = this.changeDestination.bind(this);
        this.changeHotel = this.changeHotel.bind(this);
        this.clickSearch = this.clickSearch.bind(this);
        this.handleHotel = this.handleHotel.bind(this);
        this.writehotelname = this.writehotelname.bind(this);
        this.clean = this.clean.bind(this);
        this.updateHotels = this.updateHotels.bind(this);

        this.state = {
            destinations: this.getglobaldata('destinations') || [],
            location: this.getglobaldata('Id_Location'),
            hotels: null,
            hotel: null,
            transfers: null,
            searching: false,
            hotelid: null,
            hotelname: '',
            allowedChars: new RegExp(/^[a-zA-Z\s]+$/), 
            defaultdestinationname:'',
            UpateHotelList:{}
        };
    }

    updateHotels(value){       
        this.setState({
            UpateHotelList:value
        })
    }

    componentDidMount() {


        this.getdispatcher('load.destinations',function(data){

            this.setState({
                    destinations: this.getglobaldata('destinations') || [],
                    location: this.getglobaldata('Id_Location'),
                }, function(){
                     this.setdefaults();
                }.bind(this));

        }.bind(this));

        
        window.addEventListener('selected.hotel.general', this.handleHotel);
    }

    setdefaults(){
      this.setautocomplete();
      var destination = this.getdefaultdestination();
      this.state.defaultdestinationname = destination?destination.Name:'';
      this.forceUpdate();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.destinations !== this.state.destinations) {
            const Id_Location = this.getglobaldata('Id_Location');
            this.setState({
                location: Id_Location,
            });
        }
        if (prevState.location !== this.state.location && this.state.location !== -1) {
            this.setState({ hotels: null });
            var options = {
                arraydata: 'hotels',
                method: 'GetHotels',
                query: {
                    Id_Destination: this.state.destination,
                    Id_Location: this.state.location
                },
                callback: function (data) {
                    this.setState({
                        hotels: data,
                        hotel: -1,
                        allowedChars: new RegExp(/^[a-zA-Z\s]+$/),
                    });
                }.bind(this)
            };
            this.all(options);
        }

        if (prevState.hotels !== this.state.hotels) {
            let hotels = this.state.hotels;
            const allowedChars = this.state.allowedChars;
            
            //se deshabilita este autocomplrete por que ya se hizo un componente global
            /*autocomplete({
                input: document.getElementById('search_tr1'),
                minLength: 1,
                onSelect: (item, inputfield) => {
                    inputfield.value = item.Name
                    this.state.hotelname = item.Name;
                    const detail = {
                        data: item.Id,
                        name: item.Name
                    };
                    var customEvent = new CustomEvent('selected.hotel.general', { detail: detail });
                    window.dispatchEvent(customEvent);
                },
                fetch: function (text, callback) {
                    var match = text.toLowerCase();
                    callback(hotels.filter(hotel => {
                        return hotel.Name.toLowerCase().indexOf(match) !== -1;
                    }));
                },
                render: function (item, value) {
                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(value, 'gi');
                        var inner = item.Name.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.label;
                    }
                    return itemElement;
                },
                emptyMsg: this.t("No Hotels found"),
                customize: function (input, inputRect, container, maxHeight) {
                    if(this.ismobile()){
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                        container.style.left = "5px";
                        container.style.right = "5px";
                        container.style.width = "";
                    }
                    else if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }.bind(this)
            });*/


        }/*
        if (prevState.hotelname !== this.state.hotelname) {
            var customEvent = new CustomEvent('selected.hotel.general', {
                detail: {
                    data: this.state.hotelid,
                    name: this.state.hotelname
                }
            });
            window.dispatchEvent(customEvent);
        }*/
    }

    changeDestination(data) {

        this.resetlocationandfilters(data.Id_Location);
        this.resetdestinationandfilters(data.Id_Destination);

        this.setState({ location: data.Id_Location,defaultdestinationname:data.Name });
    }

    changeHotel(event) {
        this.setState({
            hotel: event.target.value,
            transfers: null,
        });
    }

    clickSearch() {

        ///////////////anterior
        this.navigate(`search/transfers/${this.state.location}/${this.state.hotel}`)
        
        ///////////////nuevo
        this.navigate(`search/transfers/${this.state.location}/${this.getglobaldata('Id_Hotel')}`)

     /*   if (location.host != 'localhost:3000') {
            window.location.href = `search/transfers/${this.state.location}/${this.state.hotel}`;
        } else {
            window.location.href = `/search/transfers/${this.state.location}/${this.state.hotel}`;
        }*/
    }

    handleHotel(event) {
        this.setState({
            hotel: event.detail.data,
            hotelname: event.detail.name
        });
    }

    writehotelname(event) {
        console.log(this.state.defaultdestinationname)
        this.setState({ hotelname: event.target.value });
    }

    clean() {
        const detail = {
            data: -1,
            name: '',
        };
        var customEvent = new CustomEvent('selected.hotel.general', { detail: detail });
        window.dispatchEvent(customEvent);
        this.setState({
            hotelname: ''
        });
    }

    getdefaultdestination(){
        return this.state.destinations.find(destination => {return destination.Id_Location === this.state.location;});

    }


    setautocomplete(){
            /*let destinations = this.state.destinations;
            const allowedChars = this.state.allowedChars;
            autocomplete({
                input: document.getElementById('destinations_autocomplete3'),
                minLength: 1,
                onSelect: (item, inputfield) => {
                    inputfield.value = item.Name;
                    $('.autocomplete').hide();
                    this.changeDestination(item);
                },
                fetch: function (text, callback) {
                    var match = text.toLowerCase();
                    callback(destinations.filter(destination => {
                        return destination.label.toLowerCase().indexOf(match) !== -1;
                    }));
                },
                render: function (item, value) {
                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(value, 'gi');
                        var inner = item.label.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.label;
                    }
                    return itemElement;
                },
                emptyMsg: this.t("No Destination found"),
                customize: function (input, inputRect, container, maxHeight) {
                    if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }
            });*/
    }

    writecity(event) {
        $('.autocomplete').show();
        this.setState({ defaultdestinationname: event.target.value });
        
    }

    cleandestination(){
      this.setState({ defaultdestinationname: ''});
    }


    render() {
        return (
            this.state.destinations !== null ? (
                <div className="tab-pane" id="transfers">
                    <h3>{this.t('Find your next experience')}</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="select-label">{this.t('Destination')}</label>

                                 <form className='input-group'>
                                 <SearchDestinationGlobal identifierSearch={"_2"} sendItem={(value)=>{this.updateHotels(value)}}/>
                                    {/*<input value={this.state.defaultdestinationname} onChange={this.writecity.bind(this)} id="destinations_autocomplete3" type="text" placeholder={this.t("Type your City")} autoComplete="off" className='form-control input_tour' />
                                    <div className='input-group-append inp_appet'>
                                    <button className="btn btn-outline-secondary btn_clearb" type="button"><i className="icon-cancel" onClick={this.cleandestination.bind(this)}></i></button>
                                    </div>*/}
                                </form>


                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="select-label">{this.t('Hotel Name')}</label>
                                <InputSearchHotel  origin="none" handle={()=>{}} reUpdate={this.state.UpateHotelList} identifierSearch={"_2"}/>
                                
                                {/*<form className="input-group input_cleart">
                                    
                                    <input id="search_tr1" type="text" placeholder={this.t("Type your Hotel Name")}
                                        autoComplete="off" value={this.state.hotelname} onChange={this.writehotelname}
                                        className="form-control input_tour" />
                                    <div className="input-group-append inp_appet">
                                        <button className="btn btn-outline-secondary btn_clearb" type="button"><i className="icon-cancel" onClick={this.clean}></i></button>
                                    </div>
                                </form>*/}


                                {/*<select className="form-control" onChange={this.changeHotel} value={this.state.hotel}>
                                    <option value="-1" disabled>{this.t('Select')}</option>
                                    {
                                        this.state.hotels !== null ? (
                                            this.state.hotels.map((hotel, index) => {
                                                return (
                                                    <option key={`hotel.search-${index}`} value={hotel.Id}>{hotel.Name}</option>
                                                )
                                            })
                                        ) : null
                                    }
                                </select>*/}
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label><i className="icon-calendar-7"></i> Date</label>
                                <input className="date-pick form-control" data-date-format="M d, D" type="text" />
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3">
                            <div className="form-group">
                                <label>Adults</label>
                                <div className="numbers-row">
                                    <input type="text" value="1" id="adults" className="qty2 form-control" name="quantity" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-9">
                            <div className="form-group">
                                <div className="radio_fix">
                                    <label className="radio-inline" style={{ paddingLeft: 0 }}>
                                        <input type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked /> One Way
                                                </label>
                                </div>
                                <div className="radio_fix">
                                    <label className="radio-inline">
                                        <input type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" /> Return
                                                </label>
                                </div>
                            </div>
                        </div>
            </div> */}
                    <hr />
                    <button className="btn_searchn" onClick={this.clickSearch}><i className="icon-search"></i>{this.t('Search now')}</button>
                    {
                        this.state.transfers !== null ? (
                            this.state.searching ? (<div>{this.t('Searching...')}</div>) : (
                                this.state.transfers.length > 0 ? (
                                    this.state.transfers.map((transfer, index) => {
                                        return (
                                            <SearchTransfersResult key={`tour_container-${index}`} data={transfer} />
                                        )
                                    })
                                ) : <div>{this.t('No matches were found in the search!')}</div>
                            )
                        ) : null
                    }
                </div>
            ) : null
        )
    }
}

export default withRouter(SearchTransfers)