import React, { Component } from 'react';
import Section from '../components/component.section';
import ReviewCard from '../components/component.review.card';
import { NavLink,withRouter,Redirect } from "react-router-dom";
import Global from '../globals/global';
const $ = window.$;

class TrendingReviews extends Global {

    constructor(props) {
        super(props);

        this.state = {
            trendingTours: null,
            trendings: null,
        };
    }

    componentDidMount() {
       
        const Id_Destination = this.getglobaldata('Id_Destination');
        const Id_Location = this.getglobaldata('Id_Location');
        const Id_Language = this.getglobaldata('Id_Language');
        const Id_Currency = this.getglobaldata('Id_Currency');

        var options = {
            arraydata: 'trendingTours',
            method: 'GetTrendingTours',
            query: {
                isFullInfo: true,
                destination: Id_Destination !== null ? Id_Destination : '',
                location_list: Id_Location !== null ? (Id_Location === -1 ? '' : Id_Location) : '',
                Id_Language: Id_Language,
                Id_Currency: Id_Currency
            },
            callback: function (data) {
                this.setState({
                    trendingTours: data,
                });
            }.bind(this)
        };
        this.all(options);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.trendingTours !== this.state.trendingTours) {
            const Id_Destination = this.getglobaldata('Id_Destination');
            this.setState({
                trendings: this.state.trendingTours.filter(trendingTour => trendingTour.Id_Destination === Id_Destination),
            });
        }
    }

    render() {

        if(!this.config().trending) return (<Redirect to='/tours/1'></Redirect>)

        return (
            <div>
                <Section origin="trending.reviews" />

                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/tours/1">{this.t("Tours")}</NavLink></li>
                                <li>{this.t('Trending & Reviews')}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position  */}

                    <div className="container margin_60">
                        {
                            this.state.trendings !== null ? (
                                this.state.trendings.map((trending, index) => {
                                    return (
                                        <ReviewCard key={`ReviewCard-${index}`} data={trending} index={index} />
                                    )
                                })
                            ) : <div>{`${this.t('Loading...')}`}</div>
                        }
                    </div>
                    {/* End container  */}

                    <div id="overlay"></div>
                    {/* Mask on input focus  */}
                </main>
                {/* End main  */}
            </div>
        )
    }
}

export default TrendingReviews