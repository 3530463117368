import React,{Component} from "react";
import ToursList from "../components/component.tours.list";
import { NavLink,withRouter,Redirect } from "react-router-dom";
import Section from '../components/component.section';
import DestinationSelector from "../components/component.destination.selector";
import ShopsList from "../components/component.shops.list";
import Global from '../globals/global';
const $ = window.$;

class dining extends Global {
  constructor(props) {
    super(props);

    var currencyid = this.getglobaldata('Id_Currency');
    var languageid = this.getglobaldata('Id_Language');
    var destinationid = this.getglobaldata('Id_Destination');
    var locationid = this.getglobaldata('Id_Location');

    if (this.props.match) {
      if (this.props.match.params) {
       
        if (this.props.match.params.language)
          languageid =
            this.props.match.params.language.toLowerCase() == "en" ? 1 : 2;
        if (this.props.match.params.destinationid)
          destinationid = this.props.match.params.destinationid;

        if (this.props.match.params.currency) {
          if (this.props.match.params.currency.toLowerCase() == "mxn")
            currencyid = 2;
          if (this.props.match.params.currency.toLowerCase() == "cad")
            currencyid = 3;
        }
      }
    }

    this.state = {
      shops: [],
      languageid: languageid,
      destinationid: destinationid,
      totalshops: 0,
      loading:false
    };
  }

  componentDidMount() {
    //this.starttours();
  }

  componentWillMount() {
    this.getshops();
  }

  getshops() {
     this.state.loading = true;
     this.forceUpdate();

    var options = {
      arraydata: "shops", 
      method: "GetFullShopsInfo",
      query: {
        Id_Language: this.state.languageid,
        id_destination: this.state.destinationid,
        location_list:this.state.locationid,
       // ShopFilterType:1,
        id_category:this.props.category || 2 //dining
      },
      callback: function(shops, jsonresponse) {
        
        this.state.totalshops = shops.length;
        this.state.loading = false;
       
      }.bind(this)
    };


    this.all(options);
  }


  render() {
    
    var shops = this.state.shops;

    if(!this.config().dining) return (<Redirect to='/tours/1'></Redirect>)
   

    return (

       <div>

       <Section origin={this.props.origin || 'dining'} />

        {/* End section */}
        <main>
          <div id="position">
            <div className="container">
              <ul>
                <li><NavLink to="/home">{this.t("Home")}</NavLink>
                </li>
                <li>{this.t("Dining & Shopping")}</li>
              </ul>
            </div>
          </div>
          {/* End Position */}
          <div className="container margin_60">
            <div className="card">
              <div className="card-body bor_t">
                {/*TOUR & ACTIVITIES */}
                <div className="row">
                  <div className="col-lg-12">

                    {this.state.loading?
                      <span className="found_s">(<b>{this.t("Loading...")}</b>)</span>
                      :
                      <span className="found_s">({this.state.totalshops} {this.t("founds")})</span>   
                    }
                    

                    <DestinationSelector origin='page' text={this.props.text || this.t("Dining options in")} />

                  </div>
                </div>
              </div>{/*end card-body*/}
            </div>{/*end card*/}
            <div className="row">
              <div className="col-lg-12">
                <div className="main_title">
                </div>
              </div>

           
            <ShopsList shops={shops} />

             
            </div>
            {/* End row */}
          </div>
          {/*End container */}
          <div id="overlay" />
          {/* Mask on input focus */}
        </main>
        {/* End main */}
      </div>
      
    );
  }
}

export default dining


