import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/CheckoutForm";

import StripeAPI from "./api/StripeAPI";
import "./index.css";
import "./App.css";
var config = require('../configs/config');

let locale = 'en';

if(Number(getglobaldata('Id_Language')) == 2) locale = 'es';
if(Number(getglobaldata('Id_Language')) == 3) locale = 'fr';

const stripePromise = StripeAPI.getStripePublicKey().then(key => loadStripe(key,{locale:locale}));  

export default function App(props) {
  return (
    <div className="">

          <img
                            src={"https://cdn.mobilesail.com/reactproject/img/cards_2.png"}
                            width={207}
                            height={43}
                            alt='cards'
                            className="cards"
                          />
        
          <Elements stripe={stripePromise}>
            <CheckoutForm cartParams="parametros carrito" {...props} /> 
          </Elements>
    
    </div>
  );
}


function getglobaldata(varname_) {

  let brand = config.brand;

  if (!localStorage.getItem("globaldata_"+brand))
    localStorage.setItem("globaldata_"+brand, JSON.stringify({}));

  var globaldata = JSON.parse(localStorage.getItem("globaldata_"+brand));
  var customvar = varname_ ? eval("globaldata." + varname_) : globaldata;

  return customvar;
}
