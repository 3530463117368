import React, { Component } from 'react';
import IconWishlist from './component.icon.wishlist';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class SpecialCard extends Global {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
        });
    }



    render() {
       if(process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget'){
         return (
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                {
                    this.state.data !== null ? (
                        <div className="tour_container">
                            {this.state.data.ActivityDetails.isTopRated === '-1' ? <div className="ribbon_3"><span>{this.t('Top Rated')}</span></div> : null}

                            <div className="img_container">
                                <a href={"tourdetails/"+this.state.data.ActivityDetails.friendlyurl+"/"+this.state.data.ActivityDetails.Id}>
                                    <img src={this.state.data.ActivityDetails.Images.TabletThumbImages[Math.floor(Math.random() * this.state.data.ActivityDetails.Images.TabletThumbImages.length)]}
                                        width="800" height="533" className="img-fluid" alt="image" />
                                    {
                                        this.state.data.ActivityDetails.Price !== this.state.data.ActivityDetails.NormalPrice ? (
                                            <div className="short_info">
                                                <span className="price_offer">
                                                    <small className="dollar_text"> {this.t('FROM')} </small>
                                                    <sup>$</sup>
                                                    {this.state.data.ActivityDetails.Price}
                                                    <small className="dollar_text"> {this.t('NOW')}</small>
                                                </span>
                                                <span className="price cross">
                                                    <sup>$</sup>
                                                    {this.state.data.ActivityDetails.NormalPrice}
                                                    <small className="dollar_text"> {this.state.data.ActivityDetails.CurrencyCode}</small>
                                                </span>
                                            </div>
                                        ) : (
                                                <div className="short_info">
                                                    <span className="price">
                                                        <small className="dollar_text"> {this.t('FROM')} </small>
                                                        <sup>$</sup>
                                                        {this.state.data.ActivityDetails.Price}
                                                        <small className="dollar_text"> {this.state.data.ActivityDetails.CurrencyCode}</small>
                                                    </span>
                                                </div>
                                            )
                                    }
                                    {Number(this.state.data.ActivityDetails.OfferAvailable) == -1?<i className="icon-star star_i"><span>{this.t("Special")}</span></i>:''}
                                </a>
                            </div>
                            <div className="tour_title">
                                <h3><strong>{this.state.data.ActivityName}</strong></h3>
                                <p>{this.t('From')}  {this.state.data.LocationName}</p>
                         
                               <div className="d-flex justify-content-between">

                                <div className="icon_bottm">
                    <a href={"tourdetails/"+this.state.data.ActivityDetails.friendlyurl+"/"+this.state.data.ActivityDetails.Id}>
                      <i className="icon-thumbs-up" />
                      {this.t("Review")}
                    </a>
                     </div>

                                <div className="icon_bottm">
                                        <a href={"tourdetails/"+this.state.data.ActivityDetails.friendlyurl+"/"+this.state.data.ActivityDetails.Id}><i className="icon-info-circled"></i>{this.t('Details')} </a>
                                    </div>

                                    <div className="">
                                <div className="special_icon">
                                    <i className="icon-star-filled"></i>
                                    <span>{this.t('Special')}</span>
                                </div>
                                </div>

                                    </div>

                                <hr />

                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <i className="icon-clock"></i>
                                        {` ${this.state.data.ActivityDetails.hDuration}h ${this.state.data.ActivityDetails.mDuration}m`}
                                    </div>

                                    <div className="icon_bottm_cart ">
                                    <NavLink to={"/tours/availability/"+this.state.data.ActivityDetails.Id+"/add"} className="cart_pop"><i className="icon-basket" /> {this.t("Add Cart")} </NavLink>
                                </div>


                                  
                                </div>

                                <div className="row">
                                    <div className="col-12 no_pad title_save">
                                        <h6>{this.t('Savings & Smiles')}</h6>
                                    </div>

                                    <div className="col-12">
                                        <span className="color_red"><strong>{this.state.data.ShortDescription}</strong></span>
                                        <p>{this.state.data.LongDescription}</p>
                                    </div>
                                </div>
                                {/* end rating */}
                                 <IconWishlist InWishlist={this.state.data.ActivityDetails.InWishlist} tourid={this.state.data.ActivityDetails.Id} tourinfo={this.state.data.ActivityDetails} />
                                {/* End wish list */}
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
       }


         if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
        return (
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                {
                    this.state.data !== null ? (
                        <div className="tour_container">
                            {this.state.data.ActivityDetails.isTopRated === '-1' ? <div className="ribbon_3"><span>{this.t('Top Rated')}</span></div> : null}

                            <div className="img_container">
                                <NavLink to={"/tourdetails/"+this.state.data.ActivityDetails.friendlyurl+"/"+this.state.data.ActivityDetails.Id}>
                                    <img src={this.state.data.ActivityDetails.Images.TabletThumbImages[Math.floor(Math.random() * this.state.data.ActivityDetails.Images.TabletThumbImages.length)]}
                                        width="800" height="533" className="img-fluid" alt="image" />
                                    {
                                        this.state.data.ActivityDetails.Price !== this.state.data.ActivityDetails.NormalPrice ? (
                                            <div className="short_info">
                                                <span className="price_offer">
                                                    <small className="dollar_text"> {this.t('FROM')} </small>
                                                    <sup>$</sup>
                                                    {this.state.data.ActivityDetails.Price}
                                                    <small className="dollar_text"> {this.t('NOW')}</small>
                                                </span>
                                                <span className="price cross">
                                                    <sup>$</sup>
                                                    {this.state.data.ActivityDetails.NormalPrice}
                                                    <small className="dollar_text"> {this.state.data.ActivityDetails.CurrencyCode}</small>
                                                </span>
                                            </div>
                                        ) : (
                                                <div className="short_info">
                                                    <span className="price">
                                                        <small className="dollar_text"> {this.t('FROM')} </small>
                                                        <sup>$</sup>
                                                        {this.state.data.ActivityDetails.Price}
                                                        <small className="dollar_text"> {this.state.data.ActivityDetails.CurrencyCode}</small>
                                                    </span>
                                                </div>
                                            )
                                    }
                                    {Number(this.state.data.ActivityDetails.OfferAvailable) == -1?<i className="icon-star star_i"><span>{this.t("Special")}</span></i>:''}
                                </NavLink>
                            </div>
                            <div className="tour_title">
                                <h3><strong>{this.state.data.ActivityName}</strong></h3>
                                <p>{this.t('From')}  {this.state.data.LocationName}</p>
                         
                               <div className="d-flex justify-content-between">

                                <div className="icon_bottm">
                    <NavLink to={"/tourdetails/"+this.state.data.ActivityDetails.friendlyurl+"/"+this.state.data.ActivityDetails.Id}>
                      <i className="icon-thumbs-up" />
                      {this.t("Review")}
                    </NavLink>
                     </div>

                                <div className="icon_bottm">
                                        <NavLink to={"/tourdetails/"+this.state.data.ActivityDetails.friendlyurl+"/"+this.state.data.ActivityDetails.Id}><i className="icon-info-circled"></i>{this.t('Details')} </NavLink>
                                    </div>

                                    <div className="">
                                <div className="special_icon">
                                    <i className="icon-star-filled"></i>
                                    <span>{this.t('Special')}</span>
                                </div>
                                </div>

                                    </div>

                                <hr />

                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <i className="icon-clock"></i>
                                        {` ${this.state.data.ActivityDetails.hDuration}h ${this.state.data.ActivityDetails.mDuration}m`}
                                    </div>

                                    <div className="icon_bottm_cart ">
                                    <NavLink to={"/tours/availability/"+this.state.data.ActivityDetails.Id+"/add"} className="cart_pop"><i className="icon-basket" /> {this.t("Add Cart")} </NavLink>
                                </div>


                                  
                                </div>

                                <div className="row">
                                    <div className="col-12 no_pad title_save">
                                        <h6>{this.t('Savings & Smiles')}</h6>
                                    </div>

                                    <div className="col-12">
                                        <span className="color_red"><strong>{this.state.data.ShortDescription}</strong></span>
                                        <p>{this.state.data.LongDescription}</p>
                                    </div>
                                </div>
                                {/* end rating */}
                                 <IconWishlist InWishlist={this.state.data.ActivityDetails.InWishlist} tourid={this.state.data.ActivityDetails.Id} tourinfo={this.state.data.ActivityDetails} />
                                {/* End wish list */}
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )

    }


    }



}

export default withRouter(SpecialCard)