import React, { Component } from 'react';
import IconWishlist from './component.icon.wishlist';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class TourCard extends Global {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            tour_review: null,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }

        if (prevState.data !== this.state.data) {
            if (this.state.data.tour_reviews !== null && this.state.data.tour_reviews.length > 0) {
                this.setState({
                    tour_review: this.state.data.tour_reviews[0],
                });
            }
        }
    }

    render() {
        return (
            (this.state.data !== null && this.state.tour_review !== null) ? (
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                    <div className="tour_container">
                        {
                            this.state.data.isTopRated === '-1' ? (<div className="ribbon_3"><span>{this.t("Top Rated")}</span></div>) : null
                        }
                        <div className="img_container">
                            <a href={"tourdetails/"+this.state.data.friendlyurl+"/"+this.state.data.Id}>
                                <img src={this.state.data.Images.TabletThumbImages[0]} width={800} height={533} className="img-fluid" alt="image" />
                                {
                                    this.state.data.Price !== this.state.data.NormalPrice ? (
                                        <div className="short_info">
                                            <span className="price_offer">
                                                <small className="dollar_text"> {this.t("FROM")} </small>
                                                <sup>$</sup>
                                                {Math.round(this.state.data.Price)}
                                                <small className="dollar_text"> {this.t("NOW")}</small>
                                            </span>
                                            <span className="price cross">
                                                <sup>$</sup>
                                                {Math.round(Number(this.state.data.NormalPrice))}
                                                <small className="dollar_text"> {this.state.data.CurrencyCode}</small>
                                            </span>
                                        </div>
                                    ) : (
                                            <div className="short_info">
                                                <span className="price">
                                                   <small className="dollar_text"> {this.t("FROM")} </small>
                                                    <sup>$</sup>
                                                    {Math.round(Number(this.state.data.Price))}
                                                    <small className="dollar_text"> {this.state.data.CurrencyCode}</small>
                                                </span>
                                            </div>
                                        )
                                }
                                {Number(this.state.data.OfferAvailable) == -1?<i className="icon-star star_i"><span>{this.t("Special")}</span></i>:''}
                            </a>
                        </div>
                        <div className="tour_title">
                            <h3><strong>{this.state.data.Name}</strong></h3>
                            <p>{this.t("From")} {!this.state.data.LocationName?this.state.data.DestinationName:this.state.data.LocationName}</p>
                            <p>{this.state.tour_review.Description} </p>
                            <p>{this.state.tour_review.UserName}, {this.state.tour_review.State}, {this.state.tour_review.Country}</p>

                            <div className="d-flex justify-content-between">
                            <div className="icon_bottm">
                                    <a href={"tourdetails/"+this.state.data.friendlyurl+"/"+this.state.data.Id}><i className="icon-info-circled" />{this.t("Details")} </a>
                                </div>
                               
                                
                      <div className="icon_bottm">
                    <a href={"tourdetails/"+this.state.data.friendlyurl+"/"+this.state.data.Id}>
                      <i className="icon-thumbs-up" />
                      {this.t("Review")}
                    </a>
                     </div>
                     </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <div className=""><i className="icon-clock" /> {`${this.state.data.hDuration}h ${this.state.data.mDuration}m`}</div>
                                
                                <div className="icon_bottm_cart ">
                                    <NavLink to={"/tours/availability/"+this.state.data.Id+"/add"} className="cart_pop"><i className="icon-basket" /> {this.t("Add Cart")} </NavLink>
                                </div>
                            </div>
                            {/* end rating */}
                            <IconWishlist InWishlist={this.state.data.InWishlist} tourid={this.state.data.Id} tourinfo={this.state.data} />
                            {/* End wish list*/}
                        </div>
                    </div>
                    {/* End box tour */}
                </div>
            ) : null
        )
    }
}

export default withRouter(TourCard)