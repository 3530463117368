import React,{Component} from "react";
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class creditcardtypes extends Global {
  constructor(props) {
    super(props);
    this.state = {
      creditcardtypes:[],
      value:-1
    };
  }

  componentWillMount() {
    this.getcreditcardtypes();
  }

  getcreditcardtypes(){
    var options = {
      method:'GetCreditCardNames',
      callback:function(data){
       this.state.creditcardtypes =data;// this.config().brand=='viajeypunto'?data.filter(cardtype => {return cardtype.Id == 1 || cardtype.Id == 2}):data;

       this.forceUpdate();
      }.bind(this)
    }

    this.all(options);
  }

  onChange(event) {
    let value = event.target.value;
   // console.log('event_',event_)
    // var target = $(event_.target);
    // var value = target.val();
    this.setState({value:value},function(){
     this.props.onChange(value);
    }.bind(this)
    );

    
  }

  render() {

    var options = {
      array:this.state.creditcardtypes,
      valuename:'Id',
      textname:'Name'
    }

    return (
      <select
        value={this.state.value}
        onChange={this.onChange.bind(this)}
        className="form-control"
        name={this.props.name}
      >
        <option disabled="disabled" value={-1}>
          {this.t("Select credit card type")}
        </option>
        
        {this.selectoptions(options)}
      </select>
    );
  }
}

export default withRouter(creditcardtypes)
