import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class sunexprivacy extends Global {
    render() {
        return (
            

   <div className="row">
            <div className="col-lg-12">
                <h3 className="blue_text"><strong>Privacy Policy.</strong> </h3>

                </div>
                
                <div className="col-lg-12">

                <h2 className="terms_title mt-15">Sun Excursions Website and Mobile Application</h2>

                <p className="text-justify">
                    Effective Date: January 1<sup>st</sup>, 2017
                </p>

                <p className="text-justify">
                    Sun Excursions Caribbean, Inc. (hereinafter "Sun" or "We") privacy policy contains information about collecting users' personal data, products and services offered or performed by Sun or its related entities and the manner of its processing. We respect the right to privacy of our software users (hereinafter "User(s) or "You") and take reasonable steps for the user to be familiar with the manner in which rendered accessible information via our website and mobile application is processed. Due to the implementation of comprehensive security regulations, personal data provided by our website and mobile application users is of highest security standards. The acceptance of terms and conditions for the use of any services or deciding on using or purchasing any product offered by Sun or its related entities, means accepting Sun Privacy Policy hereof.
                </p>

                <h3 className="terms_title mt-15">Provided data</h3>

                <p className="text-justify">
                    Collected by Sun, personal data and other user's information is used in order to complete user registration, to process online excursion sales and at the same time to ensure access to Sun services and products. While using our mobile software system we may request that you provide us with certain personal information, without limitation, the user's name, email address and other contact information, travel information such as tourist destination, hotel, arrival and departure dates, departure airlines, flight numbers, and optionally children´s age and possible interests you may want information and offers on which you can opt out of receiving at any time. Some of the provided user's information such as: your IP address, domain, browser type, operation system type, mobile phone specifications, may be automatically acquired when a user  uses the mobile app. When contacting Sun, the user may be requested to render accessible analogical data to us in order to complete transactions. Personal data provided in connection with the correspondence between the user and Sun shall be used strictly to reply to inquiries made by the user or to transfer information. We use the information you provide us  for the purposes of managing access to your mobile app account, processing online excursion sales and other products or services as offered by Sun, processing reservations and operating with third party suppliers the rendering of services or goods you purchase from Sun. 
                </p>

                <p className="text-justify">
                    Sun may also use your personal information to send you push notifications or other direct communications about products or services that Sun believes may be of interest to you per your customized preferences under the "wish list" section of the website or app. Nonetheless, users may eliminate customized push notifications should they opt not to construct a "wish list" and then receive only general offers and alerts.
                </p>

                <p className="text-justify">
                    When using our mobile application we may collect device information such as device type, operating system type and application version. We use this information in order to provide an optimized version of our application for your device type.
                </p>

                <h3 className="terms_title mt-15">Geo-Location Data</h3>

                <p className="text-justify">
                    We do not ask you for, access or track any location based information from your mobile device at any time while downloading or using our mobile app or services. Nonetheless, we will request the name of hotel where you will be staying at the tourist destination of your choice and Sun will use this information to process your online purchases for routing and pickup times for transfers or tours as well as send you the most updated offers and promotions of excursions or other goods and services most conveniently available from your hotel.
                </p>

                <h3 className="terms_title mt-15">Third Party Information</h3>

                <p className="text-justify">
                 <strong>- Contacts and Microphone Activations</strong> <br />
                    We allow you to provide us with third party personal data in the form of your contacts if you choose to and activating your microphone after downloading the app.  This will allow you to conveniently make calls to speak with our concierge assistants as well as provide you the possibility of making calls from destination to the USA and CANDIAN numbers in your contacts, or if you opt to not provide contacts, manually dialing numbers instead.  You may opt to use our online CHAT to contact the concierge assistants in addition to making calls and speaking with them.  The information that you provide and features you opt in to use is solely for the purposes described. We will not use this information for any other purpose. 
                </p>

                <h3 className="terms_title mt-15">Children personal data</h3>

                <p className="text-justify">
                    Sun shall take precautions to ensure the highest security level of all information and all personal data provided using our services and products. We do not knowingly collect personal information from anyone under the age of 18.
                </p>

                <p className="text-justify">
                    When a user's age of children are provided in their profile by their agent it is for the purpose of making sure a specific activity is conditionally available to a person of that age, and the eligibility for any special offers and rates to be accessible for persons of that age. 
                </p>

                <p className="text-justify">
                    The name, gender, date of birth or other minor's personal information is not required. 
                </p>

                <p className="text-justify">
                    Users  may also choose not disclose children's ages until the time of purchase, but must do so at that time, in order to make certain the child's age will not disallow participation in an activity and correct rate is charged, otherwise Sun will be relieved of responsibilities.
                    <br />
                    Users  may also choose not disclose children's age.
                </p>

                <h3 className="terms_title mt-15">Data disclosure</h3>

                <p className="text-justify">
                    We do not sell your personal data to third parties. Sun shall transfer no information or personal data to third parties for marketing purposes.
                </p>

                <p className="text-justify">
                    Sun's main purpose of gathering personal data and users' other information is to  operate, maintain, and provide to you the features and functionality of the Mobile Concierge website and app.
                </p>

                <p className="text-justify">
                    Personal data and other information provided by users shall not be disclosed to third parties unless necessary to further process your excursion or other service purchases or unless the obligation to disclose the information to third parties results from the currently effective provisions of law, such as to comply with a subpoena, or similar legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request, or if it is necessary for  Sun´s security and its rights protection, including the protection against the claims submitted by the third parties. The provided user's information may be utilized in the event of breach of terms and conditions for the use of services, misuse of funds, the necessity to take action against all user's unlawful actions. Sun shall transfer no information or personal data to third parties for marketing purposes.
                </p>

                <p className="text-justify">
                    Sun reserves the right to use the provided data for communication purposes and other purposes related with your purchase and confirmations as well as to send you push notifications per your customized "wish list".
                </p>

                <p className="text-justify">
                    Sun reserves the right to disclose personal data and other information relating to the user to third parties suppliers or affiliated with Sun connected with providing services and its current business activity, such as payment processing, executing or managing excursion packages, processing restaurant reservations, providing customer support and sending emails out on our behalf. Sun shall observe due diligence to make sure for the processing of data disclosed to entities affiliated with Sun to be in compliance with our privacy policy and the currently effective law.
                </p>

                <p className="text-justify">
                    Credit/payment card data provided by the user are disclosed solely to professional companies that conduct non-cash transactions and only to the extent necessary to effect the payments. 
                    <br />
                    Except for the above situations Sun shall not disclose any information or personal data provided by users.
                    <br />
                    If we are involved in a merger, acquisition, or sale of all or a portion of our assets, you will be notified via email and/or prominent notice on our Web site of any change in ownership or uses of your personal data, as well as any choices you may have regarding your personal data
                </p>

                <h3 className="terms_title mt-15">Mobile Analytics</h3>

                <p className="text-justify">
                    We use online and mobile analytics software to allow us to better understand and improve the functionality of our systems. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. We do not link the information we store within the analytics software to any personal data you submit within the mobile app.
                </p>

                <h3 className="terms_title mt-15">Surveys</h3>

                <p className="text-justify">
                    From time-to-time we may provide you the opportunity to participate in a survey. If you participate, we will require your name and email address. Participation in these surveys is completely voluntary and you therefore have a choice whether or not to participate and disclose this information.
                </p>

                <h3 className="terms_title mt-15">Data protection</h3>

                <p className="text-justify">
                    Sun applies technical safety measures of the highest standards to protect the provided data against loss, destruction, misuse, unauthorized access or disclosure. The used measures and technology ensure complete safety of the data provided by the user. The data is available solely to the user or to a person indicated by him provided authorized access has been granted to that person. You control who you allow access to the app by  disclosing your account password.
                </p>

                <p className="text-justify">
                    The security of your personal data is important to us. We follow generally accepted industry standards to protect the personal data submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security.
                </p>

                <p className="text-justify">
                    Sun takes all necessary steps to update and modernize data protection system. No data transmission via the Internet, however, can guarantee 100% safety.
                </p>

                <p className="text-justify">
                    When you enter sensitive information (such as credit card number) on our Web site or within our mobile application, we encrypt the transmission of that information using secure socket layer technology (SSL) This way no one can read, interpret or access this data without the appropriates algorithms to decode them.  
                </p>

                <h3 className="terms_title mt-15">Privacy policy modification</h3>

                <p className="text-justify">
                    We may update this privacy statement to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice either on our Web Site or on the Mobile App interphase prior to the change becoming effective. We encourage you to periodically review this  policy for the latest information on our privacy practices.
                </p>

                <h3 className="terms_title mt-15">Data gathering entity</h3>

                <p className="text-justify">
                    Conducting its activity, under the business name of Sun Excursions, with its registered office: The Accountants Center, River Road, St. Michael, Barbados, shall be the entity gathering personal data and other information.
                </p>

                <h3 className="terms_title mt-15">Commercial transactions</h3>

                <p className="text-justify">
                    Sun reserves the right to use its privacy policy, user's account and provided data and information for the benefit of an entity that has become either a dependent entity, an associated entity, or has come into being as a result of merger or transformation of Sun.
                </p>

                <h3 className="terms_title mt-15">Access, Amendment, Deactivation and Opposition.</h3>

                <p className="text-justify">
                    If your personal data changes, or if you no longer desire our service, you may access, amend, update, delete inaccuracies or request deactivation of your account by making the change within the "Edit Account" section of the Mobile Concierge application or by emailing us at <a href="mailto:privacypolicy@sunexcursions.com">privacypolicy@sunexcursions.com</a>. You may also contact Sun to oppose on the use of your personal information, provided, however, Sun may not be able to continue providing you the services and functionalities of the website or Sun application. We will respond to your privacy policy requests within 30 days. Should you request deactivation of your Sun app account Sun undertakes to have all your personal data deleted in its records. All users are encouraged to contact Sun at the email address cited above to obtain further information on the applicable procedures to access, amend, update, delete inaccuracies, deactivate your account or oppose to the use of your personal data.
                </p>

                <h3 className="terms_title mt-15">Terms and conditions of use</h3>

                <p className="text-justify">
                    Using offered products and services rendered by Sun the user is obliged to comply with rules contained in the terms and conditions available on our Terms of Use sections of the website and mobile application.
                </p>

                <h3 className="terms_title mt-15">Contact</h3>

                <p className="text-justify">
                    Should you have any inquiries or doubts regarding our privacy policy or applied procedures feel free to contact us at <a href="mailto:privacypolicy@sunexcursions.com">privacypolicy@sunexcursions.com</a>.
                </p>
            </div>
        </div>

        )
    }
}

export default withRouter(sunexprivacy)