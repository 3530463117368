import React, { Component } from "react";
import TrendingTour from "../components/component.trending.tour";
//import HomeDestinations from "../components/component.home.destinations";
import InputSearchHotel from "../components/component.input.search.hotel";
import { NavLink, withRouter } from "react-router-dom";
import SearchTours from "../components/component.search.tours";
import SearchTransfers from "../components/component.search.transfers";
import SearchAllTours from "../components/component.search.alltours";
import Search from "../components/component.search";
import PopularDestinations from "../components/component.destinations.popular";
import Global from "../globals/global";

//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;

class Home extends Global {
  constructor(props) {
    super(props);
    this.state = {
      tours: [],
    };
  }

  componentDidMount() {
    this.rondombanner();

    let url_hash = "";
    if (location.hash != "") {
      url_hash = location.hash.replace("#/", "");
      this.navigate(url_hash);
    }
  }

  changehotel(event) {
    if (event.origin === "select") this.navigate("transfers");
  }

  

  rondombanner() {
    if (!this.config().homebannerrandom) return;

    let image = this.image("img/home_1.jpg"); //default

    let europeimage = this.image("img/home_europe.jpg");

    var randomvalue = Math.floor(Math.random() * 11 + 1); //valor de entre 1 y 11

    var randomimage = this.image("img/home_" + randomvalue + ".jpg");

    if (
      randomvalue == 11 &&
      this.config().destinationsregions.search("3") != -1
    )
      image = europeimage;

    if (randomvalue < 11) image = randomimage;

    $(".home_b").attr("style", "background:url(" + image + ") !important; background-repeat: no-repeat !important; background-size: cover !important;");
  }

  redirectOnlineProduct() {
    console.log(this.getglobaldata());
    var global_ = this.getglobaldata();

    if (!("Id_Destination" in global_)) {
      window.location.href = "/tours/all-destinations/0/Online/1";
    }

    if (global_.Id_Destination == "") {
      window.location.href = "/tours/all-destinations/0/Online/1";
    }

    Object.entries(global_.destinations).map((value, index) => {
      if (global_.Id_Location != -1) {
        if (value[1].Id_Destination == "area_" + global_.Id_Location) {
          console.log("redirect location");
          window.location.href =
            "/tours/" +
            value[1].friendlyurl +
            "/" +
            global_.Id_Destination +
            "/" +
            global_.Id_Location +
            "/Online/1";
        }
      } else {
        if (value[1].Id_Destination == global_.Id_Destination) {
          console.log("redirect destino");
          window.location.href =
            "/tours/" +
            value[1].friendlyurl +
            "/" +
            value[1].Id_Destination +
            "/Online/1";
        }
      }
    });
  }

  render() {
    //Globalmente tenemos disponible la lista de tours  en this.state.tours
    return (
      <div>

        <Search origin="home" />

        <main>
          {Number(this.getglobaldata("Id_Destination")) == 600 ? (
            <div className="container mt-30">
              <div className="card warning_msg_c">
                <div className="card-body card_tour">
                  <div className="row">
                    <div className="col-2">
                      <img
                        src="https://cdn.mobilesail.com/reactproject/img/warning_c.png"
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                    <div className="col-10">
                      <p>
                        {this.t(
                          "Dear visitor, due the contingency of COVID-19, a travel authorization is now required to travel. Get everything you need to know about travelling to Jamaica at this time:"
                        )}
                        <a
                          href="https://www.visitjamaica.com/travelauthorization/"
                          className="jamaic_btn"
                          target="_blank"
                        >
                          {this.t("Find out more and get yours here")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          

          {/* TEST NEW ONLINE CATEGORI */}
          {/*<button onClick={this.redirectOnlineProduct.bind(this)} className="btn btn_seetours">
            AQUI VA EL BANNER
          </button> */}


          {/* Middle section */}
          <PopularDestinations />

          {/* @if brand = 'bookingwidget2' */}

          {/*  <TrendingTour /> */}

          {/* @endif  */}

          {/* End container */}
          {/* end Middle section */}
          {/* Airport Transfers section */}

          {this.config().transfers ? (
            <section className="promo_full">
              <div className="promo_full_wp magnific">
                <div>
                  <h3>
                    {this.t("AIRPORT TRANSFERS ~ GET TO YOUR HOTEL IN STYLE!")}
                  </h3>
                  <p>
                    {this.t(
                      "Get to your hotel with style, select or search your hotel to get our best selection of airport-hotel transfer services. Air conditioned comfortable coaches, minivans or private vehicles will be waiting to take you to your destination."
                    )}
                  </p>
                  <InputSearchHotel
                    origin="home"
                    handle={this.changehotel.bind(this)} 
                    identifierSearch={"_1"}
                  />
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          {/* End Airport Transfers section */}
          {/* Our Destinations section */}

          {/******************BUSCADOR DE TOURS quitar comentarios de los siguientes div y el componente de busqueda para probar *********************************************/}
          {/*NOTES: function onChange() of every page if exist, function setfilter() of every page if exist, function clearfilter() of every page if exist*/}
          {/*<div className="card-body card_tour">         
          <div className="row">         
          <SearchAllTours redirect={true} onChange={()=>{}} setfilter={()=>{}} clearfilter={()=>{}}/>
          </div>
          </div>*/}

          <div className="white_bg">
            <div className="container margin_60">
              <div className="banner colored">
                <h4
                  dangerouslySetInnerHTML={{
                    __html: this.t("Discover our Top tours <span>now</span>"),
                  }}
                ></h4>
                <p>
                  {this.t("See our best tour places at {{brandname}}", {
                    brandname: this.config().brandname,
                  })}
                </p>
                <NavLink
                  to="/tours/1"
                  className="btn_1 white"
                  onClick={this.settourfilter.bind(this, "istoprated", -1)}
                >
                  {this.t("Read more")}
                </NavLink>
              </div>

              {/* @if brand = 'bookingwidget2' */}
              {/*  <HomeDestinations /> */}
              {/* @endif  */}

              {/* End row */}
            </div>
            {/* End container */}
          </div>
          {/* End Destinations section */}
          {/* Promo section */}

          {this.getglobaldata("Id_Language") != 3 ? (
            <div className="white_bg beach_back">
              <div className="mask_b">
                <div className="container margin_60">
                  <div className="main_title">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: this.t(
                          "Why <span className='blue_text'>book ahead</span>?"
                        ),
                      }}
                    ></h2>
                  </div>
                  <div className="row book_ahead">
                    <div className="col-md-4 text-center">
                      <i className="icon_set_1_icon-36" />
                      <h3 className="blue_text">
                        {this.t("Save time & money")}
                      </h3>
                      <p>
                        {this.t(
                          "You won't find it cheaper online or anywhere else!"
                        )}
                      </p>
                    </div>
                    <div className="col-md-4 text-center">
                      <i className="icon_set_2_icon-108" />
                      <h3 className="blue_text">
                        {this.t("Don't be disappointed")}
                      </h3>
                      <p>
                        {" "}
                        {this.t(
                          "When you book ahead, you are guaranteed not to miss out on popula tours & activities."
                        )}
                      </p>
                    </div>
                    <div className="col-md-4 text-center">
                      <i className="icon_set_1_icon-29" />
                      <h3 className="blue_text">
                        {this.t("Travel with confidence")}
                      </h3>
                      <p>
                        {this.t(
                          "Our providers are hand-picked, safe and reliable, so you always get what you pay for."
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <NavLink
                        to="/tours/1"
                        className="btn btn_seetours"
                        onClick={this.scrolltop.bind(this)}
                      >
                        {this.t("See All Experiences")}
                      </NavLink>
                      <br />
                    </div>
                  </div>
                </div>
                {/* End container */}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* @if transfers = true */}

          {this.config().transfers ? (
            <div className="container margin_60">
              <div className="main_title">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: this.t(
                      "Some <span className='blue_text'>good</span> reasons"
                    ),
                  }}
                ></h2>
                <p>{this.t("We have the best reasons to travel")}</p>
              </div>

              {/* Banners Bottom */}
              <div className="row">
                <div
                  className="col-lg-6 space_bannerb wow zoomIn"
                  data-wow-delay="0.2s"
                >
                  <NavLink
                    to="/tours/1"
                    className="banner_link"
                    onClick={this.settourfilter.bind(this, "istoprated", -1)}
                  >
                    <div className="banner_h_left">
                      <div className="banner_left">
                        <img
                          src={this.image(
                            "img/logo_banner_" +
                              this.config().logo +
                              ".png?v=" +
                              this.version()
                          )}
                          className="img-fluid"
                        />
                      </div>
                      <div className="banner_right">
                        <h1
                          dangerouslySetInnerHTML={{
                            __html: this.t("Most <br />Popular <br /> Tours"),
                          }}
                        ></h1>
                      </div>
                    </div>
                    <div className="banner_btext">
                      {/*  <img src={this.image("img/banner_face.png?v="+this.version())} className="img-fluid" /> */}
                      <button>{this.t("SEE NOW!")}</button>
                      <span>
                        {this.t("Only on {{brandname}}!", {
                          brandname: this.config().brandname,
                        })}
                      </span>
                    </div>
                  </NavLink>
                </div>
                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.2s">
                  <NavLink
                    to="/tours/1"
                    className="banner_link"
                    onClick={this.settourfilter.bind(this, "categories", [
                      "Water",
                    ])}
                  >
                    <div className="banner_h_right">
                      <div className="banner_left">
                        <h1
                          dangerouslySetInnerHTML={{
                            __html: this.t("The best <br />Water Tours"),
                          }}
                        ></h1>
                      </div>
                      <div className="banner_right">
                        <img
                          src={this.image(
                            "img/logo_banner2_" +
                              this.config().logo +
                              ".png?v=" +
                              this.version()
                          )}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="banner_btext">
                      {/*  <img src={this.image("img/banner_face.png?v="+this.version())} className="img-fluid" /> */}
                      <button>{this.t("SEE NOW!")}</button>
                      <span>
                        {this.t("Only on {{brandname}}!", {
                          brandname: this.config().brandname,
                        })}
                      </span>
                    </div>
                  </NavLink>
                </div>
              </div>

              {/*End row */}
            </div>
          ) : (
            ""
          )}

          {/* @endif */}

          {/* End Promo section */}
        </main>
      </div>
    );
  }
}

export default Home;
