module.exports = {
    gaid:'UA-40645563-8', //Google Analtics Id  //Totoura Account
    gtmid:false,  //Google Tag Manager Id
    email:'contactus@indestination.store',
    phone:'800-444-0656',
    sbiid:3, //wepartner/indestinationstore
    activityrateid:1,
	brand:'bookingwidget2',
	brandname:'Booking Widget 2',
	basename:'bookingwidget2',
    logo:'bookingwidget',
    baseurl:"bookingwidget2",
    apiurl:'https://api-totoura-sandbox.mobilesail.com/',
    imagescdn:'https://cdn-totoura-sandbox.mobilesail.com/images/',
    cdn:'https://cdn.mobilesail.com/reactproject/',
    noheaderpages:['login','travelagentmessage','mitmessage','bancomermessage'],
    destinationscountries:'167', //Mexico   //'167,65'   // 'all'
    destinationsregions:'1', //Mexico   //'1,2,3'   // 'all'
    localwhislist:true,
    defaultlanguageid:2,  //1=englis, 2=spanish, 3:french,
    defaultcurrencyid:2,  //1=usd, 2=mxn, 3=cad, 4=eur
    languagesdropdown:'1,2', //todo,
    currenciesdropdown:'1,2', //todo,
    travelagentid:null, //undefined
    travelagencyid:null,   //Sandbox- Agency de Felipe - id =387
    enableagentsearch:false,
    transfers:false,
    experiences:true,
    header:true,
    footer:false,
    myaccount:false,
    shopping:false,
    dining:false,
    languages:true,
    currencies:true,
    tours:true,
    home:false,
    trending:true,
    specials:true,
    wishlist:true,
    contact:false,
    search:false,
    weather:false,
    destinations:true,
    disablebooking:false,
    threextwo:true,
    mailinfo:{ 
               brand:'Booking Widget',
               logo:'https://cdn.mobilesail.com/reactproject/img/mail_bookingwidget.png',
               background:'#d4002a',
               fromemail:'noreply@indestination.store'
             },
    loftloaderoptions:{
                bg_color:                      '#d41f2c',
                bg_gradient:                   true,
                bg_gradient_start_color:       '#d41f2c',
                bg_gradient_end_color:         '#860f0f',
                bg_gradient_angel:             30,
                bg_opacity:                    1,
                bg_animation:                  'fade',
                loader_type:                   'imgloading',
                loader_image:                  'img/loader_bookingwidget.png',
                loader_image_width:            '220px',
                loader_direction:              'vertical',
                loader_looping:                'once',
                progress_type:                 'none',
                message_text:                  '',
                message_size:                  '19px',
                message_color:                 '#ffffff',
                load_time:                     1
    }
}