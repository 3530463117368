import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import DestinationSelector from "../components/component.destination.selector";
import Weather from "../components/component.weather";
import Language from "../components/component.language";
import Currency from "../components/component.currency";
import Global from '../globals/global';
//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;

var activestyle={
    fontWeight: "bold",
    color: "orange"
};

class Menu extends Global {

    hideoption(){
        var destinationid = Number(this.getglobaldata('Id_Destination'));
        var indestinations = [3,4,5,6]
        return indestinations.indexOf(destinationid) == -1;
    }


    componentDidMount(){

      /*  this.getdispatcher('menu.setoptions',function(data){
          this.setmenuoptions();
          this.forceUpdate();
        }.bind(this))*/

    }


    componentWillMount(){
           this.setmenuoptions();
           //this.forceUpdate()
    }

    prevent(event){
        event.preventDefault();
    }

    grouplink(){

        if(this.config().groupinfo){

        let group = this.config().groupinfo;
        let url = "/group/"+group.UrlName;
        let url_2 = "group/"+group.UrlName;

        if(location.pathname.search(url) != -1) {
             return (
                    <li>
                         <NavLink to='/'  activeClassName='active' >{group.Name}</NavLink>
                    </li>
                    )

        }
        
           
         return (
                    <li>
                        <a href={url_2} activeClassName='active' >{group.Name}</a>
                    </li>
                    )

        }
        else return null

    }


landinglink(){

        if(this.config().landinginfo){

        let landing = this.config().landinginfo;
        let url = "/landing/"+landing.UrlName;
        let url_2 = "landing/"+landing.UrlName;

      if(location.pathname.search(url) != -1) {
             return (
                    <li>
                       <NavLink to={'/'}  activeClassName='active' >{landing.Name}</NavLink>
                    </li>
                    )

        }
        
           
         return (
                    <li>
                        <a href={url_2} activeClassName='active' >{landing.Name}</a>
                    </li>
                    )

        }
        else return null

    }

tourlink(){

    if(!this.config().tours) return null;

    let touroption = (<li><NavLink to="/tours/1" strict activeClassName='active' >{this.t("All Tours")}</NavLink></li>)


    if(this.config().groupinfo) return null

    if(this.config().landinginfo) return null



      return touroption
        

}

    render() {

       var logo = this.image("img/logo_sticky_"+this.config().logo+".png");
       logo = this.config().logourl?this.config().logourl:logo;

        

        return (
            <div className="main-menu">
                
                {this.config().logo != '' && this.config().logo != 'nologo'?
                <div id="header_menu">

                    {this.config().logourl?
                      <img src={logo} style={{width:'60px'}} alt={this.config().brandname} data-retina="true" />
                      :
                       <img src={logo}  alt={this.config().brandname} data-retina="true" />
                    }
                   
                </div>
                :<div className='mb-5'></div>}
                
                <a href="" onClick={this.eventpreventdefault.bind(this)} className="open_close" id="close_in"><i className="icon_set_1_icon-77"></i></a>
                <ul className='nav_bottom menupopup  list-inline d-lg-flex justify-content-center'>

                {this.config().home?
                    <li>
                        <NavLink to="/home" strict activeClassName='active' >{this.t("Home")}</NavLink> 
                    </li>
                :''}

                 {this.config().transfers?
                    <li>
                        <NavLink to="/transfers" strict activeClassName='active' >{this.t("Transfers")}</NavLink>
                    </li>
                 :''}

                 
                 {this.tourlink()}
                 

                 {this.config().trending?
                    <li>
                        <NavLink to="/trendingrev" activeClassName='active' >{this.t("Trending & Reviews")}</NavLink>
                    </li>
                 :''}

                 {this.config().specials?
                    <li>
                        <NavLink to="/specials" activeClassName='active' >{this.t("Specials")}</NavLink>
                    </li>
                 :''}

                 {this.config().wishlist?
                    <li><NavLink to="/wishlist" activeClassName='active'>{this.t("Wish List")}</NavLink></li>
                  :''}

                   
                    {this.hideoption()  && this.config().dining?<li><NavLink to="/dining" activeClassName='' >{this.t("Dining")}</NavLink></li>:''}
                   

                    
                    {this.hideoption() && this.config().shopping?<li><NavLink to="/shopping" activeClassName='' >{this.t("Shopping")}</NavLink></li>:''}
                   

                     {this.config().contact?
                    <li>
                        <NavLink to="/contact" activeClassName='active' >{this.t("Contact")}</NavLink>
                    </li>
                    :''}

                       
                    {/*this.config().brand == 'toorzy' && this.config().navambassadors?
                        <li>                           
                           <a href="https://embajador.toorzy.com/" class="ambassador_link_m">{this.t("Ambassador")} <i class="icon-star"></i></a>
                        </li>
                     :''*/}
                   

                    {this.grouplink()}
                    {this.landinglink()}




                            <div className="resp_b">

                        {this.config().destinations?
                            <li><DestinationSelector locationNotification={false} /></li> 
                        :''}

                         {this.config().weather?
                            <li><Weather /></li> 
                         :''}

                          {this.config().languages?
                            <li className='m-2' ><Language header={false} className='form-control' /></li>
                         :''}

                         {this.config().currencies?
                            <li className='m-2' ><Currency  header={false} className='form-control' /></li>
                         :''}

                           {this.config().myaccount?
                             <li> <NavLink to="/myaccount" activeClassName='active' >{this.t('My Account')}</NavLink></li>
                           :''}

                            
                            {this.getglobaldata('user') && this.config().myaccount?<li><NavLink to="/" onClick={this.logout.bind(this)} >{this.t('Logout')}</NavLink></li>:''}
                            

                            </div>


                </ul>
            </div>
        )
    }
}

export default withRouter(Menu)