import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PostalCodeElement,
} from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import StripeAPI from "../api/StripeAPI";



export default function CheckoutForm(props) {
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [formProps, setFormProps] = useState(props);
  const [params, setParams] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [stripeAPIPaymentId, setStripeAPIPaymentId] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const translate = props.translate;

  const invalidphonenumbers = ()=>{
    return [
      "0000000000",
      "1111111111",
      "2222222222",
      "3333333333",
      "4444444444",
      "5555555555",
      "6666666666",
      "7777777777",
      "8888888888",
      "9999999999",
      "1234567890",
    ];
  }

  const isemail=(email)=>{
    console.log('email',email)
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }
  

  const justNumbers = (string)=>{
  var numsStr = string.replace(/[^0-9]/g, '');
  return numsStr;
}

  useEffect(() => {
    setFormProps(props);
    // Step 1: Fetch product details such as amount and currency from
    // API to make sure it can't be tampered with in the client.
    setAmount(props.Amount);
    setCurrency(props.Currency);

    // Step 2: Create PaymentIntent over Stripe API
    StripeAPI.createStripeCart({
      payment_method_types: ["card"],
      params: props.params,
    })
      .then((data) => {

        if(data.result){
        setClientSecret(data.StripePaymentIntent.client_secret);

        setStripeAPIPaymentId(data.Id_StripeAPIPayment)
        
        if(data.params){
          setParams(data.params);
        }
       }else{
        setErrorMessage(data.errorMessage)
       }

      })
      .catch((err) => {
        setError(err.message);
      });
  }, [props]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (ev.target.name.value == "") {
      setError(translate("Enter Name as it appears on the Credit Card"));
      return;
    }

    console.log()
    
    if(params){
    if (!isemail(ev.target.email.value.trim())) {
      setError(translate("Enter a valid email"));
      return;
    }
   

     var phone = justNumbers(ev.target.phone.value.trim());

     console.log(' phone', phone)

    if (
      (phone.length >= 0 && phone.length < 10) ||
      invalidphonenumbers().indexOf(phone) != -1
    ){
      setError(translate("Enter a valid 10 digit phone number"));
    return;
    }

    }

    setProcessing(true);

    // Step 3: Use clientSecret from PaymentIntent and the CardEle

    console.log(
      "elements.getElement(CardElement)",
      elements.getElement(CardElement)
    );

    var billing_details = {
      name: ev.target.name.value
    }

    if(params){
      billing_details.phone =  ev.target.phone.value;
      billing_details.email = ev.target.email.value;
    }
    // to confirm payment with stripe.confirmCardPayment()
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: billing_details,
      },
    });

    if (payload.error) {
      setError(payload.error.message);
      setProcessing(false);
      console.log("[error]", payload.error);
    } else {
      setError(null);
      setSucceeded(true);
      setProcessing(false);
      setMetadata(payload.paymentIntent);
      console.log("[PaymentIntent]", payload.paymentIntent);

      props.aprovedpayment();
    }
  };

  const renderSuccess = () => {
    // <code>{JSON.stringify(metadata, null, 2)}</code>
    return (
      <div className="sr-field-success message">
        <h1>Pago realizado exitosamente</h1>
        <p>Gracias por tu pago</p>
        <pre className="sr-callout">
         
        </pre>
      </div>
    );
  };


  const  copyUrl = ()=>{

  /* Get the text field */
  var copyText = document.getElementById("copyPaymentUrl");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

   /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.value);

  /* Alert the copied text */
  //alert("Url copiada");
}

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    const currencyLabel = ()=>{

      if(params.currencyLabel) return params.currencyLabel;

       var curr =  Number(params.Id_Currency);

       if(curr == 1) return 'USD';
       if(curr == 2) return 'MXN';
       if(curr == 3) return 'CAD';
       if(curr == 4) return 'EUR';
    }

    const origin = props.this.config().customUrl && window.self !== window.top?props.this.config().customUrl:window.location.origin+window.location.pathname.replace('/checkout','');

    return (
      <form onSubmit={handleSubmit}>


        <div className="row">

        {params?
          <div className="col-md-12">
          <h5>{params.concept} </h5>
          <h2>${params.amount} <small>{currencyLabel()}</small></h2>

          </div>
        :null
        }
          <div className="col-md-6">
          <label>
       {translate("Name as it appears on the Credit Card")} </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={translate("Name as it appears on the Credit Card")}
              autoComplete="cardholder"
              className="form-control"
            />
            
          </div>



     <div className="col-md-6">
      <label>{translate("Credit Card Number")}</label>
        <CardNumberElement
          className="form-control"
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
    
      </div>
      </div> 
      
      <div className="row mt-3">
      <div className="col-md-3">
      <label>{translate("Valid Thru")}</label>
        <CardExpiryElement
          className="form-control"
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
     
      </div>

      <div className="col-md-3">
      <label>CVC</label>
        <CardCvcElement
          className="form-control"
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      
       </div>

       </div>

       {params?
        <div className="row mt-5">
          <div className="col-md-6">
          
             <label>
       {translate("Email")} </label>
            <input
              defaultValue={params.email}
              type="text"
              id="email"
              name="email"
              placeholder={translate("Enter a valid email")}
              autoComplete="cardholder"
              className="form-control"
            />

          </div>
          <div className="col-md-6">
           <label>
       {translate("Phone")} </label>
            <input
            defaultValue={params.phone}
              type="text"
              id="phone"
              name="phone"
              placeholder={translate("Enter phone number")}
              autoComplete="cardholder"
              className="form-control"
              maxLength="10"
            />
          </div>
          </div>
        :null
        }
       
      

        {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn checkout_popc mt-3"
          disabled={processing || !clientSecret || !stripe}
        >
          {processing
            ? translate("We are processing your request, please wait...")
            : translate("Submit Payment")}
        </button>



         {props.this.config().adminid && !params && (props.this.config().customUrl || window.self === window.top)? <div>
           <input onClick={copyUrl} value={origin+'/payment/stripe/'+stripeAPIPaymentId} readonly id="copyPaymentUrl" className="mt-5 p-2 disabled" style={{width:'100%'}} />
           
          
          </div>:''}

      </form>
    );
  };


  if(errorMessage != ''){
    return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        <h5>{errorMessage}</h5>
      </div>
    </div>
  );
  }

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
}
