import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class TourRulesThings extends Global {
    render() {
        return (
            <div>
                <p className="blue_text"> <strong>{this.props.title}</strong></p>
                <ul className="list_ok">
                    {
                        this.props.list? (
                            this.props.list.map((data, index) => {
                                return (
                                    <li key={`TourRulesThings-${index}-${data.Id}`}>{data.Name}</li>
                                )
                            })
                        ) : null
                    }
                </ul>
            </div>
        )
    }
}

export default withRouter(TourRulesThings)