import React, { Component } from 'react';
import { Redirect,withRouter } from 'react-router-dom'

import Select from 'react-select';
import SearchToursResult from './component.search.tours.result';
import Global from '../globals/global';
import SearchDestinationGlobal from './component.search.destination';
import autocomplete from 'autocompleter';

//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;
//const autocomplete = window.autocomplete;

class SearchTours extends Global {

    constructor(props) {
        super(props);

        this.changeSearch = this.changeSearch.bind(this);
        this.changeDestination = this.changeDestination.bind(this);
        this.clickSearch = this.clickSearch.bind(this);
        this.sendItemsFromGlobalSearch = this.sendItemsFromGlobalSearch.bind(this);

        this.state = {
            destinations: this.getglobaldata('destinations') || [],
            Id_Destination: this.getglobaldata('Id_Destination'),
            Id_State:this.getglobaldata('Id_State'),
            Id_Location:this.getglobaldata('Id_Location'),
            search: null,
            tours: null,
            searching: false,
            redirect: false,
            allowedChars: new RegExp(/^[a-zA-Z\s]+$/),
            defaultdestinationname:''
        };
    }

    componentDidMount() {
        
        this.setdefaults();
        this.getdispatcher('load.destinations',function(data){

            this.setState({
                    destinations: this.getglobaldata('destinations') || [],
                    Id_Destination: this.getglobaldata('Id_Destination'),
                    Id_State:this.getglobaldata('Id_State'),
                    search: '',
                }, function(){
                     this.setdefaults();
                }.bind(this));

        }.bind(this));
    }

    setdefaults(){
      
      //this.setautocomplete();
      var destination = this.getdefaultdestination();
      this.setState({
        defaultdestinationname:(destination?destination.Name:''),
      });
      
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevState.destinations !== this.state.destinations) {
            
            const Id_Destination = this.getglobaldata('Id_Destination');
            this.setState({
                Id_Destination: Id_Destination,
                Id_State:this.getglobaldata('Id_State')
            });
        }
        if (prevState.redirect !== this.state.redirect && this.state.redirect) {
            
            this.setState({
                redirect: false,
            });
        }
    }

    changeSearch(event) {
        
        this.setState({ search: event.target.value });
    }

    changeDestination(data) {
        
        let locationid = -1;

        var stateid = '';
        var destinationid = data.Id_Destination;
        if(data.Id_Destination.search('state_') != -1) {  //detectamos si la variable lleva la plabra state_

            stateid = Number(data.Id_Destination.replace('state_',''));
            destinationid = '';
        }


         if(data.Id_Destination.search('area_') != -1) {  //detectamos si la variable lleva la plabra area_

            locationid = Number(data.Id_Destination.replace('area_',''));
            destinationid = data.destinationid;
            stateid = '';
        }
         
        /*this.state.Id_Destination = destinationid;
        this.state.Id_State = stateid;
        this.state.tours = null;
        this.state.defaultdestinationname = data.Name;

        this.setglobaldata('destinationinfo',data)
        this.forceUpdate();*/

        this.setState({
            Id_Destination: destinationid,
            Id_State:stateid,
            Id_Location:locationid ,
            tours: null,
            defaultdestinationname:data.Name
        }, function(){
           //console.log('this.state main',this.state)
           //console.log('destinationinfo main',data)
           this.setglobaldata('destinationinfo',data)
        }.bind(this));
        
    }

    clickSearch() {
       
      {/*start - Es temporal porque hay un bug, no actuliza el setstate*/}   
     if(this.getglobaldata('destinationinfo')){
            
            let globaldestinationid = this.getglobaldata('destinationinfo').Id_Destination;
            
            this.state.Id_Destination = globaldestinationid;
            this.state.Id_State = '';
            this.state.Id_Location = -1;
            if(globaldestinationid.search('state_') != -1) {  //detectamos si la variable lleva la plabra state_
                
                this.state.Id_State = Number(globaldestinationid.replace('state_',''));
                this.state.Id_Destination = '';
            }

            if(globaldestinationid.search('area_') != -1) {  //detectamos si la variable lleva la plabra area_
                
                this.state.Id_Location = Number(globaldestinationid.replace('area_',''));
                this.state.Id_Destination = this.getglobaldata('destinationinfo').destinationid;
            }

        }
      {/*fisnih - Es temporal porque hay un bug, no actuliza el setstate*/} 

        

        var destination = this.getdefaultdestination();
       
        let locationid = -1;
        var stateid = '';
        var destinationid = destination.Id_Destination;
        if(destination.Id_Destination.search('state_') != -1) {  //detectamos si la variable lleva la plabra state_

            stateid = Number(destination.Id_Destination.replace('state_',''));
            destinationid = '';
        }

        if(destination.Id_Destination.search('area_') != -1) {  //detectamos si la variable lleva la plabra state_

            locationid = Number(destination.Id_Destination.replace('area_',''));
            destinationid = destination.destinationid;
        }

          
        this.resetdestinationandfilters(destinationid);
        this.resetlocationandfilters(locationid);
        this.resetstateandfilters(stateid);

        var pathname = location.pathname;
       // destination.Name = destination.Name.replace(/[^a-zA-Z ]/g, "");
       // var destinationname = destination.Name.toLowerCase().replace(/ /g,'-');

        this.gaDestinationEvent(destination.label);

        
        if(locationid != -1) this.navigate("tours/"+destination.friendlyurl+"/"+destinationid+"/"+locationid+"/1");
        else this.navigate("tours/"+destination.friendlyurl+"/"+destination.Id_Destination+"/1");
      



        /*
        if (location.host != 'localhost:3000') {
            window.location.href = `/totoura/search/tours/${this.state.destination}/${this.state.search}`;
        } else {
            window.location.href = `/search/tours/${this.state.destination}/${this.state.search}`;
        }*/

    }

    getdefaultdestination(){

        //console.log('this.state.Id_Destination main',this.state.Id_Destination)
        //console.log('this.state.Id_state main',this.state.Id_State)
        //console.log('this.state main',this.state)
        return this.state.destinations.find(destination => {
            
            //return this.state.Id_Destination == destination.Id_Destination;
            if(Number(destination.destinationid)){
                
                return String(destination.Id_Destination) === String('area_'+this.state.Id_Location);
            } 
            if(Number(destination.Id_Destination)){
                
                return Number(destination.Id_Destination) === Number(this.state.Id_Destination);
            } 
            if(String(destination.Id_Destination)){
                
                return String(destination.Id_Destination) === String('state_'+this.state.Id_State);
            }

            
 
            });

    }


    setautocomplete(){
            let destinations = this.state.destinations;
            const allowedChars = this.state.allowedChars;
            /*autocomplete({
                input: document.getElementById('destinations_autocomplete2'),
                minLength: 1,
                onSelect: function (item, inputfield){
                    inputfield.value = item.Name;
                    
                    this.changeDestination(item);
                }.bind(this),
                fetch: function (text, callback) {
                    var match = text.toLowerCase();
                    callback(destinations.filter(destination => {
                        return destination.label.toLowerCase().indexOf(match) !== -1;
                    }));
                },
                render: function (item, value) {
                    $('.autocomplete').remove();

                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(value, 'gi');
                        var inner = item.label.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.label;
                    }
                    return itemElement;
                },
                emptyMsg: this.t("No Destination found"),
                customize: function (input, inputRect, container, maxHeight) {
                    if(this.ismobile()){
                        console.log('container.style.bottom',(window.innerHeight - inputRect.bottom + input.offsetHeight) + "px")
                        //if (maxHeight < 100) {
                        container.style.width = "100%";

                        if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";

                        console.log('(window.innerHeight',window.innerHeight)
                        console.log('(inputRect.bottom ',inputRect.bottom )
                        console.log('(inputRect.bottom ',input.offsetHeight)
                         }

                     //   container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                      //  container.style.maxHeight = "140px";
                        //container.style.left = "5px";
                        //container.style.right = "5px";
                      //  container.style.width = "100%";
                      // } 
                    }
                    else if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }.bind(this)
            });*/
    }

    writecity(event) {
        this.setState({ defaultdestinationname: event.target.value });
        
    }

    clean(){
      this.setState({ defaultdestinationname: ''});
    }

    sendItemsFromGlobalSearch(value1){
               
        this.setState(value1,function(){
            //console.log("new state",this.state)            
        })
    }

    render() {
        return (
            this.state.destinations !== null ? (
               <div className="tab-pane active show" id="tours">
                    <div className="row">
                            <div className="col-md-9 mx-auto">

                         <h3>{this.t('Find your next experience')}</h3>
                           
                          <label>{this.t('Destination')}</label>
                                

                         
                          <SearchDestinationGlobal identifierSearch={"_1"} onClick={this.clickSearch} sendItem={(value)=>this.sendItemsFromGlobalSearch(value)}/>
                          {/*<input value={this.state.defaultdestinationname} onChange={this.writecity.bind(this)} id="destinations_autocomplete2" type="text" placeholder={this.t("Type your City")} autoComplete="off" className='form-control input_tour' />
                          <div className='input-group-append inp_appet'>
                          <button className="btn btn-outline-secondary btn_clearb" type="button"><i className="icon-cancel" onClick={this.clean.bind(this)}></i></button>
                          </div>*/}



                    </div>
                </div>
                    

                </div>
            ) : null
        )
    }
}

export default withRouter(SearchTours)