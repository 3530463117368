import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Language from "../components/component.language";
import Currency from "../components/component.currency";
import Menu from "../components/component.menu";
import CartHeader from "../components/component.cart.header";
import DestinationSelector from "../components/component.destination.selector";
import Weather from "../components/component.weather";
import Search from "../components/component.search";
import Global from "../globals/global";
//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;

class header extends Global {
    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);  

        this.state = {};
    }

    openModal() {
        $.magnificPopup.open({
            items: {
                src: "#search_p",
            },
            type: "inline",
            fixedContentPos: true,
            fixedBgPos: true,
            overflowY: "auto",
            closeBtnInside: true,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: "my-mfp-zoom-in",
        });
    }

    render() {
        var disablepages = this.config().noheaderpages || [];
        const disable = disablepages.find((page) => {
            return location.pathname.search(page) != -1;
        });

        if (disable) return null;

        var agentmoduleclass = "";
        if (this.config().enableagentsearch) agentmoduleclass = "nav_editor";

        return (
            <header className={`back_blueh ${agentmoduleclass}`}>
                <div id="top_line">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 icon_changec">
                                {this.config().destinations ? (
                                    <DestinationSelector
                                        locationNotification={true}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-6">
                                <ul id="top_links">
                                    {this.config().myaccount ? (
                                        <li>
                                            {" "}
                                            <NavLink to="/myaccount">
                                                {this.getglobaldata("user") ? (
                                                    <span>
                                                        <i className="far fa-user-circle"></i>{" "}
                                                        {
                                                            this.getglobaldata(
                                                                "user"
                                                            ).FirstName
                                                        }
                                                    </span>
                                                ) : (
                                                    this.t("My Account")
                                                )}
                                            </NavLink>
                                        </li>
                                    ) : (
                                        ""
                                    )}

                                    {this.getglobaldata("user") &&
                                    this.config().myaccount ? (
                                        <li>
                                            <NavLink
                                                to="/"
                                                onClick={this.logout.bind(this)}
                                            >
                                                {this.t("Logout")}
                                            </NavLink>
                                        </li>
                                    ) : (
                                        ""
                                    )}

                                    {this.config().languages ? (
                                        <li>
                                            <Language />
                                        </li>
                                    ) : (
                                        ""
                                    )}

                                    {this.config().currencies ? (
                                        <li>
                                            <Currency />
                                        </li>
                                    ) : (
                                        ""
                                    )}

                                    {this.config().search ? (
                                        <li>
                                            <a
                                                onClick={this.openModal}
                                                className="search_pop i_search"
                                            >
                                                <i className="icon_search"></i>
                                            </a>
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            </div>
                        </div>
                        {/*---- End row --- */}
                    </div>
                    {/*---- End container--- */}
                </div>
                {/*---- End top line--- */}

                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div id="logo_home">
                                <h1>
                                    <a href="https://viajeypunto.com.mx/" title="Vacasa">
                                        Viaje y Punto
                                    </a>
                                </h1>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="btn text-white orange_playas">
                                <a
                                    className="text-white"
                                    href="https://viajeypunto.com.mx/playas/"
                                    style={{ textDecoration: "none" }}
                                >
                                    <img
                                        src="https://www.viajeypunto.com.mx/img/percentage.png"
                                        height="20px"
                                        width="auto"
                                    />{" "}
                                    Playas
                                </a>
                            </div>
                            <a href="http://www.viajeypunto.com.mx/">
                                <img
                                    src={this.image(
                                        "img/logoright_viajeypunto.png"
                                    )}
                                    className="img-fluid logo_rightm"
                                />
                            </a>
                        </div>
                        <nav className="col-12">
                            <a
                                className="cmn-toggle-switch cmn-toggle-switch__htx open_close"
                                href="javascript:void(0);"
                            >
                                <span>Menu mobile</span>
                            </a>
                            <div className="main-menu">
                                <div id="header_menu">
                                    <img
                                        src={this.image(
                                            "img/logoright_viajeypunto.png"
                                        )}
                                        width="auto"
                                        height={34}
                                        alt="viajeypunto"
                                        data-retina="true"
                                    />
                                </div>
                                <a
                                    href="#"
                                    className="open_close"
                                    id="close_in"
                                >
                                    <i className="icon_set_1_icon-77" />
                                </a>
                                <ul className="nav_bottom list-inline">
                                    <a
                                        className="text-white orange_playas_resp"
                                        href="https://viajeypunto.com.mx/playas/"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <img
                                            src="https://www.viajeypunto.com.mx/img/percentage.png"
                                            height="20px"
                                            width="auto"
                                        />{" "}
                                        Playas
                                    </a>
                                    <li>
                                        <a href="https://viajeypunto.com.mx">
                                            Inicio
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://viajeypunto.com.mx/mexico?_ga=2.129789701.880747548.1594921247-192810828.1591920779"
                                            className="active_forever_p"
                                        >
                                            {" "}
                                            México
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://viajeypunto.com.mx/´a/?_ga=2.199967655.880747548.1594921247-192810828.1591920779">
                                            {" "}
                                            Canadá
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://reservaciones.viajeypunto.com.mx">
                                            {" "}
                                            Avión + Hotel
                                        </a>
                                    </li>
                                    <li className="submenu">
                                        <a
                                            href="javascript:void(0);"
                                            className="show-submenu"
                                        >
                                            Tu Estilo{" "}
                                            <i className="icon-down-dir" />
                                        </a>
                                        <ul>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=102">
                                                    Aventura
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=103">
                                                    Cultura e historia
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=105">
                                                    Diversión
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=110">
                                                    Eventos especiales
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=107">
                                                    Familiar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=104">
                                                    Gastronomía
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=106">
                                                    Playas
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=60">
                                                    Rutas de México
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=65">
                                                    Solo para Dos
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/experiencias/?a=116">
                                                    Vive la Experiencia{" "}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="submenu">
                                        <a
                                            href="javascript:void(0);"
                                            className="show-submenu"
                                        >
                                            Cruceros{" "}
                                            <i className="icon-down-dir" />
                                        </a>
                                        <ul>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/cruceros/?a=105">
                                                    Diversión
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.viajeypunto.com.mx/cruceros/?a=107">
                                                    Familiar
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="submenu">
                                        <a
                                            href="javascript:void(0);"
                                            className="show-submenu active_forever"
                                        >
                                            Experiencias{" "}
                                            <i className="icon-down-dir" />
                                        </a>
                                        <ul>
                                            <li>
                                                <NavLink to="/home">
                                                    Elige tu destino
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/tours/1">
                                                    Listado de Experiencias
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/trendingrev">
                                                    Reseñas
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/wishlist">
                                                    Favoritos
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/contact">
                                                    Contacto
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="https://viajeypunto.com.mx/contacto.html">
                                            Contacto
                                        </a>{" "}
                                    </li>
                                    <li className="submenu top_number_viaje">
                                        <a
                                            href="javascript:void(0);"
                                            className="show-submenu"
                                        >
                                            <img
                                                src="https://www.viajeypunto.com.mx/img/info.png"
                                                height="20px"
                                                width="auto"
                                            />{" "}
                                            +5255 4630 3030{" "}
                                            <i className="icon-down-open-mini" />
                                        </a>
                                        <ul className="phone_subm">
                                            <span className="atencion_cli_b">
                                                Ventas
                                            </span>
                                            <li>
                                                <a
                                                    href="tel:+525546303030"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <img
                                                        src="https://www.viajeypunto.com.mx/img/ico-phone.png"
                                                        height="15px"
                                                        width="auto"
                                                    />
                                                    &nbsp; +5255 4630 3030
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://api.whatsapp.com/send?phone=5215533997764&text=Hola,%20quiero%20cotizar%20un%20servicio%20de%20viaje&b=483"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <img
                                                        src="https://www.viajeypunto.com.mx/img/ico-whatsapp.png"
                                                        height="15px"
                                                        width="auto"
                                                    />
                                                    &nbsp; +5255 3399 7764
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="mailto:servicio@viajeypunto.com.mx"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <img
                                                        src="https://www.viajeypunto.com.mx/img/icon-correo.svg"
                                                        height="15px"
                                                        width="auto"
                                                    />
                                                    &nbsp;
                                                    reservaciones@viajeypunto.com.mx
                                                </a>
                                            </li>
                                            <span
                                                className="dropdown-item-text text-white"
                                                style={{ fontSize: "12px" }}
                                            >
                                                <img
                                                    src="https://www.viajeypunto.com.mx/img/ico-time.png"
                                                    height="15px"
                                                    width="auto"
                                                />
                                                &nbsp; Horarios de atención:
                                                <br />
                                                &nbsp;Lun - Vier 09:00 am - 9:30
                                                pm
                                                <br />
                                                &nbsp;Sab 09:00 am - 6:00 pm
                                                <br />
                                                &nbsp;Dom 10:00 am - 2:00 pm
                                            </span>
                                            <div className="dropdown-divider" />
                                            <span className="atencion_cli_b">
                                                Atención al cliente
                                            </span>
                                            <li>
                                                <a
                                                    href="tel:+525523057802"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <img
                                                        src="https://www.viajeypunto.com.mx/img/ico-phone.png"
                                                        height="15px"
                                                        width="auto"
                                                    />
                                                    &nbsp; +5255 2305 7802
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://api.whatsapp.com/send?phone=5215523057802&text=Hola,%20%20necesito%20comunicarme%20con%20el%20%C3%A1rea%20de%20Atenci%C3%B3n%20al%20Cliente"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <img
                                                        src="https://www.viajeypunto.com.mx/img/ico-whatsapp.png"
                                                        height="15px"
                                                        width="auto"
                                                    />
                                                    &nbsp; +5255 2305 7802
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="mailto:servicioalcliente@viajeypunto.com.mx"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <img
                                                        src="https://www.viajeypunto.com.mx/img/icon-correo.svg"
                                                        height="15px"
                                                        width="auto"
                                                    />
                                                    &nbsp;
                                                    reservaciones@viajeypunto.com.mx
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    {/*menu responsive*/}

                                    <div className="resp_b">
                                        {this.config().destinations ? (
                                            <li>
                                                <DestinationSelector
                                                    locationNotification={false}
                                                />
                                            </li>
                                        ) : (
                                            ""
                                        )}

                                        {this.config().weather ? (
                                            <li>
                                                <Weather />
                                            </li>
                                        ) : (
                                            ""
                                        )}

                                        {this.config().languages ? (
                                            <li className="m-2">
                                                <Language
                                                    header={false}
                                                    className="form-control"
                                                />
                                            </li>
                                        ) : (
                                            ""
                                        )}

                                        {this.config().currencies ? (
                                            <li className="m-2">
                                                <Currency
                                                    header={false}
                                                    className="form-control"
                                                />
                                            </li>
                                        ) : (
                                            ""
                                        )}

                                        {this.config().myaccount ? (
                                            <li>
                                                {" "}
                                                <NavLink
                                                    to="/myaccount"
                                                    activeClassName="active"
                                                >
                                                    {this.t("My Account")}
                                                </NavLink>
                                            </li>
                                        ) : (
                                            ""
                                        )}

                                        {this.getglobaldata("user") &&
                                        this.config().myaccount ? (
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    onClick={this.logout.bind(
                                                        this
                                                    )}
                                                >
                                                    {this.t("Logout")}
                                                </NavLink>
                                            </li>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </ul>
                            </div>
                            {/* End main-menu */}
                            <ul id="top_tools">
                                <li className="cart_home">
                                    <CartHeader />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                {/*  chat icon --*/}
                <a
                    href="#"
                    className="chat_icon"
                    onClick={this.openchat.bind(this)}
                >
                    <img src={this.image("img/chat_icon.png")} />
                </a>
            </header>
        );
    }
}

export default header;
