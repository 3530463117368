import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import SearchTours from './component.search.tours';
import SearchTransfers from './component.search.transfers';
import Global from '../globals/global';

class Search extends Global {

 

    tourstab(){
      
         if(this.config().tours){
        return (<li><a href="#tours" data-toggle="tab" className="active show">{this.t("Tours")}</a></li>);
          }

          return null;
    }

    transferstab(){

       if(this.config().transfers){
        return (<li><a href="#transfers" data-toggle="tab" className="show">{this.t("Transfers")}</a></li>);
        }

        return null;
    }
    render() {
        return (
            <section id="search_container" className={this.props.origin === 'home' ? 'home_b' : ''}>
                <div id="search" className={this.props.origin === 'home' ? 'home_b_search' : ''}>
                    <ul className="nav nav-tabs m-t-75">
                    {this.tourstab()}                    
                    {this.config().transfers?this.transferstab():''}
                  </ul>

                    <div className="tab-content opac_tab">
                        <SearchTours />
                        {this.config().transfers?<SearchTransfers />:''}
                    </div>
                    
                </div>
            </section>
        )
    }
}

export default withRouter(Search)