import Crud from './global.crud'  
import TagManager from 'react-gtm-module'
import moment from "moment";


//if (typeof window === "undefined")  var window={}

const location = window.location;
export default class gtm extends Crud{

gtmisenabled(){

 // return false;//temporal

  if(!this.config().gtmid) return false;
  else return true;

}

gtmEventLinkClick(data){


/* window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
    event: 'gtm.linkClick',
    pagePath: url,
    pageTitle:url,
});*/



/*const tagManagerArgs = {
  gtmId: this.config().gtmid,
  }*/

//console.log('window.dataLayer',window.dataLayer);
//TagManager.initialize(tagManagerArgs)

/*  const tagManagerArgs = {
    gtmId: this.config().gtmid,
    events: {
        linkClick: url
    }
}
 
TagManager.initialize(tagManagerArgs)

/*const tagManagerArgs = {
    gtmId: this.config().gtmid,
    events: {
        linkClick: url
    }
}*/


const tagManagerArgs = {
 // gtmId: this.config().gtmid,
   /* dataLayer: {
        event: 'gtm.linkClick',
        pagePath: url,
        pageTitle:url,
        'gtm.linkClick':url,
        'gtm.click':url 
    },*/
   dataLayer: data,

    dataLayerName : 'dataLayer'
}

  TagManager.dataLayer(tagManagerArgs)


console.log('window.dataLayer - ',window.dataLayer)





}



gtminitialize(){

  if(!this.config().gtmid) if(!this.gtmisenabled()) return;


  let tagManagerArgs = {
    gtmId: this.config().gtmid,
    dataLayerName : 'dataLayer',
   
  /*  dataLayer:{
        "page" : {'type' : 'pageview',   'language' : 'EN',   'section': 'pageview', 'path': location.pathname, 'name': 'pageview'  },
        "device" : "desktop",   
        "event" : "user_pageview",   
        "eventCallback" : function(e) {

            console.log('eventCallback - onPageView');
            console.log(e);
        } 
    
  }*/

}

  TagManager.initialize(tagManagerArgs)

}

gtmtouravailability(products){
  
  let products_ = [
   /*  {   
         "id" : 111,   
         "name" : 'Tour Name,   
         "price" : 111.00,    
         "brand" : this.config().brandname,       
         "category" : "Tour", 
         "variant" : "Tour",  
         "quantity" : 1   
       }*/
       ];
  if(!products) products_ = products;
  
  return;
  if(!this.gtmisenabled()) return;


console.log('check avalabiltiy gtm');

  let tagManagerArgs = {
    dataLayer:{
     "page": {'type' : 'checkout',   'language' : 'EN',   'section': 'touravailability', 'path': location.pathname, 'name': 'touravailability'  },
     "device": "desktop",
     "event" : "touravailability",   
     "ecommerce" : { 
       "checkout" : {  
        "actionField" : {"step": 1}, 
        "products" : products_,  
     }  
   },
   "eventCallback" : function(e) {

            console.log('eventCallback- TourAvailabilityDataLayer');
           console.log(e);
        } 
      },

    dataLayerName: 'TourAvailabilityDataLayer'
    
  }

  TagManager.dataLayer(tagManagerArgs)

  console.log('TagManager',TagManager)

}


gtmcheckout(){

  return;

  if(!this.gtmisenabled()) return;

  let products = [];

  this.getglobaldata('Cart').map(function(item,index){
    
    products.push(
      {   
         "id" : item.itemtype == 1?item.tourid:item.transferid,   
         "name" : item.itemtype == 1?item.tourname:item.tranfertype,   
         "price" : item.total,    
         "brand" : this.config().brandname,       
         "category" : item.itemtype == 1?"Tour":"Transfer", 
         "variant" : item.itemtype,  
         "quantity" : 1   
       }
    );

    }.bind(this));


  let tagManagerArgs = {
    dataLayer:{
     "page": {'type' : 'checkout',   'language' : 'EN',   'section': 'checkout', 'path': location.pathname, 'name': 'checkout'  },
     "device": "desktop",
     "event" : "checkout",   
     "ecommerce" : { 
       "checkout" : {  
        "actionField" : {"step": 1}, 
        "products" : products 
     }  
   },
   "eventCallback" : function(e) {

            console.log('eventCallback- onCheckout');
           console.log(e);
        } 
      },

    dataLayerName: 'CheckoutDataLayer'
    
  }

  TagManager.dataLayer(tagManagerArgs)
  

}



/*

gtmcartconfirmationn(item, params_){

   var Page = location.pathname;

   console.log('dataLayer onBookingConfirmation');


  var data = {   
   "event": "bookingConfirmation",  
   "ecomm_pagetype" : "checkout", 

   "page": {  
     "type"     : "checkout",  
     "language" : "EN", 
     "section"  : "ecommFunnel",   
     "device"   : 'desktop'  
   },

   "eventCallback" : function(e) {

    console.log('eventCallback- onBookingConfirmation');
    console.log(e);
        }
 };

 if(item.itemtype == 1) { 
   data.ecomm_prodid = item.tourid; 
   data.activity = {    
     "country"     : " ", 
     "name"        : item.tourname,   
     "category"    : "Tour",  
     "date"        : moment(item.filters.activitydate).format('YYYY-MM-DD'),  
     "price"       : item.total,  
     "time"        : item.filters.activitytime, 
   };

   data.participants =  { 
     "numbers" : {  
       "total"    : Number(item.children.qty)+Number(item.childrenfree.qty)+Number(item.adults.qty), 
       "adults"   : item.adults.qty,
       "children" : Number(item.children.qty)+Number(item.childrenfree.qty)
     }, 
     "info" :{  
      "firstName"       : params_.user.name,  
      "lastName"        : params_.user.lastname,
      "email"           : params_.user.email,  
      "hotelName"       : '',   
      "arrivalDate"     : '',  
      "departureDate"   : '',  
      "vacationCompany" : " "   
    }  
  };

  data.pricing = { 
    "adults"     : item.adults.qty,   
    "children"   : Number(item.children.qty)+Number(item.childrenfree.qty),  
    "taxes"      : 0.00,  
    "total"      : item.total,   
    "couponAmount" : -item.coupon.discount,
    "coupon"     : item.coupon.code,
    "currency"   : item.currency  
  };

  data.ecommerce = { 
    "currencyCode" : item.currency,  
    "purchase" : {  
     "actionField" : {   
       "id" : params_.TransactionId,  
       "revenue" : parseFloat(item.total) - parseFloat(item.coupon.discount), 
       "tax" : 0.00,   
       "couponAmount" : -iitem.coupon.discount,   
       "coupon" : item.coupon.code   
     },
   "products" : {  
     "id":  item.tourid,   
     "name" : item.tourname,  
     "brand" : " ",  
     "price" : parseFloat(item.total),       
     "category" : "Tour", 
     "variant" : "Tour",   
     "quantity" : 1       
   }}  
 };


 TagManager.dataLayer(data)

}

else if(item.ItemType == 2){
  data.ecomm_prodid = item.TransferParams.Id_Transfer;

  data.transfers = {  
    "type"             : item.TransferParams.Details.Name, 
    "option"           : item.TransferParams.TripType+', per '+item.TransferParams.PaymentBy,  
    "category"         : "Transfer",  
    "hotelName"        : item.TransferParams.HotelInfo.Name, 
    "arrivalAirport"   : item.TransferParams.ArrivalAirportInfo.Name,
    "departureAirport" : item.TransferParams.DepartureAirportInfo.Name,
    "price"            : item.TransferParams.Details.TotalToPay,   
    "arrivalDate"      : item.UserTravelInfo.ArrivalDate,   
    "arrivalTime"      : item.UserTravelInfo.ArrivalTime,  
    "departureDate"    : item.UserTravelInfo.DepartureDate,   
    "departureTime"    : item.UserTravelInfo.DepartureTime 
  };

  data.participants = { 
   "numbers" : {  
    "total"    : parseInt(item.AdultsNum)+parseInt(item.ChildrenNumber), 
    "adults"   : item.AdultsNum,
    "children" : item.ChildrenNumber 
  }, 
  "info" :{  
    "firstName"       : _GlobalData.LoginInfo.FirstName,  
    "lastName"        : _GlobalData.LoginInfo.LastName, 
    "email"           : _GlobalData.LoginInfo.Email,   
    "hotelName"       : item.TransferParams.HotelInfo.Name,   
    "arrivalDate"     : item.UserTravelInfo.ArrivalDate,  
    "departureDate"   : item.UserTravelInfo.DepartureDate,  
    "vacationCompany" : " "  
  }  
};

data.pricing = { 
 "adults"     : item.TotalAdults,
 "children"   : parseFloat(parseFloat(item.TotalChildrens) + parseFloat(item.TotalKids)),   
 "taxes"      : 0.00,  
 "total"      : item.TotalToPay,    
 "couponAmount"     : -item.CouponInfo.DiscountAmount, 
 "coupon"     : item.CouponInfo.Code, 
 "currency"   : _GlobalData.lb_Currency  
};


data.ecommerce = { 
 "currencyCode" : _GlobalData.lb_Currency,  
 "purchase" : {  
   "actionField" : {   
     "id" : params_.TransactionId,  
     "revenue" : parseFloat(item.TotalToPay) - parseFloat(item.CouponInfo.DiscountAmount), 
     "tax" : 000,   
     "couponAmount" : -item.CouponInfo.DiscountAmount,   
     "coupon" : item.CouponInfo.Code,   
   }, 
 "products" : {  
   "id": item.TransferParams.Id_Transfer,  
   "name" : item.TransferParams.Details.Name,  
   "brand" : " ",  
   "price" : parseFloat(item.TotalToPay),       
   "category" : "Transfer", 
   "variant" : item.TransferParams.TripType+', per '+item.TransferParams.PaymentBy,  
   "quantity" : 1       
 }} 
};

TagManager.dataLayer(data)
}



}
*/

}


