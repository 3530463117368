import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
import moment from "moment";
class AdviceMessage extends Global {

    constructor(props) {
        super(props);

        this.state = {
            id_destination:props.id_destination
        };
    }

  

    render() {

        if(moment() > moment('2023-09-20') ) return null;

        if(this.state.id_destination === 3)
        return (
            
            <div className="alert alert-warning" role="alert">
            <b>Please be advised:</b> Due to weather conditions in most parts of the country, all outside activities will be closed this week until September 21st.
       
         </div> 
        )
        else return null;
    }
}

export default withRouter(AdviceMessage)