import React, { Component } from "react";
import TourPricesTable from "../components/component.tour.prices.table";
import CartList from "../components/component.cart.list";
import moment from "moment";
//import validator from "validator";
import { NavLink, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import InputSearchHotel from "../components/component.input.search.hotel";
import CreditCardTypes from "../components/component.creditcardtypes.selector";
import VacationCompanies from "../components/component.vacationcompanies.selector";
import ErrorMessages from "../components/component.error.messages";
import Section from "../components/component.section";
import PaymentProcessor from "../components/component.payment.processor.mit";
import Global from "../globals/global";
import ReactResizeDetector from "react-resize-detector";
import StripesProcessor from "../stripes/App";
import CreditCards from "../components/component.credit.cards";

const $ = window.$;
const location = window.location;

class checkout extends Global {
  constructor(props) {
    super(props);

    this.checkTerms = this.checkTerms.bind(this);

    this.checkPaymentAfterPoints = this.checkPaymentAfterPoints.bind(this);

    var paymentprocessorid = this.paymentprocessorid();
    // this.config().enablepaymenttypes = true;

    // if (this.config().brand == "viajeypunto") this.config().adminid = 395;

    var paymenttypeid = 1;
    var enablepaymenttypes = this.config().enablepaymenttypes;

    if (this.grandtotal() == 0) {
      paymenttypeid = 5; //Credit
      enablepaymenttypes = true;
    }
/*
    enablepaymenttypes = true;
    this.config().nocreditcardcharge =true;
    this.config().paymenttypeid = 5;*/


   console.log('this.config()',this.config());
    if (this.config().nocreditcardcharge) paymenttypeid = 4; // paymenttypeid = 4 =  manual  //no charge card
 

    if (this.config().paymenttypeid) paymenttypeid = this.config().paymenttypeid; 

    if (this.config().brand == "viajeypunto") {
      if (this.config().nocreditcardcharge) paymenttypeid = -3; // paymenttypeid = -4 =  manual
    }

    if (this.config().brand == "viajeypunto") {
      if (this.config().enablefolio) paymenttypeid = -5;
    }

    this.state = {
      user: {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        useraccountid: -1,
        travelinfo: {
          arrivaldate: new Date(),
          departuredate: new Date(),
          hotelid: -1,
          vacationcompanyname: "",
          vacationcompanyid: -1,
          findaboutus: "",
        },
        paymentinfo: {
          name: "",
          paymenttypeid: paymenttypeid,
          creditcardtypeid: -1,
          creditcardnumber: "",
          expirationmonth: -1,
          expirationyear: -1,
          securitycode: "",
          zipcode: "",
        },
        paymentinfo2: {
          name: "",
          paymenttypeid: paymenttypeid,
          creditcardtypeid: -1,
          creditcardnumber: "",
          expirationmonth: -1,
          expirationyear: -1,
          securitycode: "",
          zipcode: "", 
        },
      },
      messages: [],
      processing: false,
      paymentprocessorid: paymentprocessorid, //2:bancomer MST, 3:MIT, 1:WorldPay, 4: Bnacomer Trafic, 5: Ninguno (reserva sin tarjeta),
      acceptTerms: true,
      miturl: false,
      bancomerurl: false,
      hastransportationincluded: 0,
      totalhotels: -1,
      itemdestinationid: null,
      enablepaymenttypes: enablepaymenttypes,

      paymentparams: null,
      usebancomerpoints: false,
      PaymentOptions: {
        usebancomerpoints: 0,
        monthinterestfree: 0,
        months: 0,
        usebbvawallet: 0,
        confirmpaymentafterpoints: -1,
      },
      PaymentOptions2: {
        usebancomerpoints: 0,
        monthinterestfree: 0,
        months: 0,
        usebbvawallet: 0,
        confirmpaymentafterpoints: -1,
      },
      addanothercard: false,
      folio: "",
      BBVAAPIPaymentPath:'',
      WoldPayPaymentPath:''
    };
  }
  paymentprocessorid() {
    var paymentprocessorid = 2;

    if (this.getglobaldata("Id_Currency") != 2) paymentprocessorid = 2;

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget3") {
      if (this.getpaymentprocessorid())
        paymentprocessorid = this.getpaymentprocessorid();
    }

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget4") {
      if (this.getpaymentprocessorid())
        paymentprocessorid = this.getpaymentprocessorid();
    }

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget") {
      if (this.getpaymentprocessorid())
        paymentprocessorid = this.getpaymentprocessorid();
    }

    //this.config().defaultpaymentprocessorid = 3;
    if (this.config().defaultpaymentprocessorid)
      paymentprocessorid = this.config().defaultpaymentprocessorid;

    if (this.config().nocreditcardcharge) {
      //paymentprocessorid = 1;
    }

    if (this.getBookingSiteDetails(paymentprocessorid))
        paymentprocessorid = this.getBookingSiteDetails(paymentprocessorid);

    return paymentprocessorid;
  }

  getpaymentprocessorid() {
    let paymentprocessorid = null;

    if (this.getglobaldata("TourResellersIds")) {
      let destinationinfo = this.getglobaldata("destinationinfo");
      if (this.getglobaldata("TourResellersIds") == "1") {
        paymentprocessorid = 1;
      } else if (this.getglobaldata("TourResellersIds") == "2") {
        paymentprocessorid = 2;
      }
    }

    let Cart = this.getglobaldata("Cart");
    let hastransfers = false;
    Cart.map(function (item, index) {
      if (Number(item.itemtype) == 2) hastransfers = true;
    });

    if (hastransfers) paymentprocessorid = 1;

    return paymentprocessorid;
  }

  gethastransportationincluded() {
    let Cart = this.getglobaldata("Cart");

    if (!Cart) return;

    let hastransportationincluded = 0;
    Cart.map(
      function (item, index) {
        if (
          Number(item.itemtype) == 1 &&
          Number(item.hastransportationincluded) == -1
        )
          hastransportationincluded = -1;

        this.state.itemdestinationid = item.destinationid;
      }.bind(this)
    );

    this.state.hastransportationincluded = hastransportationincluded;

    let destinationid = Number(
      this.getglobaldata("Id_Destination") || this.state.itemdestinationid
    );
    let currencyid = Number(this.getglobaldata("Id_Currency"));

    if (this.BBVATrafic()) {
      if (destinationid == 3 || destinationid == 6)
        this.state.paymentprocessorid = 1;
      else if (currencyid == 2 || currencyid == 1)
        this.state.paymentprocessorid = 2;
      

    }
  }

  BBVATrafic() {
    
    /*if(this.config().brand == "sunexcursions"){
      return true;
    }*/
    
    if (this.config().defaultpaymentprocessorid) {
      let defaulta = this.config().defaultpaymentprocessorid;
      return Number(this.config().defaultpaymentprocessorid) == 4;
    }

    return this.config().brand == "trafictours";
  }

  initiateCheckout(event){
    //fb pixel event
        if (this.getglobaldata("Cart").length > 0) {
          this.fbp().trackCustom(event, {
            content_type: "product",
            //search_string:text,
            //content_id:'1',
            //product_group:['3568354979886981'],
            /*city:destinationinfo.Name?destinationinfo.Name:'',
            country:destinationinfo.country?destinationinfo.country:'',
            region:'',*/
            checkin_date: "",
            checkout_date: "",
            //product_catalog_id:'241939520697998',
            value: this.grandtotal(),
            currency: this.getglobaldata("Cart")[0].currency,
            contents: this.getglobaldata("Cart"),
          });
        }
  }

  getBookingSiteDetails(paymentprocessorid){
    let newPaymentId = null;
    let destinationinfo = this.getglobaldata("destinationinfo");
    let brandInfo = this.config().brand;

    if(destinationinfo !== undefined && Number(destinationinfo.Id_Country) == 167){

      /*if(brandInfo == "sunexcursions"){
        return newPaymentId = 2;
      }*/
    }

    return paymentprocessorid;
  }

  /**
   * Component Did Mount
   * Adding Before Unload Behavior
   **/
  componentDidMount() {
    // this.gethastransportationincluded();

    this.setuser();

    this.getdispatcher(
      "cart.view",
      function (data) {
        this.updateview();
        
        this.initiateCheckout("InitiateCheckout");
       
      }.bind(this)
    );



     this.initiateCheckout("InitiateCheckout");


    this.getdispatcher(
      "select.agency",
      function (data) {
        if (this.config().nocreditcardcharge)
          this.state.user.paymentinfo.paymenttypeid = 4;
        // paymenttypeid = 4 =  manual
        else this.state.user.paymentinfo.paymenttypeid = 1;

        if (this.config().brand == "viajeypunto") {
          if (this.config().enablefolio)
            this.state.user.paymentinfo.paymenttypeid = -5;
        }

        if(this.config().paymenttypeid) this.state.user.paymentinfo.paymenttypeid = this.config().paymenttypeid;

        this.state.paymentprocessorid = this.paymentprocessorid();
        this.validations();
      }.bind(this)
    );

    this.getdispatcher(
      "has.hotels",
      function (data) {
        this.state.totalhotels = data.detail.totalhotels;
        if (data.detail.totalhotels == 0) {
          this.state.hastransportationincluded = 0;
        }

        this.validations();
      }.bind(this)
    );

    this.changepaymenttype();

    //  this.validations();

    window.addEventListener(
      "message",
      function (event) {
        if (event.data.nbResponse == "Aprobado") {
          this.aprovedpayment();
          this.initiateCheckout("Purchase");
        }

        if(event.data.Id_BancomerAPIPayment){
          this.setState({
            BBVAAPIPaymentPath:'/payment/bbva/'+event.data.Id_BancomerAPIPayment
          })
        }

        //console.log('EVENT',event)
      }.bind(this)
    );

    //  window.onbeforeunload = function() { return  this.t("Sure you want leave the site?"); }.bind(this);

    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
    });

    //Send to Google tag Manager
    this.gtmcheckout();
  }

  aprovedpayment() {
    //google analytics event
    this.ga().event({
      category: "Checkout",
      action: "Success payment",
      label: "Success payment",
    });

    $.magnificPopup.close();
    this.successredirect();
  }

  setuser() {
    if (this.getglobaldata("user")) {
      const user = this.getglobaldata("user");
      this.state.user.name = user.FirstName;
      this.state.user.lastname = user.LastName;
      this.state.user.email = user.Email;
      this.state.user.paymentinfo.name = user.FirstName + " " + user.LastName;
      $('input[name="user.name"]').val(this.state.user.name);
      $('input[name="user.lastname"]').val(this.state.user.lastname);
      $('input[name="user.email"]').val(this.state.user.email);
      $('input[name="user.paymentinfo.name"]').val(
        this.state.user.paymentinfo.name
      );
    }
  }

  updateview() {
    if (this.grandtotal() == 0) {
      this.state.user.paymentinfo.paymenttypeid = 5; //Credit
      this.state.enablepaymenttypes = true;
    } else {
      if (this.config().nocreditcardcharge) {
        this.state.user.paymentinfo.paymenttypeid = 4; //manual
        if (this.config().brand == "viajeypunto")
          this.state.user.paymentinfo.paymenttypeid = -3; //manual
      } else {
        this.state.user.paymentinfo.paymenttypeid = 1;
        this.state.enablepaymenttypes = this.config().enablepaymenttypes;
      }
    }

    if (this.config().brand == "viajeypunto") {
      if (this.config().enablefolio)
        this.state.user.paymentinfo.paymenttypeid = -5;
    }

    if(this.config().paymenttypeid) this.state.user.paymentinfo.paymenttypeid = this.config().paymenttypeid;

    //  this.gethastransportationincluded()

    // this.validations();

    this.changepaymenttype();

    this.forceUpdate();
  }

  grandtotal() {
    if (!this.getglobaldata("Cart")) return 0;

    var Cart = this.getglobaldata("Cart");
    //console.log("carrito",Cart);
    var grandtotal = 0;
    Cart.map(function (item, index) {
      grandtotal = parseFloat(
        parseFloat(grandtotal) + parseFloat(item.total)
      ).toFixed(2);
    });

    return Math.round(grandtotal);
  }

  changeinputform(event, name) {
    this.changeinput(event, name);

    var options = {
      callback: this.validations.bind(this),
      clear: true,
      duration: 1, //seg
    };
    this.delay(options);
    //this.validations();
  }

  changearrivaldate(date) {
    this.state.user.travelinfo.arrivaldate = date;
    this.validations();
  }

  changedeparturedate(date) {
    this.state.user.travelinfo.departuredate = date;
    this.validations();
  }

  changehotel(hotel) {
    this.state.user.travelinfo.hotelid = hotel.data;

    this.validations();
  }

  changecreditcardtype(value) {
    var user = this.state.user;
    var paymentinfo = user.paymentinfo;

    if (value != 3) $(".creditcardnumber").attr("maxlength", "16");
    if (value == 3) $(".creditcardnumber").attr("maxlength", "15");

    this.state.user.paymentinfo.creditcardtypeid = value;
    this.validations();
  }

  validations() {
    this.messages();
    this.forceUpdate();
  }


  changepaymentprocessor(event){
    let paymentprocessorid = event.target.value;
    this.setState({paymentprocessorid});
  }

  invalidphonenumbers() {
    return [
      "0000000000",
      "1111111111",
      "2222222222",
      "3333333333",
      "4444444444",
      "5555555555",
      "6666666666",
      "7777777777",
      "8888888888",
      "9999999999",
      "1234567890",
    ];
  }

  messages() {
    var user = this.state.user;
    var travelinfo = user.travelinfo;
    var messages = [];

    if (user.name.trim() == "") messages.push(this.t("Enter First Name"));
    if (user.lastname.trim() == "") messages.push(this.t("Enter Last Name"));

    if (this.config().brand == "viajeypunto") {
      if (this.config().enablefolio) {
        if (this.state.folio == "") messages.push(this.t("Ingresa el folio"));
      }
    }

    if (
      this.state.paymentprocessorid == 1 &&
      this.state.user.paymentinfo.paymenttypeid != -5
    ) {
      messages = messages.concat(this.paymentinfovalidations());

      if (this.state.addanothercard)
        messages = messages.concat(this.paymentinfovalidations2());
    }

    /* if (
      !validator.isMobilePhone(user.phone, ["es-MX"]) ||
      this.invalidphonenumbers().indexOf(user.phone) != -1
    )
     messages.push(this.t("Enter a valid 10 digit phone number"));
*/
    if (user.phone.trim() == "") messages.push(this.t("Enter phone number"));

    if (
      (user.phone.trim().length > 0 && user.phone.trim().length < 10) ||
      this.invalidphonenumbers().indexOf(user.phone) != -1
    )
      messages.push(this.t("Enter a valid 10 digit phone number"));

    if (this.state.totalhotels > 0) {
      if (
        travelinfo.hotelid == -1 &&
        this.state.hastransportationincluded == -1
      )
        messages.push(this.t("Select Hotel"));
    }

    /* @if brand = "bookingwidget3" */
    //if (travelinfo.hotelid == -1 && this.state.hastransportationincluded == -1) messages.push(this.t("Select Hotel"));
    // if (travelinfo.hotelid == -1 && this.state.totalhotels >0) messages.push(this.t("Select Hotel"));
    /* @endif */

    /* @if brand = "bookingwidget4" */
    // if (travelinfo.hotelid == -1 && this.state.totalhotels >0) messages.push(this.t("Select Hotel"));
    /* @endif */

    /* @if brand = "bookingwidget2" */
    // if (travelinfo.hotelid == -1 && this.state.hastransportationincluded == -1) messages.push(this.t("Select Hotel"));
    /* @endif */

    /* @if brand = "bookingwidget" */
    //if (travelinfo.hotelid == -1 && this.state.hastransportationincluded == -1) messages.push(this.t("Select Hotel"));
    /* @endif */

    // if (travelinfo.hotelid == -1) messages.push(this.t("Select Hotel"));

    /*if (travelinfo.arrivaldate == "")
      messages.push(this.t("Select Arrival Date"));
    if (travelinfo.arrivaldate == "")
      messages.push(this.t("Select Departure Date"));
    */

    if (!this.isemail(user.email)) messages.push(this.t("Enter a valid email"));
    /*if(ptravelinfo.vacationcompanyid == -1) messages.push(this.t('Select Vacation Company'));
     if(travelinfo.findaboutus == '') messages.push(this.t('Enter Find About Us'));*/

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget") {
      if (!this.config().travelagencyid)
        messages.push(this.t("Select a Agency"));
    }

    /** Removing Before Unload Behavior **/
    /* if(this.messages === undefined || this.messages === '' || this.messages === []){
      window.onbeforeunload = function() { } // Prevent before unload
    } else{
      window.onbeforeunload = function() { return this.t("Sure you want leave the site?"); }
    }*/

    this.state.messages = messages;
  }

  paymentinfovalidations() {
    var user = this.state.user;
    var messages = [];
    var paymentinfo = user.paymentinfo;

    if (paymentinfo.name == "")
      messages.push(this.t("Enter Name as it appears on the Credit Card"));
    if (paymentinfo.creditcardtypeid == -1)
      messages.push(this.t("Invalid credit card"));

    if (
      !/^[0-9]{16,16}$/i.test(paymentinfo.creditcardnumber) &&
      paymentinfo.creditcardtypeid != 3
    ) {
      messages.push(this.t("Enter credit card number  - 16 Digits"));
    } else if (
      !/^[0-9]{15,15}$/i.test(paymentinfo.creditcardnumber) &&
      paymentinfo.creditcardtypeid == 3
    ) {
      messages.push(this.t("Enter credit card number  - 15 Digits"));
    }

    if (!this.creditCardValidateDate(paymentinfo)) {
      messages.push(this.t("Invalid expiration date"));
      // messages.push(this.t("Select Expiration Month"));
    }
    /* if (paymentinfo.expirationyear == -1)
      messages.push(this.t("Select Expiration Year"));*/

    if (this.state.PaymentOptions.usebbvawallet == 0) {
      if (
        !/^[0-9]{3,3}$/i.test(paymentinfo.securitycode) &&
        paymentinfo.creditcardtypeid != 3
      )
        messages.push(this.t("Enter Security Code (CCV) - 3 Digits"));
      else if (
        !/^[0-9]{4,4}$/i.test(paymentinfo.securitycode) &&
        paymentinfo.creditcardtypeid == 3
      )
        messages.push(this.t("Enter Security Code (CCV) - 4 Digits"));
    }

    if (paymentinfo.zipcode == "") messages.push(this.t("Enter Zip Code"));

    return messages;
  }

  creditCardValidateDate(paymentinfo) {
    var currentmonth = Number(moment().month() + 1);
    var currentyear = String(moment().year());
    var expirationmonth = Number(paymentinfo.expirationmonth);
    var expirationyear = Number(paymentinfo.expirationyear);

    currentyear = Number(currentyear.substring(currentyear.length - 2));

    if (
      expirationmonth == -1 ||
      paymentinfo.expirationyear == -1 ||
      expirationmonth < 1 ||
      expirationmonth > 12 ||
      expirationyear < currentyear ||
      (expirationyear == currentyear && expirationmonth < currentmonth)
    )
      return false;

    return true;
  }

  paymentinfovalidations2() {
    var user = this.state.user;
    var messages = [];
    var paymentinfo = user.paymentinfo2;

    if (paymentinfo.name == "")
      messages.push(this.t("Enter Name as it appears on the Credit Card"));
    if (paymentinfo.creditcardtypeid == -1)
      messages.push(this.t("Invalid credit card 2"));

    if (
      !/^[0-9]{16,16}$/i.test(paymentinfo.creditcardnumber) &&
      paymentinfo.creditcardtypeid != 3
    ) {
      messages.push(this.t("Enter credit card 2 number  - 16 Digits"));
    } else if (
      !/^[0-9]{15,15}$/i.test(paymentinfo.creditcardnumber) &&
      paymentinfo.creditcardtypeid == 3
    ) {
      messages.push(this.t("Enter credit card 2 number  - 15 Digits"));
    }

    if (!this.creditCardValidateDate(paymentinfo)) {
      messages.push(this.t("Invalid expiration date - card 2"));
      // messages.push(this.t("Select Expiration Month"));
    }
    /*
    if (paymentinfo.expirationmonth == -1)
      messages.push(this.t("Select Expiration Month - card 2"));
    if (paymentinfo.expirationyear == -1)
      messages.push(this.t("Select Expiration Year - card 2"));
    */
    if (this.state.PaymentOptions2.usebbvawallet == 0) {
      if (
        !/^[0-9]{3,3}$/i.test(paymentinfo.securitycode) &&
        paymentinfo.creditcardtypeid != 3
      )
        messages.push(this.t("Enter Security Code (CCV) - card 2 - 3 Digits"));
      else if (
        !/^[0-9]{4,4}$/i.test(paymentinfo.securitycode) &&
        paymentinfo.creditcardtypeid == 3
      )
        messages.push(this.t("Enter Security Code (CCV) - card 2 - 4 Digits"));
    }

    if (paymentinfo.zipcode == "")
      messages.push(this.t("Enter Zip Code - card 2"));

    return messages;
  }

  submitpayment() {
    // window.onbeforeunload = function() { } // Remove Prevent before unload
    this.validations();

    if (this.state.messages.length > 0) {
      alert(this.t("Please verify your information"));
      return;
    }

    this.state.processing = true;
    this.forceUpdate();
    this.userregister();
  }

  userregister() {
    if (!this.hoteloptons().enable) this.state.user.travelinfo.hotelid = -1;

    var params = {
      username: this.state.user.email,
      LoginType: "NoPassword",
      Email: this.state.user.email,
      password: "",
      FirstName: this.state.user.name,
      LastName: this.state.user.lastname,
      Destination:
        this.getglobaldata("Id_Destination") || this.state.itemdestinationid,
      Location:
        this.getglobaldata("Id_Destination") &&
        this.getglobaldata("Id_Location")
          ? this.getglobaldata("Id_Location")
          : "",
      //ArrivalDate: moment(this.state.user.travelinfo.arrivaldate).format("YYYY-MM-DD"),
      //DepartureDate: moment(this.state.user.travelinfo.departuredate).format("YYYY-MM-DD"),
      Id_Language: this.getglobaldata("Id_Language"),
      AdultsNum: 1,
      ChildrenNum: 0,
      id_hotel:
        this.state.user.travelinfo.hotelid == -1
          ? ""
          : this.state.user.travelinfo.hotelid,
      //Id_VacationCompany: this.state.user.travelinfo.vacationcompanyid,
      YouFind: this.state.user.travelinfo.findaboutus,
      OtherOperator: "",
      Phone: this.state.user.phone,
    };

    if (this.config().travelagentid)
      params.Id_TravelAgent = this.config().travelagentid;
    if (this.config().travelagencyid)
      params.Id_TravelAgency = this.config().travelagencyid;

    //console.log('params...........',params)
    //return;

    var options = {
      method: "UserRegister",
      body: params,
      callback: function (jsonresponse) {
        this.state.user.useraccountid = jsonresponse.Id_UserAccount;

        this.setuserinfo(jsonresponse);

        this.dobookingcartpayment();
      }.bind(this),
      error: function (jsonresponse) {
        this.state.messages.push(
          this.t(
            "Could not register the account, please try again or contact for help"
          )
        );
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this),
    };

    this.create(options);
  }

  bookingpaymentparams() {
    var cart = this.getcart();

    var paymenttypeid = this.state.user.paymentinfo.paymenttypeid;

    if (this.config().brand == "viajeypunto") {
      if (Number(paymenttypeid) < -1) paymenttypeid = 4;
      if (this.config().enablefolio) paymenttypeid = 5;
    }

    var concept = this.state.concept;

    if(this.config().concept) concept = this.config().concept; 

    var params = {
      concept:concept,
      folio: this.state.folio,
      ReservationName: this.state.user.name + " " + this.state.user.lastname,
      CreditsRebateUsed: "no",
      Id_PaymentType: paymenttypeid,
      Id_CreditCardType: this.state.user.paymentinfo.creditcardtypeid,
      CC_Name: this.state.user.paymentinfo.name,
      CC_Number: this.state.user.paymentinfo.creditcardnumber,
      CC_ExpirationMonth: this.state.user.paymentinfo.expirationmonth,
      CC_ExpirationYear: this.state.user.paymentinfo.expirationyear,
      Id_BookingPlatform: this.config().bookingplatformid
        ? this.config().bookingplatformid
        : 4,
      HotelRoomNum: "",
      CC_CCV: this.state.user.paymentinfo.securitycode,
      ConfirmCode: "",
      Comments: "",
      CC_Address: "",
      CC_Id_Country: -1,
      CC_City: "",
      CC_State: "",
      CC_ZipCode: this.state.user.paymentinfo.zipcode,
      Id_UserAccount: this.state.user.useraccountid,
      // CurrencyRate: 19, //this.getglobaldata('CurrencyRate'),
      Id_Currency: this.getglobaldata("Id_Currency"),
      HotelUnKnown: this.state.user.travelinfo.hotelid == -1 ? true : false,
      Id_SBI: this.config().sbiid,
      usebancomerpoints: this.state.usebancomerpoints ? -1 : 0,
      PaymentOptions: this.state.PaymentOptions,
      Cart: JSON.stringify(cart),
      brand: this.config().brand,
      brandName: this.config().brandname,
    };

    //for Ambassador project
    if (this.getglobaldata("locationSearch.Id_Ambassador")) {
      params.Id_Ambassador = this.getglobaldata("locationSearch.Id_Ambassador");
    }

    if (this.config().mailinfo) params.MailSettings = this.config().mailinfo;

    if (this.config().adminid) params.Id_Admin = this.config().adminid;

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget") {
      if (this.config().brand == "indestinationstore")
        delete params.MailSettings;
    }

    if (this.config().referrerurl)
      params.ReferrerUrl = this.config().referrerurl;

    if (this.state.addanothercard) {
      params.CreditCards = this.getCreditCards();
    }

    params.originUrl = this.originCustomUrl();

    return params;
  }

  getCreditCards() {
    const paymentinfo = this.state.user.paymentinfo;
    const paymentinfo2 = this.state.user.paymentinfo2;

    // console.log("paymentinfotwo", paymentinfo2);

    return [
      {
        Id_CreditCardType: paymentinfo.creditcardtypeid,
        CC_Name: paymentinfo.name,
        CC_Number: paymentinfo.creditcardnumber,
        CC_ExpirationMonth: paymentinfo.expirationmonth,
        CC_ExpirationYear: paymentinfo.expirationyear,
        CC_CCV: paymentinfo.securitycode,
        CC_ZipCode: paymentinfo.zipcode,
        PaymentOptions: this.state.PaymentOptions,
      },
      {
        Id_CreditCardType: paymentinfo2.creditcardtypeid,
        CC_Name: paymentinfo2.name,
        CC_Number: paymentinfo2.creditcardnumber,
        CC_ExpirationMonth: paymentinfo2.expirationmonth,
        CC_ExpirationYear: paymentinfo2.expirationyear,
        CC_CCV: paymentinfo2.securitycode,
        CC_ZipCode: paymentinfo2.zipcode,
        PaymentOptions: this.state.PaymentOptions2,
      },
    ];
  }

  bancomerprocessorparams(custom_params) {
    // window.onbeforeunload = function() { } // Remove Prevent before unload
    var originurl = location.origin;
    var hrefurl = location.href;

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget") {
      hrefurl = document.referrer;
    }

    if (this.config().referrerurl) hrefurl = this.config().referrerurl;

    var bancomerurl =
      originurl +
      (this.config().basename == "" ? "" : "/") +
      this.config().basename +
      "/bancomermessage";

    var params = {  
      ReferrerUrl: hrefurl,
      Amount: this.grandtotal(),
      Id_Currency: this.getglobaldata("Id_Currency"),
      Id_Language: this.getglobaldata("Id_Language"),
      Cart: this.getcart(),
      UrlOK: bancomerurl + "/success",
      UrlKO: bancomerurl + "/error",
      Id_MobileApp: 2, //2 = Totoura //0  = TRAFICTOURS
      ipnBancomer: this.config().apiurl + "ipnBancomer",
    };

    if (this.config().brand == "trafictours" || this.config().brand == "sunexcursions") params.Id_MobileApp = 0;

    if(custom_params){
      params = Object.assign(params,custom_params)
    }

    return params;
  }

  mitprocessorparams() {
    // window.onbeforeunload = function() { } // Remove Prevent before unload
    var originurl = location.origin;
    var hrefurl = location.href;

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget") {
      hrefurl = document.referrer;
    }

    if (this.config().referrerurl) hrefurl = this.config().referrerurl;

    var miturl =
      originurl +
      (this.config().basename == "" ? "" : "/") +
      this.config().basename +
      "/mitmessage";

    var params = {
      ReferrerUrl: hrefurl,
      Id_Currency: this.getglobaldata("Id_Currency"),
      Id_Language: this.getglobaldata("Id_Language"),
      Cart: this.getcart(),
      Id_MobileApp: 2, //Totoura
      amount: this.grandtotal(),
      email: this.state.user.email,
      currency: this.getglobaldata("Id_Currency"),
      UrlOK: miturl + "/success",
      UrlKO: miturl + "/error",
      UrlPE: miturl + "/pending",
    };

    return params;
  }


  paramsStripe(){

    var params = $.extend(
      this.bookingpaymentparams(),
      this.mitprocessorparams()
    );

    if(params.Id_PaymentType == 5){
      params.createPaymentUrl = true;
    }

    return params;

  }

  domitpayment() {
    
    this.setState(
      { paymentparams: this.paramsStripe(), processing: false },
      function () {
        this.state.paymentparams = false;

        this.focusdiv(".checkboxacceptterms");

        //this.openmitpopup();
      }.bind(this)
    );

    /* var options = {
      method: "doMITPayment",
      body: {params:params},
      callback: function (jsonresponse) {

        var miturl = jsonresponse.mit_url_payment.replace('"','');
        
        this.setState({processing:false,miturl:miturl.replace('"','')})
        
        this.openmitpopup();

      }.bind(this),
      error: function (jsonresponse) {
        
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this)
    };

    this.create(options);
    */
  }

  dobancomerpaymentv1() {
    var params = $.extend(
      this.bookingpaymentparams(),
      this.bancomerprocessorparams()
    );

    params.fromajax = true;

    
    
    if (this.getglobaldata("user")) {
      const user = this.getglobaldata("user");
      params.UserAccessToken = user.UserAccessToken;
      params.DeviceAccessToken = user.DeviceAccessToken;
    }

    var bf = new Buffer(JSON.stringify(params));
    var paramsencoded = bf.toString("base64");

    var bancomerurl =
      this.config().apiurl + "doBancomerPayment?params=" + paramsencoded;

    this.setState(
      { processing: false, bancomerurl: bancomerurl },
      function () {
        this.createbancomeriframe();
        this.openbancomerpopup();
      }.bind(this)
    );
  }

  paramsBancomerPaymentv2(){

    var originurl = location.origin;

    var urlok =
      originurl +
      (this.config().basename == "" ? "" : "/") +
      this.config().basename +
      "/purchase/detail/cart/payment";



    var params = $.extend(
      this.bookingpaymentparams(),
      this.bancomerprocessorparams()
    );

   // params.UrlOK = urlok;

    params.fromajax = true;

    

    if(params.Id_PaymentType == 5){
      params.createPaymentUrl = true;
    }

    
    if(this.config().msi_bbva){
      params.msi_bbva = this.config().msi_bbva;
      params.msi_bbva_with_interest = this.config().msi_bbva_with_interest;
      params.msi_bbva_payments = this.config().msi_bbva_payments;
      params.msi_bbva_deferred = this.config().msi_bbva_deferred;
      params.msi_bbva_min_amount = this.config().msi_bbva_min_amount;
      params.msi_bbva_min_amount_currency = this.config().msi_bbva_min_amount_currency;
    }

    var bf = new Buffer(JSON.stringify(params));
    return bf.toString("base64");




  }

  dobancomerpaymentv2() {
    var paramsencoded = this.paramsBancomerPaymentv2();

    var options = {
      method: "doBancomerPaymentV2",
      body: { params: paramsencoded},
      callback: function (jsonresponse) {
        var bancomerurl = jsonresponse.url;

        this.setState(
          { processing: false, bancomerurl: bancomerurl,BBVAAPIPaymentPath:'/payment/bbva2/'+jsonresponse.Id_BancomerAPIPayment },
          function () {

            //location.href = bancomerurl;
            this.createbancomeriframe();
            this.openbancomerpopup();
          }.bind(this)
        );
      }.bind(this),
      error: function (jsonresponse) {
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this),
    };

    this.create(options);
  }

  dobookingcartpayment() {
    if (this.state.paymentprocessorid == 2) {
      if (this.BBVATrafic()) this.dobancomerpaymentv1();
      else this.dobancomerpaymentv2();
    } else if (this.state.paymentprocessorid == 3) {
      this.domitpayment();
    } else {
      this.otherpaymentprocessor();
    }

  }

  successredirect() {
    this.setglobaldata("Cart", []);
    this.pushnavigate("/purchase/detail/cart/payment");
  }

  otherpaymentprocessor() {
    // console.log('this.bookingpaymentparams()',this.bookingpaymentparams())

    // return;

    var params = this.bookingpaymentparams();
    
    //si la reservacion es reserve 24 horas creamos stripe y BBVA pagos
    if(params.Id_PaymentType == 5){
      params.paramsBBVAv2 = this.paramsBancomerPaymentv2();
      params.paramsStripe = this.paramsStripe(); 
      params.paramsWorldPay = {
        url_base:this.originCustomUrl(),
        amount:this.grandtotal(),
        id_currency:this.getglobaldata("Id_Currency"),
        merchant_code:1}
    }

    var options = {
      method: "doBookingCartPayment",
      body: params,
      type:'POST',
      callback: function (jsonresponse) {
        this.successredirect();
      }.bind(this),
      error: function (jsonresponse) {
        this.state.messages.push(this.t(jsonresponse.errorMessage));
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this),
    };

    this.create(options);
  }

  getcart() {
    var globalcart = this.getglobaldata("Cart");

    var cart = [];

    var concept = [];

    globalcart.map(function (item, index) {
      
      concept.push(item.tourname?item.tourname:item.tranfertype)

      var item_ = {};

      if (item.itemtype == 1) {
        item_.Id_Destination = item.destinationid;
        item_.ItemType = item.itemtype;
        item_.Id = item.tourid;
        item_.ActivityDate = moment(item.filters.activitydate).format(
          "YYYY-MM-DD"
        );
        item_.ActivityTime = item.filters.activitytime;
        item_.AdultsNum = item.adults.qty;
        item_.KidNum = 0;
        item_.ChildrenNum = item.children.qty;
        item_.ChildFreeNum = item.childrenfree.qty;
        item_.TourExtras = item.extras;
        item_.CouponInfo = {};
        item_.CouponInfo.Name = item.coupon.name;
        item_.CouponInfo.DiscountAmount = item.coupon.discount;
        item_.CouponInfo.Code = item.coupon.code;
        item_.TourExtras = item.filters.extras;
        item_.Id_ActivityRate = item.activityrateid;
        item_.ChildrenAges = item.filters.childrenages.join(",");
        item_.Id_ModalityType = item.Id_ModalityType;
        item_.extendedChilds = item.extendedChilds;
        item_.extendedAdults = item.extendedAdults;
      }

      item_.isbZeroCommission = item.commisionzero;
      item_.isZeroCommission = item.commisiondiscount;
      item_.leadname = item.leadname;

      if (item.itemtype == 2) {
        let childrenages = [];

        item.childrenages.map(function (childrenage, index) {
          childrenages.push(childrenage.value);
        });

        item_.ChildrenAges = childrenages.join(",");
        item_.ItemType = item.itemtype;
        item_.UserTravelInfo = {
          ArrivalDate: moment(item.arrivalActivityDateTime).format(
            "YYYY-MM-DD"
          ),
          DepartureDate: moment(item.departureActivityDateTime).format(
            "YYYY-MM-DD"
          ),
          DepartureTime: moment(item.departureActivityDateTime).format("HH:mm"),
          ArrivalTime: moment(item.arrivalActivityDateTime).format("HH:mm"),
          Id_ArrivalAirline: item.arrivalairlineid,
          ArrivalFlightNum: item.arrivalflight,
          Id_DepartureAirline: item.departureairlineid,
          DepartureFlightNum: item.departureflight,
        };

        item_.TransferParams = {
          Id_Transfer: item.transferid,
          Id_TransferPrice: item.transferpriceid,
          Id_ArrivalAirport: item.arrivalairportid,
          Id_DepartureAirport: item.departureairportid,
          Id_Hotel: item.hotelid,
          Details: {
            AdultsNumber: item.adults.qty,
            KidNum: 0,
            ChildrenNum: item.children.qty,
            ChildFreeNum: item.childrenfree.qty,
            ChildsAdultsNum: "0",
            TripType: item.triptypeid,

            CouponInfo: {
              Id_Coupon: item.coupon.Id_Coupon,
              DiscountAmount: item.coupon.discount,
              Name: item.coupon.name,
              Code: item.coupon.code,
            },
          },
        };
      }

      cart.push(item_);
    });

    this.state.concept = concept.join(" | ");

    return cart;
  }

  checkTerms(event) {
    this.setState({
      acceptTerms: event.target.checked,
    });
  }

  checkPaymentAfterPoints(event) {
    var value_;
    if (event.target.checked === true) value_ = -1;
    if (event.target.checked === false) value_ = 0;
    this.state.PaymentOptions.confirmpaymentafterpoints = value_;
    this.setState({}, () => {
      //console.log(this.state.PaymentOptions);
    });
  }

  checkBancomerPoints(index, event) {
    var value = event.target.value;

    if (index == "") {
      if (this.state.PaymentOptions.usebancomerpoints == -1)
        this.state.PaymentOptions.usebancomerpoints = 0;
      else this.state.PaymentOptions.usebancomerpoints = -1;
    }

    if (index == 2) {
      if (this.state.PaymentOptions2.usebancomerpoints == -1)
        this.state.PaymentOptions2.usebancomerpoints = 0;
      else this.state.PaymentOptions2.usebancomerpoints = -1;
    }

    // console.log("this.state.PaymentOptions2", this.state.PaymentOptions2);
    // console.log("this.state.PaymentOptions1", this.state.PaymentOptions);

    this.setState({});
  }

  setCreditCardIssuer(index, issuer) {
    // console.log("issuer checkpot", issuer);
    // console.log("index", index);

    if (index == 0) index = "";

    let creditcardtypeoptions = {
      name: `user.paymentinfo${index}.creditcardtypeid`,
      value: -1,
    };

    if (issuer.name == "visa") {
      creditcardtypeoptions.value = 1;
    }

    if (issuer.name == "amex") {
      creditcardtypeoptions.value = 3;
    }

    if (issuer.name == "mastercard") {
      creditcardtypeoptions.value = 2;
    }

    if (issuer.name == "discover") {
      creditcardtypeoptions.value = 4;
    }

    this.changeinputform({}, creditcardtypeoptions);
  }

  resetBBVABenefits(index) {
    if (index == 0) index = "";

    if (index == "") {
      $("#bbvabenefits").val("none");
      this.state.PaymentOptions.monthinterestfree = 0;
      this.state.PaymentOptions.months = 0;
      // this.state.user.paymentinfo.paymenttypeid = -3;
    }

    if (index == 2) {
      $("#bbvabenefits2").val("none");

      this.state.PaymentOptions2.monthinterestfree = 0;
      this.state.PaymentOptions2.months = 0;
      //this.state.user.paymentinfo2.paymenttypeid = -3;
    }
  }

  resetPaymentOptions(index) {
    if (index == 0) index = "";

    if (index == "") {
      this.state.PaymentOptions.usebbvawallet = 0;
      this.state.PaymentOptions.usebancomerpoints = 0;
    }

    if (index == 2) {
      this.state.PaymentOptions2.usebbvawallet = 0;
      this.state.PaymentOptions2.usebancomerpoints = 0;
    }
  }

  disabledBBVABenefitsForm(index) {
    if (index == 0) index = "";

    if (index == "") {
      $(".bbvapaymenttypes").attr("disabled", "disabled");
      $("#customCheckwallet").attr("disabled", "disabled");
      $("#customCheckpoints").attr("disabled", "disabled");
      $("#bbvabenefits").attr("disabled", "disabled");
    }

    if (index == 2) {
      $(".bbvapaymenttypes2").attr("disabled", "disabled");
      $("#customCheckwallet2").attr("disabled", "disabled");
      $("#customCheckpoints2").attr("disabled", "disabled");
      $("#bbvabenefits2").attr("disabled", "disabled");
    }
  }

  enableBBVABenefitsForm(index) {
    if (index == 0) index = "";

    if (index == "") {
      $(".bbvapaymenttypes").removeAttr("disabled");
      $("#customCheckwallet").removeAttr("disabled");
      if (this.state.user.paymentinfo.paymenttypeid != -3) {
        $("#customCheckpoints").removeAttr("disabled");
        $("#bbvabenefits").removeAttr("disabled");
      }
    }

    if (index == 2) {
      $(".bbvapaymenttypes2").removeAttr("disabled");
      $("#customCheckwallet2").removeAttr("disabled");
      if (this.state.user.paymentinfo2.paymenttypeid != -3) {
        $("#customCheckpoints2").removeAttr("disabled");
        $("#bbvabenefits2").removeAttr("disabled");
      }
    }
  }

  creditCardChange(index, data) {
    console.log("Creditcard datos", data);

    if (index == 0) index = "";

    //  console.log("index2", index);
    let creditcardtypeid =
      index == ""
        ? this.state.user.paymentinfo.creditcardtypeid
        : this.state.user.paymentinfo2.creditcardtypeid;

    if (data.name == "number") {
      data.name = `user.paymentinfo${index}.creditcardnumber`;
      data.value = data.value.split(" ").join("");

      if (
        (creditcardtypeid == 3 && data.value.length > 14) ||
        (creditcardtypeid != 3 && data.value.length > 15 && !data.isBBVA)
      ) {
        this.resetBBVABenefits(index);
        this.resetPaymentOptions(index);
        this.disabledBBVABenefitsForm(index);
      } else {
        this.enableBBVABenefitsForm(index);
      }
    }

    if (data.name == "expiry") {
      let expirySplit = data.value.split("/");

      let month = expirySplit[0];
      let year = -1;

      if (expirySplit[1]) year = expirySplit[1];

      data.name = `user.paymentinfo${index}.expirationmonth`;
      data.value = month;

      this.changeinputform({}, data);

      data.name = `user.paymentinfo${index}.expirationyear`;
      data.value = year;
    }

    if (data.name == "cvc") data.name = `user.paymentinfo${index}.securitycode`;
    if (data.name == "zipcode") data.name = `user.paymentinfo${index}.zipcode`;
    if (data.name == "name") data.name = `user.paymentinfo${index}.name`;

    this.changeinputform({}, data);
  }

  openmitpopup() {
    $.magnificPopup.open({
      items: {
        src: "#payment_p",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
      callbacks: {
        open: function () {
          //google analytics event
          this.ga().event({
            category: "Checkout",
            action: "Open MIT processor Modal",
            label: "Open MIT processor Modal",
          });
        }.bind(this),

        close: function () {}.bind(this),
      },
    });
  }

  worldPayGeneratePaymentLink(){
    console.log("todo bien");

    //user register params
    if (!this.hoteloptons().enable) this.state.user.travelinfo.hotelid = -1;

    var params_user_register = {
      username: this.state.user.email,
      LoginType: "NoPassword",
      Email: this.state.user.email,
      password: "",
      FirstName: this.state.user.name,
      LastName: this.state.user.lastname,
      Destination:
        this.getglobaldata("Id_Destination") || this.state.itemdestinationid,
      Location:
        this.getglobaldata("Id_Destination") &&
        this.getglobaldata("Id_Location")
          ? this.getglobaldata("Id_Location")
          : "",
      //ArrivalDate: moment(this.state.user.travelinfo.arrivaldate).format("YYYY-MM-DD"),
      //DepartureDate: moment(this.state.user.travelinfo.departuredate).format("YYYY-MM-DD"),
      Id_Language: this.getglobaldata("Id_Language"),
      AdultsNum: 1,
      ChildrenNum: 0,
      id_hotel:
        this.state.user.travelinfo.hotelid == -1
          ? ""
          : this.state.user.travelinfo.hotelid,
      //Id_VacationCompany: this.state.user.travelinfo.vacationcompanyid,
      YouFind: this.state.user.travelinfo.findaboutus,
      OtherOperator: "",
      Phone: this.state.user.phone,
    };

    if (this.config().travelagentid){
      params_user_register.Id_TravelAgent = this.config().travelagentid;
    }
    if (this.config().travelagencyid){
      params_user_register.Id_TravelAgency = this.config().travelagencyid;
    }

    var options = {
      method: "UserRegister",
      body: params_user_register,
      callback: function (jsonresponse) {
        this.state.user.useraccountid = jsonresponse.Id_UserAccount;

        this.setuserinfo(jsonresponse);

        /////////////generar la liga de pago
        var params = this.bookingpaymentparams();
        console.log(params);

        var bf = new Buffer(JSON.stringify(params));
        bf = bf.toString("base64");

        var options_worldpay = {
          method: "worldPayPaymentLink",
          body: {params:bf,total:this.grandtotal(),Id_Currency:this.getglobaldata("Id_Currency")},
          type:'POST',
          callback: function (jsonresponse) {

            if(jsonresponse.result == true){
              console.log(jsonresponse);
              this.setState({
                WoldPayPaymentPath:'/payment/worldpay/'+jsonresponse.Id_WorldPayApiPayment
              });
            }else{
              this.state.messages.push(
                this.t(
                  "Could not generate payment link, please try again or contact for help"
                )
              );
              this.state.processing = false;
              this.forceUpdate();
            }
          }.bind(this),
          error: function (jsonresponse) {
            this.state.messages.push(
              this.t(
                "Could not generate payment link, please try again or contact for help"
              )
            );
            this.state.processing = false;
            this.forceUpdate();
          }.bind(this),
        };
        this.create(options_worldpay);
        /////////////generar la liga de pago

      }.bind(this),
      error: function (jsonresponse) {
        this.state.messages.push(
          this.t(
            "Could not register the account, please try again or contact for help"
          )
        );
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this),
    };

    this.create(options);
  }

  openbancomerpopup() {
    $.magnificPopup.open({
      items: {
        src: "#payment_bancomer",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
      callbacks: {
        open: function () {
          //google analytics event
          this.ga().event({
            category: "Checkout",
            action: "Open BANCOMER processor Modal",
            label: "Open BANCOMER processor Modal",
          });
        }.bind(this),

        close: function () {
          //this.setState({bancomerurl:false})
          $("#bancomer-iframe").empty();
        }.bind(this),
      },
    });
  }

  /**
   * @function open3dpopup
   **/
  open3dpopup() {
    $.magnificPopup.open({
      items: {
        src: "#securem",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
      callbacks: {
        open: function () {},

        close: function () {
          //this.state.bancomerurl = false;
        }.bind(this),
      },
    });
  }

  hoteloptons() {
    let options = {
      mandatory: false,
      enable: true,
    };

    if (this.state.totalhotels == 0) options.enable = false;

    if (
      this.state.hastransportationincluded == -1 &&
      this.state.totalhotels > 0
    )
      options.mandatory = true;

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget2") {
      if (this.state.hastransportationincluded == 0) {
        options.enable = false;
        options.mandatory = false;
      }

      if (this.config().brand == "avis") {
        options.enable = false;
        options.mandatory = false;
      }
    }

    return options;
  }

  onResize(width, height) {
    if (this.ismobile()) {
      var widthmodalcontent = $(".small-dialog-header").width();

      var iframewidth = widthmodalcontent - 20;

      $("iframe").attr("style", "width:" + iframewidth + "px;height:100%");
    }

    //$('.width1').text("("+widthmodalcontent+")")
    //$('.width2').text("("+iframewidth+")")

    return <div></div>;
  }

  createbancomeriframe() {
  

    var src = this.state.bancomerurl;
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", src);
    ifrm.setAttribute("allowfullscreen", true);
    ifrm.setAttribute("frameborder", "0");
    ifrm.setAttribute("scrolling", "no");

    $("#bancomer-iframe").empty();
    document.getElementById("bancomer-iframe").appendChild(ifrm);

    $("#bancomer-iframe iframe").on(
      "load",
      function () {
        this.onload();

        if (this.ismobile()) {
          $("#payment_bancomer").attr("style", "height:1850px");
          (this.BBVATrafic())
            ? $("#bancomer-iframe iframe").attr("style", "height:1800px;top: -45px;position: relative;")
            :$("#bancomer-iframe iframe").attr("style", "height:1800px;top: -15px;position: relative;");
          
        } else {
          $("#payment_bancomer").attr("style", "height:1550px");
          (this.BBVATrafic())
            ? $("#bancomer-iframe iframe").attr("style", "height:1800px;top: -45px;position: relative;")
            :$("#bancomer-iframe iframe").attr("style", "height:1490px;top: -15px;position: relative;");;
        }

      }.bind(this)
    );
  }

  changepaymenttype(event) {
    //if(!this.state.enablepaymenttypes) return;

    let paymenttypeid = this.state.user.paymentinfo.paymenttypeid;

    if (event) if (event.target) paymenttypeid = event.target.value;

    if (Number(paymenttypeid) == 1) {
      this.state.paymentprocessorid = this.paymentprocessorid();
      this.gethastransportationincluded();
    } else if (Number(paymenttypeid) == 5) {
      this.gethastransportationincluded();
      this.state.paymentprocessorid = 5;
    }

    this.validations();
  }

  changepaymenttypeoptions(index, event) {
    //if(!this.state.enablepaymenttypes) return;

    // console.log("ondex", index);

    var paymenttypeid = event.target.value;

    if (Number(paymenttypeid) == -3) {
      if (index == "") this.state.PaymentOptions.usebancomerpoints = 0;
      if (index == 2) this.state.PaymentOptions2.usebancomerpoints = 0;
    }

    if (index == "")
      this.state.user.paymentinfo.paymenttypeid = Number(paymenttypeid);
    if (index == 2)
      this.state.user.paymentinfo2.paymenttypeid = Number(paymenttypeid);

    if (
      Number(paymenttypeid) == -2 ||
      Number(paymenttypeid) == -3 ||
      Number(paymenttypeid) == -4 ||
      Number(paymenttypeid) == -6
    ) {
      this.state.paymentprocessorid = 1;
      this.gethastransportationincluded();
    }

    /* if (Number(paymenttypeid) == -4) {
      if (index == 0) this.state.PaymentOptions.usebancomerpoints = -1;
      if (index == 2) this.state.PaymentOptions2.usebancomerpoints = -1;
      // this.state.PaymentOptions.monthinterestfree = 0;
      // this.state.PaymentOptions.months = 0;
      // this.state.PaymentOptions.usebbvawallet = 0;
    } else {
      if (index == 0) this.state.PaymentOptions.usebancomerpoints = 0;
      if (index == 2) this.state.PaymentOptions2.usebancomerpoints = 0;
    }*/

    /* if(Number(paymenttypeid) != -2){
      $("#bbvabenefits").val('none');
      this.state.PaymentOptions.usebancomerpoints = 0;
      this.state.PaymentOptions.monthinterestfree = 0;
      this.state.PaymentOptions.months = 0;
     // this.state.PaymentOptions.usebbvawallet = 0;
    }*/

    /* if (Number(paymenttypeid) == -5) {
      this.gethastransportationincluded();
      this.state.paymentprocessorid = 5;
    }*/

    /* if (Number(paymenttypeid) == -6) {
      this.state.addanothercard = true;
    } else this.state.addanothercard = false;*/

    this.resetBBVABenefits(index);

    this.validations();
  }

  onload() {
    // console.log("onlaoded");
    this.scrolltop(".mfp-container");
  }

  changeBBVAWallet(index, event) {
    // var value = event.target.value;

    if (index == "") {
      if (this.state.PaymentOptions.usebbvawallet == -1)
        this.state.PaymentOptions.usebbvawallet = 0;
      else this.state.PaymentOptions.usebbvawallet = -1;
    }

    if (index == 2) {
      if (this.state.PaymentOptions2.usebbvawallet == -1)
        this.state.PaymentOptions2.usebbvawallet = 0;
      else this.state.PaymentOptions2.usebbvawallet = -1;
    }

    this.setState({});
  }

  changePaymentOptions(index, event) {
    var value = event.target.value;

    if (index == 0) {
      if (value == "none") {
        // this.state.PaymentOptions.usebancomerpoints = 0;
        this.state.PaymentOptions.monthinterestfree = 0;
        this.state.PaymentOptions.months = 0;
        // this.state.PaymentOptions.usebbvawallet = 0;
      } else {
        // this.state.PaymentOptions.usebancomerpoints = 0;
        this.state.PaymentOptions.monthinterestfree = -1;
        this.state.PaymentOptions.months = value;
        // this.state.PaymentOptions.usebbvawallet = 0;
      }
    }

    if (index == 2) {
      if (value == "none") {
        // this.state.PaymentOptions.usebancomerpoints = 0;
        this.state.PaymentOptions2.monthinterestfree = 0;
        this.state.PaymentOptions2.months = 0;
        // this.state.PaymentOptions.usebbvawallet = 0;
      } else {
        // this.state.PaymentOptions.usebancomerpoints = 0;
        this.state.PaymentOptions2.monthinterestfree = -1;
        this.state.PaymentOptions2.months = value;
        // this.state.PaymentOptions.usebbvawallet = 0;
      }
    }

    this.setState({});
  }

  addAnotherCard(event) {
    this.eventpreventdefault(event);

    var states = {
      addanothercard: !this.state.addanothercard,
    };

    if (this.state.addanothercard) {
      states.paymentinfo2 = {
        name: "",
        paymenttypeid: 1,
        creditcardtypeid: -1,
        creditcardnumber: "",
        expirationmonth: -1,
        expirationyear: -1,
        securitycode: "",
        zipcode: "",
      };

      states.PaymentOptions2 = {
        usebancomerpoints: 0,
        monthinterestfree: 0,
        months: 0,
        usebbvawallet: 0,
        confirmpaymentafterpoints: -1,
      };
    }

    this.setState(
      states,
      function () {
        this.validations();
      }.bind(this)
    );
  }

  BBVABenefits(index) {
    let paymenttypeid = this.state.user.paymentinfo.paymenttypeid;

    if (index == 0) index = "";

    var usebancomerpoints =
      index == ""
        ? this.state.PaymentOptions.usebancomerpoints
        : this.state.PaymentOptions2.usebancomerpoints;
    var usebbvawallet =
      index == ""
        ? this.state.PaymentOptions.usebbvawallet
        : this.state.PaymentOptions2.usebbvawallet;

    var bbvabenefitsdisable =
      this.grandtotal() < 1300 && Number(this.getglobaldata("Id_Currency")) == 2
        ? true
        : false;
    var bbvabenefitsmin =
      this.grandtotal() < 1300 && Number(this.getglobaldata("Id_Currency")) == 2
        ? " - Minimo $1300 MXN"
        : "";
    var disablebancomerpoints = false;
    var disableMSI = false;

    var usebbvawalletcheck =
      Number(this.state.PaymentOptions.usebbvawallet) == -1 ? true : false;
    var usebbvapointscheck =
      Number(this.state.PaymentOptions.usebancomerpoints) == -1 ? true : false;

    if (index == "") {
      if (Number(this.state.user.paymentinfo.paymenttypeid) != -2) {
        bbvabenefitsdisable = true;
        disableMSI = true;
      }

      disablebancomerpoints =
        Number(this.state.user.paymentinfo.paymenttypeid) == -3 ? true : false;
    } else if (index == 2) {
      usebbvawalletcheck =
        Number(this.state.PaymentOptions2.usebbvawallet) == -1 ? true : false;
      paymenttypeid = Number(this.state.user.paymentinfo2.paymenttypeid);

      if (Number(this.state.user.paymentinfo2.paymenttypeid) != -2) {
        bbvabenefitsdisable = true;
        disableMSI = true;
      }

      usebbvapointscheck =
        Number(this.state.PaymentOptions2.usebancomerpoints) == -1
          ? true
          : false;

      disablebancomerpoints =
        this.state.user.paymentinfo2.paymenttypeid == -3 ? true : false;
    }

    if (this.state.user.paymentinfo.paymenttypeid == -5)
      return (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>{this.t("Folio")}</label>
              <span className="text-danger"> *</span>
              <input
                onChange={this.changeinputform.bind(this)}
                type="text"
                className="form-control"
                name="folio"
              />
            </div>
          </div>
        </div>
      );

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>{this.t("Payment type")}</label>
            <select
              className={`form-control bbvapaymenttypes${index}`}
              name={`user.paymentinfo${index}.paymenttypeid`}
              value={paymenttypeid}
              onChange={this.changepaymenttypeoptions.bind(this, index)}
            >
              <option value={-1} disabled>
                {this.t("Select payment type")}
              </option>
              <option value={-3}>{this.t("Tarjeta de debito")}</option>
              <option value={-2}>{this.t("Credit Card")}</option>
            </select>
          </div>
        </div>

        <div className="col-sm-6">
          <label htmlFor={`bbvabenefits${index}`} className="">
            {this.t("Beneficios BBVA")}
          </label>

          {disableMSI ? (
            <select
              className="form-control"
              id={`bbvabenefits${index}`}
              disabled
            >
              <option value="none">{this.t("Precio en un Solo Pago")}</option>
            </select>
          ) : (
            <select
              className="form-control"
              onChange={this.changePaymentOptions.bind(this, index)}
              id={`bbvabenefits${index}`}
            >
              <option value="none">{this.t("Precio en un Solo Pago")}</option>

              {/* <option value="3" disabled={bbvabenefitsdisable}>
              3 {this.t("meses sin intereses")}
              {bbvabenefitsmin}
            </option>
          */}
              <option value="6" disabled={bbvabenefitsdisable}>
                6 {this.t("meses sin intereses")}
                {bbvabenefitsmin}
              </option>
              <option value="12" disabled={bbvabenefitsdisable}>
                12 {this.t("meses sin intereses")}
                {bbvabenefitsmin}
              </option>
              <option value="15" disabled={bbvabenefitsdisable}>
                15 {this.t("meses sin intereses")}
                {bbvabenefitsmin}
              </option>
              <option value="18" disabled={bbvabenefitsdisable}>
                18 {this.t("meses sin intereses")}
                {bbvabenefitsmin}
              </option>

              {/*<option value="21" disabled={bbvabenefitsdisable}>
              21 {this.t("meses sin intereses")}
              {bbvabenefitsmin}
            </option>
             */}

              <option value="Skip &Payment 3+13" disabled={bbvabenefitsdisable}>
                Skip &Payment 3+13 {this.t("meses sin intereses")}
                {bbvabenefitsmin}
              </option>
            </select>
          )}
        </div>

        <div className="col-sm-6">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              checked={usebbvawalletcheck}
              id={`customCheckwallet${index}`}
              value="bbvawallet"
              onClick={this.changeBBVAWallet.bind(this, index)}
            />
            <label class="form-check-label" for={`customCheckwallet${index}`}>
              {this.t("Usar BBVA Wallet")}
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              checked={usebbvapointscheck}
              id={`customCheckpoints${index}`}
              value="bbvapoints"
              onClick={this.checkBancomerPoints.bind(this, index)}
              disabled={disablebancomerpoints}
            />
            <label class="form-check-label" for={`customCheckpoints${index}`}>
              {this.t("Use bancomer points")}
            </label>
          </div>
        </div>

        <div className="col-sm-12">
          {this.config().brand == "viajeypunto" ? (
            <ul className="text-danger">
              {usebancomerpoints == -1 ? (
                <li>
                  {this.t(
                    "I confirm the missing payment if I do not have enough points"
                  )}
                </li>
              ) : (
                ""
              )}
              {usebbvawallet == -1 ? (
                <li>
                  {this.t(
                    "Con esta forma de pago se comunicaran con usted para validar su codigo de seguridad Digital (CVV)"
                  )}
                </li>
              ) : (
                ""
              )}
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }



  copyUrl(){

  /* Get the text field */
  var copyText = document.getElementById("copyBBVAPaymentUrl");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

   /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.value);

  /* Alert the copied text */
  //alert("Url copiada");
}  

copyWorldPayUrl(){

  /* Get the text field */
  var copyText = document.getElementById("copyWorldPayPaymentUrl");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

   /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.value);

  /* Alert the copied text */
  //alert("Url copiada");
}

  PaymentProcessorsOptions(){

  	let options = [];
  	let list = this.config().paymentprocessorslist;

  
    if(!list){
  	options.push(<option value="2">{this.t("BBVA")}</option>);

  	options.push(<option value="3">{this.t("Stripe")}</option>);

  	options.push(<option value="1">{this.t("WorldPay")}</option>);
  }else{
     
     if(list.includes(2)){
       options.push(<option value="2">{this.t("MST BBVA")}</option>);
     }
     
     if(list.includes(3)){
  	  options.push(<option value="3">{this.t("MST Stripe")}</option>);
  	 }
     
     if(list.includes(1)){
  	   options.push(<option value="1">{this.t("WorldPay")}</option>);
  	 }

  	 if(list.includes(4)){
  	   options.push(<option value="1">{this.t("BBVA Trafic")}</option>);
  	 }


  }


    return options;

  }


  originCustomUrl(){
    return this.config().customUrl && window.self !== window.top?this.config().customUrl:window.location.origin+window.location.pathname.replace('/checkout','');
          
  }


  render() {
    var Cart = this.getglobaldata("Cart") || [];
    var user = this.state.user;
    var messages = this.state.messages;

    var paymentprocessorid = this.state.paymentprocessorid;

    const originCustomUrl = this.originCustomUrl();
   

    var originurl = location.origin;
    var originpath =
      originurl +
      (this.config().basename == "" ? "" : "/") +
      this.config().basename;
    var termsurl = originpath + "/terms";
    var privacyurl = originpath + "/privacy";

    var cardimage = "img/cards.png";

    if (this.config().brand == "viajeypunto") cardimage = "img/cards_2.png";

    var creditCardDisabledFields = [];
    if (this.state.PaymentOptions.usebbvawallet == -1) {
      creditCardDisabledFields.push("cvc");
    }

    var creditCardDisabledFields2 = [];
    if (this.state.PaymentOptions2.usebbvawallet == -1) {
      creditCardDisabledFields2.push("cvc");
    }

    if (Cart.length == 0) return <Section origin="empty.cart" />;

    return (
      <div>
        <Section origin="checkout" />
        {/* End section */}
        <main>
          <div id="position">
            <div className="container">
              <ul>
                <li>
                  <NavLink to="/home">{this.t("Home")}</NavLink>
                </li>
                <li>{this.t("Checkout")}</li>
              </ul>
            </div>
          </div>
          {/* End Position */}
          <div className="container margin_60">
            <div className="row">
              {/* Col-lg-8 */}
              <div className="col-lg-8">
                <div onChange={this.changeinputform.bind(this)}>
                  <button
                    className="btn_category"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse_tour"
                    aria-expanded="false"
                    aria-controls="collapse_tour"
                  >
                    {this.t("Cart List")}
                  </button>
                  <div className="collapse show" id="collapse_tour">
                    <div className="card_tours">
                      <div className="tour_container">
                        <div className="row">
                          <div className="col-lg-12 color_citylife">
                            <CartList updateview={this.updateview.bind(this)} />
                          </div>
                        </div>{" "}
                        {/*end row*/}
                      </div>{" "}
                      {/*end tour container*/}
                    </div>
                  </div>
                  {/*end collapse*/}

                  <div className="mt-30" />
                  <div className="form_title">
                    <h3>
                      <strong>1</strong>
                      {this.t("Lead Name on Reservation")}
                    </h3>
                    <p>{this.t("Your Details")}</p>
                  </div>
                  <div className="step">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>{this.t("First name")}</label>
                          <span className="text-danger"> *</span>
                          <input
                            type="text"
                            className="form-control"
                            name="user.name"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>{this.t("Last name")}</label>
                          <span className="text-danger"> *</span>
                          <input
                            type="text"
                            className="form-control"
                            name="user.lastname"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*End step */}

                  {/*****************************************Start Billing Details *******************************************/}
                  
                  {this.state.enablepaymenttypes ? (
                    <div>
                      <div className="form_title">
                        <h3>
                          <strong>2</strong>
                          {this.t("Payment Information")}
                        </h3>
                      </div>

                      <div className="step">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>{this.t("Payment type")}</label>
                              <select
                                className="form-control"
                                name="user.paymentinfo.paymenttypeid"
                                value={user.paymentinfo.paymenttypeid}
                                onChange={this.changepaymenttype.bind(this)}
                              >
                                <option value={-1} disabled>
                                  {this.t("Select payment type")}
                                </option>

                                {!this.config().nocreditcardcharge?
                                <option value="1">
                                  {this.t("Credit Card")}
                                </option>
                                :null}

                                <option value="5">
                                  {this.config().brand == "viajeypunto"
                                    ? this.t("Credito")
                                    : this.t("Reserve 24hrs")}
                                </option>
                              </select>
                            </div>
                          </div>

                        
                        {this.config().enablepaymentprocessors?
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>{this.t("Payment processor")}</label>
                              <select
                                className="form-control"
                                name="paymentprocessorid"
                                value={this.state.paymentprocessorid}
                                onChange={this.changepaymentprocessor.bind(this)}
                              >


                                {user.paymentinfo.paymenttypeid == 1?
                                	this.PaymentProcessorsOptions()
                                	:<option value="-1" disabled>No merchant</option>}

                               

                              </select>
                            </div>
                          </div>:null}


                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {paymentprocessorid == 1 ? (
                    <div>
                      <div className="form_title">
                        <h3>
                          {!this.state.enablepaymenttypes ? (
                            <strong>2</strong>
                          ) : (
                            ""
                          )}

                          <span>{this.t("Billing Details")}</span>
                        </h3>

                        {!this.state.enablepaymenttypes ? (
                          <p>{this.t("Payment Information")}</p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="step">
                        {this.config().brand == "viajeypunto" ? (
                          <div>{this.BBVABenefits(0)}</div>
                        ) : (
                          ""
                        )}

                        {this.state.user.paymentinfo.paymenttypeid != -5 ? (
                          <div>
                            <CreditCards
                              translate={this.t.bind(this)}
                              onChange={this.creditCardChange.bind(this, 0)}
                              issuer={this.setCreditCardIssuer.bind(this, 0)}
                              disableFields={creditCardDisabledFields}
                            />
                            
                            

                            {this.state.addanothercard ? (
                              <div>
                                <hr />

                                {this.config().brand == "viajeypunto" ? (
                                  <div>{this.BBVABenefits(2)}</div>
                                ) : (
                                  ""
                                )}

                                <CreditCards
                                  translate={this.t.bind(this)}
                                  onChange={this.creditCardChange.bind(this, 2)}
                                  issuer={this.setCreditCardIssuer.bind(
                                    this,
                                    2
                                  )}
                                  disableFields={creditCardDisabledFields2}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {this.config().brand == "viajeypunto" ? (
                              <b style={{ fontSize: "15px" }}>
                                <br />
                                <a
                                  href="#"
                                  className="btn_category d-table"
                                  onClick={this.addAnotherCard.bind(this)}
                                >
                                  {this.state.addanothercard ? (
                                    <span>- Remover la segunda tarjeta</span>
                                  ) : (
                                    <span>+ Agregar otra tarjeta</span>
                                  )}
                                </a>
                              </b>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/*End step */}

                      {/*check box payment after points */}

                      {/*END check box payment after points */}
                    </div>
                  ) : (
                    ""
                  )}
                  {/*End billing details */}

                  <div className="form_title">
                    <h3>
                      {paymentprocessorid == 1 ||
                      this.state.enablepaymenttypes ? (
                        <strong>3</strong>
                      ) : (
                        <strong>2</strong>
                      )}

                      {this.t("User Info")}
                    </h3>
                    <p>{this.t("Detail Information")}</p>
                  </div>
                  <div className="step">
                    {this.hoteloptons().enable ? (
                      <div className="form-group">
                        <label>{this.t("Hotel Name")}</label>

                        {this.hoteloptons().mandatory ? (
                          <span className="text-danger"> *</span>
                        ) : (
                          ""
                        )}

                        <InputSearchHotel
                          handle={this.changehotel.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/*  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>{this.t("Arrival Date")}</label>
                        <span className="text-danger"> *</span>
                        <br />

                        <DatePicker
                          selected={user.travelinfo.arrivaldate}
                          minDate={moment()}
                          dateFormat="YYYY-MM-dd"
                          className="form-control"
                          placeholderText={this.t("Arrival Date")}
                          onChange={this.changearrivaldate.bind(this)}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>{this.t("Departure Date")}</label>
                        <span className="text-danger"> *</span>
                        <br />

                        <DatePicker
                          selected={user.travelinfo.departuredate}
                          minDate={moment()}
                          dateFormat="YYYY-MM-dd"
                          className="form-control"
                          placeholderText={this.t("Departure Date")}
                          onChange={this.changedeparturedate.bind(this)}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>*/}

                    <div className="row">
                      <div className="col-sm-6">
                        <label>{this.t("Phone")}</label>
                        <span className="text-danger"> *</span>
                        <input
                          type="text"
                          name="user.phone"
                          placeholder={this.t("Phone")}
                          className="form-control"
                          maxLength="10"
                        />
                      </div>

                      <div className="col-sm-6">
                        <label>{this.t("Email")}</label>
                        <span className="text-danger"> *</span>
                        <input
                          type="text"
                          name="user.email"
                          className="form-control"
                        />
                      </div>

                      {this.config().brand == "viajeypunto" ? (
                        <ul className="text-danger col-sm-12">
                          <br />
                          {this.state.PaymentOptions.usebbvawallet == -1 ||
                          this.state.PaymentOptions2.usebbvawallet == -1 ? (
                            <li>
                              {this.t(
                                "Es importante proporcionar un número de teléfono correcto a 10 dígitos, de esta manera podremos comunicarnos con usted en caso de requerir validar cierta información o en caso de algún problema con su método de pago."
                              )}
                              {/*this.t(
                                    "Con esta forma de pago se comunicaran con usted para validar su codigo de seguridad Digital (CVV)"
                                  )*/}
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr />

                    {/*    <h3>{this.t("Optional")}</h3>
                  <div className="form-group">
                    <label>{this.t("Your Vacation Company")}</label>

                    <VacationCompanies
                      value={user.travelinfo.vacationcompanyid}
                      name="user.travelinfo.vacationcompanyid"
                    />
                  </div>
                  <div className="form-group">
                    <label>{this.t("How did you find about us?")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.travelinfo.findaboutus}
                      name="user.travelinfo.findaboutus"
                    />
                  </div>

                  */}

                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.acceptTerms}
                          className="custom-control-input checkboxacceptterms"
                          id="customCheck1"
                          onClick={this.checkTerms}
                        />
                        <label
                          className="custom-control-label receive_em"
                          for="customCheck1"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'I agree to the <a href="{{termsurl}}" target="_blank">terms & conditions</a>',
                              { termsurl: termsurl }
                            ),
                          }}
                        ></label>
                      </div>
                    </div>

                    <ErrorMessages messages={messages} />

                    <hr />

                    {!this.state.processing && !this.state.paymentparams ? (
                      <div className="col-lg-4 col-sm-6">
                        <button
                          style={
                            this.state.acceptTerms
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          className="btn checkout_popc"
                          onClick={this.submitpayment.bind(this)}
                        >
                          {" "}
                          {/*paymentprocessorid == 3 ? this.t("Continue"):this.t("Submit Payment")*/}
                          {this.t("Submit Payment")}
                        </button>

                        {/*this.state.user.paymentinfo.paymenttypeid == 1 && this.config().adminid != null && this.config().adminid != undefined?
                            <div>                              
                                <button
                                  style={                                    
                                    { display: "block" }                                     
                                  }
                                  className="btn checkout_popc"
                                  onClick={this.worldPayGeneratePaymentLink.bind(this)}
                                >
                                  {" "}                                  
                                  {this.t("Generate Payment Link")}
                                </button>  
                                                   
                            </div>:null
                                */}

                        {this.state.WoldPayPaymentPath != '' && (this.config().customUrl || window.self === window.top)? 
                        <div style={{width:'100%',background:'white'}} className=''>
                          <input onClick={this.copyWorldPayUrl.bind(this)} value={originCustomUrl+this.state.WoldPayPaymentPath} readonly id="copyWorldPayPaymentUrl" className="p-2 disabled" style={{width:'100%'}} />
                                                                
                        </div>:''}        


                      </div>
                    ) : (
                      <span>
                        {this.state.processing ? (
                          <h5>
                            {" "}
                            {this.spinner(
                              this.t(
                                "We are processing your request, please wait..."
                              )
                            )}
                          </h5>
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                  </div>
                  {/*End step */}
                </div>

                {paymentprocessorid == 3 && this.state.paymentparams ? (
                  <div className="StripeFromDiv">
                    <div className="form_title">
                      <h3>
                        <strong>3</strong>

                        {this.t("Billing Details")}
                      </h3>
                    </div>

                    <div className="step">
                      <div className="form-group">
                        <StripesProcessor
                          Amount={this.grandtotal()}
                          translate={this.t.bind(this)}
                          Currency={Cart[0].currency}
                          params={this.state.paymentparams}
                          aprovedpayment={this.aprovedpayment.bind(this)}
                          this={this}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div id="policy">
                  <ul>
                    <li>
                      {this.t(
                        "You will be charged the total amount once your order is confirmed."
                      )}
                    </li>
                    <li>
                      {this.t(
                        "If confirmation isn't received instantly, an authorization for the total amount will be held until your booking is confirmed."
                      )}
                    </li>
                    <li>
                      {this.t(
                        "You can cancel for free up to 48 hours before the day of the experience, local time."
                      )}
                    </li>
                  </ul>
                </div>
              </div>

              {/* End col lg-8 */}
              {/* Aside */}
              <aside className="col-lg-4">
                <div className="sidebar-item">
                  <div className="make-me-sticky">
                    <div className="box_style_1">
                      <h3 className="inner">- {this.t("Summary")} -</h3>
                      <table className="table table_summary">
                        <tbody>
                          <tr className="total">
                            <td>
                              {this.t("Grand Total")} {Cart[0].currency}: $
                              {this.currencyformat(this.grandtotal())}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {this.config().brand == "viajeypunto" ? (
                                <span>
                                  {this.state.PaymentOptions
                                    .monthinterestfree == -1 ? (
                                    <span>
                                      <b>{this.state.PaymentOptions.months}</b>{" "}
                                      {this.t("meses sin intereses")}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.PaymentOptions
                                    .usebancomerpoints == -1 ||
                                  this.state.PaymentOptions2
                                    .usebancomerpoints == -1 ? (
                                    <span>
                                      <b>
                                        <br />
                                        {this.currencyformat(
                                          parseInt(this.grandtotal()) * 10
                                        )}
                                      </b>{" "}
                                      {this.t("Bancomer points")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            checked={this.state.acceptTerms}
                            className="custom-control-input"
                            id="customCheck2"
                            onClick={this.checkTerms}
                          />
                          <label
                            className="custom-control-label receive_em"
                            for="customCheck2"
                            dangerouslySetInnerHTML={{
                              __html: this.t(
                                'I agree to the <a href="{{termsurl}}" target="_blank">terms & conditions</a>',
                                { termsurl: termsurl }
                              ),
                            }}
                          ></label>
                        </div>
                      </div>

                      {!this.state.processing && !this.state.paymentparams ? (
                        <a
                          href="javascript:void(0)"
                          style={
                            this.state.acceptTerms
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          className="btn checkout_popc"
                          onClick={this.submitpayment.bind(this)}
                        >
                          {/*paymentprocessorid == 3 ? this.t("Continue"):this.t("Submit Payment")*/}
                          {this.t("Submit Payment")}
                        </a>
                      ) : (
                        <span>
                          {this.state.processing ? (
                            <h5>{this.spinner("...")}</h5>
                          ) : (
                            ""
                          )}
                        </span>
                      )}

                      <NavLink className="btn_full_outline" to="/cart">
                        <i className="icon-right" /> {this.t("Back")}
                      </NavLink>

                      <br />

                      <ErrorMessages messages={messages} />
                    </div>
                    <div className="box_style_4 lead_text">
                      <p>
                        {this.t(
                          "The Lead Name on Reservation must present a valid Photo ID when redeeming their {{brandname}} Voucher(s). Accompanying children do not require Photo ID.",
                          { brandname: this.config().brandname }
                        )}
                        <br /> <br />
                        {this.t(
                          "This booking will be listed as {{company}} on your credit card statement.",
                          {
                            company:
                              this.config().brand == "viajeypunto"
                                ? "ICONN Services"
                                : this.state.paymentprocessorid == 2 ||
                                  this.state.paymentprocessorid == 3
                                ? "Mobile Sail Technologies"
                                : "Totoura",
                          }
                        )}
                      </p>

                      {this.config().brand != "viajeypunto" ? (
                        <p>
                          <a
                            onClick={this.open3dpopup.bind(this)}
                            className="secure_toolt securem_pop secure_brand"
                            data-toggle="tooltip"
                            title={this.t("¿Qué significa 3DSecure?")}
                          >
                            <i className="icon-lock" />
                            {this.t("Secure Transaction Statement")}{" "}
                          </a>
                          <br />

                          {this.state.paymentprocessorid == 3 ||
                          this.state.paymentprocessorid == 2 ? (
                            <span>
                              {this.t("Esta transaccion esta protegida con")}{" "}
                              <a
                                onClick={this.open3dpopup.bind(this)}
                                className="secure_toolt securem_pop secure_brand"
                                data-toggle="tooltip"
                                title={this.t("¿Qué significa 3DSecure?")}
                              >
                                {" "}
                                {this.t("3DSecure, ")}
                              </a>
                              {this.t(
                                "nuevo sistema de pago desarrollado por Visa y Mastercard para la realización de compras seguras en Internet que autentifica al comprador como legítimo titular de la tarjeta que está utilizando."
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    {this.config().brand != 'delmarboutiqueaccommodation'?
                    <div className="box_style_4">
                      <i className="icon_set_1_icon-57" />
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: this.t(
                            "Need <span className='blue_text'>Help?</span>"
                          ),
                        }}
                      ></h4>
                      {this.config().phone != ''?
                      <a href={"tel:" + this.config().phone} className="phone">
                        {this.config().phone}
                      </a>:''}
                      <a href={"mailto:" + this.config().email}>
                        {this.config().email}
                      </a>
                      <br />
                      <small>
                        {this.t("Our Vacation Concierge is available.")} <br />
                        {this.t("Everyday from 9:00am to 6:00pm Central")}
                      </small>
                    </div>
                    :null}
                  </div>
                </div>
              </aside>
              {/* End aside */}
            </div>
            {/*End row */}
          </div>
          {/*End container */}

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 no_pad">
                <div className="details_tour bottom_text">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.t(
                        "{{brandname}} facilitates your booking, but a third party experience operator provides the tour/activity directly to you. {{brandname}} does not provide experiences or activities itself. By clicking 'Book Now', you are acknowledging that you have read and are bound by Totoura <a href='{{termsurl}}' target='_blank'>Customer Terms of Use</a>, <a href='{{privacyurl}}' target='_blank'>Privacy Policy</a>, plus the tour operator's rules & regulations.",
                        {
                          termsurl: termsurl,
                          privacyurl: privacyurl,
                          brandname: this.config().brandname,
                        }
                      ),
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div id="overlay" />
          {/* Mask on input focus */}
        </main>
        {/* End main */}

        {/* Mit Modal */}
        <div id="payment_p" className="zoom-anim-dialog mfp-hide">
          <div className="small-dialog-header">
            <h3>{this.t("Payment")}</h3>
          </div>

          {/* <PaymentProcessor url={this.state.miturl} paymentprocessorid={this.state.paymentprocessorid} />  */}
        </div>
        {/* /Mit Modal */}

        {/* /3D Secure Modal */}
        <div id="securem" className="zoom-anim-dialog mfp-hide">
          <div className="small-dialog-header">
            <h3>3D SECURE</h3>
          </div>
          <div className="tour_container">
            <div className="tour_title">
              <h3>
                <strong>{this.t("¿Que significa 3DSECURE?")}</strong>
              </h3>
              <br />
              <p className="text-justify">
                {this.t(
                  "3D Secure (o 3DS) es un sistema de seguridad para los pagos online con tarjeta bancaria. Este sistema funciona para las tarjetas Visa y MasterCard. El procedimiento de autenticación del titular de la tarjeta es muy sencillo y consta de 3 etapas."
                )}
                <br />
                <br />
                <strong>
                  {this.t(
                    "- Al realizar la reserva del billete en nuestro sitio web, deben indicarse los datos de la tarjeta."
                  )}
                  <br />
                  {this.t(
                    "- Tras la verificación, se abre una ventana 3D Secure: el banco del titular de la tarjeta pide que se introduzcan ciertos datos para verificar si es el propietario de la misma. En la mayoría de los casos, se trata de un código de un solo uso que el banco envía por SMS al teléfono móvil."
                  )}
                  <br />
                  {this.t(
                    "- Por último, después de confirmar la etapa anterior*, y de autorizar el pago, se confirma la compra."
                  )}
                  <br />
                  <br />
                  {this.t(
                    "El pago 3D Secure está disponible en los bancos con el nombre 'Verified by Visa' para las tarjetas Visa y 'MasterCard SecureCode' para las tarjetas MasterCard. Para más información sobre este servicio y los trámites para suscribirse, contacte con su banco."
                  )}
                </strong>
                <br />
                <br />
                {this.t(
                  "* Tres intentos fallidos durante la etapa de autenticación provocan la cancelación de la transacción y el bloqueo de la tarjeta bancaria. En tal caso, le recomendamos contactar con su banco."
                )}
              </p>
            </div>
            <div className="tour_title"></div>
          </div>
        </div>
        {/* /3D Secure Modal */}

        {/* Bancomer Modal */}
        <div id="payment_bancomer" className="zoom-anim-dialog mfp-hide">
          <div className="small-dialog-header">
            <h3>{this.t("Payment")}</h3>
          </div>

          <div id="bancomer-iframe" style={{overflow:"hidden"}}></div>
          

          {this.state.BBVAAPIPaymentPath != '' && (this.config().customUrl || window.self === window.top)? 
            <div style={{width:'100%',background:'white'}} className='p-5'>
           <input onClick={this.copyUrl.bind(this)} value={originCustomUrl+this.state.BBVAAPIPaymentPath} readonly id="copyBBVAPaymentUrl" className="p-2 disabled" style={{width:'100%'}} />
           
          
          </div>:''}

          
          

          {/*this.state.bancomerurl?<PaymentProcessor url={this.state.bancomerurl} paymentprocessorid={this.state.paymentprocessorid}  />:''*/}
        </div>
        {/* Bancomer Modal */}

        <ReactResizeDetector handleWidth handleHeight>
          {({ width, height }) => this.onResize(width, height)}
        </ReactResizeDetector>
      </div>
    );
  }
}

export default withRouter(checkout);
