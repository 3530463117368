import React,{Component} from "react";
import { NavLink } from 'react-router-dom';
import Currency from "../components/component.currency";
import Language from "../components/component.language";
import moment from "moment";
import Global from '../globals/global';
import FooterToorzy from "../partials/partial.footer.toorzy";
//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;
var activestyle={
    fontWeight: "bold",
    color: "orange"
};


export default class footer extends Global {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount(){
           this.setmenuoptions();
           //this.forceUpdate()
    }

    render() {

         var disablepages = this.config().noheaderpages || [];

         const disable = disablepages.find(page => {
                return location.pathname.search(page) != -1;
         })
         if(disable) return null;


         if(this.config().brand == 'toorzy'){
          return <FooterToorzy />
         }
         
        return (


            <footer className="revealed">
  <div className="container">
    <div className="row">


      {this.config().mexicophone?
      <div className="col-md-4">
         {this.config().brand == 'trafictours' || this.config().brand == 'totoura' || this.config().brand == 'sunexcursions' || this.config().brand == 'transat' || this.config().brand == 'turissimojamaica' || this.config().brand == 'turissimo'?
                            <h3>{this.t("Need help?")}</h3>
                        : <h3>{this.t("Need help with experiences?")}</h3>}
                        {this.config().phone != ''?
        <a href={"tel:"+this.config().phone} id="phone" className="price_ph">+{this.config().phone} {this.t('from')} {this.t('USA / CANADA')}</a>
        :''
                 }
                 
        <a href={"tel:"+this.config().mexicophone} id="phone">+{this.config().mexicophone} {this.t('from')} {this.t('MEXICO')}</a>

       
        <a href={"mailto:"+this.config().email} id="email_footer">{this.config().email}</a>
      </div>:
      <div className="col-md-4">
         {this.config().brand == 'trafictours' || this.config().brand == 'totoura' || this.config().brand == 'sunexcursions' || this.config().brand == 'transat' || this.config().brand == 'turissimojamaica' || this.config().brand == 'turissimo'?
                            <h3>{this.t("Need help?")}</h3>
                        : <h3>{this.t("Need help with experiences?")}</h3>}
        <a href={"tel:"+this.config().phone} id="phone">+{this.config().phone}</a>

        {this.config().brand == "cityexpressions" ? (
        
        <a href="tel:800-444-0656" className="phone_footer">800-444-0656</a>

        ) :null}

        {this.config().brand == "oyorooms" ? (
        
        <a href="tel:800-060-0032" className="phone_footer">800-060-0032</a>

        ) :null}

        <a href={"mailto:"+this.config().email} id="email_footer">{this.config().email}</a>


      </div>
      }


      <div className="col-md-3">
        <h3>{this.t("Store")}</h3>
        <ul>

          <li><NavLink to="/cart" activeStyle={activestyle} >{this.t("Cart")}</NavLink></li>
          <li><NavLink to="/checkout" activeStyle={activestyle} >{this.t("Checkout")}</NavLink></li>
          <li><NavLink to="/terms" activeStyle={activestyle} >{this.t("Terms and condition")}</NavLink></li>
          {this.config().brand == 'viajeypunto'?<li><NavLink to="/privacy" activeStyle={activestyle} >{this.t("Privacy Policy")}</NavLink></li>:''}
        </ul>
      </div>
      <div className="col-md-3">
        <h3>{this.t("Discover")}</h3>
        <ul>
          {this.config().tours?<li><NavLink to="/tours/1" activeStyle={activestyle} >{this.t("Tours")}</NavLink></li>:''}
          {this.config().wishlist?<li><NavLink to="/wishlist" activeStyle={activestyle} >{this.t("Wishlist")}</NavLink></li>:''}
          {this.config().contact?<li><NavLink to="/contact" activeStyle={activestyle} >{this.t("Contact")}</NavLink></li>:''}
        </ul>
      </div>
      <div className="col-md-2">
        <h3>{this.t("Settings")}</h3>
        <div className="styled-select">
          <Language header={false} />
         </div>
         <div className="styled-select">
           <Currency header={false} />
         </div>
      </div>
    </div>
    {/* End row */}
    
    {this.config().brand == 'oyorooms'?
        <div className="row">
      <div className="col-md-12">
        <div id="social_footer">
          <ul>
            <li>
              <a href="https://www.facebook.com/OYOMexico/" target="_blank">
                <i className="icon-facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/oyo.mexico/" target="_blank">
                <i className="icon-instagram" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/MexicoOyo" target="_blank">
                <i className="icon-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC1b6tyXZTHdIZ5vmgoAqn9w"
                target="_blank"
              >
                <i className="icon-youtube-play" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/oyo-mexico/"
                target="_blank"
              >
                <i className="icon-linkedin" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    :''}

  </div>
  {/* End container */}

{/*  <div className='text-center'>v {this.version()}</div> */} 
  
</footer>

        );
    }
}

