import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class PersonsSelector extends Global {

    constructor(props) {
        super(props);

        this.selectOptions = this.selectOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            done: false,
            label: null,
            value: null,
            minValue: null,
            maxValue: null,
        };
    }

    componentDidMount() {
        this.setState({
            done: true,
            label: this.props.label !== null ? this.props.label : '',
            value: this.props.value,
            minValue: this.props.minValue,
            maxValue: this.props.maxValue,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            if (this.props.handle !== null) {
                this.props.handle(this.state.value);
            }
        }
    }

    selectOptions(minValue, maxValue) {
        var options = [];

        for (var i = minValue; i <= maxValue; i++) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }

        return options;
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
        });
    }

    render() {
        return (
            this.state.done ? (
                <div className="col-md-6">
                    <label>{this.state.label}</label>
                    <select className="form-control" value={this.state.value} onChange={this.handleChange} >
                        <option value={-1} disabled>{this.t('Select')} {this.state.label !== null ? this.state.label : ''}</option>
                        {
                            (this.state.minValue !== null && this.state.maxValue !== null) ?
                                this.selectOptions(this.state.minValue, this.state.maxValue) :
                                null
                        }
                    </select>
                </div>
            ) : null
        )
    }
}

export default withRouter(PersonsSelector)