import React from "react";
import Pagination from "../components/component.pagination";
import ToursFilter from "../components/component.tours.filter";
import DatePicker from "react-datepicker";
import moment from "moment";
import { NavLink, withRouter } from "react-router-dom";
import TourPricesTable from "../components/component.tour.prices.table";
import TourPricesTable__ from "../components/component.tour.prices.table.2";
import ErrorMessages from "../components/component.error.messages";
import Coupon from './component.coupon';
import Global from '../globals/global';
import queryString from 'query-string';
//if (typeof window === "undefined")  var window={}
const $ = window.$;

class ToursAvailability extends Global {
  constructor(props) {
    super(props);

    var tourid = null;
    var action = null;
    var itemindex = -1;

    if (this.props.match) {
      if (this.props.match.params) {
        if (this.props.match.params.tourid)
          tourid = this.props.match.params.tourid;
        if (this.props.match.params.action)
          action = this.props.match.params.action;
        if (this.props.match.params.itemindex >= 0)
          itemindex = this.props.match.params.itemindex;
      }
    }

  

    this.state = {
      urlLink:'',
      tourid: tourid,
      action: action,
      itemindex: itemindex,
      tourprices: {},
      tourpricinginfo: {},
      filters: {
        activitydate: new Date(),
        activitytime: "",
        adultsnum: 1,
        childrennum: 0,
        childrenages: [],
        couponcode: "",
        extras:[]
      },

      calculations: {
        itemtype: 1,
        adults: {
          total: 0,
          qty: 1,
          age: 0,
          price: 0
        },
        children: {
          total: 0,
          qty: 0,
          minage: 0,
          maxage: 0,
          price: 0
        },
        childrenfree: {
          total: 0,
          qty: 0,
          minage: 0,
          maxage: 0,
          price: 0
        },
        group: {
          total: 0,
          qty: 1,
          age: 0,
          price: 0
        },
        passengers: {
          total: 0,
          qty: 1,
          age: 0,
          price: 0
        },
        extras: [],
        coupon: {
          couponid:-1,
          name: "",
          discount: 0,
          code:''
        },
        total: 0,
        totalfordiscount:0
      },
      messages: [],
      childrenmessages: [],
      defaultmessages: [],
      childrenmessages_2:[],
      cartitemsnum: 0,
      addedtocart: false,
      redirect: true,
      loading:true,
      datepickermindate:new Date(),
      disablechildren:false,
      notourinfomessage:'',
      disabledatepicker:false,
      startdidmount:true,
      StartTimes:[],
      scheduleinfo:[],
      datepickeroffdays:[],
      availableprices:true,
      hasMandatoryExtras:true,
      hasMandatoryExtrasCount:0,
      continueCart:true
    };
  }

  componentDidMount() {

   

    this.start();
  }

  componentWillUnmount() {
    
    //$.magnificPopup.close();
  }


   componentDidUpdate(prevProps, prevState) {
    

      $('.react-datepicker__input-container input').attr('readonly','readonly');
    }

  start() {

   /* const parsed={
      ages:[1,2]
    };

    const stringified = queryString.stringify(parsed);
    console.log('stringified',queryString.parse(stringified))*/
var urlParsed = queryString.parse(window.location.search);
  if(urlParsed.setUrl){
    
    if(!this.getglobaldata('filter')){
      this.setglobaldata('filter',{});
    }
    
    if(!this.getglobaldata('filter.touravailability')) {
      this.setglobaldata('filter.touravailability',{});
    }

    if(urlParsed.childrenages && !$.isArray(urlParsed.childrenages)) {
      urlParsed.childrenages = urlParsed.childrenages.split(',');
    }

    //console.log('urlParsed.childrenages',urlParsed)

    
      this.setglobaldata('filter.touravailability',urlParsed);
    }
    
    

    this.setstartfilters();
    this.gettourpricinginfo();
   // this.gettourprices();

   if (!this.ismobile() && this.enableelement()) this.openpopup()
    
  }

  
  enableelement(){
    return  this.props.origin !='tourdetails';
  }

  setlandingpageinfo(){

    
    if(!this.config().landinginfo) return false;

    
    
    if(Number(this.config().landinginfo.Id_Tour) != Number(this.state.tourid)) return false;


    this.state.filters.activitydate = moment(this.config().landinginfo.ActivityDate)._d;
    this.state.filters.activitytime = this.config().landinginfo.ActivityTime;
    this.state.disabledatepicker = true;

    
    return true;
  }

  openpopup(){

    if(this.props.onlytable) return;

    $.magnificPopup.open({
      items: {
        src: "#cart"
      },
      type: "inline",
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-zoom-in',
      callbacks: {
        open: function() {},

        close: function() {
          if (this.state.redirect) {
            if(!this.props.onlytable) this.props.history.push('/tours/1'); //this.goback();
          }
        }.bind(this)
      }
    });

  }


  setstartfilters() {



    var Cart = this.getglobaldata("Cart") || [];
    var itemindex = this.state.itemindex;

    if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});

    if (!this.getglobaldata("filter.touravailability"))
      this.setglobaldata("filter.touravailability", {});
    else this.state.filters = this.getglobaldata("filter.touravailability");

    if(!this.state.filters.extras) this.state.filters.extras = [];
    if(!this.state.filters.childrenages) this.state.filters.childrenages= [];

    let validextrasbytour = [];

    if(Array.isArray(this.state.filters.extras)){
    this.state.filters.extras.map(function(extra,index){
        if(extra.Id_Tour) {if(extra.Id_Tour == this.state.tourid) validextrasbytour.push(extra)}
    }.bind(this));
    }



   this.state.filters.extras = validextrasbytour;

    if (itemindex >= 0) {
      if (Cart[itemindex]) {
        if (Cart[itemindex].filters)
          this.state.filters = Cart[itemindex].filters;
          
      }
    }

    this.state.filters.activitydate = moment(
      this.state.filters.activitydate
    )._d;

    this.setlandingpageinfo();

    
  }

  setfilters() {
    
    var tourprices = this.state.tourprices;

    

    if (Number(tourprices.QtyInventoryPTour) < Number(this.state.filters.adultsnum)) {this.state.filters.adultsnum = 1;}
    if (Number(tourprices.QtyInventoryPTour) <Number(this.state.filters.childrennum)) {this.state.filters.childrennum = 0;}

    if (Number(this.state.filters.childrennum) == 0) {this.state.filters.childrenages = [];}



    if (Number(this.state.filters.childrennum) > 0) {
      var childrenages = [];
      this.state.filters.childrenages.map(function(age, index) {

        if (Number(age) <= Number(tourprices.MaxAgeChild) && Number(age) >= Number(tourprices.MinAge)) childrenages.push(age);

      });

      this.state.filters.childrenages = childrenages;
    }

  //  alert(this.state.filters.childrenages)

    
  }

  gettourpricinginfo() {
    

    this.state.loading=true;
    this.forceUpdate();;

    const Id_Language = this.getglobaldata("Id_Language");
    const Id_Currency = this.getglobaldata("Id_Currency");

    

    var options = {
      method: "GetTourPricingInfo",
      query: {
        id: this.state.tourid,
        Id_Language: Id_Language,
        Id_Currency: Id_Currency,
        ActivityDate:moment(this.state.filters.activitydate).format("YYYY-MM-DD"),
      },
      callback: function(jsonresponse) {

        //deytectar si tiene extras obligatorios
        var is_mandatory = false;
        jsonresponse.tour_extras.map((extra)=>{
          if(extra.mandatory == -1){
            is_mandatory = true;
          }
        });

        this.setState({
          tourpricinginfo:jsonresponse,
          hasMandatoryExtras:is_mandatory,
          continueCart:!is_mandatory
        },function(){
              
              this.setglobaldata('TourResellersIds', jsonresponse.ResellersIds);
              this.setminbookingdays()
              if(this.props.onload) this.props.onload({tourinfo:this.state.tourpricinginfo});
              
              this.gettourprices()
              

        }.bind(this));

        

        
      }.bind(this),
      error: function() {
       // this.pushnavigate("/tours/1");
      }.bind(this)
    };

    this.single(options);
  }

  customSetDaysOff(){
    var tourprices = this.state.tourprices;
    var Id_Destination = tourprices.Id_Destination;

    var daysOff = [];

    if(Id_Destination == 6){
       daysOff = [
         '2022-12-25',
         '2023-01-01',
       ];
    }
    return daysOff 
  }

  setdaysoff(){
   
    //close days
    var setDaysOffToState = [];   
    var activityDateisOffDay = false; 

    //unimos las fechas off del tour y las fechas custom
    var Days_off = this.state.tourpricinginfo.Days_off.concat(this.customSetDaysOff());


    //console.log('Days_off',Days_off);
    //set off days in datepicker
    Days_off.map((value,index)=>{ 

      setDaysOffToState[index] = moment(value)._d 
      
      //check if activity day exist in off days
      if(moment(this.state.filters.activitydate).format("YYYY-MM-DD") == value){
        activityDateisOffDay = true;        
      } 

    })    
    this.setState({datepickeroffdays:setDaysOffToState})

    ///console.log('activityDateisOffDay',activityDateisOffDay);

    //if activity day exist in off days, activity day must move to next available date
    if(activityDateisOffDay){

        var datepickermindate_ = this.state.datepickermindate; 
        var datepickermaxdate_ = moment().add(365, "days");
        var start_find = datepickermindate_.format("YYYY-MM-DD");
        var end_find = datepickermaxdate_.format("YYYY-MM-DD");
       
            
        //all days available for season in datepicker
        var checkDays = [];
        while(start_find <= end_find){
          checkDays.push(start_find);
          start_find = moment(start_find).add(1,'days').format("YYYY-MM-DD")         
        }
       // console.log(checkDays)
        //find an available days without offdays
        var available_days = [];       
        checkDays.map((value)=>{
          if(Days_off.indexOf(value) > -1){}           
          else{
            available_days.push(value);
          }
        })
       
        //set new activity date (next available date after actual activity date)
        var match_new_activity_date = false;
        available_days.map((value,index)=>{
          var actual_activty_date = moment(this.state.filters.activitydate).format("YYYY-MM-DD");
          var searchDate = moment(value).format("YYYY-MM-DD")
          if(match_new_activity_date == false){
            if(searchDate < actual_activty_date){
              
            }else{
              match_new_activity_date = true;              
              this.state.filters.activitydate = moment(value)._d
              this.forceUpdate();
            }
          }
        })

    }
  }

  
  

  setmessages(){

    var tourinfo = this.state.tourpricinginfo;
    //console.log(tourinfo);
    this.state.defaultmessages = [];
    this.state.childrenmessages = [];
    this.state.childrenmessages_2 = [];
    this.state.disablechildren = false;

    if(Number(tourinfo.MinAge) >= 0 && Number(tourinfo.MaxFreeAge) > Number(tourinfo.MinAge) && Number(tourinfo.MaxFreeAge) > 0) this.state.childrenmessages.push(this.t("Children Free ({{minage}} - {{maxfreeage}})",{maxfreeage:tourinfo.MaxFreeAge,minage:tourinfo.MinAge}));
    
    if(Number(tourinfo.MinAge) > 0) {
     this.state.defaultmessages.push(this.t("Children under ({{minage}}) are not allowed",{minage:tourinfo.MinAge}));
      //if(Number(tourinfo.MaxAgeChild) < 0) this.state.defaultmessages.push(this.t("Children under ({{minage}}) are not allowed",{minage:tourinfo.MinAge}));
     }
     
    if(Number(tourinfo.MaxAgeChild) > 0 && Number(tourinfo.MaxAgeChild) > Number(tourinfo.MinAge)) {
      this.state.childrenmessages.push(this.t("Children ({{minage}}+) Adult Price",{minage:Number(tourinfo.MaxAgeChild)+1}));
    }else if(Number(tourinfo.MaxAgeChild) < Number(tourinfo.MaxFreeAge) && Number(tourinfo.MaxFreeAge) > 0 && Number(tourinfo.MaxFreeAge) > Number(tourinfo.MinAge)){
      this.state.childrenmessages.push(this.t("Children ({{minage}}+) Adult Price",{minage:Number(tourinfo.MaxFreeAge)+1}));
    }
    
    
   if(Number(tourinfo.MinAge) > Number(tourinfo.MaxAgeChild) && Number(tourinfo.MinAge) >= 0) {
    
    if(Number(tourinfo.MaxFreeAge) < 0){
      this.state.disablechildren = true;
      
      this.state.filters.childrenages = [];
      this.state.filters.childrennum = 0;
      
      if(Number(tourinfo.MinAge) < 18) this.state.defaultmessages.push(this.t("Children ({{minage}}+) Adult Price",{minage:Number(tourinfo.MinAge)}))
    }else{
      if(Number(tourinfo.MinAge) < 18) this.state.defaultmessages.push(this.t("Children ({{MaxFreeAge}}+) Adult Price",{MaxFreeAge:Number(tourinfo.MaxFreeAge)+1}))
    }
   }
   

   if(Number(tourinfo.MinAge) < 0 && Number(tourinfo.MaxAgeChild) < 0 && Number(tourinfo.MaxFreeAge) < 0) {
      this.state.disablechildren = true;
      this.state.filters.childrenages = [];
      this.state.filters.childrennum = 0;
      this.state.defaultmessages.push(this.t("Children are not allowed on this tour"))
   }

  }

  gettourprices() {


    const Id_Language = this.getglobaldata("Id_Language");
    const Id_Currency = this.getglobaldata("Id_Currency");

    var params = {
        id: this.state.tourid,
        tour_date: moment(this.state.filters.activitydate).format("YYYY-MM-DD"),
        Id_Language: Id_Language,
        Id_Currency: Id_Currency
      }

    if(this.props.threextwoDiscounted) params.threextwoDiscounted = this.props.threextwoDiscounted?-1:0;

    var options = {
      method: "GetTourPrices",
      query: params,
      callback: function(jsonresponse) {

        this.setState({tourprices:jsonresponse.tour_data[0],availableprices:true},function(){
              this.setfilters();

              this.setdaysoff()

              this.calculations();

             
     
        }.bind(this));

        
      }.bind(this),

      error:function(result){
         this.state.loading=false;
         this.state.availableprices = false
         this.state.messages.push(this.t("For this activity please call concierge to confirm"));
         this.forceUpdate();
      }.bind(this)
      
    };


    if(this.props.Id_ActivityRate) options.query.Id_ActivityRate = this.props.Id_ActivityRate

    this.single(options);
  }


  setminbookingdays(){

    

    if(this.setlandingpageinfo()) return;

   
    
    var tourinfo = this.state.tourpricinginfo;
    var duration = moment.duration(Number(tourinfo.MinBookingTime), 'hours');
    var days = duration.asDays();

    if(this.config().enable_min_booking_time){

    }
    else if(this.config().adminid) days = 0;
    
    this.state.datepickermindate = moment().add(days, "days");
    
    

     if(!this.state.startdidmount) return;



      if(moment(this.state.filters.activitydate) < this.state.datepickermindate){
       this.state.filters.activitydate = moment(this.state.datepickermindate)._d;
      }
    
     
  
    tourinfo.tour_days = tourinfo.tour_days.sort((a, b) => (a.StartDate > b.StartDate) ? 1 : -1);

    for (var i = 0; i < tourinfo.tour_days.length; i++){ 

      let Season = tourinfo.tour_days[i];
      var StartDate = moment(Season.StartDate);
      
      if (i == 0){
       if(moment(this.state.filters.activitydate) < StartDate)  {
        this.state.filters.activitydate = StartDate._d;
        this.gettourpricinginfo();
      }

      if(StartDate < this.state.datepickermindate && moment(this.state.filters.activitydate) < this.state.datepickermindate)  {
        this.state.filters.activitydate = moment(this.state.datepickermindate)._d;
        this.gettourpricinginfo();
      }
       
      }

     


    }
    

    



  }

  SelectOptions(min, max) {
    var Options = [];

    min = parseInt(min);
    max = parseInt(max);
    for (var i = min; i <= max; i++) {
      Options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return Options;
  }



  timesoptions() {
    var tourinfo = this.state.tourpricinginfo;
    var date = moment(this.state.filters.activitydate).startOf('day')


    var StartTimes = [];


    if (tourinfo.tour_days) {
      tourinfo.tour_days.map(
        function(Season, index) {
         
          var StartDate = moment(Season.StartDate);
          var EndDate = moment(Season.EndDate);
         
        // Season.ScheduleInfo = null;
           
         


          if (date >= StartDate && date <= EndDate) {


              var selectedtime = false;
              var defaulttime = "";



               if(Season.ScheduleInfo){
          if (Season.ScheduleInfo.length == 0){
                  StartTimes.push(
                    <option key={-2} value={-2} disabled>
                      {this.t("Not Available")}
                    </option>
                  );

                selectedtime = false;
                defaulttime = -2;

          }
          }else{
            StartTimes.push(
                    <option key={-2} value={-2} disabled>
                      {this.t("Not Available")}
                    </option>
                  );

                   selectedtime = false;
                defaulttime = -2;

          }



              if(Season.ScheduleInfo){
              this.state.scheduleinfo = Season.ScheduleInfo;
           
              
            
              Season.ScheduleInfo.map(
              function(time, index) {
                
                if (index == 0) defaulttime = time.StartTime;
                if (this.state.filters.activitytime == time.StartTime)
                  selectedtime = true;
              
                  StartTimes.push(
                    <option key={index} value={time.StartTim}>
                      {time.StartTime} {time.MeetingPoint != ''? '/ '+time.MeetingPoint:''}
                    </option>
                  );
              }.bind(this)
            );

             
           }



            if (!selectedtime) {
              this.state.filters.activitytime = defaulttime;
            }


           // console.log('selectedtime',selectedtime)
           // console.log('defaulttime',defaulttime)
           // console.log('this.state.filters.activitytime',this.state.filters.activitytime)


          }
        }.bind(this)
      );
    }

    this.state.StartTimes = StartTimes;
    
   // return StartTimes;
  }

  /*

  timesoptions() {
    var tourinfo = this.state.tourpricinginfo;
    var date = moment(this.state.activitydate).startOf('day')

    var StartTimes = [];


    if (tourinfo.tour_days) {
      tourinfo.tour_days.map(
        function(Season, index) {
          Season.StartTimes;

          var StartDate = moment(Season.StartDate);
          var EndDate = moment(Season.EndDate);



          if (date >= StartDate && date <= EndDate) {
            var selectedtime = false;
            var defaulttime = "";

            // Season.StartTimes = '';

            if(Season.StartTimes){

            if (Season.StartTimes == "") Season.StartTimes = "-1";

            
              Season.StartTimes.split(",").map(
              function(time, index) {
                
                if (index == 0) defaulttime = time;
                if (this.state.filters.activitytime == time)
                  selectedtime = true;

                if (time == -1)
                  StartTimes.push(
                    <option key={index} value={time} disabled>
                      {this.t("Not Available")}
                    </option>
                  );
                else
                  StartTimes.push(
                    <option key={index} value={time}>
                      {time}
                    </option>
                  );
              }.bind(this)
            );
          }


            if (!selectedtime) {
              this.state.filters.activitytime = defaulttime;
            }


          }
        }.bind(this)
      );
    }

    this.state.StartTimes = StartTimes;
    
   // return StartTimes;
  }
  */

  changeactivitydate(date) {
    this.state.startdidmount = false;
   
    this.state.filters.activitydate = date;
    if(this.state.itemindex  >= 0) this.addtocart(false);
    this.calculations();
    //this.gettourprices();
    this.setstartfilters();
    this.gettourpricinginfo();
  }

  availableweekdays(date) {

    var tourinfo = this.state.tourpricinginfo;

    var WeekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      
    ];
    var days = [];
    var AvailableDays = [];
    var DefaultAvailableDays = [];



    let seasonresult = false

    for (var i = 0; i < tourinfo.tour_days.length; i++) {



      let Season = tourinfo.tour_days[i];
      DefaultAvailableDays = Season.AvailableDays.split(",");
      var StartDate = moment(Season.StartDate)._d;
      var EndDate = moment(Season.EndDate)._d;
      if (date >= StartDate && date <= EndDate){
        AvailableDays = Season.AvailableDays.split(",");
        seasonresult = true;
      }//else seasonresults.push(false);


    }

    if(!seasonresult) return false;

    

    if (AvailableDays.length == 0) AvailableDays = DefaultAvailableDays;

    var inArray = this.inArray;
    WeekDays.map(function(Day, index) {
      if (inArray(Day, AvailableDays) != -1) days.push(index);
    });

    var day = moment(date).weekday();
    var result = this.inArray(day, days) != -1 ? true : false;

    
    return result;
  }

  inArray(item, array_) {
    return array_.indexOf(item);
  }

  changeactivitytime(event) {
    var activitytime = event.target.value;
    this.state.filters.activitytime = activitytime;
    console.log('this.state.filters.activitytime',this.state.filters.activitytime);
    this.calculations();
  }

  changeadults(event) {
    var adultsnum = event.target.value;
    this.state.filters.adultsnum = adultsnum;

    this.calculations();
  }

  changechildren(event) {
    var tourprices = this.state.tourprices;
    var childrennum = event.target.value;
    this.state.filters.childrennum = childrennum;

    if (childrennum == 0) this.state.filters.childrenages = [];

    this.calculations();
  }

  calculations() {

    this.timesoptions()

    this.setmessages();

    var tourinfo = this.state.tourpricinginfo;
    var tourprices = this.state.tourprices;
    var childrennum = this.state.filters.childrennum;
    if(childrennum == null || childrennum == undefined || childrennum == "")childrennum = 0;

    var adultsnum = this.state.filters.adultsnum;
    var qtypersons = parseInt(childrennum) + parseInt(adultsnum);
    var qtyinventory = tourprices.QtyInventoryPTour;

    this.state.calculations.childrenfree.qty = 0;


    

    //Adults
    this.state.calculations.adults.qty = adultsnum;
    this.state.calculations.adults.price = this.parsefloat(
      tourprices.AdultPrice
    );
    this.state.calculations.adults.total = this.parsefloat(
      tourprices.AdultPrice * adultsnum
    );
    this.state.calculations.adults.age = parseInt(tourprices.MaxAgeChild) + 1;
    
    
    if (this.state.filters.childrenages.length < childrennum)
      childrennum = this.state.filters.childrenages.length;

    this.state.messages = [];
    var notallowedmessage = "";
    //Children Free
    this.state.filters.childrenages.map(
      function(age, index) {
        age = parseInt(age);

        if (age <= tourprices.MaxFreeAge && age >= tourprices.MinAge) {
          childrennum--;
          this.state.calculations.childrenfree.qty++;
        } else if (age < tourprices.MinAge) {
          childrennum--;
          notallowedmessage = this.t("Children under {{MinAge}} are not allowed on this tour",{MinAge:tourprices.MinAge});
        }
      }.bind(this)
    );

    if (notallowedmessage != "") this.state.messages.push(notallowedmessage);

    this.state.calculations.childrenfree.price = this.parsefloat(0);
    this.state.calculations.childrenfree.total = this.parsefloat(0);
    this.state.calculations.childrenfree.agerange =
      tourprices.MaxFreeAge > tourprices.MinAge
        ? tourprices.MinAge + " - " + tourprices.MaxFreeAge
        : 0 + " - " + tourprices.MinAge;

    //Children
    this.state.calculations.children.qty = childrennum;
    this.state.calculations.children.price = this.parsefloat(
      tourprices.ChildPrice
    );

    
    this.state.calculations.children.total = this.parsefloat(
      tourprices.ChildPrice * childrennum
    );
    this.state.calculations.children.agerange =
      (tourprices.MaxFreeAge > tourprices.MinAge
        ? parseFloat(tourprices.MaxFreeAge) + 1
        : parseInt(tourprices.MinAge) +
          (tourprices.MaxFreeAge == tourprices.MinAge ? 1 : 0)) +
      " - " +
      tourprices.MaxAgeChild;

    this.state.calculations.total = this.parsefloat(
      parseFloat(this.state.calculations.children.total) +
        parseFloat(this.state.calculations.adults.total)
    );
    

    //Price by Passengers
    var one = tourprices.OnePasengersPrice;
    var two = tourprices.TwoPasengersPrice;
    var three = tourprices.ThreePasengersPrice;
    var four = tourprices.FourPasengersPrice;

    var normalone = tourprices.NormalOnePasengersPrice;
    var normaltwo = tourprices.NormalTwoPasengersPrice;
    var normalthree = tourprices.NormalThreePasengersPrice;
    var normalfour = tourprices.NormalFourPasengersPrice;

    var passengers = [0, one, two, three, four];
    var normalpassengers = [0, normalone, normaltwo, normalthree, normalfour];

    if (tourprices.Id_TourPriceTypeDef == 3) {
      var maxqtypassengers = 4;

      for (var i = 0; i <= maxqtypassengers; i++) {
        if (passengers[i] == 0 && qtypersons == i) {
          this.state.calculations.passengers.total = "N/A";
          this.state.messages.push(this.t("No price available for {{qtypersons}} passenger",{qtypersons:qtypersons}));
        } else if (qtypersons == i) {
          this.state.calculations.passengers.total = passengers[i];
          this.state.calculations.passengers.price = passengers[i];
        }
      }

      if (qtypersons > maxqtypassengers) {
        this.state.calculations.passengers.total = "N/A";
        this.state.messages.push(this.t("No price available for {{qtypersons}} passenger",{qtypersons:qtypersons}));
      }

      this.state.calculations.passengers.qty = qtypersons;

      this.state.calculations.total = this.state.calculations.passengers.total;
    } else if (tourprices.Id_TourPriceTypeDef == 2) {
      this.state.calculations.total = tourprices.GroupPrice;
      this.state.calculations.group.qty = qtypersons;
      this.state.calculations.group.total = tourprices.GroupPrice;
      this.state.calculations.group.price = tourprices.GroupPrice;
    }

    if (qtypersons > qtyinventory)
      this.state.messages.push(this.t("FOR MORE THAN {{qtyinventory}} PASSENGERS CONTACT CONCIERGE",{qtyinventory:qtyinventory})
      );


    var extrasinfo = tourinfo.tour_extras;
    var extrasselected = this.state.filters.extras;
    this.state.calculations.extras = [];
    var extrastotal = 0;

    extrasinfo.map(function(extra,index){

      extrasselected.map(function(extra_,index_){
           if(extra.Id_TourExtra == extra_.Id_TourExtra) {
            var extratotal = parseFloat(parseFloat(extra_.Qty)*parseFloat(extra.Price));
            $('.tourextra'+extra.Id_TourExtra).val(extra_.Qty);
         
            this.state.calculations.extras.push({
              tourextraid:extra.Id_TourExtra,
              name:extra.Name,
              qty:extra_.Qty,
              price:extra.Price,
              total:extratotal
            })

            extrastotal = parseFloat(parseFloat(extrastotal)+parseFloat(extratotal));
          }
      }.bind(this));

    }.bind(this));

    this.state.addedtocart = false;
    this.state.loading=false;


    this.state.calculations.Id_TourPriceTypeDef = Number(tourprices.Id_TourPriceTypeDef);
    this.state.calculations.currency = tourprices.CurrencyCode;

    this.state.calculations.activityrateid = tourprices.Id_ActivityRate;
    this.state.calculations.destinationid = tourprices.Id_Destination;

    this.state.calculations.languageid = this.getglobaldata('Id_Language');
    this.state.calculations.currencyid = this.getglobaldata('Id_Currency');

    
    this.state.calculations.tourid = this.state.tourid;
    this.state.calculations.tourname = tourprices.Name;
    this.state.calculations.pricetype = tourprices.PriceType;
    this.state.calculations.filters = this.state.filters;
    this.state.calculations.isthreextwo = Number(tourprices.isThreeXTwo);
    this.state.calculations.hastransportationincluded = Number(tourprices.hasTransportationIncluded);

    const totalfordiscount = parseFloat(parseFloat(this.state.calculations.total)+parseFloat(extrastotal));
    this.state.calculations.totalfordiscount = totalfordiscount;
    this.state.calculations.totalbeforediscount = totalfordiscount;
    this.state.calculations.total = parseFloat(parseFloat(this.state.calculations.totalbeforediscount)-parseFloat(this.state.calculations.coupon.discount)).toFixed(2);
   
    if(this.state.calculations.total < 0 || this.state.filters.activitytime == '')  this.state.messages.push(this.t("Tour Activity Date Unavailable"));

    this.state.calculations.beforeotherdiscountstotal = this.state.calculations.total;



    this.state.filters.setUrl=true;
    this.setglobaldata("filter.touravailability", this.state.filters);
    
    if(this.config().travelagentid) this.state.filters.agentid = this.config().travelagentid;
    const urlParams= queryString.stringify(this.state.filters);
    //const pathname = window.location.pathname.replace('/'+this.config().brand, "");
    var pathAndSearch = `${window.location.pathname}?${urlParams}`;
    this.ChangeUrl('details', pathAndSearch)
    

    var fullUrl = window.location.href;

    if(this.config().customUrl && window.self !== window.top){
      pathAndSearch = pathAndSearch.replace('/'+this.config().brand, "");
      fullUrl = `${this.config().customUrl}${pathAndSearch}`; 
    }
    
     
    this.setcartitemsnum();
    this.othermessges();


    this.roundrices()


    this.setState({
     calculations:this.state.calculations,
     urlLink:fullUrl
    },function(){

      console.log('this.state.fullUrl',this.state.urlLink)

      if(this.props.onlytable) {
         this.addtocart(false);
        // this.senddispatcher('cart.view',{match:this.props.match});
      }else {

        //send to GTM
        this.gtmtouravailability([
          {   
           "id" : this.state.calculations.tourid,   
           "name" : this.state.calculations.tourname,   
           "price" : this.state.calculations.total,    
           "brand" : this.config().brandname,       
           "category" : "Tour", 
           "variant" : "Tour",  
           "quantity" : 1   
          }
         ]);

      }
      this.checkContinueCart();

    }.bind(this));

    
    
  }

  ChangeUrl(title, url) {
    if (typeof (window.history.pushState) != "undefined") {
        var obj = { Title: title, Url: url };
        window.history.pushState(obj, obj.Title, obj.Url);
    } else {
        alert("Browser does not support HTML5.");
    }
  }


  roundrices() {
    this.state.calculations.adults.total = Math.round(
      this.state.calculations.adults.total
    );
    this.state.calculations.adults.price = Math.round(
      this.state.calculations.adults.price
    );

    this.state.calculations.children.total = Math.round(
      this.state.calculations.children.total
    );
    this.state.calculations.children.price = Math.round(
      this.state.calculations.children.price
    );

    this.state.calculations.childrenfree.total = Math.round(
      this.state.calculations.childrenfree.total
    );
    this.state.calculations.childrenfree.price = Math.round(
      this.state.calculations.childrenfree.price
    );

    this.state.calculations.group.total = Math.round(
      this.state.calculations.group.total
    );
    this.state.calculations.group.price = Math.round(
      this.state.calculations.group.price
    );

    this.state.calculations.total= Math.round(
      this.state.calculations.total
    );
  }

  othermessges() {
    var childrennum = this.state.filters.childrennum;



    var childrenageslength = this.state.filters.childrenages.length;
    var tourinfo = this.state.tourpricinginfo;
    var tourprices = this.state.tourprices;

    if (this.state.filters.activitytime == -1)
      this.state.messages.push(this.t("Select start Time"));

    if (this.state.filters.activitytime == -2)
      this.state.messages.push(this.t("Activity Time Unavailable"));

    if (childrenageslength < childrennum)
      this.state.childrenmessages_2.push(this.t("Select children ages"));

   // if(tourprices.available == 0)  this.state.messages.push(this.t("Tour Activity Date Unavailable"));

    if(tourinfo.Id_InventoryType == 2) this.state.messages.push(this.t("For this activity please call concierge to confirm"));
    if(!this.getglobaldata('user')) {

      if(this.config().disablebooking) this.state.messages.push(this.t("Contact your travel agent to register for your benefits. You must speak with a concierge to book this option. In the meantime, you can browse our destinations.")); 
    }
}

  parsefloat(value) {
    return parseFloat(value).toFixed(2);
  }

  changechildage(index, event) {
    var childage = event.target.value;
    this.state.filters.childrenages[index] = childage;
    this.calculations();
  }

  handleCoupon(discount) {
     
      if(!discount ) {

      this.claercoupon();

      }else{
      
      if(discount.result) this.setcoupon(discount);
      else this.claercoupon();
      
      } 

      this.calculations();
       
    }

    claercoupon(){
      this.state.calculations.coupon.couponid = -1;
      this.state.calculations.coupon.name = '';
      this.state.calculations.coupon.discount = 0;
      this.state.calculations.coupon.code = '';
      this.state.filters.couponcode = '';
    }

    setcoupon(discount){
      this.state.calculations.coupon.couponid = discount.CouponInfo.Id_Coupon;
      this.state.calculations.coupon.name = discount.CouponInfo.Name;
      this.state.calculations.coupon.discount = discount.CouponInfo.DiscountAmount;
      this.state.calculations.coupon.code = discount.CouponInfo.Code;
      this.state.filters.couponcode = discount.CouponInfo.Code;
    }

  selectchildrenages() {
   
    var tourinfo = this.state.tourpricinginfo;
    var childrennum = this.state.filters.childrennum;
    var childrenages = this.state.filters.childrenages;

   

    var selectages = [];
    var childrenageslength = childrenages.length;

    if (childrenageslength > childrennum)
      childrenages.splice(childrennum, childrenageslength);

    let minage =tourinfo.MinAge;
    let maxage =tourinfo.MaxAgeChild;

    if(Number(tourinfo.MaxAgeChild) < Number(tourinfo.MaxFreeAge) && Number(tourinfo.MaxFreeAge) > 0 && Number(tourinfo.MaxFreeAge) > Number(tourinfo.MinAge)) maxage = tourinfo.MaxFreeAge;

    for (var i = 0; i < childrennum; i++) {
      var ChildrenAge = -1;

      if (childrenages[i]) ChildrenAge = childrenages[i];

      selectages.push(
        <div key={i} className={this.enableelement()?"col-md-3":'col-md-6'}>
          <select
            className="form-control"
            value={ChildrenAge}
            onChange={this.changechildage.bind(this, i)}
          >
            <option value={-1} disabled>
              {this.t("Child")} {i + 1}
            </option>
            {this.SelectOptions(minage, maxage)}
          </select>
        </div>
      );
    }

    return selectages;
  }

  addtocart(closepopup) {

    if (closepopup) {
      this.state.redirect = false;
      $.magnificPopup.close();
    }

    if (this.state.addedtocart) return false;

    if (!this.getglobaldata("Cart")) this.setglobaldata("Cart", []);
    var Cart = this.getglobaldata("Cart");
    var calculations = this.state.calculations;
    var itemindex = this.state.itemindex;

    

    if (itemindex  >= 0) Cart[itemindex] = calculations;
    else Cart.push(calculations);

    this.setglobaldata("Cart", Cart);


    this.setcartitemsnum();

    this.state.addedtocart = true;

    this.senddispatcher("cart.header", {});

    if (itemindex  >= 0) {

      //google analytics event
      this.ga().event({
         category: 'Cart',
         action: 'Cart item edited',
         label: 'Cart item edited'
      });

      if(Cart.length-1 == itemindex && this.props.updateview) this.props.updateview(); //this.senddispatcher('cart.view',{match:this.props.match});

    } else {

      //google analytics event
      this.ga().event({
         category: 'Cart',
         action: 'Added item to Cart',
         label: 'Added item to Cart'
      });

      this.forceUpdate();
    }


     

  }

  setcartitemsnum() {
    if (!this.getglobaldata("Cart")) this.setglobaldata("Cart", []);
    var Cart = this.getglobaldata("Cart");
    this.state.cartitemsnum = Cart.length;
  }

extraoptions(ExtraInfo_){
  var Adults = this.state.filters.adultsnum;
  var ChildrenNum = this.state.filters.childrennum;
  var TotalPeople = Number(Adults)+Number(ChildrenNum);
  var ChildrenAges = this.state.filters.childrenages;
  var Id_TourExtraType = ExtraInfo_.Id_TourExtraType;
  var Options = [];

  var TotalExtraPeople = 0;
  if(Id_TourExtraType == 5){
    var PersonsDivision = TotalPeople/ExtraInfo_.PersonsNum;
    if(PersonsDivision == Infinity) PersonsDivision = 0;

    var QtyPersonsFree = parseInt(PersonsDivision);

    QtyPersonsFree = QtyPersonsFree*ExtraInfo_.QtyPersonsFree;

    TotalExtraPeople = QtyPersonsFree;
  }
  else if(Id_TourExtraType == 4){

    var AdultsDivision = Adults/ExtraInfo_.AdultsNum;


    if(AdultsDivision == Infinity) AdultsDivision = 0;

    AdultsDivision = parseInt(AdultsDivision);

   
    var ChildrenQtyExtra = AdultsDivision*ExtraInfo_.QtyChildrenFree;
      

    var ChildrenQtyExtra_ = 0;
     ChildrenAges.map(function(ChildAge,index){
  
   if(ExtraInfo_.MaxChildrenAge > ChildAge) {
    
    ChildrenQtyExtra_++;
   
    }
  });

     if(ChildrenQtyExtra_ < ChildrenQtyExtra) ChildrenQtyExtra = ChildrenQtyExtra_;
     TotalExtraPeople = ChildrenQtyExtra;
  }
   else if(Id_TourExtraType == 3){

    var AdultsDivision_ = Adults/ExtraInfo_.AdultsNum;


    if(AdultsDivision_ == Infinity) AdultsDivision_ = 0;

    AdultsDivision_ = parseInt(AdultsDivision_);

  
    var AdultsQtyExtra = AdultsDivision_*ExtraInfo_.QtyAdultsFree;
      
     TotalExtraPeople = AdultsQtyExtra;
  }

  else if(Id_TourExtraType == 2){

    var ChildenDivision = ChildrenNum/ExtraInfo_.ChildrenNum;


    if(ChildenDivision == Infinity) ChildenDivision = 0;

    ChildenDivision = parseInt(ChildenDivision);

    var ChildrenQtyExtra = ChildenDivision*ExtraInfo_.QtyChildrenFree;
      

    var ChildrenQtyExtra_ = 0;
     ChildrenAges.map(function(ChildAge,index){
  
   if(ExtraInfo_.MaxChildrenAge > ChildAge) {
    
    ChildrenQtyExtra_++;
   
    }
  });

     if(ChildrenQtyExtra_ < ChildrenQtyExtra) ChildrenQtyExtra = ChildrenQtyExtra_;
     TotalExtraPeople = ChildrenQtyExtra;
  }
  else TotalExtraPeople = TotalPeople;

  for (var i = 0; i <= TotalExtraPeople; i++) {
    Options.push(<option value={i} key={i}>{i}</option>);
  }


  //para los extras obligatorios
  if(this.state.hasMandatoryExtrasCount != TotalExtraPeople){
    this.setState({
      hasMandatoryExtrasCount:TotalExtraPeople
    }); 
  }

  return Options;


}

  changeextra(extra_,event){
    var extras = this.state.tourpricinginfo.tour_extras;
    var qty = event.target.value;
    var currentextrasid = [];

    this.state.filters.extras.map(function(extra,index){

         currentextrasid.push(extra.Id_TourExtra);
         if(extra_.Id_TourExtra == extra.Id_TourExtra){
          if(qty > 0){
            this.state.filters.extras[index].Qty = qty;
          }
          else {
            this.state.filters.extras.splice(index,1);
            currentextrasid.splice(index,1);
          }
         }

    }.bind(this));

    if(currentextrasid.indexOf(extra_.Id_TourExtra) == -1 && qty > 0) this.state.filters.extras.push({Id_TourExtra:extra_.Id_TourExtra,Qty:qty, Id_Tour:extra_.Id})

    this.calculations();
        
  }

  changeextraPlusMinus(extra_,qty){
      
    if(qty <= 0)qty = 0;
    var extras = this.state.tourpricinginfo.tour_extras;    
    var currentextrasid = [];

    this.state.filters.extras.map(function(extra,index){

         currentextrasid.push(extra.Id_TourExtra);
         if(extra_.Id_TourExtra == extra.Id_TourExtra){
          if(qty > 0){
            this.state.filters.extras[index].Qty = qty;
          }
          else {
            this.state.filters.extras.splice(index,1);
            currentextrasid.splice(index,1);
          }
         }

    }.bind(this));

    if(currentextrasid.indexOf(extra_.Id_TourExtra) == -1 && qty > 0) this.state.filters.extras.push({Id_TourExtra:extra_.Id_TourExtra,Qty:qty, Id_Tour:extra_.Id})

    this.calculations();
   
        
  }

  checkContinueCart(){

    var hasMandatory = false;
    this.state.tourpricinginfo.tour_extras.map(function(extra,index){
      if(extra.mandatory == "-1")hasMandatory = true;
    }.bind(this));

   

    if(hasMandatory == true){
     
     
      var continueCart_ = false;
      this.state.filters.extras.map(function(extra,index){
        
        
        this.state.tourpricinginfo.tour_extras.map(function(extra_original,index){
         
          if(extra_original.mandatory == "-1" && extra.Id_TourExtra == extra_original.Id_TourExtra && parseInt(extra.Qty) > 0)continueCart_ = true;
        }.bind(this));
        
       
      }.bind(this));

     

      this.setState({
        continueCart:continueCart_
      });    
    }
  }

  MeetingPoint(){

    return [];

    var scheduleinfo = this.state.scheduleinfo;


    var schedule= scheduleinfo.find(schedule =>{

      return schedule.StartTime == this.state.filters.activitytime
    })


    return schedule?schedule.MeetingPoint!=''?[schedule.MeetingPoint]:[]:[]


  }



  copyUrl() {

  /* Get the text field */
  var copyText = document.getElementById("copyDetailsUrl");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

   /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.value);

  /* Alert the copied text */
  //alert("Url copiada");
}

  render() {
    var tourinfo = this.state.tourpricinginfo;
    var tourprices = this.state.tourprices;
   

    //remaining de los extras obligatorios
    if(this.state.tourpricinginfo.tour_extras){
      var remaining_extras = parseInt(this.state.hasMandatoryExtrasCount);
      
      var hasMandatory = false;
      this.state.tourpricinginfo.tour_extras.map(function(extra,index){
        if(extra.mandatory == "-1")hasMandatory = true;
      }.bind(this));

      if(hasMandatory == true){     
      
        var continueCart_ = false;
        this.state.filters.extras.map(function(extra,index){
        
          
          this.state.tourpricinginfo.tour_extras.map(function(extra_original,index){          
            if(extra_original.mandatory == "-1" && extra.Id_TourExtra == extra_original.Id_TourExtra)remaining_extras = remaining_extras - parseInt(extra.Qty);
          }.bind(this));
          
        
        }.bind(this));
    
      }
    }

    /////////////////////////////////////////////////////////////////////////

    

    
    var datepickermindate = this.state.datepickermindate; 
    var datepickermaxdate = moment().add(365, "days");
    var filters = this.state.filters;
    var activitydate = filters.activitydate;
    var calculations = this.state.calculations;
    var messages = this.state.messages;
    var cartitemsnum = this.state.cartitemsnum;
    var itemindex = this.state.itemindex;
    var withPortal = true;
    var datepickeroffdays = this.state.datepickeroffdays;


    //if(this.ismobile()) withPortal = false;

    if(window.self !== window.top) withPortal = false;


     if(this.state.loading)  return (<div>{this.spinner()}</div>)
   //  if(this.state.notourinfomessage != '') return (<div><h3>{this.state.notourinfomessage}</h3><br/><h5>{this.t('Phone')}:<br/> {this.config().phone} ({this.t('Toll Free')})</h5></div>)
     
     
    /* if(!this.state.availableprices) return (

      <div className="tour_title">
              <h3>
                <strong>No hay precios disponibles para este tour</strong>
              </h3>
            </div>

      )*/




     if(this.props.onlytable)  return (
      <div>
       <TourPricesTable calculations={calculations} />
                                       <Coupon
                                            
                                            onlytable={this.props.onlytable}
                                            Id_ActivityType={1}
                                            ActivityDate={moment(activitydate).format('YYYY-MM-DD')}
                                            ActivityTime={filters.activitytime}
                                            AdultsNum={filters.adultsnum}
                                            ChildsNum={filters.childrennum}
                                            Id_Activity={calculations.tourid}
                                            total={calculations.totalfordiscount}
                                            code={filters.couponcode}
                                            handle={this.handleCoupon.bind(this)}
                                        />
      </div>
    );


    return (
      
          <div className={this.enableelement()?"tour_container":''}>

            {this.enableelement()?
              <div className="tour_title">
              <h3>
                <strong>{tourinfo.TourName}</strong>
              </h3>
              <p>{this.t("From")} {tourinfo.DestinationName}</p>
            </div>
            :''
            }

            <div className="tour_title cityexp_text_t">


            <div className="form-group row">
                <div className="col-md-6">
                  {this.enableelement()?<label><i className="icon-calendar-7"></i> {this.t("Activity Date")}</label>:<label><i className="icon-calendar-7"></i> {this.t("Date")}</label>}
                  <br/>
                  <DatePicker
                    selected={filters.activitydate}
                    minDate={datepickermindate._d}
                    maxDate={datepickermaxdate._d}
                    excludeDates={datepickeroffdays}
                    withPortal={withPortal}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    placeholderText={this.t("Activity Date")}
                    filterDate={this.availableweekdays.bind(this)}
                    onChange={this.changeactivitydate.bind(this)}
                    disabled={this.state.disabledatepicker}
                  />
                </div>
                <div className="col-md-6">
                  {this.enableelement()?<label><i className=" icon-clock"></i>{this.t("Activity Time")}</label>:<label><i className=" icon-clock"></i>{this.t("Time")}</label>}
                  <select
                    value={filters.activitytime}
                    className="form-control"
                    onChange={this.changeactivitytime.bind(this)}
                    disabled={this.state.disabledatepicker}
                  >
                    <option value={-1} disabled>
                     {this.t(" Select Time")}
                    </option>
                    {this.state.StartTimes}
                  </select>
                </div>
              </div>





            <ErrorMessages messages={messages} />
            <ErrorMessages messages={this.state.defaultmessages}  colorclass='alert-primary' />
            <br/>

              <div className="form-group row">
                <div className="col-md-6">
                  <label>{this.t("Adults")}</label>

                  <select
                    className="form-control"
                    value={filters.adultsnum}
                    onChange={this.changeadults.bind(this)}
                  >
                    <option value={-1} disabled>
                      {this.t("Select Adults")}
                    </option>
                    {this.SelectOptions(1, tourprices.QtyInventoryPTour)}
                  </select>
                </div>

              
              
                <div className="col-md-6">
                  <label>{this.t("Children")}</label>

                  <select
                    className="form-control"
                    value={filters.childrennum}
                    onChange={this.changechildren.bind(this)}
                    disabled={this.state.disablechildren}
                  >
                    <option value={-1} disabled>
                      {this.t("Select Children")}
                    </option>
                    {this.SelectOptions(0, tourprices.QtyInventoryPTour)}
                  </select>
                </div>
              

                {/* ############### Start Children Ages ###############  */}
                {filters.childrennum > 0 ? (
                  <div className="col-md-12 text-center"><br/>
                    <label>{this.t("Children Ages")}</label>
                    
                    <br/>
                    <ErrorMessages messages={this.state.childrenmessages_2} />
                    <br/>
                    
                    <div className="row">
                      {this.selectchildrenages(tourprices.MinAge)}
                    </div>
                    <br/>
                    <ErrorMessages messages={this.state.childrenmessages} colorclass='alert-primary'/>
                  </div>
                ) : (
                  ""
                )}
                {/* ############### End Children Ages ###############  */}
              </div>

              
            
           
              <div className="row">
              <div className="col-md-12">
             {tourinfo.tour_extras.length >0 ? <h3 className="panel-title"><i className="text-danger fa fa-bookmark"></i><span className="text-muted"> {this.t("Extras & Specials")}</span></h3>:''}
             {this.state.hasMandatoryExtras == true?<label style={{fontSize:'10px',color:'red'}}><span > {this.t("To continue with the reservation, it is necessary to complete the following options:")}</span></label>:''}
             {this.state.hasMandatoryExtras == true?<label style={{fontSize:'13px',color:'#DC3545'}}><br/><span > <strong>{this.t("remaining")}: {remaining_extras}</strong></span></label>:''}
             {tourinfo.tour_extras.map(function(extra, index){ 
               console.log("extra",extra);
              let extracolclass = 'col-sm-6';
              if(!this.enableelement()) extracolclass = 'col-sm-12';
              
              var value_extra_ = 0;
              //extras obligatorios
              this.state.filters.extras.map(function(extra_,index){

                if(extra_.Id_TourExtra == extra.Id_TourExtra)value_extra_ = extra_.Qty;  
                     
              }.bind(this));

              
              
              return ( 

             <div className="row" key={index}>
               
              <div className={extracolclass}>
                 
                  <label> {extra.Name}</label>
                
              </div>

              
              
              


              {extra.mandatory == -1?
              <div class="col-12">  

              <div className="col-sm-6 tourextras" style={{display:'none'}}>
              <select  className={'form-control tourextra'+extra.Id_TourExtra} onChange={this.changeextra.bind(this,extra)} >
              {this.extraoptions(extra)}
              </select>
              </div>

              <div class="row d-flex">
                  <div class="col-sm-6 d-flex">
                          <span class="input-group-prepend">
                              <button onClick={()=>this.changeextraPlusMinus(extra,value_extra_-1)} type="button" class="btn btn-outline-secondary btn-number">
                                  <span class="fa fa-minus"></span>
                              </button>
                          </span>
                          <span class="input-group-prepend">
                          <input type="text" name="" class="form-control input-number" value={value_extra_} disabled/>
                          </span>
                          <span class="input-group-append">
                              <button onClick={()=>this.changeextraPlusMinus(extra,value_extra_+1)} type="button" class="btn btn-outline-secondary btn-number" disabled={remaining_extras <= 0?true:false}>
                                  <span class="fa fa-plus"></span>
                              </button>
                          </span>                              
                  </div>
              </div>
              </div>
              :
              <div className="col-sm-6 tourextras">
              <select  className={'form-control tourextra'+extra.Id_TourExtra} onChange={this.changeextra.bind(this,extra)} >
              {this.extraoptions(extra)}
              </select>
              </div>
              }

              


            </div>

            );
          }.bind(this))}
          </div>
          </div>

             {this.state.calculations.total > 0 ? <div className="row">
                <div className="col-md-12">
                  <div className="tour_rates">
                    <h2>
                      <strong>
                        {this.t("Rates are per {{pricetype}} in {{currency}}",{pricetype:tourprices.PriceType,currency:calculations.currency})}
                      </strong>
                    </h2>
                  </div>
                </div>
              </div>:''}

              {/* ################################# Start Prices table ################################# */}

              {this.state.calculations.total > 0 ?
                
                <span>{this.enableelement()?
                  <div><TourPricesTable calculations={calculations} /></div>:
                  <div><TourPricesTable__ calculations={calculations} /></div>
                }</span>

                :''}


              {/* ################################# End Prices table ################################# */}
              
             
               <Coupon
                                            Id_ActivityType={1}
                                            ActivityDate={moment(filters.activitydate).format('YYYY-MM-DD')}
                                            ActivityTime={filters.activitytime}
                                            AdultsNum={filters.adultsnum}
                                            ChildsNum={filters.childrennum}
                                            Id_Activity={calculations.tourid}
                                            total={calculations.totalfordiscount}
                                            code={filters.couponcode}
                                            ChildrenAges={filters.childrenages.join(',')}
                                            handle={this.handleCoupon.bind(this)}
                                        />


                
              
              {this.MeetingPoint().length > 0?
                <div className="row">
              <label>{this.t('Meeting Point')}<br/></label>
              <ErrorMessages messages={this.MeetingPoint()}  colorclass='alert-primary' />
              </div>:''
              }

              {this.state.continueCart?
              <div>
              {/*Buttons Checkout & Cart*/}
              <hr />
              {messages.length == 0 && this.state.childrenmessages_2.length == 0? (
                <div className="row">
                  <div className={this.enableelement()?"col-md-6":'col-md-12'}>
                    <div className="tour_rates">
                      {itemindex  >= 0? (
                        <NavLink                          
                          to="/cart"
                          className="btn add_cartbtn"
                          onClick={this.addtocart.bind(this, true)}
                        >
                          <span>{this.t("Finish Edition")} </span>
                        </NavLink>
                      ) : (
                        <div
                          
                          className="btn add_cartbtn"
                          onClick={this.addtocart.bind(this, false)}
                        >
                          <span>{this.t("Add to Cart")} </span>
                          <span className="cart_icon">
                            <i className="icon-cart" />
                            <strong>{cartitemsnum}</strong>
                          </span>{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={this.enableelement()?"col-md-6":'col-md-12'}>
                    <div className="tour_rates">
                      {itemindex  == -1 ? (
                        <NavLink                          
                          to="/checkout"
                          className="btn checkout_popc"
                          onClick={this.addtocart.bind(this, true)}
                        >
                          {this.t("Check out now")}
                          <i className="icon-check" />{" "}
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/*End Buttons Checkout & Cart*/}  
              </div>:null}


            </div>

{this.config().adminid && (this.config().customUrl || window.self === window.top)?
           <div>
           <textarea  onClick={this.copyUrl.bind(this)} value={this.state.urlLink} readOnly id="copyDetailsUrl" className="mt-5 p-2 disabled" style={{border: '1px solid #333333',
  width: '100%',
  height: '100px',
  overflow: 'hidden'}} />

</div>:null}
          </div>
       
    );
  }


}


export default ToursAvailability
