import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}
const $ = window.$;

class Error extends Global{ 

    constructor(props) {
    super(props);

    this.state ={
       
      }

  }

    componentDidMount(){

      

    }


    render() {

       

        return (
            <section className="decline_b">
                <div className="intro_title error">
                    <h4 className="animated fadeInDown">404</h4>
                    <p className="animated fadeInDown">{this.t("OOPS!! PAGE NOT FOUND")}.</p>
                    <NavLink className="animated fadeInUp button_intro" to="/home">{this.t("Back to home")}</NavLink>
                    <NavLink className="animated fadeInUp button_intro outline" to="/tours">{this.t("View all tours")}</NavLink>
                </div>
            </section>
        )
    }

}

export default Error