import React, { Component } from 'react';
import TourReviews from './component.tour.reviews';
import TourRulesThings from './component.tour.rules.things';
import { NavLink, withRouter } from "react-router-dom";
import IconWishlist from "./component.icon.wishlist";
import Global from '../globals/global';

class ReviewCard extends Global {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            console.log('trending', this.state.data);
        }
    }

    getPreviewImgURL() {
        return this.state.data.Images[Math.floor(Math.random() * this.state.data.Images.length)];
    }

    render() {
        var index = this.props.index;
        return (
            this.state.data !== null ? (
                <div className="card mb-30">
                    <div className="card-body bor_t">

                        <div className="row">
                            <div className="col-lg-6">
                                <h6 className="title_trendr"><strong>{this.state.data.Name}</strong> </h6>
                            </div>

                            <div className="col-lg-2 clock_trend">
                                <span><i className="icon-clock"></i><br /> {`${this.state.data.hDuration}h ${this.state.data.mDuration}m`}</span>
                            </div>

                            <div className="col-lg-4">
                                <NavLink to={"/tours/availability/" + this.state.data.Id + "/add"} className="ava_btn cart_pop">{this.t('Check Availability & Prices')}</NavLink>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                {
                                    this.state.data.isTopRated === '-1' ? <div className="ribbon_3"><span>{this.t('Top Rated')}</span></div> : null
                                }
                                <img src={this.state.data.Images.TabletOriginalImages[Math.floor(Math.random() * this.state.data.Images.TabletOriginalImages.length)]} className="img-fluid" alt="image" style={{width:'100%',height:'100%'}}/>
                                <div className="short_back">
                                    <span className="price"><small className="dollar_text"> {this.t('FROM')} </small><sup>$</sup>{Math.round(this.state.data.NormalPrice)}<small className="dollar_text"> {this.state.data.CurrencyCode}</small></span>
                                </div>

                          
                                <div style={{position:'absolute',bottom:'50px',left:'10px'}} >
                                <IconWishlist InWishlist={this.state.data.InWishlist} tourid={this.state.data.Id} className='wish_blue' tourinfo={this.state.data} />
                                </div>

                            </div>

                            <div className="col-lg-6 scroll_rev">
                                <h6 className="blue_text"><strong>{this.t('Reviews')}</strong> </h6>
                                <hr />
                                <TourReviews data={this.state.data.tour_reviews} />
                            </div>
                        </div>

                        {/* tabs */}
                        <div className="col-lg-12">
                            {/* Nav tabs  */}
                            <ul className="nav nav-tabs tabs_rev" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href={"#menu4"+index}>{this.t('Highlights')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href={"#menu5"+index}>{this.t('Rules & Things')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href={"#menu6"+index}>{this.t('Map')}</a>
                                </li>
                            </ul>

                            {/* Tab panes  */}
                            <div className="tab-content no_border">
                                <div id={"menu4"+index} className="container tab-pane">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4 className="highlights_title blue_text">{this.t('Tour Description')}</h4>
                                            <p>{this.state.data.LongDescription}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id={"menu5"+index} className="container tab-pane fade">
                                    <br />
                                    <TourRulesThings title={this.t("What's included")} list={this.state.data.tour_inclusions} />
                                    <TourRulesThings title={this.t("Tour Requirements")} list={this.state.data.tour_requirements} />
                                    <TourRulesThings title={this.t("What To Bring")} list={this.state.data.tour_bring} />
                                </div>

                                <div id={"menu6"+index} className="container tab-pane fade">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="map_t">
                                                 <iframe src = {"https://maps.google.com/maps?q="+this.state.data.GpsLocationLat+","+this.state.data.GpsLocationLong+"&hl=es;z=8&output=embed"}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>{/* end tab-panes */}
                        </div>{/* end col-12 */}
                    </div>{/* end card-body */}
                </div>
            ) : <div>{`${this.t('Loading...')}`}</div>
        )
    }
}

export default withRouter(ReviewCard)