import React, { Component } from "react";
import Section from "../components/component.section";
import { NavLink, withRouter } from "react-router-dom";
import Global from "../globals/global";
//import footerToorzy from "../partials/partial.footer.toorzy";

import {
    validateForm,
    validateField,
} from "../components/component.form.validator.js";
import Button from "../components/component.button.loader";

const apiurl = "https://api-tagentpro-sandbox.mobilesail.com/commission_project";
const $ = window.$;

class HomeLanding extends Global {
    constructor(props) {
    super(props);
        this.state = {
            errorMessage:'',
            userExist:false,
            loading:false,
            destinations: this.getglobaldata("destinations") || [],
            selectedfilter:null,
            selectedFiltervalue:null
        }
        
    }

     componentDidMount() {
    this.getdispatcher(
      "load.destinations",
      function(data) {
        this.setState(
          {
            destinations: this.getglobaldata("destinations") || []
          },
          function() {}.bind(this)
        );
      }.bind(this)
    );
  }


  setOtherFilters(){
     this.settourfilter(this.state.selectedfilter, this.state.selectedFiltervalue);
  }

  /**
   * @function opendiagrampopup
   **/
  opendiagrampopup(filter, value, event) {

    this.setState({
      selectedfilter:filter,
      selectedFiltervalue:value
    },function(){



    if(!this.config().changedestination){


      this.gotours(this.getDestination(this.getglobaldata("Id_Destination"),true),event);

    }else{

    
    $.magnificPopup.open({
      items: {
        src: ".askdestinyhome",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
      callbacks: {
        open: function () {},

        close: function () {
          //this.state.bancomerurl = false;
        }.bind(this),
      },
    });

    }



    })


    
  }

  
  getDestination(id,otherfilter){

    var destination_ = this.state.destinations.find(function(destination) {
      return Number(destination.Id_Destination) == id;
    });

    if(otherfilter && destination_) destination_.setOtherFilters = this.setOtherFilters.bind(this);

    return destination_;

    
  }


    render() {
        
        return (
            <div>

    <section className="home_main">
        <div className="container-fluid banner_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="title_banner_home">

                  <h4 className="exp_title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              ' The Best <span className="blue_text">Experiences</span>'
                            ),
                          }}
                        ></h4>

                    <p className="exp_title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Welcome to <span className="blue_text"><b>{{brandname}}</b></span>, the best place where we give you the best experiences. Explore our destinations and discover the options we have for you.',{ brandname: this.config().brandname }
                            ),
                          }}
                        ></p>



                </div>
              </div>
            </div>{/*end row*/}
          </div>{/*end container*/}
        </div>
    </section>

                <main>

<div className="white_bg">
    <div className="container margin_60">

          {/*start top cards*/}  
        {this.config().changedestination?
          <div className="row my-3">
          {this.config().brand != 'pricetravel' && this.config().brand != 'marivaldistinct' && this.config().brand != 'marivalemotions' && this.config().brand != 'marivalarmony' && this.config().brand != 'jorieandpietro' && this.config().brand != 'delmarboutiqueaccommodation' && this.config().brand != 'inovationtravel'?               
            <div className="col-lg-4 col-md-6 col-4 mx-auto">
              
              <a href="#" className="card_home_hov"
               onClick={this.gotours.bind(this, this.getDestination(1))}>
                <div className="card card_homeb card_rotate_a">
    
                  <img
                    src={this.image(
                    "img/homelanding/b1.jpg"
                    )}
                    className="card-img-top"
                />

                  <div className="card-body">
                    <h5 className="card-title">{this.t("Cancunp")}</h5>
                  </div>
                </div>
              </a>
            </div>
            :''}
            {this.config().brand != 'marivaldistinct' && this.config().brand != 'marivalemotions' && this.config().brand != 'marivalarmony' && this.config().brand != 'jorieandpietro' && this.config().brand != 'delmarboutiqueaccommodation' && this.config().brand != 'inovationtravel'?  
            <div className="col-lg-4 col-md-6 col-4 mx-auto">
              <a href="#" className="card_home_hov"
              onClick={this.gotours.bind(this, this.getDestination(5))}
              >
                <div className="card card_homeb card_rotate_b">

                <img
                    src={this.image(
                    "img/homelanding/b2.jpg"
                    )}
                    className="card-img-top"
                />


                  <div className="card-body">
                    <h5 className="card-title">Cabos</h5>
                  </div>
                </div>
              </a>
            </div>
             :''}
            <div className="col-lg-4 col-md-6 col-4 mx-auto">
              <a href="#" className="card_home_hov"
               onClick={this.gotours.bind(this, this.getDestination(2))}>
                <div className="card card_homeb card_rotate_c">
                 
                <img
                    src={this.image(
                    "img/homelanding/b3.jpg"
                    )}
                    className="card-img-top"
                />

                  <div className="card-body">
                    <h5 className="card-title">Vallarta</h5>
                  </div>
                </div>
              </a>
            </div>


          </div>

        :null}{/*end row*/}
          {/*end Top Cards*/}




          <div className="title_best">
           
            <h3
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              ' The Best <span className="blue_text"><b>activities for you</b></span>'
                            ),
                          }}
                        ></h3>

          </div>

          {/*start best activities cards*/}                      
          <div className="row">

          <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card card_activity_cont">

              <img
                    src={this.image(
                    "img/homelanding/a1.jpg"
                    )}
                    className="card-img"
                />

                <div className="card-img-overlay card_activity">
                <h5     className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Explore <br /> the seabed'
                            ),
                          }}
                        ></h5>

                    <p className="card-text"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Dive and learn <br /> about the best of the ocean'
                            ),
                          }}
                        ></p>
                        
                 
                  <a onClick={this.opendiagrampopup.bind(this, "categories", ["Water"])} className="askdestiny_pop stretched-link">{this.t("Explore")}</a>   
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card card_activity_cont">

                <img
                    src={this.image(
                    "img/homelanding/a2.jpg"
                    )}
                    className="card-img"
                />


                <div className="card-img-overlay card_activity">
                <h5     className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Excitement <br /> to the fullest'
                            ),
                          }}
                        ></h5>
       
                  <p className="card-text"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Drive and dare <br /> to travel the best roads'
                            ),
                          }}
                        ></p>


                  <a onClick={this.opendiagrampopup.bind(this, "categories", ["Land"])} className="askdestiny_pop stretched-link">{this.t("Explore")}</a>    
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card card_activity_cont">
      
              <img
                    src={this.image(
                    "img/homelanding/a3.jpg"
                    )}
                    className="card-img"
                />

                <div className="card-img-overlay card_activity">
                  <h5     className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Live  <br /> and cultivate'
                            ),
                          }}
                        ></h5>

                      <p className="card-text"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Know the best <br /> of our culture'
                            ),
                          }}
                        ></p>

                  <a onClick={this.opendiagrampopup.bind(this, "categories", ["Cultural"])} className="askdestiny_pop stretched-link">{this.t("Explore")}</a>   
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card card_activity_cont">

              <img
                    src={this.image(
                    "img/homelanding/a4.jpg"
                    )}
                    className="card-img"
                />

                <div className="card-img-overlay card_activity">
                <h5     className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Enjoy <br /> the best environment'
                            ),
                          }}
                        ></h5>
                        <p className="card-text"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Dinner and <br /> have a quiet evening'
                            ),
                          }}
                        ></p>
                  <a onClick={this.opendiagrampopup.bind(this, "categories", ["Nightlife"])} className="askdestiny_pop stretched-link">{this.t("Explore")}</a>   
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card card_activity_cont">

              <img
                    src={this.image(
                    "img/homelanding/a5.jpg"
                    )}
                    className="card-img"
                />

                <div className="card-img-overlay card_activity">
                <h5     className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Master <br /> your skills'
                            ),
                          }}
                        ></h5>
                          <p className="card-text"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Dare to have <br /> an extreme adventure'
                            ),
                          }}
                        ></p>
                  <a onClick={this.opendiagrampopup.bind(this, "categories", ["Adventure"])} className="askdestiny_pop stretched-link">{this.t("Explore")}</a>   
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card card_activity_cont">

              <img
                    src={this.image(
                    "img/homelanding/a6.jpg"
                    )}
                    className="card-img"
                />

                <div className="card-img-overlay card_activity">
                <h5     className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Family <br /> fun'
                            ),
                          }}
                        ></h5>
                        <p className="card-text"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              'Have a magical  <br /> and unique adventure'
                            ),
                          }}
                        ></p>
                  <a onClick={this.opendiagrampopup.bind(this, "categories", ["Family"])} className="askdestiny_pop stretched-link">{this.t("Explore")}</a>   
                </div>
              </div>
            </div>


          </div>    





          <div className="title_why mt-5">

            <h3
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              ' Why should <span className="blue_text"><b>you choose us</b></span>'
                            ),
                          }}
                        ></h3>


          </div>
          
          <div className="row">
            <div className="col-md-4">
              <div className="card card_why_choose">

                <img
                    src={this.image(
                    "img/homelanding/opt_image1.png"
                    )}
                    className="card-img-top"
                />

                <div className="card-body">
                  <h5>{this.t("Save time & money")}</h5>
                  <p>
                       {this.t(
                          "You won't find it cheaper online or anywhere else!"
                       )}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card card_why_choose">

              <img
                    src={this.image(
                    "img/homelanding/opt_image2.png"
                    )}
                    className="card-img-top"
                />

                <div className="card-body">
                  <h5>{this.t("Quick booking")}</h5>
                  <p>
                  {this.t(
                          "When you book ahead, you are guaranteed not to miss out on popula tours & activities."
                        )}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card card_why_choose">

              <img
                    src={this.image(
                    "img/homelanding/opt_image3.png"
                    )}
                    className="card-img-top"
                />

                <div className="card-body">
                  <h5>{this.t("Travel with confidence")}</h5>
                  <p>
                  {this.t(
                          "Our providers are hand-picked, safe and reliable, so you always get what you pay for."
                        )}
                  </p>
                </div>
              </div>
            </div>
          </div>    
          {/*start best activities cards*/}                

        {/*start best experiences card*/}   
          <div className="mt-5" />
          <div className="row">
            <div className="col-lg-6">
              <div className="card card_bestexp">

                <img
                    src={this.image(
                    "img/homelanding/banner_bottom_a.jpg"
                    )}
                    className="card-img"
                />

                <div className="card-img-overlay">
                  <h5     
                          className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              ' The Best <br/>Experiences'
                            ),
                          }}
                        ></h5>


                  <p className="card-text">{this.t("Discover and plan your trip")}</p>
                  <a onClick={this.opendiagrampopup.bind(this)} className="askdestiny_pop">{this.t("Explore")}</a>     
                  
                </div>
              </div>
            </div>
            
            <div className="col-lg-6">
              <div className="card card_bestexp">

              <img
                    src={this.image(
                    "img/homelanding/banner_bottom_c.jpg"
                    )}
                    className="card-img"
                />


                <div className="card-img-overlay">
                <h5     
                          className="card-title"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              ' The most <br/>Popular'
                            ),
                          }}
                        ></h5>
                  <p className="card-text text-white">{this.t("Discover the most outstanding")}</p>
                   {/*<a href="tours/1" onClick={this.settourfilter.bind(this, "istoprated", -1)}>{this.t("Explore")}</a>*/}   

                   <NavLink
                  to="/tours/1"
                  className=""
                  onClick={this.settourfilter.bind(this, "istoprated", -1)}
                >
                  {this.t("Explore")}
                </NavLink>


                </div>
              </div>
            </div>
          </div>  
           {/*end best experiences card */}     


    </div>
</div>
      


<div id="askdestiny" className="askdestinyhome zoom-anim-dialog mfp-hide">
        <div className="small-dialog-header ask_dialog_dest">
          <h3>{this.t("Destiny")}</h3>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h4 className="destiny_question">{this.t("What destination do you want to visit?")}</h4>
          </div>
          {this.config().brand != 'pricetravel' && this.config().brand != 'marivaldistinct' && this.config().brand != 'marivalemotions' && this.config().brand != 'marivalarmony' && this.config().brand != 'jorieandpietro' && this.config().brand != 'delmarboutiqueaccommodation' && this.config().brand != 'inovationtravel'?
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 mx-auto">
            <a href="#"
             onClick={this.gotours.bind(this, this.getDestination(1,true))}>
              <div className="card card-block d-flex border-0">

                <img
             src={this.image(
             "img/homelanding/q1_cancun.jpg"
              )}
             className="card-img card_ask_image"
           />

                <div className="card-overlay" />
                <div className="card-body align-items-center d-flex justify-content-center card-img-overlay border-top-0">
                  <h5 className="askcard_title">{this.t("Cancunp")}</h5>
                </div>
              </div>
            </a>
          </div>
          :''}

          {this.config().brand != 'marivaldistinct' && this.config().brand != 'marivalemotions' && this.config().brand != 'marivalarmony' && this.config().brand != 'jorieandpietro' && this.config().brand != 'delmarboutiqueaccommodation' && this.config().brand != 'inovationtravel'?
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 mx-auto">
            <a href="#"
             onClick={this.gotours.bind(this, this.getDestination(5,true))}>
              <div className="card card-block d-flex border-0">

              <img
             src={this.image(
             "img/homelanding/q2_cabos.jpg"
              )}
             className="card-img card_ask_image"
           />

                <div className="card-overlay" />
                <div className="card-body align-items-center d-flex justify-content-center card-img-overlay border-top-0">
                  <h5 className="askcard_title">Cabos</h5>
                </div>
              </div>
            </a>
          </div>
          :''}
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 mx-auto">
            <a href="#"
             onClick={this.gotours.bind(this, this.getDestination(2,true))}>
              <div className="card card-block d-flex border-0">

              <img
             src={this.image(
             "img/homelanding/q3_vallarta.jpg"
              )}
             className="card-img card_ask_image"
           />


                <div className="card-overlay" />
                <div className="card-body align-items-center d-flex justify-content-center card-img-overlay border-top-0">
                  <h5 className="askcard_title">Vallarta</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>







                    <div id="overlay"></div>
                    {/* Mask on input focus */}
                </main>
                {/* End main */}
            </div>
        );
    }
}

export default HomeLanding;
