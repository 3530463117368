import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import IconWishlist from "./component.icon.wishlist";
import Global from "../globals/global";

import {FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon} from "react-share";
       


//if (typeof window === "undefined")  var window={}
const $ = window.$;
const discount = 0.1;

class tourslist extends Global {
  
  

  constructor(props) {
    super(props);

    if(!this.getglobaldata('recommendToursList')) this.setglobaldata('recommendToursList',[]);

     const initialRecommendedTours = this.getglobaldata('recommendToursList');

     this.iframecontrol({checkedTours:initialRecommendedTours});

    this.state = {
      checkedTours:initialRecommendedTours,
      languageId:this.getglobaldata('Id_Language')
    };
  }

  componentDidMount() {
    if (this.props.slider) {
      $("#blogSlider").multislider({
        duration: 750,
        interval: 4500,
      });
    }
  }

  removetour(index) {
    this.props.tours.splice(index, 1);
    this.forceUpdate();
  }

  settourdestination(tour, event) {
    if (this.config().toursListCheckBox) {
      event.preventDefault();
    }

    this.setglobaldata("Tour_Id_Destination", tour.Id_Destination);
    this.setglobaldata("Tour_Id_Location", tour.Id_Location);
  }

  cartnavigation(tour, event) {
    event.preventDefault();

    this.settourdestination(tour, event);

    this.pushnavigate("/tours/availability/" + tour.Id + "/add");
  }

  /**
   * @function limitText
   * @params {string} text
   * @return {string} short_text
   **/
  limitText(text) {
    let short_text = "";
    let limit = 150;

    if (text.length > limit) {
      short_text = text.substring(0, limit) + "..";
    } else {
      short_text = text;
    }

    return short_text;
  }

  shareDirectLink(tourInfoDirectLink){

        
        console.log('tourInfoDirectLink',tourInfoDirectLink);
        
        
        //lo grabamos en la lista de local storage
        this.setglobaldata('recommendToursDirect',tourInfoDirectLink);

        //enviamos fuera del iframe
       this.iframecontrol({tourInfoDirectLink:tourInfoDirectLink});

        //lo seteamos
        this.setState({tourInfoDirectLink:tourInfoDirectLink})
  }

  checkTour(tourInfo, checked){
       
        var tourExists = false;
        var indexExixts = null;
        var checkedTours = this.state.checkedTours

        console.log('tourInfo',tourInfo);
        console.log('checked',checked);

        //verificamos si existe en tour en la lista checkedTours
        checkedTours.map((tour, index) => {
            if (tourInfo.Id === tour.Id) {
                tourExists = true;
                indexExixts = index;
            }
        });
        //si existe el tour, y la variable checked viene false, lo eliminamos con slice

        if (tourExists && checked === false) checkedTours.splice(indexExixts, 1);
        else if (!tourExists && checked) checkedTours.push(tourInfo);

        //lo grabamos en la lista de local storage
        this.setglobaldata('recommendToursList',checkedTours);

        //enviamos fuera del iframe
       this.iframecontrol({checkedTours:checkedTours});

        //lo seteamos
        this.setState({checkedTours:checkedTours})
    }


    

  tourcard(tour, index) {
    //ribbon_tdetails

    var cdnimages = this.cdnimages() + "tours/";
    var originclass = this.props.slider ? "ribbon_tdetails" : "";
    var originclass2 = this.props.slider ? "slider_details" : "";

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget") {
      return this.tourcard_(tour, index);
    }

    if (this.config().brand == "taconnect" || this.config().adminid)
      return this.tourcard_(tour, index);

    var priceWithoutDiscount = Math.round(
      (100 * Math.round(tour.Price)) / (100 - tour.discountag)
    );


     //verificamos si el tour esta checked o no
            const checkedTours = this.state.checkedTours;

            let checkedTour = checkedTours.find((tour_) => {
                return tour_.Id === tour.Id;
            });

            //si el tour existe en checkedTours lo ponemos true si no false
            tour.checked = checkedTour ? true : false;

            
    var searchQuery = ``;
    if(this.getglobaldata("locationSearch.Id_Ambassador")){      
        var Id_Ambassador = this.getglobaldata("locationSearch.Id_Ambassador");  
        searchQuery = `&Id_Ambassador=${Id_Ambassador}`;
    }

    
    if (this.config().travelagentid){
    var travelagentid = this.config().travelagentid;
    searchQuery = `${searchQuery}&agentid=${travelagentid}`;
  }

    var shareUrl = `${this.config().domain}/tourdetails/${tour.friendlyurl}/${tour.Id}?languageid=${this.state.languageId}${searchQuery}`;

//  if(this.config().brand == 'cityexpressions'){
//    tour.LocationName = tour.LocationName?(tour.LocationName.search('Riviera Maya')?tour.LocationName.replace('Riviera Maya','Riviera Maya - Playa del Carmen'):tour.LocationName):tour.LocationName;

//   }

    return (
      <div className="tour_container">

          

        {Number(tour.isTopRated) == -1 ? (
          <div className={"ribbon_3 " + originclass}>
            <span>{this.t("Top Rated")}</span>
          </div>
        ) : (
          ""
        )}

        <div className="img_container">


         {this.config().toursListCheckBox ? (
              <div className="btn-group btn_opensocial">
                <button
                  type="button"
                  className="btn btn_share_b dropdown-toggle waves-effect waves-light"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-share-alt" />{" "}
                  <i className="mdi mdi-chevron-down" />
                </button>
                <div className="dropdown-menu">
               

                  <WhatsappShareButton 
                url={shareUrl}
                title={this.t('I recommend this tour')}
                separator=":-> "
                className={''}>
                 <WhatsappIcon  round size={36} />
              </WhatsappShareButton>

                  <FacebookShareButton 
                url={shareUrl}
                quote={tour.ShortDescription}
                hashtag="#bestExperience"
                className={''}>
                 <FacebookIcon  round size={36} />
              </FacebookShareButton>


                <button
                  type="button"
                  className="btn btn_share_link"
                  onClick={()=>this.shareDirectLink(tour)}                 
                >
                  <i class="fas fa-link mx-auto"></i>                  
                </button>

                
                </div>
              </div>
            ) : null}
            
          <a
            href={"tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
            onClick={this.settourdestination.bind(this, tour)}
          >
           

            <img
              src={
                cdnimages + "thm_" + tour.Id + "_1_tablet.jpg?crc=" + tour.CRC32
              }
              width={800}
              height={533}
              className="img-fluid"
              alt={tour.Name}
            />

            <div className="short_info">
              {tour.Price != tour.NormalPrice ? (
                <div>
                  <span className="price_offer">
                    <sup>$</sup>
                    {this.currencyformat(Math.round(tour.Price))}
                    <small className="dollar_text"> {this.t("NOW")}</small>
                  </span>
                  <span className="price cross">
                    <sup>$</sup>
                    {this.currencyformat(Math.round(tour.NormalPrice))}
                    <small className="dollar_text"> {tour.CurrencyCode}</small>
                  </span>
                </div>
              ) : (
                <span className="price">
                  <small className="dollar_text"> {this.t("FROM")} </small>
                  <sup>$</sup>
                  {this.currencyformat(Math.round(tour.Price))}
                  <small className="dollar_text"> {tour.CurrencyCode}</small>
                </span>
              )}

              {tour.discountag != "" && this.config().discounttag ? (
                <div className="discount_number">
                  <sup>$</sup>
                  {this.currencyformat(priceWithoutDiscount)}
                </div>
              ) : null}

              {tour.discountag != "" && this.config().discounttag ? (
                <div className="discount_tag">
                  <i className="icon-tag"></i>-{tour.discountag}%
                </div>
              ) : null}


              {tour.falseprice && tour.falseprice != "" && this.config().hasfalseprice ? (
              <div className="discount_number" style={{right:'25px'}}>

                <sup>$</sup>
                {this.currencyformat(Math.round(tour.falseprice))}
              </div>
            ) : null}




            </div>

            {this.config().toursListCheckBox ? (
              <div onClick={()=>this.checkTour(tour,!tour.checked)} className="checkbox checkbox-primary checkbox-circle check_tour">
                <input  checked={tour.checked} id={`tour-checkbox-${index}`} type="checkbox" />
                <label htmlFor={`tour-checkbox-${index}`}></label>
              </div>
            ) : null}

            {Number(tour.isThreeXTwo) == -1 && this.config().threextwo ? (
              <div className="top_rated_b" onClick={(e) => this.onClick3x2(e)}>
                <i className="fa"></i>
                {this.t("Valid 3rd Tour Free")}
              </div>
            ) : (
              ""
            )}

            {Number(tour.OfferAvailable) == -1 ? (
              <i className="icon-star star_i">
                <span>{this.t("Special")}</span>
              </i>
            ) : (
              ""
            )}
          </a>
        </div>
        {/*<span className="tag_promo">Aprovecha este 15% OFF</span>*/}
        <div className={"tour_title " + originclass2}>
          <a
            href={"tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
            onClick={this.settourdestination.bind(this, tour)}
          >
            <h3>
              <strong>{tour.Name}</strong>
            </h3>
          </a>

          {tour.TypeNameEng.search("Online") != -1 ? (
            <div className="d-flex justify-content-between">
              <div>
                <strong>{this.t("Online")}</strong>
              </div>
              <div className="ms-blob ms-red"></div>
            </div>
          ) : (
            <p>
              {this.t("From")}{" "}
              {!tour.LocationName ? tour.DestinationName : tour.LocationName}
            </p>
          )}

          <p className="tour_height">{this.limitText(tour.ShortDescription)}</p>

          {!this.config().toursListCheckBox ? (
            <div className="d-flex justify-content-between">
              <div className="icon_bottm">
                <a
                  href={"tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
                  onClick={this.settourdestination.bind(this, tour)}
                >
                  <i className="icon-info-circled" />
                  {this.t("Details")}
                </a>
              </div>

              <div className="icon_bottm">
                <a
                  href={"tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
                  onClick={this.settourdestination.bind(this, tour)}
                >
                  <i className="icon-thumbs-up" />
                  {this.t("Review")}
                </a>
              </div>

              {Number(tour.OfferAvailable) == -1 ? (
                <div className="special_icon">
                  <i className="icon-star-filled"></i>
                  <span>{this.t("Special")}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}

          {!this.config().toursListCheckBox ? <hr /> : null}

          {!this.config().toursListCheckBox ? (
            <div className="d-flex justify-content-between">
              {Number(tour.Id_ModalityType) == 2 ? (
                <div className="">
                  <i className="icon-clock" /> {tour.ExtendedDuration}{" "}
                  {this.t("days")}
                </div>
              ) : (
                <div className="">
                  <i className="icon-clock" />{" "}
                  {tour.hDuration == 0 ? "" : tour.hDuration + "h"}{" "}
                  {tour.mDuration == 0 ? "" : tour.mDuration + "m"}
                </div>
              )}

              <div className="icon_bottm_cart ">
                <NavLink
                  to="#"
                  className="cart_pop"
                  onClick={this.cartnavigation.bind(this, tour)}
                >
                  <i className="icon-basket" /> {this.t("Add Cart")}
                </NavLink>
              </div>
            </div>
          ) : null}
          {/* end rating */}

          {!this.config().toursListCheckBox ? (
            <IconWishlist
              tourinfo={tour}
              InWishlist={tour.InWishlist}
              tourid={tour.Id}
              index={index}
              onchange={this.removetour.bind(this)}
              removetours={this.props.removetours || false}
            />
          ) : null}

          {/* End wish list*/}
        </div>
      </div>
    );
  }

  onClick3x2(e) {
    e.preventDefault();

    if (this.props.onClick3x2) this.props.onClick3x2(e);

    //alert()
  }

  tourcard_(tour, index) {
    //ribbon_tdetails

    var cdnimages = this.cdnimages() + "tours/";
    var originclass = this.props.slider ? "ribbon_tdetails" : "";
    var originclass2 = this.props.slider ? "slider_details" : "";

    var priceWithoutDiscount = Math.round(
      (100 * Math.round(tour.Price)) / (100 - tour.discountag)
    );

    return (
      <div className="tour_container">
        {Number(tour.isTopRated) == -1 ? (
          <div className={"ribbon_3 " + originclass}>
            <span>{this.t("Top Rated")}</span>
          </div>
        ) : (
          ""
        )}

        <div className="img_container">
          <NavLink
            to={"/tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
            onClick={this.settourdestination.bind(this, tour)}
          >
            <img
              src={
                cdnimages + "thm_" + tour.Id + "_1_tablet.jpg?crc=" + tour.CRC32
              }
              width={800}
              height={533}
              className="img-fluid"
              alt={tour.Name}
            />

            <div className="short_info">
              {tour.Price != tour.NormalPrice ? (
                <div>
                  <span className="price_offer">
                    <sup>$</sup>
                    {this.currencyformat(Math.round(tour.Price))}
                    <small className="dollar_text"> {this.t("NOW")}</small>
                  </span>
                  <span className="price cross">
                    <sup>$</sup>
                    {this.currencyformat(Math.round(tour.NormalPrice))}
                    <small className="dollar_text"> {tour.CurrencyCode}</small>
                  </span>
                </div>
              ) : (
                <span className="price">
                  <small className="dollar_text"> {this.t("FROM")} </small>
                  <sup>$</sup>
                  {this.currencyformat(Math.round(tour.Price))}
                  <small className="dollar_text"> {tour.CurrencyCode}</small>
                </span>
              )}
            </div>

            {tour.discountag != "" && this.config().discounttag ? (
              <div className="discount_number">
                <sup>$</sup>
                {this.currencyformat(priceWithoutDiscount)}
              </div>
            ) : null}

            {tour.discountag != "" && this.config().discounttag ? (
              <div className="discount_tag">
                <i className="icon-tag"></i>-{tour.discountag}%
              </div>
            ) : null}


            {tour.falseprice && tour.falseprice != "" && this.config().hasfalseprice ? (
              <div className="discount_number">
                <sup>$</sup>
                {this.currencyformat(Math.round(tour.falseprice))}
              </div>
            ) : null}

         


            {Number(tour.isThreeXTwo) == -1 && this.config().threextwo ? (
              <div className="top_rated_b" onClick={(e) => this.onClick3x2(e)}>
                <i className="fa"></i>
                {this.t("Valid 3rd Tour Free")}
              </div>
            ) : (
              ""
            )}

            {Number(tour.OfferAvailable) == -1 ? (
              <i className="icon-star star_i">
                <span>{this.t("Special")}</span>
              </i>
            ) : (
              ""
            )}
          </NavLink>
        </div>
        <div className={"tour_title " + originclass2}>
          <NavLink
            to={"/tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
            onClick={this.settourdestination.bind(this, tour)}
          >
            <h3>
              <strong>{tour.Name}</strong>
            </h3>
          </NavLink>
          {tour.TypeNameEng.search("Online") != -1 ? (
            <div className="d-flex justify-content-between">
              <div>
                <strong>{this.t("Online")}</strong>
              </div>
              <div className="ms-blob ms-red"></div>
            </div>
          ) : (
            <p>
              {this.t("From")}{" "}
              {!tour.LocationName ? tour.DestinationName : tour.LocationName}
            </p>
          )}

          <p>{this.limitText(tour.ShortDescription)}</p>

          <div className="d-flex justify-content-between">
            <div className="icon_bottm">
              <NavLink
                to={"/tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
                onClick={this.settourdestination.bind(this, tour)}
              >
                <i className="icon-info-circled" />
                {this.t("Details")}
              </NavLink>
            </div>

            <div className="icon_bottm">
              <NavLink
                to={"/tourdetails/" + tour.friendlyurl + "/" + tour.Id+this.dataQuery()}
                onClick={this.settourdestination.bind(this, tour)}
              >
                <i className="icon-thumbs-up" />
                {this.t("Review")}
              </NavLink>
            </div>

            {Number(tour.OfferAvailable) == -1 ? (
              <div className="special_icon">
                <i className="icon-star-filled"></i>
                <span>{this.t("Special")}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr />

          <div className="d-flex justify-content-between">
            {Number(tour.Id_ModalityType) == 2 ? (
              <div className="">
                <i className="icon-clock" /> {tour.ExtendedDuration}{" "}
                {this.t("days")}
              </div>
            ) : (
              <div className="">
                <i className="icon-clock" />{" "}
                {tour.hDuration == 0 ? "" : tour.hDuration + "h"}{" "}
                {tour.mDuration == 0 ? "" : tour.mDuration + "m"}
              </div>
            )}

            <div className="icon_bottm_cart ">
              <NavLink
                to="#"
                className="cart_pop"
                onClick={this.cartnavigation.bind(this, tour)}
              >
                <i className="icon-basket" /> {this.t("Add Cart")}
              </NavLink>
            </div>
          </div>
          {/* end rating */}

          <IconWishlist
            tourinfo={tour}
            InWishlist={tour.InWishlist}
            tourid={tour.Id}
            index={index}
            onchange={this.removetour.bind(this)}
            removetours={this.props.removetours || false}
          />

          {/* End wish list*/}
        </div>
      </div>
    );
  }

  deselectAllCheckedTours(e){
       e.preventDefault();
       this.setglobaldata('recommendToursList',[]);

       //enviamos fuera del iframe
       this.iframecontrol({checkedTours:[]});
       this.setState({checkedTours:[]});
  }

  render() {
    var tours = this.props.tours;
    var cdnimages = this.cdnimages() + "tours/";

    /*  if(this.config().disabletoursids){
      tours = tours.filter((tour)=>{
           return this.config().disabletoursids.indexOf(Number(tour.Id)) == -1;

      });
    }*/

    // let disabletoursids = [1891,1909,2783,2785,2841,2807,2797]

    if (this.props.slider)
      return (
        <div id="blogSlider">
          <div className="MS-content">
            {tours.map(
              function (tour, index) {
                return (
                  <div
                    key={index}
                    className="item wow zoomIn"
                    data-wow-delay="0.1s"
                  >
                    {this.tourcard(tour, index)}

                    {/* End box tour */}
                  </div>
                );
              }.bind(this)
            )}
          </div>
          <div className="MS-controls">
            <button className="MS-left">
              <i className="icon-left-open" aria-hidden="true"></i>
            </button>
            <button className="MS-right">
              <i className="icon-right-open" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      );
      
    
    return (
      <div className="row">
 
      {this.state.checkedTours.length > 0 && this.config().toursListCheckBox?
        <div className="col-lg-12">
          <div className="main_title"><b>{this.state.checkedTours.length}</b> experiencias seleccionadas (<a href="#" onClick={(e)=>this.deselectAllCheckedTours(e)}>Deseleccionar todo</a>)</div>
          
        </div>:null
      }

        {tours.map(
          function (tour, index) {
            return (
              <div
                key={index}
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                {this.tourcard(tour, index)}

                {/* End box tour */}
              </div>
            );
          }.bind(this)
        )}
      </div>
    );
  }
}

export default withRouter(tourslist);
