import React, { Component } from 'react';
import { Redirect,withRouter } from 'react-router-dom'

import Select from 'react-select';
import SearchToursResult from './component.search.tours.result';
import Global from '../globals/global';
import autocomplete from 'autocompleter';
//if (typeof window === "undefined")  var window={}

const $ = window.$;
const location = window.location;
//const autocomplete = window.autocomplete;

class SearchTours extends Global {

    constructor(props) { 
        super(props);

        this.changeSearch = this.changeSearch.bind(this);
        this.changeDestination = this.changeDestination.bind(this);
       

        this.state = {
            destinations: this.getglobaldata('destinations') || [],
            Id_Destination: this.getglobaldata('Id_Destination'),
            Id_State:this.getglobaldata('Id_State'),
            Id_Location:this.getglobaldata('Id_Location'),
            search: null,
            tours: null,
            searching: false,
            redirect: false,
            allowedChars: new RegExp(/^[a-zA-Z\s]+$/),
            defaultdestinationname:''
        };
    }

    componentDidMount() {

        this.setdefaults();
        this.getdispatcher('load.destinations',function(data){

            this.setState({
                    destinations: this.getglobaldata('destinations') || [],
                    Id_Destination: this.getglobaldata('Id_Destination'),
                    Id_State:this.getglobaldata('Id_State'),
                    search: '',
                }, function(){
                     this.setdefaults();
                }.bind(this));

        }.bind(this));
    }

    setdefaults(){
      this.setautocomplete();
      var destination = this.getdefaultdestination();
      this.setState({
        defaultdestinationname:(destination?destination.Name:''),
      });
      
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.destinations !== this.state.destinations) {
            const Id_Destination = this.getglobaldata('Id_Destination');
            this.setState({
                Id_Destination: Id_Destination,
                Id_State:this.getglobaldata('Id_State')
            });
        }
        if (prevState.redirect !== this.state.redirect && this.state.redirect) {
            this.setState({
                redirect: false,
            });
        }
    }

    changeSearch(event) {
        this.setState({ search: event.target.value });
    }

    changeDestination(data) {

        let locationid = -1;

        var stateid = '';
        var destinationid = data.Id_Destination;
        if(data.Id_Destination.search('state_') != -1) {  //detectamos si la variable lleva la plabra state_

            stateid = Number(data.Id_Destination.replace('state_',''));
            destinationid = '';
        }


         if(data.Id_Destination.search('area_') != -1) {  //detectamos si la variable lleva la plabra area_

            locationid = Number(data.Id_Destination.replace('area_',''));
            destinationid = data.destinationid;
            stateid = '';
        }
         
        /*this.state.Id_Destination = destinationid;
        this.state.Id_State = stateid;
        this.state.tours = null;
        this.state.defaultdestinationname = data.Name;

        this.setglobaldata('destinationinfo',data)
        this.forceUpdate();*/

        this.setState({
            Id_Destination: destinationid,
            Id_State:stateid,
            Id_Location:locationid ,
            tours: null,
            defaultdestinationname:data.Name
        }, function(){
           this.setglobaldata('destinationinfo',data)

           this.searchEvents(data.Name);

           this.props.sendItem(this.state);
        }.bind(this));
        
    }

    

    getdefaultdestination(){

       // console.log('this.state.Id_Destination',this.state.Id_Destination)
      //  console.log('this.state.Id_state',this.state.Id_State)
        return this.state.destinations.find(destination => {
            
            if(Number(destination.destinationid)) return String(destination.Id_Destination) === String('area_'+this.state.Id_Location);
            if(Number(destination.Id_Destination)) return Number(destination.Id_Destination) === Number(this.state.Id_Destination);
            if(String(destination.Id_Destination)) return String(destination.Id_Destination) === String('state_'+this.state.Id_State);

            
 
            });

    }


    setautocomplete(){
            let destinations = this.state.destinations; 
            const allowedChars = this.state.allowedChars;
            autocomplete({
                input: document.getElementById('destinations_autocomplete_global_destinations'+this.props.identifierSearch),
                minLength: 1,
                onSelect: function (item, inputfield){
                    inputfield.value = item.Name;
                    
                    this.changeDestination(item);
                    
                }.bind(this),
                fetch: function (text, callback) {
                    var match = this.normalizeText(text.toLowerCase())
                    callback(destinations.filter(destination => {
                         var string_norm = this.normalizeText(destination.label)

                        return string_norm.toLowerCase().indexOf(match) !== -1;
                    }));
                }.bind(this),
                render: function (item, value) {
                    $('.autocomplete').remove();

                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(value, 'gi');
                        var inner = item.label.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.label;
                    }
                    return itemElement;
                },
                emptyMsg: this.t("No Destination found"),
                customize: function (input, inputRect, container, maxHeight) {
                    if(this.ismobile()){
                        //console.log(input);
                        //console.log('zIndex',$("#destinations_autocomplete_global_destinations_1").css("z-index"))
                        document.getElementsByClassName('autocomplete')[0].style.zIndex = '9999';
                       // console.log('container.style.bottom',(window.innerHeight - inputRect.bottom + input.offsetHeight) + "px")
                        //if (maxHeight < 100) {
                        container.style.width = "100%";

                        if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";

                       // console.log('(window.innerHeight',window.innerHeight)
                       // console.log('(inputRect.bottom ',inputRect.bottom )
                       // console.log('(inputRect.bottom ',input.offsetHeight)
                         }

                     //   container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                      //  container.style.maxHeight = "140px";
                        //container.style.left = "5px";
                        //container.style.right = "5px";
                      //  container.style.width = "100%";
                      // } 
                    }
                    else if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }.bind(this)
            });
    }

    writecity_global(event) {
        this.setState({ defaultdestinationname: event.target.value });
        
    }

    clean_global(){
      this.setState({ defaultdestinationname: ''});
    }

    render() {
        return (
            this.state.destinations !== null ? (                                               

                           <div className="input-group mb-3">
                            <input onClick={this.select.bind(this)} value={this.state.defaultdestinationname} onChange={this.writecity_global.bind(this)} id={"destinations_autocomplete_global_destinations"+this.props.identifierSearch} type="text" placeholder={this.t("Type your City")} autoComplete="off" className='form-control input_tour' />
                           

                            <div className="input-group-append">
                                      <button onClick={this.props.onClick} className="btn btn-outline-secondary btn_clearb btn_search_h" type="button"><span className="icon-search form-control-feedback"></span></button>
                                    </div>

                         
                         </div>
                         
            ) : null
        )
    }
}

export default withRouter(SearchTours)