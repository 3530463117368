import React,{Component} from "react";
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class errormessages extends Global {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    var messages = this.props.messages || [];
    var colorclass = 'errors';
    var iconclass = 'icon-warning';

    if(this.props.colorclass){
      colorclass = this.props.colorclass;
      iconclass = 'icon-exclamation';
    }

    return (
      <div>
        {messages.length > 0 ? (
          <div className={colorclass}>
            {messages.map(function(message, index) {
              return (
                <div key={index}>
                  <i className={iconclass}></i>
                  <span>{message}</span>
                  <br />
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(errormessages)
