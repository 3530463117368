import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class Weather extends Global {
    render() {
        return (
            <a href="#"><i className="icon-cloud-sun"></i> <strong>85 °F</strong></a>
        )
    }
}

export default withRouter(Weather)