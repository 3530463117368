import React,{Component} from "react";
import { NavLink,withRouter,Redirect } from "react-router-dom";
import Shops from '../pages/page.dining';
import Global from '../globals/global';
const $ = window.$;

class shopping extends Global {


  render() {
    
   if(!this.config().shopping) return (<Redirect to='/tours/1'></Redirect>)

    return (

     <Shops text={this.t("Shopping in")} category={1} origin='shopping' />
      
    );
  }
}

export default shopping


