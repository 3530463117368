import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class AirportSelector extends Global {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);  

        this.state = {
            airports: null,
            airport: null,
            Id_Location:this.props.locationid
        };
    }

    componentDidMount() {
        const Id_Destination = this.getglobaldata('Id_Destination');
        var options = {
            arraydata: 'airports',
            method: 'GetAirports',
            query: {
                Id_Destination: Id_Destination !== null ? Id_Destination : ''
            },
            callback: function (data) {

                

                let defaultairportid = this.setairportid(data);

                this.setState({
                    airports: data,
                    airport: defaultairportid,
                });
            }.bind(this)
        };
        this.all(options);
    }


    setairportid(data){

              let airport_ = null;
              let firstitem = -1;

              if(data){

              airport_ = data.find((airport)=>{

                      if(airport.LocationsIds) {
                        let Id_Location_ = airport.LocationsIds.split(',').find((Id_Location)=>{ return Number(Id_Location) == Number(this.state.Id_Location)})
                        
                        return Number(this.state.Id_Location) == Number(Id_Location_?Id_Location_:null)
                      }else return null;

                })
              

               firstitem = (data[0]?data[0].Id:-1);
               }

                let defaultairportid = airport_?(airport_.Id?airport_.Id:firstitem):firstitem

                return defaultairportid;

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.airport !== this.state.airport) {
            this.props.handle(this.state.airport);
        }


        

        if (prevProps.locationid !== this.props.locationid) {


            this.setState({
            Id_Location:this.props.locationid
          },()=>{

        let defaultairportid = this.setairportid(this.state.airports);

        console.log('prevProps.locationid 2',prevProps.locationid);
        console.log('this.props.locationid 2',this.props.locationid);
        console.log('defaultairportid',defaultairportid);
          this.setState({
            airport: defaultairportid,
          });

          });
            
        }


    }

    componentWillReceiveProps(nextProps){

        
    }

    handleChange(event) {
        this.setState({
            airport: event.target.value,
        });
    }

    render() {
        return (
            this.state.airports !== null ? (
                this.state.airports.length > 0 ? (
                    <select name="" className="form-control"
                        onChange={this.handleChange} value={this.state.airport}>
                        <option value="-1" disabled>{this.t('Select Arrival Airport')}</option>
                        {
                            this.state.airports.map((airport, index) => {
                                return (
                                    <option key={`airportOption-${index}`} value={airport.Id}>{airport.Name}</option>
                                )
                            })
                        }
                    </select>
                ) : <div>{this.t('No available')}</div>
            ) : <div>{this.t('Loading...')}</div>
        )
    }
}

export default AirportSelector