import React,{Component} from "react";
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
import segmentize from 'segmentize';

class pagination  extends Global {
constructor(props) {
super(props);
this.state = {

};

}

componentWillMount(){
  
}

onChange(page_,event_){

  event_.preventDefault();
  var options = {page:page_, push: true}

  this.props.onChange(options);
}



render() {

   if(this.props.options.loadmore){

    if(this.props.options.pages == this.props.options.page) return null

    let nextpage = Number(this.props.options.page)+1

    return (
    
   <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-end">


      <li className="page-item">
      <h5><a className="page-link" href={"#"+nextpage} onClick={this.onChange.bind(this,nextpage)} tabIndex="-1">{this.t('Ver más')}</a></h5>
      </li>
    
  
  </ul>
</nav>

   );


   }

   var options = segmentize(this.props.options);

 
   return (
    
   <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-end">


  {options.beginPages.map(function(val,index){
    return(
      <li className="page-item" key={index}>
      <a className="page-link" href={"#"+val} onClick={this.onChange.bind(this,val)} tabIndex="-1">{val}</a>
      </li>
    )
  }.bind(this))}
  

  {options.previousPages.map(function(val,index){
    return(
      <li className="page-item" key={index}>
      <a className="page-link" href={"#"+val} onClick={this.onChange.bind(this,val)} tabIndex="-1">{val}</a>
      </li>
    )
  }.bind(this))}



   
   {options.centerPage.map(function(val,index){
    return(
      <li className="page-item active" key={index}>
      <a className="page-link" onClick={this.onChange.bind(this,val)} href={"#"+val} tabIndex="-1">{val}</a>
      </li>
    )
  }.bind(this))}

  
    

    {options.nextPages.map(function(val,index){
    return(
      <li className="page-item" key={index}>
      <a className="page-link" href={"#"+val} onClick={this.onChange.bind(this,val)} tabIndex="-1">{val}</a>
      </li>
    )
  }.bind(this))}

     {options.endPages.map(function(val,index){
    return(
      <li className="page-item" key={index}>
      <a className="page-link" href={"#"+val} onClick={this.onChange.bind(this,val)} tabIndex="-1">{val}</a>
      </li>
    )
  }.bind(this))}

  </ul>
</nav>

   );
  }
  }
 
  export default withRouter(pagination)