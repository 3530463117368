import React,{Component} from "react";
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class tourpricestable  extends Global {
constructor(props) {
super(props);
this.state = {

  calculations:this.props.calculations

};

}

componentWillReceiveProps(nextProps){

  this.setState({calculations:nextProps.calculations},function(){

  // if(this.props.updateview) this.props.updateview()

  }.bind(this));

}


setotherdicsounts(calculations){

  if(!calculations.otherdiscounts) return null;

  return(<div className="col-md-12">
          <div className="row">
                            
                                    <div className="col-md-8 text-warning">
                                        <p className="price_c">{calculations.otherdiscounts.name}</p>
                                    </div>
                               
                          
                                
                                    <div className="col-md-4">
                                        <p  className="price_r">{calculations.currency} -${calculations.otherdiscounts.discount}</p>
                                    </div>
                                
                            
                    </div>
                    </div>        )


}


setcoupon(calculations){

  calculations.coupon.couponid = Number(calculations.coupon.couponid);

  return(<div className="col-md-12">
          <div className="row">
                           
                            {
                                calculations.coupon.couponid> 0 ? (
                                    <div className="col-md-8 text-warning">
                                        <p className="price_c">{calculations.coupon.name}</p>
                                    </div>
                                ) : null
                            }
                            {
                                calculations.coupon.couponid > 0 ? (
                                    <div className="col-md-4">
                                        <p className="price_r">-${this.currencyformat(Math.round(calculations.coupon.discount))}</p>
                                    </div>
                                ) : null
                            }
                    </div>
                    </div>        )

}


ExtendedPrices(){
  var calculations = this.state.calculations;

  if(Number(calculations.Id_ModalityType) == 1) return null;

   
       return  (<div className="col-md-12">

           
                {calculations.extendedAdults?calculations.extendedAdults.map((extended,index)=>{
                 return(
                  <div className="row" key={index}>
                <div className="col-md-4">
                  <p># {this.t("Adults")} {calculations.adults.age}+</p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{extended.qty} @ ${this.currencyformat(extended.Price)}</p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(extended.total)}</p>
                </div>
              </div>
              )
              }):null}
            

                {calculations.extendedChilds?calculations.extendedChilds.map((extended,index)=>{
                return (
                <div className="row" key={index} >
                <div className="col-md-4">
                  <p># {this.t("Children")} ({extended.minAge}-{extended.maxAge})</p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{extended.qty} @ ${this.currencyformat(extended.Price)}</p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(extended.total)}</p>
                </div>
              </div>
              )
              }):null}


              </div>
              )

           

}


PriceTypeDef1(){
  var calculations = this.state.calculations;

  if(Number(calculations.Id_ModalityType) == 2) return null;

   if(calculations.Id_TourPriceTypeDef == 1)
             
           return  (<div className="col-md-12">

              {calculations.adults.qty > 0?
                <div className="row">
                <div className="col-md-4">
                  <p># {this.t("Adults")} {calculations.adults.age}+</p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{calculations.adults.qty} @ ${this.currencyformat(calculations.adults.price)}</p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(calculations.adults.total)}</p>
                </div>
              </div>
              :''}

              {calculations.children.qty >0?
                <div className="row">
                <div className="col-md-4">
                  <p># {this.t("Children")} ({calculations.children.agerange})</p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{calculations.children.qty} @ ${this.currencyformat(calculations.children.price)}</p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(calculations.children.total)}</p>
                </div>
              </div>
              :''}


              {calculations.childrenfree.qty >0?
                <div className="row">
                <div className="col-md-4">
                  <p># {this.t("Free Children")} ({calculations.childrenfree.agerange})</p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{calculations.childrenfree.qty} @ ${this.currencyformat(calculations.childrenfree.price)}</p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(calculations.childrenfree.total)}</p>
                </div>
              </div>
              :''}

              </div>
              )

           return null;

}



PriceTypeDef2(){
  var calculations = this.state.calculations;

  if(Number(calculations.Id_ModalityType) == 2) return null;

   if(calculations.Id_TourPriceTypeDef == 2)
             
           return (
              <div className="col-md-12">

              {calculations.group.qty > 0?
                <div className="row">
                <div className="col-md-4">
                  <p># {this.t("Persons")} </p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{calculations.group.qty} @ </p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(calculations.group.total)}</p>
                </div>
              </div>
              :''}
              
              </div>
              )

           return null;

}


PriceTypeDef3(){
 
   var calculations = this.state.calculations;

   if(Number(calculations.Id_ModalityType) == 2) return null;

   if(calculations.Id_TourPriceTypeDef == 3)
             
           return (
               <div className="col-md-12">

              {calculations.Id_TourPriceTypeDef == 3 && calculations.passengers.qty > 0?
                <div className="row">
                <div className="col-md-4">
                  <p># {this.t("Persons")} </p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{calculations.passengers.qty} @ </p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(calculations.passengers.total)}</p>
                </div>
              </div>
              :''}
              
              </div>
              )

           return null;

}


Extras(){
  var calculations = this.state.calculations;

   if(calculations.extras.length>0)
             
           return (
                <div className="col-md-12">

              {calculations.extras.map(function(extra,index){
                return (
                  <div className="row">
                <div className="col-md-4">
                  <p>{extra.name}</p>
                </div>
                <div className="col-md-4">
                  <p className="text-center price_c">{extra.qty} @ ${this.currencyformat(extra.price)}</p>
                </div>
                <div className="col-md-4">
                  <p className="price_r">${this.currencyformat(extra.total)}</p>
                </div>
              </div>
              )
              }.bind(this))}
              
              </div>
              )

           return null;

}

  


render() {

   var calculations = this.state.calculations;

 
   return (
    
   <div className="row">
              
              {this.ExtendedPrices()}
              {this.PriceTypeDef1()}
              {this.PriceTypeDef2()}
              {this.PriceTypeDef3()}
              {this.Extras()}
              {this.setcoupon(calculations)}
              {this.setotherdicsounts(calculations)}

         <div className="col-md-12">
              <div className="row">
              <div className="col-md-12">
                  <p className="price_r"><strong>{this.t("TOTAL")} {calculations.currency} ${this.currencyformat(Math.round(calculations.total))}</strong> 
                    {this.config().brand == 'viajeypunto'?<span><br/><b>{this.currencyformat(parseInt(Math.round(calculations.total))*10)}</b> {this.t("Bancomer points")}</span>:''}
                    </p>
               </div> 
              </div>
           </div>
</div>

   );
  }
  }

  export default withRouter(tourpricestable)