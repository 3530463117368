import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class Currency extends Global {

    constructor(props) {
        super(props);

        this.handle = this.handle.bind(this);

        this.state = {
            header: null,
            curencies: null,
            defaultcurrency: null,
            reload: false,
        };
    }

    componentDidMount() {
        const Id_Currency = this.getglobaldata('Id_Currency');
        this.getglobaldata('currency');
        var options = {
            arraydata: 'curencies',
            method: 'GetCurrencies',
            query:{
                currencyids:this.config().currenciesdropdown
            },
            callback: function (data) {
                this.setState({
                    curencies: data,
                    header: this.props.header === undefined ? true : this.props.header,
                    defaultcurrency: Id_Currency !== null ? Id_Currency : 1,
                });
            }.bind(this)
        };
        this.all(options);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.defaultcurrency !== this.state.defaultcurrency) {
            const currency = this.state.curencies.find(currency => {
                return currency.Id_Currency === this.state.defaultcurrency;
            });
            this.setglobaldata('currency', currency);
            this.setglobaldata('Id_Currency', this.state.defaultcurrency);
            
            
            if (this.state.reload) {
                window.onbeforeunload = function() { } // Prevent before unload when currency change

                this.reloadPage();
               // window.location.reload(true);
            }
        }
    }

    handle(event) {

        this.setglobaldata('reloadcart',true);
        
        this.setState({
            defaultcurrency: event.target.value,
            reload: true,
        });
    }

    render() {
        return (
            this.state.curencies !== null ? (
                <select value={this.state.defaultcurrency} name="select" onChange={this.handle}
                    className={this.state.header ? 'form-control select_lang' : (this.props.class || '')} >
                    {
                        this.state.curencies.map((currency, index) => {
                            return (
                                <option key={`currencyOption-${index}`} value={currency.Id_Currency}>{currency.Code}</option>
                            )
                        })
                    }
                </select>
            ) : null
        )
    }
}

export default withRouter(Currency)