import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PurchaseHistoryCard from './component.purchase.history.card';
import Global from '../globals/global';

class PurchaseHistory extends Global {

    constructor(props) {
        super(props);

        this.state = {
            total: null,
            data: null,
            user: null,
        };
    }

    componentDidMount() {
        this.setState({
            total: 0,
            user: this.getglobaldata('user'),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.user !== this.state.user) {
            let options = {
                arraydata: 'data',
                method: 'GetPurchaseHistory',
                query: {
                    UserAccessToken: this.state.user.UserAccessToken,
                    DeviceAccessToken: this.state.user.DeviceAccessToken,
                },
                callback: function (data, result) {
                    this.setState({
                        data: data,
                        total:result.data_count,
                    });
                }.bind(this)
            };
            this.all(options);
        }

        //console.log(JSON.stringify(this.state.data));

        if(!this.state.data){
            console.log("NULL");
        }
        else{
            if(this.props.isbuyresult && this.state.data.length >= 1){
                console.log('isbuyresult: ' + this.props.isbuyresult);
                //console.log(JSON.stringify(this.state.data));
                let idtransaction = this.state.data[0].Id_UserTransaction;
                let url_detail = 'purchase/detail/'+idtransaction;
                console.log('GO DETAIL: '+ url_detail );

                this.navigate(url_detail);

            }
        }

    }
    render() {
        return (
            this.props.isbuyresult ? (
                this.state.data !== null ? (
                    <div>
                        <div className="row">
                            <div className="col-lg-12 purchasetitle">
                                <h3>{this.t('Purchase History')}</h3>
                                <span className="account_numb">1</span>
                            </div>
                        </div>
                        {
                            this.state.data.map((purchase, index) => 
                                (index === 0) ?
                                <PurchaseHistoryCard key={`PurchaseHistoryCard-${index}`} data={purchase} />
                                : null

                                )

                        }
                    </div>
                ) : <div>{this.t('Loading...')}</div>
            ) : 
            this.state.data !== null ? (
                    <div>
                        <div className="row">
                            <div className="col-lg-12 purchasetitle">
                                <h3>{this.t('Purchase History')}</h3>
                                <span className="account_numb">{this.state.total}</span>
                            </div>
                        </div>
                        {
                            this.state.data.map((purchase, index) => 
                                <PurchaseHistoryCard key={`PurchaseHistoryCard-${index}`} data={purchase} />)
                        }
                    </div>
                ) : <div>{this.t('Loading...')}</div>
        )
    }
}

export default withRouter(PurchaseHistory)