import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
import ErrorMessages from "../components/component.error.messages";
const $ = window.$;

class ReviewSend extends Global {

    constructor(props) {
        super(props);

        this.state = {
            reviewinfo:{
                City:'',
                Description:'',
                Id_Country:'',
                Id_Tour:'',
                Id_User:'',
                Name:'',
                State:''
            },
            messages: [],
            thanksMessage:false,            
        };
    }

    componentWillMount(){
        
        

        let options = {
            arraydata: "countries",
            method: "GetCountries",
            query: {},
            callback: function(data, result) {              
              result.data.map((value)=>{
                $("#CountryListReviewSend").append('<option value="'+value.Id+'">'+value.Name+'</option>');
              })
              if(this.config().brand == "viajeypunto"){
                $('#CountryListReviewSend option[value="167"]').prop('selected', true)
              }
              
            }.bind(this)
          };
          this.all(options);
    }

    componentDidMount() {
        if (this.getglobaldata("user")) {
            const user = this.getglobaldata("user");
            
            this.setState({
                reviewinfo:{
                    City:'',
                    Description:'',
                    Id_Country:'',
                    Id_Tour:this.props.data.Id_Tour,
                    Id_User:user.Id_User,
                    Name:user.FirstName+" "+user.LastName,
                    State:''
                },
                messages: [],
                thanksMessage:false      
            },()=>{
                if(this.state.reviewinfo.Id_User == "" || this.state.reviewinfo.Id_User == null)$('#reviewForm').hide();
                if(this.state.reviewinfo.Id_Tour == "" || this.state.reviewinfo.Id_Tour == null)$('#reviewForm').hide();
                if(this.state.reviewinfo.Name == "" || this.state.reviewinfo.Name == " " || this.state.reviewinfo.Name == null)$('#reviewForm').hide();
                
            });
        }
    }

    sendReview(){        
        
        this.state.reviewinfo.Id_Country = $('select[name="reviewinfo.Id_Country"]').val();
        this.state.reviewinfo.State = $('input[name="reviewinfo.State"]').val();
        this.state.reviewinfo.City = $('input[name="reviewinfo.City"]').val();
        this.state.reviewinfo.Description = $('textarea[name="reviewinfo.Description"]').val();

        this.setState({},()=>{
            this.validationInfoReview();
        })
    }

    validationInfoReview(){
        

        var messages = []

        if(this.state.reviewinfo.City == ""){
            messages.push(this.t("Enter your City"));
        }
        if(this.state.reviewinfo.Description == "" || this.state.reviewinfo.Description.length < 10){
            messages.push(this.t("Enter at least 10 characters in review"));
        }
        if(this.state.reviewinfo.Id_Country == ""){
            messages.push(this.t("Select your Country"));
        }
        if(this.state.reviewinfo.Id_Tour == ""){
            
        }
        if(this.state.reviewinfo.Id_User == ""){
            
        }
        if(this.state.reviewinfo.Name == ""){
            
        }
        if(this.state.reviewinfo.State == ""){
            messages.push(this.t("Enter your State/Province"));
        }

        this.setState({
            messages:messages
        },()=>{
            if(this.state.messages.length == 0){
                    let options = {                    
                    method: "RegisterPendingUserReview",
                    query: this.state.reviewinfo,
                    callback: function(data, result) { 

                      if(result.result){                        
                        $('input[name="reviewinfo.State"]').val("");
                        $('input[name="reviewinfo.City"]').val("");
                        $('textarea[name="reviewinfo.Description"]').val("");

                        this.setState({
                            thanksMessage:true
                        },()=>{
                            setTimeout(function(){
                                this.setState({
                                    thanksMessage:false
                                })
                            }.bind(this), 3000);
                        })
                        
                      }else{

                        messages.push(this.t("There was an error, please try again"));
                        this.setState({
                            messages:messages
                        })

                      }

                    }.bind(this)
                    };
                    this.all(options);
            }
        })
    }

    
    

    render() {
       
        return (
            <div>
                <div id="reviewForm">
                <hr />
                
                <h6 className="title_accdet ">{this.t("HOW DID YOU LIKE THIS ACTIVITY?")}</h6>
                <p className="info_text_acc ">{this.t("Send us a review of your experience using the following form.")}</p>
                        <div className="row">
                            <div className="col-md-4">
                            <div className="form-group">
                                <label>{this.t("Your Country")}</label>
                                <span className="text-danger"> *</span>
                                <select
                                className="form-control"
                                name="reviewinfo.Id_Country"
                                id="CountryListReviewSend"                                
                                >
                                </select>
                            </div>
                            </div>

                            <div className="col-md-4">
                            <div className="form-group">
                                <label>{this.t("Your State/Province")}</label>
                                <span className="text-danger"> *</span>
                                <input
                                    type="text"
                                    name="reviewinfo.State"
                                    className="form-control"
                                />
                            </div>
                            </div>

                            <div className="col-md-4">
                            <div className="form-group">
                                <label>{this.t("Your City")}</label>
                                <span className="text-danger"> *</span>
                                <input
                                    type="text"
                                    name="reviewinfo.City"
                                    className="form-control"
                                />
                            </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                            <div className="form-group">
                                <label>{this.t("Your Review")}</label>
                                <span className="text-danger"> *</span>
                                <textarea
                                    rows="10"                                    
                                    name="reviewinfo.Description"
                                    className="form-control"
                                    style={{resize: 'none'}}
                                />
                            </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-sm-6" style={{float:'left'}}>
                                <button                                
                                className="btn checkout_popc"
                                onClick={this.sendReview.bind(this)}
                                >
                                {this.t("Send Review")}
                                </button>

                                {this.state.thanksMessage == true?        
                                <h6 className="title_accdet thanksMessage">{this.t("Thank you for sending us your review")}</h6>
                                :null}    
                            </div>
                        </div>

                        <div className="row">
                        <ErrorMessages messages={this.state.messages} />
                        </div>
                        
                                       
                <hr />
                </div>
            </div>            
        ) 
        
    }
}

export default withRouter(ReviewSend)