import React,{Component} from "react";
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class CartAside extends Global {

   constructor(props) {
    super(props);
    this.state = {
      update:false
    };
  }

  componentDidMount() {


    /* this.getdispatcher('cart.view',function(data){
          this.updateview()
    
      }.bind(this))
*/

  }

  componentWillReceiveProps() {

          this.updateview()
    


  }

  updateview(){
    this.setState({update:true});
  }


  grandtotal() {
    var Cart = this.getglobaldata("Cart");
    var grandtotal = 0;
    Cart.map(function(item, index) {
      grandtotal = parseFloat(
        parseFloat(grandtotal) + parseFloat(item.total)
      ).toFixed(2);
    });

    console.log('grandtotal',grandtotal);

    return Math.round(grandtotal);
  }


  render(){

    var Cart = this.getglobaldata("Cart");
    var cartlength = Cart.length;
    var activate = '';
    if(cartlength <= 1) activate = 'no';

 return(
  <aside className="col-lg-4">
             <div className={"sidebar-item"+activate}>
                <div className={"make-me-sticky"+activate}>
                <div className="box_style_1">
                  <h3 className="inner">- {this.t('Summary')} -</h3>
                  <table className="table table_summary">
                    <tbody>
                      <tr className="total">
                        <td>
                          {this.t("Grand Total")} {Cart[0].currency}: ${this.currencyformat(this.grandtotal())}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {this.config().brand == 'viajeypunto'?<span><br/><b>{this.currencyformat(parseInt(this.grandtotal())*10)}</b> {this.t("Bancomer points")}</span>:''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <NavLink className="btn checkout_popc" to="/checkout">
                    {this.t("Check out")}
                  </NavLink>
                  <NavLink to='/tours' className="btn_full_outline">
                    <i className="icon-right" /> {this.t("Continue shopping")}
                  </NavLink>
                </div>

                {this.config().brand != 'delmarboutiqueaccommodation'?
                <div className="box_style_4">
                  <i className="icon_set_1_icon-57" />
                  <h4 dangerouslySetInnerHTML={{__html: this.t("Need <span className='blue_text'>Help?</span>")}}></h4>
                  {this.config().phone != ''?
                  <a href={"tel:"+this.config().phone} className="phone">
                        {this.config().phone}
                     </a>:''}
                     
                     <a href={"mailto:"+this.config().email}>{this.config().email}</a><br/>
                  <small>
                    {this.t("Our Vacation Concierge is available.")} <br />
                    {this.t("Everyday from 9:00am to 6:00pm Central")}
                  </small>
                </div>
                :null
              }
                </div>
                </div>
              </aside>
              )

  }

}

export default withRouter(CartAside)
