import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
import autocomplete from 'autocompleter';
//if (typeof window === "undefined")  var window={}

//const autocomplete = window.autocomplete;
const $ = window.$;

class InputSearchHotel extends Global {

    constructor(props) {
        super(props);

        this.writehotel = this.writehotel.bind(this);
        this.handleHotel = this.handleHotel.bind(this);
        this.clean = this.clean.bind(this);

        this.state = {
            hotels: null,
            allowedChars: null,
            defaulthotelname: '',
            disabled:false,
            loading:false
        };
    }

    componentWillReceiveProps(nextProps){
        
        //console.log("next props",nextProps)

        if("reUpdate" in nextProps){

            if(nextProps.reUpdate.Id_Destination != this.props.reUpdate.Id_Destination || nextProps.reUpdate.Id_Location != this.props.reUpdate.Id_Location || nextProps.reUpdate.Id_State != this.props.reUpdate.Id_State){
            
                this.setState({
                    loading:true
                })
                
                
                
                this.setglobaldata('Id_Destination',nextProps.reUpdate.Id_Destination);
                this.setglobaldata('Id_Location', nextProps.reUpdate.Id_Location);
                this.setglobaldata('Id_State', nextProps.reUpdate.Id_State);

                let hotelid = -1;
                let Id_Destination = this.getglobaldata('Id_Destination');
                let Id_Location = this.getglobaldata('Id_Location');
                let Id_State = this.getglobaldata('Id_State');

                if (this.props.match && this.props.match.url.includes('search')) {
                    if (this.props.match.params) {
                        Id_Destination = Id_Destination;
                        hotelid = this.props.match.params.hotelid;
                    }
                } else {
                    if (this.props.hotelid) {
                        this.setglobaldata('Id_Hotel', this.props.hotelid);
                    }
                    hotelid = this.getglobaldata('Id_Hotel');
                }

                var options = {
                    arraydata: 'hotels',
                    method: 'GetHotels',
                    query: {
                        Id_State:Id_State,
                        Id_Destination: Id_Destination !== null ? Id_Destination : '',
                        Id_Location: Id_Location !== null ? (Number(Id_Location) === -1 ? '' : Id_Location) : ''
                    },
                    callback: function (data) {
                        var defaulthotelname = '';
                        if (this.getglobaldata('Id_Hotel')) {
                            data.map(function (hotel, index) {
                                if (hotelid == hotel.Id) {
                                    defaulthotelname = hotel.Name;
                                }
                            })
                        }
                    
                        this.setState({
                            hotels: data,
                            allowedChars: new RegExp(/^[a-zA-Z\s]+$/),
                            defaulthotelname: defaulthotelname,
                            loading:false
                        },()=>{

                            let totalhotels = data.length;
                            this.senddispatcher('has.hotels',{totalhotels:totalhotels})
                            /////////////////////////////////////new autocomplete by props
                            let hotels = this.state.hotels;
                            const allowedChars = this.state.allowedChars;
                            

                            autocomplete({
                                input: document.getElementById('search_tr'+this.props.identifierSearch),
                                minLength: 1,
                                onSelect: (item, inputfield) => {
                                    $('.autocomplete').remove();
                                    $('.empty').remove();
                                    inputfield.value = item.Name
                                    this.state.defaulthotelname = item.Name;
                                    const detail = {
                                        data: item.Id,
                                        name: item.Name,
                                        hotel:item,
                                        origin: this.props.origin === 'home' ? 'select' : 'change'
                                    };
                                    var customEvent = new CustomEvent('selected.hotel', { detail: detail });
                                    window.dispatchEvent(customEvent);
                                },
                                fetch: function (text, callback) {
                                    $('.autocomplete').remove();
                                    $('.empty').remove();
                                    var match = this.normalizeText(text.toLowerCase());
                                    callback(hotels.filter(hotel => {

                                        var hotelName = this.normalizeText(hotel.Name);
                                        return hotelName.toLowerCase().indexOf(match) !== -1;
                                    }));
                                }.bind(this),
                                render: function (item, value) {
                                    $('.autocomplete').remove();
                                    $('.empty').remove();
                                    
                                    var itemElement = document.createElement("div");
                                    if (allowedChars.test(value)) {
                                        var regex = new RegExp(value, 'gi');
                                        var inner = item.Name.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                                        itemElement.innerHTML = inner;
                                    } else {
                                        itemElement.textContent = item.label;
                                    }
                                    return itemElement;
                                },
                                emptyMsg: this.t("No Hotels found"),
                                customize: function (input, inputRect, container, maxHeight) {
                                    if (maxHeight < 100) {
                                        container.style.top = "";
                                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                                        container.style.maxHeight = "140px";
                                    }
                                }
                            });

                            //////////////////////////////////////////////////////////////
                            
                        });
                    }.bind(this)
                };
                this.all(options);

            }else{
                //console.log("son iguales")
            }
        
        }
        
    }

    componentDidMount() {
        let hotelid = -1;
        let Id_Destination = this.getglobaldata('Id_Destination');
        let Id_Location = this.getglobaldata('Id_Location');
        let Id_State = this.getglobaldata('Id_State');

        if (this.props.match && this.props.match.url.includes('search')) {
            if (this.props.match.params) {
                Id_Destination = Id_Destination;
                hotelid = this.props.match.params.hotelid;
            }
        } else {
            if (this.props.hotelid) {
                this.setglobaldata('Id_Hotel', this.props.hotelid);
            }
            hotelid = this.getglobaldata('Id_Hotel');
        }

        this.setState({
            loading:true
        })

        var options = {
            arraydata: 'hotels',
            method: 'GetHotels',
            query: {
                Id_State:Id_State,
                Id_Destination: Id_Destination !== null ? Id_Destination : '',
                Id_Location: Id_Location !== null ? (Number(Id_Location) === -1 ? '' : Id_Location) : ''
            },
            callback: function (data) {
                var defaulthotelname = '';
                if (this.getglobaldata('Id_Hotel')) {
                    data.map(function (hotel, index) {
                        if (hotelid == hotel.Id) {
                            defaulthotelname = hotel.Name;
                        }
                    })
                }

                this.setState({
                    hotels: data,
                    allowedChars: new RegExp(/^[a-zA-Z\s]+$/),
                    defaulthotelname: defaulthotelname,
                    loading:false
                },()=>{

                    let totalhotels = data.length;
                    this.senddispatcher('has.hotels',{totalhotels:totalhotels})
                    
                   
                });
            }.bind(this)
        };
        this.all(options);

         window.addEventListener('selected.hotel', this.handleHotel);
        
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevState.hotels !== this.state.hotels) {
            let hotels = this.state.hotels;
            const allowedChars = this.state.allowedChars;
            
            autocomplete({
                input: document.getElementById('search_tr'+this.props.identifierSearch),
                minLength: 1,
                onSelect: (item, inputfield) => {
                    inputfield.value = item.Name
                    this.state.defaulthotelname = item.Name;
                    const detail = {
                        data: item.Id,
                        name: item.Name,
                        hotel:item,
                        origin: this.props.origin === 'home' ? 'select' : 'change'
                    };
                    var customEvent = new CustomEvent('selected.hotel', { detail: detail });
                    window.dispatchEvent(customEvent);
                },
                fetch: function (text, callback) {
                    var match = this.normalizeText(text.toLowerCase());
                                    callback(hotels.filter(hotel => {

                                        var hotelName = this.normalizeText(hotel.Name);
                                        return hotelName.toLowerCase().indexOf(match) !== -1;
                                    }));
                }.bind(this),
                render: function (item, value) {
                    $('.autocomplete').remove();
                    $('.empty').remove();
                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(`\\b${value}\\b`, 'gi');
                        var inner = item.Name.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.label;
                    }
                    return itemElement;
                },
                emptyMsg: this.t("No Hotels found"),
                customize: function (input, inputRect, container, maxHeight) {
                    if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }
            });
        }
        if (prevState.defaulthotelname !== this.state.defaulthotelname) {

           
            var customEvent = new CustomEvent('selected.hotel', {
                detail: {
                    data: this.getglobaldata('Id_Hotel'),
                    name: this.state.defaulthotelname,
                    hotel:this.state.hotels.find((hotel)=>{ return Number(this.getglobaldata('Id_Hotel')) == Number(hotel.Id)}),
                    origin: 'change'
                }
            });
            window.dispatchEvent(customEvent);
        }
    }

    handleHotel(event) {
        var hotelid = event.detail.data;
        var hotelname = event.detail.name;
        if(event.detail.name == ""){
            event.detail.data = -1;
            hotelid = -1;
        }
        
       
        
        this.setglobaldata('Id_Hotel', hotelid);
        this.setglobaldata('hotelname', hotelname);
        this.props.handle(event.detail);

    }

    writehotel(event) {
        
        this.setState({ defaulthotelname: event.target.value });
     
    }

    clean() {
        const detail = {
            data: -1,
            name: '',
            origin: this.state.origin === 'home' ? 'select' : 'change',
        };
        var customEvent = new CustomEvent('selected.hotel', { detail: detail });
        window.dispatchEvent(customEvent);
        this.setState({
            defaulthotelname: ''
        });
    }

    render() {
        
            return (
                
                <form className={`${this.props.origin === 'home' ? 'input-group input_cleart col-lg-6 mx-auto' : 'input-group input_cleart'}`}>
                    
                    {this.state.loading == true?
                    <input onClick={this.select.bind(this)} type="text" placeholder={this.props.disabled?'':this.t("Type your Hotel Name")} disabled={true} className={`form-control ${this.props.origin === 'home' ? 'input_airport' : 'input_tour'}`} />
                    :
                    <input onClick={this.select.bind(this)} id={"search_tr"+this.props.identifierSearch} type="text" placeholder={this.props.disabled?'':this.t("Type your Hotel Name")} disabled={this.props.disabled?this.props.disabled:this.state.disabled}
                        autoComplete="off" value={this.state.defaulthotelname} onChange={this.writehotel}
                        className={`form-control ${this.props.origin === 'home' ? 'input_airport' : 'input_tour'}`} />}
                    
                    {/*!this.props.disabled?<div className={`input-group-append ${this.props.origin === 'home' ? '' : 'inp_appet'}`}>
                        <button className="btn btn-outline-secondary btn_clearb" type="button"><i className="icon-cancel" onClick={this.clean}></i></button>
                    </div>:''*/}
                </form>
            )
        
    }
}

export default withRouter(InputSearchHotel)