import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class totouraterms extends Global {

    

    render() {
        if(this.config().brand == 'toorzy') return null;
        var Address = '';

    if(process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget2'){

       Address = (<span>{this.t('1901 South Meyers Road Suite 500')}<br />{this.t('Oakbrook Terrace, Illinois')} </span>)
     
    }
     
        return (
            

                                <div className="row">

                                    <div className="col-lg-12">
                                        <h3 className="blue_text"><strong>{this.t('Terms and Conditions')}</strong> </h3>

                                        <p>{this.t('Terms and Conditions of use of Totoura web sites and/or mobile applications.')}</p>
                                    </div>

                                    <div className="col-sm-6 text-justify">

                                        <h5 className="terms_title mt-15">{this.t('TERMS AND CONDITIONS.')}</h5>

                                        <p>{this.t(`This document contains the terms and conditions that govern the use of the internet web site www.totoura.com and totoura vacation concierge application (hereafter jointly referred to as the "Electronic Platform(s)") a property of or proprietary software licensed to totoura LLC. ("totoura") by the Customer (as hereinafter defined).  made by Customer(s) using the Electronic Platform constitutes a binding agreement and signifies the Customer's acceptance of and agreement to follow these terms and conditions and to be bound by them. Please read these Terms and Conditions carefully in order to ensure you understand them prior to booking a reservation.`)}
            </p>

                                        <p>{this.t('Every individual or legal entity accessing the Electronic Platform(s) (hereafter referred to as the "Customer") by downloading from, accessing, visiting or registering himself/herself in the Electronic Platforms, is automatically subject to these Terms and Conditions, as amended if amended from time to time.')}
            </p>

                                        <p>{this.t('TOTOURA reserves the right, at any time, to modify the content of the present conditions of use and the information that is provided in the Web Site.  The customers, users and visitors of the Web Site shall be responsible of accessing the Web Site and the present terms and conditions of use from time to time to see if modifications have taken place.')}</p>

                                        <p>{this.t('Every individual or legal entity (hereafter referred to as the “Customer”) by the mere fact of accessing, visiting or registering himself/herself in the Web Site, is automatically subject to the present terms and conditions, as well as to its future modifications. The Customer hereby declares and warrants that he/she is over 18 years of age and has the legal capacity and attributions necessary to purchase or hire in his/her own capacity or in behalf of the legal entity that the Customer declares to represent, as well as to order the charges to the credit card which information he/she provides. The parents and tutors are responsible for the purchases or reservations on-line that are made by the children under their responsibility, with or without their prior approval.')}</p>

                                        <p>{this.t('TOTOURA reserves the right, at any time, to modify the content of these Terms and Conditions of Use as well as the information that is provided in the Electronic Platform. Customers agree to be solely responsible for accessing the Electronic Platform and determining any amendments to these Terms and Conditions of Use.')}</p>

                                        <p>{this.t("The Customer hereby declares and warrants that he/she is over 18 years of age and has the legal capacity necessary to purchase or hire in his/her own capacity or in behalf of the legal entity that the Customer declares to represent, as well as to order the charges to the credit card which information he/she provides. Any purchases or reservations made on-line by children under the age of 18 years shall be the responsibility of the child's parents regardless of whether such purchases or reservations were made with the knowledge and/or approval of the parents.")}</p>

                                        <h5 className="terms_title mt-15">{this.t('LICENSE.')}</h5>

                                        <p>{this.t('TOTOURA hereby grants to Customer a non-exclusive, non-perpetual, revocable, limited license to use the Electronic Platforms for making reservations and/or purchases either by browsing on the www.totoura.com website or by downloading the Mobile Concierge application (the "App") to the wireless communication device on which it is initially installed. All rights not expressly granted are reserved by TOTOURA or its software licensor. This license does not allow Customer to use the App on any other wireless communication device other than that on which it was initially installed without the consent of TOTOURA, and Customer may not distribute or make the App available over a network where it could be used by multiple devices at the same time. Customer may not rent, lease, lend, sell, redistribute or sublicense the App. Customer may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Electronic Platforms, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law). Any attempt to do so is a violation of the rights of TOTOURA and its licensors. The terms of this license will govern any upgrades provided by TOTOURA that replace and/or supplement the original Electronic Platforms, unless such upgrade is accompanied by a separate license in which case the terms of that license will govern.')}</p>

                                        <h5 className="terms_title mt-15">{this.t('CONSENT TO USE OF DATA.')}</h5>
                                        <p>{this.t(`In order to use the Electronic Platforms, Customer may be required to register to create an account and may be asked to provide certain personal information including, without limitation, the Customer's name, email address, travel destination, hotel, dates of travel, departure airline, flight number, age of children and other contact information. TOTOURA will collect and use this information for the purposes of setting up Customer's selected password and username, maintaining and managing access to Customer's App account and processing online excursion sales made by the Customer or processing and operating with third party suppliers the rendering of services or goods presented to Customer through the App or www.totoura.com website catalogue as updated from time to time by TOTOURA. TOTOURA may also use Customer's personal information to send Customer e-mail or other communication about products and/or services that TOTOURA believes may be of interest to Customer. Customer may opt-out of this use of their personal information by contacting concierge@totoura.com.`)}</p>

                                        <p>{this.t("TOTOURA may disclose Customer's personal information as required by law or to any third parties who process the personal information on TOTOURA's behalf. Some of these service providers may be located in foreign jurisdictions and Customer's personal information may be collected, used, disclosed, stored or processed in such foreign jurisdictions and will then become subject to the legal requirements of these jurisdictions, including lawful requirements to disclose personal information to the governmental authorities in those jurisdictions.")}
            </p>

                                        <p>{this.t(`Customer specifically consents and authorizes TOTOURA to use all the information Customer provides upon registration for their App account in accordance with these Terms and Conditions. Further, Customer agrees to provide accurate, current and truthful information. Customer acknowledges and agrees that Customer is responsible for all activities occurring under their Electronic Platform account and for keeping their password and other Electronic Platform account information confidential. If Customer suspects someone is accessing or using its Electronic Platform account without its permission or if Customer needs to change its password or account information on the app, it is Customer's responsibility to contact TOTOURA.  Accordingly, Customer may contact by logging in their account on the App and go to "EDIT ACCOUNT" in the "MY ACCOUNT" section or by contacting TOTOURA at concierge@totoura.com.`)}</p>

                                        <p>{this.t('By agreeing to the Conditions of Use of Totoura’s websites and/or mobile applications Customer agrees to the collection, use and disclosure of its personal information as outlined above.   To the extent provided by applicable law, Customer may request access and correction of its personal information held by TOTOURA by contacting it at the email address set out above.')}</p>

                                        <p>{this.t('In order to proceed with transactions (such as making a reservation) through the Electronic Platforms, Customer may be required to provide additional personal information, including billing and/or credit information. This information may be collected and used either by TOTOURA or by the third party supplier of the product and/or service that is the object of the transaction and Customer agrees that the collection, use and disclosure of this personal information is also subject to the terms of the agreement governing such transaction. TOTOURA strongly recommends that Customer review these terms carefully.')}</p>

                                        <p>{this.t("TOTOURA may periodically  collect and use technical data and related information, including but not limited to technical information about the device, system and application software, and peripherals gathered to facilitate the provision of software updates, product support and other services to Customer related to the Electronic Platforms. TOTOURA may use this information for other purposes, including, by way of description only, to improve its products or to provide additional services or technologies to Customer, as long as the Customer's information is in a form that does not personally identify Customer.")}</p>

                                        <h5 className="terms_title mt-15">{this.t('TOURS AND EXCURSIONS PACKAGES.')}</h5>

                                        <p>{this.t("The purchase of one or more of the packages offered by TOTOURA to the Customer constitutes a contractual agreement between TOTOURA and the Customer and conclusively establishes the acceptance by the Customer of TOTOURA's Terms and Conditions of Use.")}</p>

                                        <h5 className="terms_title mt-15">{this.t('INCLUSIONS AND EXCLUSIONS.')}</h5>

                                        <p>{this.t('Unless specified otherwise, the base price of a package sold to a Customer will include the selected local excursion, transportation on site along with other inclusions, if any, specified in the selected product description on the Electronic Platforms. Customer acknowledges and agrees that the base price of a package excludes personal expenses, meals and drinks, insurances, taxes and service charge or other services not specified at the time of the booking.')}</p>

                                        <h5 className="terms_title mt-15">{this.t('PRODUCT DESCRIPTIONS.')}</h5>

                                        <p>{this.t("TOTOURA has put forth all necessary efforts to ensure that, at time of publication, all products' descriptions on this Web Site are accurate. For several reasons beyond the control of TOTOURA, modifications can take place which may affect products' descriptions. TOTOURA will make reasonable efforts to update the Electronic Platform(s) upon receiving notification of any such modifications. It is the Customers' responsibility to consult the Electronic Platforms to be aware of these modifications.")}</p>

                                        <h5 className="terms_title mt-15">{this.t('PRICES INDICATED.')}</h5>

                                        <p>{this.t('Prices are quoted in United-States dollars (US$), unless otherwise indicated.')}</p>

                                        <p>{this.t('The prices confirmed at the time of the booking are the prices that will be charged to the Customer and supersede any pricing advertised or indicated before the confirmation of the booking.  In addition, Customer acknowledges and agrees that prices are subject to further change at any time, without prior notice, until the Customer has paid its reservation in full.')}</p>

                                        <h5 className="terms_title mt-15">{this.t('PAYMENT.')}</h5>

                                        <p>{this.t('Customers must pay the total of all sums due at the time of the booking by means of a valid credit card. The use of a credit card number by a Customer indicates its agreement to comply with the Terms and Conditions.')}</p>

                                        <p>{this.t('Tour availability is not guaranteed until full payment is received and a confirmation message is sent by TOTOURA.')}</p>

                                        <h5 className="terms_title mt-15">{this.t('CONFIRMATION OF RESERVATION.')}</h5>

                                        <p>{this.t('TOTOURA uses electronic ticketing or voucher(s) to confirm the reservation. Upon completion of a booking by the Customer, the Customer must print the electronic ticket(s) or voucher(s) to present at check-in.  Customer may also be required to provide his/her confirmation number at check-in together with a picture id.')}</p>

                                        <p>{this.t("It is the responsibility of the Customer to ensure that the information appearing on the electronic ticket, voucher and any other reservation documents or electronic message is consistent with Customer's booking arrangements.  Restaurant reservations, when and if available from the Electronic Platforms, will be processed in accordance with the operating policies set forth from time to time by the management of restaurants affiliated to the Electronic Platforms.")}
            </p>
                                    </div>

                                    <div className="col-sm-6 text-justify">

                                        <h5 className="terms_title mt-15 ">{this.t('MODIFICATION/CANCELLATION TO A TOUR RESERVATION.')}</h5>

                                        <p>{this.t('Date and time changes to reservations may be submitted no later than twenty-four (24) hours prior to scheduled departure time. To modify his/her reservation, Customer must contact TOTOURA by using his/her preferred form of contact available at www.totoura.com, or by sending an email to concierge@totoura.com  or through the interactive interface of Mobile Concierge mobile application.  Customer acknowledges and agrees that TOTOURA cannot guarantee that changes requested by Customer can be honored.')}</p>

                                        <p>{this.t('After payment has been processed, all cancellations are subject to penalties.')}</p>

                                        <p>{this.t('Tour cancellation requests received more than twenty-four (24) hours prior to scheduled departure time are subject to a five (5) percent penalty based upon the total amount of the reservation.')}</p>

                                        <p>{this.t('Tour cancellation requests received less than twenty-four (24) hours prior to scheduled departure time will be considered a "no show" and NO REFUNDS WILL BE MADE.')}</p>

                                        <h5 className="terms_title mt-15 ">{this.t('REFUNDS.')}</h5>

                                        <p>{this.t('Except as provided in Article 10, no reimbursements or refunds will be granted to Customer for unused services.')}</p><p>{this.t('Most tours operate rain or shine; boat excursions will only be cancelled if the port authority deems sea conditions unsafe.')}</p>

                                        <p>{this.t('In the event of a medical and/or other related emergency, Customer may reschedule or cancel with a valid medical certificate. Customer must contact TOTOURA using his/her preferred form of contact available at www.totoura.com, or by sending an email to concierge@totoura.com  or through the interactive interface of the App.')}</p>

                                        <h5 className="terms_title mt-15 ">{this.t('EXCLUSION OF LIABILITY.')}</h5>

                                        <p>{this.t('The Customer acknowledges that TOTOURA makes arrangements with third-party service providers and other independent parties to provide Customers with the tours and excursions packages they purchase. Although TOTOURA takes great care in selecting these service providers, it does not have any control over their business activities and cannot be held responsible for their acts, omissions, fault or negligence, or that of their employees, nor for any loss or damages suffered as a result thereof.')}</p>

                                        <p>{this.t("TOTOURA shall not be liable for the quality or safety of activities and/or services (such as excursions, optional tours, activities, etc.) offered by its service providers, nor for any representations made by TOTOURA's agent on any such activities and/or services.")}</p>

                                        <p>{this.t('Moreover, TOTOURA shall not be liable for the failure by the service providers to provide the services due to a force majeure event or any other cause beyond the reasonable control of TOTOURA or the service providers.')}</p>

                                        <p>{this.t("TOTOURA shall not be liable in any manner for any claims, loss, damage, cost or expense arising out of personal injury, accident or death of the Customer, its invitees or guests, or for any claims, loss, damage, cost or expense arising out of loss of and/or damage to the Customers' property or any inconvenience, loss of enjoyment or disappointment that may arise from the participation of the Customer, its invitees and/or guests in any such activities and/or services  offered by TOTOURA's service providers.")}</p>

                                        <p>{this.t("Customer acknowledges that in the Electronic Platforms, there could be links to web pages of third parties and that TOTOURA's Web Site could provide information displayed by third parties that are not part of TOTOURA. Customer(s) specifically acknowledge and agree that TOTOURA does not grant any class of warranty with regards to such information and that TOTOURA shall not be held responsible for any lack of compliance by the third parties.")}</p>

                                        <p>{this.t('Customer(s) specifically agree that TOTOURA, as well as its associates, affiliates or subsidiaries are not and shall not be responsible for any damages, direct, indirect, incidental, special or consequential, that may arise from use of the Electronic Platform(s), including, by way of description:')}</p>

                                        <p>{this.t('general malfunction, transfer of electronic virus or similar when accessing, downloading or using the Electronic Platforms; typographic, technical, orthographic, design errors or those caused by the delay in the update of the server and/or Electronic Platforms or part of these, the lack of availability or delay in sending or delivering the messages of data (including electronic mail, reservation requests or sending of reservation confirmations); intromissions to the Electronic Platforms or interception of data messages; errors of the Customer when providing the information required by the Electronic Platforms, that are not immediately notified by the Customer to TOTOURA for its attention; duplicating or mistake in the processing of the charges to a credit card, due to the responsibility of the credit or banking entity; Notwithstanding the above, in case that any responsibility arises on behalf of TOTOURA, such liability shall always be limited to the amount of the consideration paid by the Customer.')}</p>

                                        <h5 className="terms_title mt-15 ">{this.t('CHANGES TO TOURS AND EXCURSIONS PACKAGES.')}</h5>

                                        <p>{this.t("Sometimes, reserved tours or excursion packages become unavailable for reasons beyond TOTOURA's control. In such case, TOTOURA reserves the right, exercisable by TOTOURA in its sole and absolute discretion, to substitute a comparable alternate tour or excursion for the unavailable tour or excursion or, alternatively, to cancel the Customer's reservation for such tour or excursion.")}</p>

                                        <p>{this.t("If TOTOURA elects to substitute a comparable tour or excursion and the substituted package is of a lesser value the Customer may refuse such substitution and TOTOURA shall reimburse the Customer the price paid by the Customer for the unavailable tour and/or excursion.  If the Customer accepts such substitution, then TOTOURA's sole obligation to Customer shall be to refund the difference in cost to Customer.  The Customer shall notify TOTOURA within 48 hours following notification of the proposed substitution if Customer refuses such substitution, otherwise it will be conclusively presumed that Customer has accepted the substitution.")}</p>

                                        <p>{this.t('If TOTOURA elects to substitute a comparable tour or excursion and the price of the substituted package is greater than that of the originally scheduled package, the Customer may refuse such substitution and TOTOURA shall reimburse the Customer the price paid by the Customer for the unavailable tour and/or excursion.  If the Customer accepts such substitution, the Customer will be required to disburse an amount equal to the difference between the price of the initial package purchased and the price of the substitute package. The Customer shall notify TOTOURA within 48 hours following notification of the proposed substitution if Customer refuses such substitution, otherwise it will be conclusively presumed that Customer has accepted the substitution.')}</p>

                                        <p>{this.t("In TOTOURA elects to cancel the Customer's reservation for such unavailable tour and/or excursion,  the liability of TOTOURA shall be limited to the reimbursement of the price of the initial package paid by the Customer.")}</p>

                                        <h5 className="terms_title mt-15 ">{this.t('GENERAL COMMENTS AND COMPLAINTS.')}</h5>

                                        <p>{this.t('Any incident, service cancellation, problem or dissatisfaction must be reported by the Customer at the time of the event or as soon as possible after, to allow TOTOURA the opportunity to rectify the situation or provide assistance.')}</p>

                                        <p>{this.t("At each destination, the Customer must contact TOTOURA's local customer service department to get assistance and to remedy any problem without delay whenever such problem can be reasonably remedied. TOTOURA's customer service department can be contacted via telephone or email as shown on www.totoura.com or the interactive interface of the App. If a problem cannot be solved or dealt with immediately, Customers are requested to forward the specifics of the problem to TOTOURA, within thirty (30) days upon return from their trip, in writing to the following address:")}</p><p>{this.t('TOTOURA LLC.')} <br />

                                        
                                        {Address}

                                        </p>

                                        <h5 className="terms_title mt-15 ">{this.t('INTELLECTUAL PROPERTY')}</h5>

                                        <p>{('Except for the license described hereunder, the access to the Electronic Platforms does not grant to the Customer any other class of right to use the nominative, non-nominative or mixed trademarks, domain names, etc., whether they are registered or not, as well as any images or any other kind of industrial or intellectual property right that is presented through the Electronic Platforms.')}</p>

                                        <p>{this.t('By accepting the terms and conditions hereof Customer agrees the Electronic Platforms contain proprietary information and material that is owned by TOTOURA and/or its applicable licensors or suppliers, and is protected by applicable intellectual property and other laws, including but not limited to copyright. Customer may not use such proprietary information or materials in any way whatsoever except for use of the Electronic Platforms in compliance with the terms and conditions hereof.')}</p>

                                        <p>{this.t('Notwithstanding any other provision of these terms and conditions, TOTOURA and its licensors reserve the right to change, suspend, remove, or disable access to any of the Electronic Platforms, content, or other materials that are presented through the Electronic Platforms without need of prior notice. In no event will TOTOURA be liable for making these changes. TOTOURA may also impose additional limits on the use of or access to certain features or portions of the Electronic Platforms, in any case and without prior notice or liability.')}</p>

                                        <p>{this.t('TOTOURA and/or its licensors or suppliers reserve all copyright derived from the Electronic Platforms. Unauthorized or unlawful use of the Electronic Platforms shall be subject to civil and criminal penalties as well as pecuniary damages for copyright infringement.')}</p>

                                        <p>{this.t("Customer shall have no right or license of use whatsoever over any material or content of TOTOURA's suppliers or merchants affiliated to the Electronic Platforms.")}</p>

                                        <h5 className="terms_title mt-15 ">{this.t('LAWS.')}</h5>

                                        <p>{this.t('This Agreement shall be governed, interpreted and construed in accordance with the laws of Barbados, and the courts of St. Michael Barbados shall have exclusive jurisdiction for all matters or proceedings between TOTOURA and the Customer.  In the event one or more of the provisions contained in this Agreement shall for any reason be held to be invalid, illegal, or unenforceable in any respect by a court of competent jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other provision of this Agreement, and this Agreement shall be construed as if such invalid, illegal, or unenforceable provision were not contained herein or therein.')}</p>

                                        <h5 className="terms_title mt-15 ">{this.t('PRIVACY, NOTIFICATIONS')} &amp; {this.t('ALERTS')}</h5>

                                        <p>{this.t("When accessing the Electronic Platform(s) and/or when creating an account, the Customer may receive an option to elect to allow TOTOURA to send alerts and notifications to them on an ongoing basis for the purpose of providing updated information on the Customer's vacation details as well as offers and promotions that the user may be interested in.")}</p>

                                        <p>{this.t("For TOTOURA's complete current PRIVACY POLICY please refer to the user account section of the Electronic Platform(s). Acceptance of these Terms and Conditions of Use are also deemed an acceptance of TOTOURA's PRIVACY POLICY.")}</p>

                                        <p>{this.t('By accepting these Terms and Conditions of Use, the Customer also agrees to receive alerts')} &amp; {this.t('notices whose purpose may include, but not be limited to, assistance and updates on vacation information as well as marketing and promotional offers Customer may find valuable. Customer may opt out of receiving such alerts and notices by notifying TOTOURA via the interactive interface of the App or emailing TOTOURA at concierge@totoura.com')}.</p>

                                        <h5 className="terms_title mt-15 ">{this.t('ENTIRE AGREEMENT.')}</h5>

                                        <p>{this.t('These Terms and Conditions, as amended, if amended from time to time, constitute the entire agreement between TOTOURA and the Customer and therefore, any prior agreement, whether verbal or written, is superseded.')}</p>

                                    </div>



                                </div>

        )
    }
}

export default withRouter(totouraterms)