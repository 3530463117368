import React, { Component } from 'react';
import Section from '../components/component.section';
import SpecialCard from '../components/component.special.card';
import { NavLink,withRouter,Redirect } from "react-router-dom";
import Global from '../globals/global';
const $ = window.$;

class Specials extends Global {

    constructor(props) {
        super(props);

        this.state = {
            destinationName: '',
            allSpecials: null,
            specials: null,
        };
    }

    componentDidMount() {

        const Id_Destination = this.getglobaldata('Id_Destination');
        const Id_Location = this.getglobaldata('Id_Location');
        const Id_Language = this.getglobaldata('Id_Language');
        const Id_Currency = this.getglobaldata('Id_Currency');

        var options = {
            arraydata: 'allSpecials',
            method: 'GetAllSpecialOffersWithoutLogin',
            query: {
                Id_Destination: Id_Destination !== null ? Id_Destination : '',
                Id_Location: Id_Location !== null ? (Id_Location === -1 ? '' : Id_Location) : '',
                Id_Language: Id_Language,
                Id_Currency: Id_Currency
            },
            callback: function (data) {
                this.setState({
                    allSpecials: data,
                });
            }.bind(this)
        };
        this.all(options);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.allSpecials !== this.state.allSpecials) {
            const Id_Destination = this.getglobaldata('Id_Destination');
            this.setState({
                specials: this.state.allSpecials.filter(special => special.Id_Destination === Id_Destination),
            });
        }
        if (prevState.specials !== this.state.specials) {
            console.log('specials', this.state.specials);
        }
    }

    render() {

        if(!this.config().specials) return (<Redirect to='/tours/1'></Redirect>)


        return (
            <div>
                <Section origin="specials" handle={destinationName => this.setState({ destinationName: destinationName })} />
                {/* End section */}

                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/tours/1">{this.t("Tours")}</NavLink></li>
                                <li>{this.t("Specials")}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position */}

                    <div className="container margin_60">
                        <div className="card">
                            <div className="card-body bor_t">

                                {/* TOUR & ACTIVITIES */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <span className="found_s">
                                            {
                                                this.state.specials !== null ? (`(${this.state.specials.length} founds)`) : null
                                            }
                                        </span>
                                        <h3 className="blue_text">
                                            <strong>{`${this.t("Special Offers in")} ${this.state.destinationName}`}</strong> 
                                            <NavLink to="/tours/1" className="btn_changeloc">
                                                <i className="icon-map"></i>
                                                <strong>{this.t('See More Tours')}</strong>
                                            </NavLink>
                                        </h3>
                                        <p className="color_red">
                                            <strong>{`${this.t('Instant Savings on Exclusive Tour SPECIALS')} --`}</strong>
                                            <span className="black_text"> {this.t("Just select your savings and bonuses as 'Tour Extras' at checkout.")}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>{/* end card-body */}
                        </div>{/* end card */}

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main_title">
                                </div>
                            </div>
                            {
                                this.state.specials !== null ? (
                                    this.state.specials.length > 0 ? (
                                        this.state.specials.map((special, index) => {
                                            return (
                                                <SpecialCard key={`SpecialCard-${index}`} data={special} />
                                            )
                                        })
                                     ) : <div  className="col-lg-12">{this.t('No Results Found')}</div>
                                ) : <div  className="col-lg-12">{this.spinner()}</div>
                            }
                        </div>
                        {/* End row */}
                    </div>
                    {/* End container */}

                    <div id="overlay"></div>
                    {/* Mask on input focus */}

                </main>
                {/* End main */}
            </div >
        )
    }
}

export default Specials