import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class IconWishlist extends Global {

    constructor(props) {
        super(props);

        this.state = {
            InWishlist: null,
        };
    }

    componentDidMount() {

        var InWishlist = this.props.InWishlist;

         if(!this.getglobaldata('wishlist')) this.setglobaldata('wishlist',[]);
       var wishlist = this.getglobaldata('wishlist');
       
       //buscamos si esxiste el tour en la lista de deseos y loc onfiguramos con -1 o 0
       if(wishlist.length > 0){
           wishlist.map(function(wish,index_){
                  if(this.props.tourid == wish.Id) InWishlist = -1;
           }.bind(this))

       }


        this.setState({
            InWishlist: InWishlist,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.InWishlist !== this.props.InWishlist) {
            this.setState({
                InWishlist: this.props.InWishlist,
            });
        }
    }

    nouser(InWishlist){

        console.log('InWishlist',InWishlist)
       if(!this.getglobaldata('wishlist')) this.setglobaldata('wishlist',[]);
             var wishlist = this.getglobaldata('wishlist');
            if(InWishlist==0){
               this.props.tourinfo.InWishlist = -1;
               wishlist.push(this.props.tourinfo);
               this.state.InWishlist = -1;
               console.log('this.props.tourinfo',this.props.tourinfo)
              
            }else {
              wishlist = wishlist.filter(function(tour, index, arr){
               return tour.Id != this.props.tourid;
              }.bind(this));
              this.state.InWishlist = 0;

            }

            this.forceUpdate();
            console.log('wishlist',wishlist)

        this.setglobaldata('wishlist',wishlist);
    }

    wishlistaction(InWishlist){

      if(InWishlist==-1){
       //google analytics event
        this.ga().event({
             category: 'Wishlist',
             action: 'Remove from Wishlist',
             label: 'Remove from Wishlist'
        });
      }else if(InWishlist==0){

        //google analytics event
        this.ga().event({
             category: 'Wishlist',
             action: 'Add to Wishlist',
             label: 'Add to Wishlist'
        });
      }


        if(!this.getglobaldata('user')) {
            this.nouser(InWishlist); return;
        }

        this.ajaxsetup();
        
        if(InWishlist==-1) this.removefromwishlist();
        else if(InWishlist==0) this.addtowishlist();

    }

    removefromwishlist(){
         this.state.InWishlist = 0;
         this.forceUpdate();

       if(this.props.onchange && this.props.removetours) this.props.onchange(this.props.index);

       var options = {
        method:'DeleteTourFromWishList',
        body:{
            tour_id: this.props.tourid,        
            pref_type: 2 
        },
        error:function(jsonresponse){
            this.state.InWishlist = -1;
            this.forceUpdate();
        }.bind(this)
       }

       this.delete(options);

       

    }

    addtowishlist(){

        this.state.InWishlist = -1;
        this.forceUpdate();

        var options = {
        method:'AddTourToWishList',
        body:{
            tour_id: this.props.tourid,        
            pref_type: 1
        },
        error:function(jsonresponse){

            this.state.InWishlist = 0;
            this.forceUpdate();
           
        }.bind(this)
       }

       this.create(options);

       

    }

    render() {
        var InWishlist = Number(this.state.InWishlist);
        var user = this.getglobaldata('user');

        if(this.config().localwhislist) user = true;
        

        return (
            InWishlist !== null ? (
                <div className={InWishlist === -1 ? 'wishlist active_wish '+(this.props.className || '') : 'wishlist '+(this.props.className || '')}  onClick={this.wishlistaction.bind(this,InWishlist)} >
                    <a className="tooltip_flip tooltip-effect-1" href="javascript:void(0);">
                        {
                            InWishlist === -1 ? '-' : '+'
                        }
                        <span className="tooltip-content-flip">

                           {user?
                            <span className="tooltip-back">
                                {InWishlist === -1 ? this.t('Delete from wishlist') : this.t('Add to wishlist')}
                            </span>
                            :
                            <span className="tooltip-back" style={{width:'200px','height':'150px'}}>
                                
                                {this.t("To add this tour to your wish list  you must log in")} <br/>
                                <NavLink to='/login' className='btn btn-warning btn-sm m-3'>{this.t("Login")}</NavLink>
                            </span>
                          }
                        </span>
                    </a>
                </div>
            ) : null
        )
    }
}

export default withRouter(IconWishlist)