import React, { Component} from 'react';
import { NavLink, withRouter } from "react-router-dom";
import TourCard from './component.tour.card';
import Global from '../globals/global';

const MAX_TRENDING_SHOW = 6;


class TrendingTour extends Global {

    constructor(props) {
        super(props);

        this.state = {
            trendings: null,
            isMaxTrendingShow: false,
        };
    }

    componentDidMount() {
        const Id_Destination = this.getglobaldata('Id_Destination');
        const Id_Location = this.getglobaldata('Id_Location');
        const Id_Language = this.getglobaldata('Id_Language');
        const Id_Currency = this.getglobaldata('Id_Currency');
        const Id_State = this.getglobaldata('Id_State');
        var options = {
            arraydata: 'trendings',
            method: 'GetTrendingTours',
            query: {
                isFullInfo: true,
                Id_State:Id_State,
                destination: Id_Destination !== null ? Id_Destination : '',
                location_list: Id_Location !== null ? (Id_Location === -1 ? '' : Id_Location) : '',
                pagination: 0,
                isRand: true,
                Id_Language: Id_Language,
                Id_Currency: Id_Currency
            },
            callback: function (data) {
                this.setState({
                    trendings: data,
                });
            }.bind(this)
        };
        this.all(options);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.trendings !== this.state.trendings) {
            if (this.state.trendings.length > MAX_TRENDING_SHOW) {
                let trendings = this.state.trendings.slice(0, 6);
                this.setState({
                    trendings: trendings,
                    isMaxTrendingShow: true
                });
            }
        }
    }

    render() {
        return (
            this.state.trendings !== null ? (
                this.state.trendings.length > 0 ? (
                    <div className="container margin_60">
                        <div className="main_title">
                            <h2>{this.t("VACATIONER REVIEWS")} {this.state.trendings[0].DestinationName}</h2>
                            <p>{this.t("Products & Prices Guaranteed!")}</p>
                        </div>
                        <div className="row">
                            {
                                this.state.trendings.map((trending, index) => {
                                    return (
                                        <TourCard key={`TourCard-${index}`} data={trending} />
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.isMaxTrendingShow ? (
                                <p className="text-center add_bottom_30">
                                    <NavLink to="/tours/1" className="btn_1 medium"><i className="icon-eye-7" />{this.t("View all tours")} </NavLink>
                                </p>
                            ) : null
                        }
                    </div>
                ) : <div className="container">{/*this.t("No results found")*/}</div>
            ) : <div className="container">{this.spinner()}</div>
        )
    }
}

export default withRouter(TrendingTour);