import React,{Component} from "react";
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class vacationcompanies extends Global {
  constructor(props) {
    super(props);
    this.state = {
      vacationcompanies:[]
    };
  }

  componentWillMount() {
    this.getcreditcardtypes();
  }

  getcreditcardtypes(){
    var options = {
      method:'GetVacationCompanies',
      callback:function(data){
       this.state.vacationcompanies = data;
       this.forceUpdate();
      }.bind(this)
    }

    this.all(options);
  }

  onChange(value) {
   // console.log('event_',event_)
    // var target = $(event_.target);
    // var value = target.val();
    if(this.props.onChange) this.props.onChange(value);
  }

  render() {

    var options = {
      array:this.state.vacationcompanies,
      valuename:'Id',
      textname:'Name'
    }

    return (
      <select
        onChange={this.onChange.bind(this)}
        className="form-control"
        value={this.props.value}
        name={this.props.name}
      >
        <option disabled="disabled" selected="selected" value={-1}>
          {this.t("Vacation Company")}
        </option>
        
        {this.selectoptions(options)}
      </select>
    );
  }
}
export default withRouter(vacationcompanies);
