import React,{Component} from "react";
import NewTourPricesTable from "../components/component.tour.availability"; 
import TourPricesTable from "../components/component.tour.prices.table";
import moment from "moment";
import spanish from 'moment/locale/es';
import french from 'moment/locale/fr';
import english from 'moment/locale/en-au';
import { NavLink, withRouter } from "react-router-dom";
import TransferPricesTable from './component.transfer.prices.table';
import NewTransferPricesTable from '../pages/page.transfers.prices';
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}

const $ = window.$;
class cartlist extends Global{
  constructor(props) {
    super(props);

   // let reloadcart = this.getglobaldata('reloadcart');
    

    //this.config().sbiid = 1;
    

    this.state = {
      reloadcart:false,
      lastcartindex:0
    };

   // this.setglobaldata('reloadcart',false);

  }

 

  componentDidMount() {



    /*  this.getdispatcher('cart.view',function(data){
       

         if(data.detail.origin) {
          
          
         }else{
           this.setState({reloadcart:false},function(){
             this.setdiscount();
            this.getpromotiondiscount(false);
           }.bind(this))
         }
       
      }.bind(this))

      */


      /*  var languageid = Number(this.getglobaldata('Id_Language'));
        if(languageid == 2) moment.locale('es');
        else if(languageid == 3) moment.locale('fr');
        else moment.locale('en');*/

    /*  this.getdispatcher('cart.view',function(data){


        if(data.detail.match.params.itemindex == this.state.lastcartindex){
         this.setdiscount();
         this.state.reloadcart = false;
         this.forceUpdate();
        }

      }.bind(this))*/

       
    


  }

  updateview(origin){


    this.props.updateview();

    

   
   if(origin == 'availability') {
     this.setdiscount(origin);
     this.getpromotiondiscount(false);
   }

  }


  componentWillMount(){


    if(this.getglobaldata('Id_Language') == 2) moment.locale('es',spanish);
       else if(this.getglobaldata('Id_Language') == 3) moment.locale('fr',french);
       else moment.locale('en-au',english);


   this.setdiscount();
   this.getpromotiondiscount(true)
   


  }

  deleteitem(index) {
    var Cart = this.getglobaldata("Cart");

    Cart.splice(index, 1);

    this.setglobaldata("Cart", Cart);

    this.updateview();

    this.senddispatcher('cart.header', {});

    this.setdiscount()
   

    this.getpromotiondiscount(true)
   // this.senddispatcher('cart.view',{});

    //google analytics event
      this.ga().event({
         category: 'Cart',
         action: 'Cart item deleted',
         label: 'Cart item deleted'
      });

    this.forceUpdate();
  }


  setdiscount(origin){
    //return;

    var Cart = this.getglobaldata("Cart");

    Cart.sort(function (a, b) {
     if(a.filters){
       return a.filters.adultsnum - b.filters.adultsnum || a.filters.childrennum - b.filters.childrennum;
     }else return -1;
    });

    this.state.lastcartindex = Cart.length-1;
    var threextwotours = [];
    var enablethreextwotours = [];
    var hasdiscount = false;
    var person_validated = '';
    Cart.map(function(item, index){

      if(Cart[index].beforechildren){
        Cart[index].children = {...Cart[index].beforechildren}; 
      }

      if(Cart[index].beforechildrenfree){
        Cart[index].childrenfree = {...Cart[index].beforechildrenfree};
      }

      if(Cart[index].totalbeforechildrenfree){
        Cart[index].total = Cart[index].totalbeforechildrenfree;
      }

         //Cart[index].threextwoDiscounted = false;
         
        // Cart[index].activityrateid = this.config().activityrateid;

        if(item.itemtype == 1 && item.isthreextwo == -1 && item.Id_TourPriceTypeDef == 1 && this.config().threextwo){ 

         Cart[index].threextwoDiscounted = true;

           if(Cart[index].filters.couponcode == ''){
          // if(Cart[index].commisiondiscount != -1) Cart[index].total = parseFloat(Cart[index].totalbeforediscount).toFixed(2)
          Cart[index].total = parseFloat(Cart[index].totalbeforediscount).toFixed(2)
            Cart[index].coupon.discount = 0;
            Cart[index].coupon.name = '';
            Cart[index].coupon.code = '';
            Cart[index].coupon.couponid = -1;
            Cart[index].filters.couponcode = ''; 
            }

            if(enablethreextwotours.indexOf(item.tourid) == -1) {
               let current_person = `${item.filters.adultsnum}${item.filters.childrennum}`;

                if(current_person != person_validated){
                  threextwotours = [];
                }

                enablethreextwotours.push(item.tourid);
                threextwotours.push({item:item,index:index});
                person_validated = current_person;
            }

         
        }


     if(threextwotours.length == 3){

     

        var totalmin = Math.min.apply(Math, threextwotours.map(function(item){return item.item.totalbeforediscount;}));
       

         var discounted = false;
         threextwotours.map(function(item){

          // if(Cart[item.index].commisiondiscount != -1) Cart[item.index].total = parseFloat(Cart[item.index].totalbeforediscount).toFixed(2)
            Cart[item.index].total = parseFloat(Cart[item.index].totalbeforediscount).toFixed(2)
            Cart[item.index].coupon.discount = 0;
            Cart[item.index].coupon.name = '';
            Cart[item.index].coupon.code = '';
            Cart[item.index].coupon.couponid = -1;
            Cart[item.index].filters.couponcode = '';

         if(item.item.total == totalmin && !discounted){
            Cart[item.index].coupon.couponid = 10000000000;   //temporal value 10000000000
            Cart[item.index].coupon.discount = parseFloat(Cart[item.index].totalbeforediscount).toFixed(2);
            Cart[item.index].coupon.name = this.t("3x2 Discount");
            Cart[item.index].total = parseFloat(0).toFixed(2);
            discounted = true;
            hasdiscount = true;
         } 


         }.bind(this));

        /*if(discounted) {
          threextwotours.map(function(item){
             if(!origin) Cart[item.index].threextwoDiscounted = discounted;
          }.bind(this));
         }*/

         threextwotours = [];

     }
       
    /*   this.setglobaldata("Cart",Cart);

       console.log('Cart[item.index] in discountset',Cart[index])

       this.commisiondiscount(index,true);*/

    }.bind(this));
 

    
    if(hasdiscount){

    Cart.map(function(item, index){

     

      if(item.coupon.discount == 0 && item.childrenfree.qty > 0){
        item.beforechildren = {...item.children};
        item.beforechildrenfree ={...item.childrenfree};
        item.children.qty = Number(item.children.qty)+Number(item.childrenfree.qty);
        item.children.total = item.children.qty*item.children.price;
        item.children.agerange =item.childrenfree.agerange+' -> '+item.children.agerange;
        item.childrenfree.qty = 0;  
        item.totalbeforechildrenfree = item.total;
        item.total = parseFloat(item.total)+parseFloat(item.beforechildrenfree.qty*item.children.price)
      }

    }.bind(this));
   }
    

    this.setglobaldata("Cart",Cart);
   /* console.log('forceUpdate.....',Cart)
    console.log('origin..............2',origin)
    this.setState({reloadcart:false},()=>{
      console.log('origin..............3',origin)
    });*/

  }

  getpromotiondiscount(reloadcart){
//this.config().enableautomaticcoupons = true;
   //return;

    if(!this.config().enableautomaticcoupons) {
      return;
    }

    var cart = this.getglobaldata("Cart");
    var CartDiscountParams = [];

    cart.map(function(item, index){ 

       //_GlobalData.Cart[index].automaticcoupon = 0;

    

        if(item.itemtype == 1 && item.isthreextwo == 0) {
          
          CartDiscountParams[index] = {
        

        'Total':item.totalbeforediscount,
        'AdultPrice':item.adults.price,
        'ChildPrice':item.children.price,
        //'KidPrice':item.KidPrice,

        'Id_ActivityType':1,  //Tour
        'Id_TourPriceType':item.Id_TourPriceTypeDef, 
         

        'ActivityDate':moment(item.filters.activitydate).format("YYYY-MM-DD"),  
        'ActivityTime':item.filters.activitytime,
        'AdultsNum':item.adults.qty,
        'ChildsNum':item.children.qty,
        //'KidsNum':item.KidNum,

        'ChildrenAges':item.filters.childrenages,
        'Id_Activity':item.tourid,
        'Id_Destination':this.getglobaldata('Id_Destination'),
        'Id_Location':this.getglobaldata('Id_Location'),
        'index':index,
        'Id_ActivityRate':1,//item.activityrateid
      
        //'Id_TravelAgent'Item:

        };


        cart[index].total = parseFloat(item.totalbeforediscount).toFixed(2)
        cart[index].coupon.discount = 0;
        cart[index].coupon.name = '';
        cart[index].coupon.code = '';
        cart[index].coupon.couponid = -1;
        cart[index].filters.couponcode = '';
        cart[index].activityrateid = this.config().activityrateid;

      }

    }.bind(this));

    let data = {'Cart':CartDiscountParams}; 

    let options = {
      method:'GetPromotionDiscount',
      query:data,
      callback:function(data){


           data.map(function(coupon, index){
            
           cart[coupon.index].activityrateid = coupon.Id_ActivityRate;
            cart[coupon.index].total = parseFloat(cart[coupon.index].totalbeforediscount).toFixed(2)-parseFloat(coupon.DiscountAmount).toFixed(2);
            cart[coupon.index].coupon.discount = parseFloat(coupon.DiscountAmount).toFixed(2);
            cart[coupon.index].coupon.name = coupon.Name;
            cart[coupon.index].coupon.code = coupon.Code;
            cart[coupon.index].coupon.couponid = 1000000000000;

          

            

           }.bind(this));



            this.setglobaldata("Cart",cart);

           // this.setreloadcart(reloadcart)
            this.setState({reloadcart:reloadcart}, function(){
                
                this.updateview()

            }.bind(this))


      }.bind(this)
    }

    this.all(options);


  }

  commisionzero(index){

    var cart = this.getglobaldata("Cart");

  if(cart[index].total == 0) return;
  var commisionzero =  $('.commisionzero-'+index).is(':checked')?-1:0;

  if(commisionzero == -1) {
    cart[index].commisiondiscount = 0;
    $('.commisiondiscount-'+index).attr('checked',false);
  }


  cart[index].commisionzero = commisionzero;

  this.setglobaldata('Cart',cart);
  this.commisiondiscount(index);

  }

  commisiondiscount(index,initialize){
  var cart = this.getglobaldata("Cart");

  console.log('Entra a comision disount',cart[index].commisiondiscount)

  if(cart[index].total == 0) return;

  var commisiondiscount =  $('.commisiondiscount-'+index).is(':checked')?-1:0;

  

  if(initialize === true) commisiondiscount = cart[index].commisiondiscount;

  console.log('commisiondiscount',commisiondiscount);
  cart[index].commisiondiscount = commisiondiscount

    if(commisiondiscount == -1) cart[index].commisionzero = 0;
 
  var discount = parseFloat(cart[index].beforeotherdiscountstotal*0.1).toFixed(2); //10%

  if(commisiondiscount == -1){
  cart[index].otherdiscounts =  {
                          name:'Savings from Agent (10% OFF)',
                          discount:discount
                         };
    

  console.log('cart[index].total',cart[index].total);
  console.log('discount',discount);

  cart[index].total = parseFloat(parseFloat(cart[index].total)-parseFloat(discount)).toFixed(2);
  console.log('cart[index].total 2',cart[index].total);

   }else {
    
    cart[index].total = cart[index].beforeotherdiscountstotal;
    cart[index].otherdiscounts = false;

    console.log('cart[index].total oter before',cart[index].total);
  }

  cart[index]['customdiscountamount'] = 0;


  this.setglobaldata('Cart',cart);
  console.log('cart 1.............................',cart)
  this.updateview(); //this.senddispatcher('cart.view',{});
  this.setState({});

  }

  leadnameinput(index, event_){

  var cart = this.getglobaldata("Cart");
  var value = event_.target.value;
  cart[index]['leadname'] = value;
 
  this.setglobaldata('Cart',cart);

}


  getdestinationname(destinationid){

    if(this.getglobaldata('destinations')){

      let destination = this.getglobaldata('destinations').find((destination)=>{
        return destination.Id_Destination == destinationid;
      })

      if(destination) return destination.Name;

      return '';
    }
  }


  reloadcart(item){

    let languageid = this.getglobaldata('Id_Language')
    let currencyid = this.getglobaldata('Id_Currency')

   

    if(this.config().enableautomaticcoupons) if(this.state.reloadcart) return true;

    if(Number(item.languageid) != Number(languageid)) return true;
    if(Number(item.currencyid) != Number(currencyid)) return true;

    //if(item.threextwoDiscounted) return true;

    return false;


  }

  customreasondiscount(index,event){
     
    
    var cart = this.getglobaldata("Cart");
  var value = event.target.value;
  cart[index]['customreasondiscount'] = value;

  cart[index].otherdiscounts =  {
    name:value,
    discount:cart[index]['customdiscountamount']
   };
 
  this.setglobaldata('Cart',cart);

  console.log('value',cart[index])
  this.setState({});

  }


  customdiscountamount(index,event){

    var cart = this.getglobaldata("Cart");

    if(cart[index]['totalbeforecustomdiscountamount']){
      cart[index]['total'] = cart[index]['totalbeforecustomdiscountamount'];
    }
     
    
    
    var value = event.target.value;

    

    var customdiscountamount = cart[index]['customdiscountamount'];
    
    var adddiscount = 0;
    //
    console.log('customdiscountamount',customdiscountamount);
    console.log('value',value);
   
    if(!customdiscountamount){
      cart[index]['totalbeforecustomdiscountamount'] = cart[index]['total'];
    }

    cart[index]['customdiscountamount'] = value;

    var total = parseFloat(cart[index]['total'])-value;

    console.log('monto',(adddiscount));
    console.log('totalindex',cart[index]['total']);
    console.log('afteradded',total);

    cart[index]['total'] = total 

    cart[index].otherdiscounts =  {
      name:cart[index]['customreasondiscount'],
      discount:value
     };

     cart[index].commisiondiscount = 0;
 
    this.setglobaldata('Cart',cart);


    //this.commisiondiscount(index,true);

    this.updateview();

    this.setState({});

  }



  render() {
    var Cart = this.getglobaldata("Cart") || [];
    let reloadcart = this.getglobaldata('reloadcart');


    return (

      <div>

        {Cart.map(
          function (item, index) {
           
            console.log("ITEMM",item);
            if(!item.commisiondiscount) item.commisiondiscount = 0;

            const tourmatch = {
                  params:{
                     tourid:item.tourid,
                     itemindex:index
                  }
                }

            const transfermatch = {
                  params:{
                     id_transfer:item.transferid,
                     id_transfer_prices:item.transferpriceid,
                     id_airport_arrival:item.arrivalairportid,
                     id_airport_departure:item.departureairportid,
                     type:item.triptype,
                     itemindex:index
                  }
            }


           if(item.itemtype==1) return (
              <div key={index} className="tour_container citylife_tour" style={item.total == 0?{background:'#f2f20047'}:{}}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="details_tour">
                      <b className="promo_normal"> {this.t("TOUR")}</b>
                      {item.isthreextwo == -1 && this.config().threextwo?<b className="promo_det_t"> {this.t("3rd Tour Free")}</b>:''}
                      <h3>
                        <strong>{item.tourname}</strong><br/><small>{this.getdestinationname(item.destinationid)}</small>
                      </h3>
                      <p>
                        <strong>{this.t("Activity Date & Time")}:</strong>{" "}
                        {moment(item.filters.activitydate).format(
                          "MMMM DD, YYYY"
                        )}{" "}
                        {item.filters.activitytime}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="btn_edit_t">


                       <button
                        className="edit_btn cart_ed"
                        onClick={this.pushnavigate.bind(this,'/tours/availability/'+item.tourid+'/edit/'+index)}
                      >
                        {this.t("Edit")} <i className="icon-edit" />
                      </button>

                     
                      <button
                        className="remove_btn"
                        onClick={this.deleteitem.bind(this,index)}
                      >
                        {this.t("Remove")} <i className="icon-trash" />{" "}
                      </button>


                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="details_tour">
                      <h2 className="price_person">
                        <strong>{this.t("Price by")} {item.pricetype}</strong>
                      </h2>
                    </div>
                  </div>
                </div>{" "}
                {/*end row*/}
                <div className="details_tour">

                 {this.reloadcart(item)?
                    <NewTourPricesTable match={tourmatch}  onlytable={true} Id_ActivityRate={item.activityrateid} updateview={this.updateview.bind(this,'availability')} />
                    :
                    <TourPricesTable calculations={item}  updateview={this.updateview.bind(this,'tourtable')} />
                 }

                


                </div>

                <div className="row p-3">
                  {item.isthreextwo == -1 && this.config().threextwo?null:
                  <div className="col-md-12 mb-3">
                         <div className="details_tour det_cart">
                           <span><i className="icon-ok-circle"></i> {this.t('Free cancellation before 48 Hours',{date:moment(item.filters.activitydate).format("MMMM DD, YYYY")})} </span>
                        </div>
                  </div>}
                  </div>   



                     
                  {this.config().bookingoptions?
                  <div>
                  <div className="row p-3">
                   {this.config().adminid?
                   <div className="col-md-12">
                       <div className="form-group lead_ag">
                           <input className="form-control" value={item.leadname} type="text" placeholder={this.t("Lead Name on Reservation")}  onChange={this.leadnameinput.bind(this,index)} />
                        </div>
                     </div>
                     :''}

                  { item.total > 0 && this.config().adminid?
                    <div className="col-md-6">

                        <div className="form-group lead_ag_check">
                            <div className="custom-control custom-checkbox">
                             <input type='checkbox'  id={"customCheck2"+index} disabled={item.total==0?true:false} checked={item.commisionzero==-1?true:false} className={'custom-control-input commisionzero-'+index} onChange={this.commisionzero.bind(this,index)} />
                             <label className="custom-control-label receive_em" htmlFor={"customCheck2"+index}>Commission Zero?</label>
                            </div>
                        </div>
                  </div>:'' }

                  { item.total > 0 && this.config().adminid?
                    <div className="col-md-6">
                        <div className="form-group lead_ag_check">
                            <div className="custom-control custom-checkbox">
                           
                             <input  id={"customCheck3"+index} type='checkbox' disabled={item.total==0?true:false} checked={item.commisiondiscount==-1?true:false} className={'custom-control-input commisiondiscount-'+index} onChange={this.commisiondiscount.bind(this,index)} />
                
                              <label className="custom-control-label receive_em" htmlFor={"customCheck3"+index}>Convert Commision into Discount?</label>
                            </div>
                            <p><span className="blue_text"><strong>Savings from Agent (10% OFF)</strong></span>
                                <br />
                                 10% discount of the total will be applied after processing the payment
                            </p>
                        </div>

                  </div>:'' }  

                

               </div>

               {this.config().adminid?
               <div className="row p-3">
               <div className="col-md-8">
                      
                      
                         <label className="">Reason for the Discount (Discount not applicable to Extras)</label>
                         <input className="form-control" defaultValue={item.customreasondiscount} type="text" placeholder={this.t("Reason discount")}  onBlur={this.customreasondiscount.bind(this,index)} />
                        
                        
                  </div>

                  <div className="col-md-4 ">

                        
                         <label className="">Monto del descuento</label>
                         <input className="form-control" defaultValue={item.customdiscountamount}  type="text" placeholder={this.t("Discount amount")}  onBlur={this.customdiscountamount.bind(this,index)} />
                        
                  </div>

                  </div>:''}

                  </div>:''}

              </div>
            );




            if(item.itemtype==2) {

              var cancelationtext = '';
              var transferdate = moment(item.arrivalActivityDateTime).format("MMMM DD, YYYY");
              if(item.triptypeid == 2) transferdate = moment(item.departureActivityDateTime).format("MMMM DD, YYYY");
              cancelationtext = this.t('Free cancellation before 48 Hours',{date:transferdate}); 

              return (
              <div key={index} className="tour_container">
                <div className="row">
                

                  <div className="col-lg-8">
                            <div className="details_tour">
                                <b  className="promo_normal"> {this.t("TRANSFER TYPE")}</b>
                                <h3> <strong>{item.hotelname}</strong> <br/><small>{this.getdestinationname(item.destinationid)}</small></h3>
                                <p className="marg-0"><strong>{item.tranfertype}, {item.triptypename}</strong></p>
                                <span className="color_red d-block mb-15">{this.t("Rates are per {{paymenttype}} in {{currency}} as shown for",{paymenttype:item.PaymentByName,currency:item.currency})}:</span>

                                {item.triptype=='round' || item.triptype=='arrival' ?
                                <div>
                                <p className="marg-0 blue_text"><strong><i className="icon-plane planev blue_text"></i> {this.t("Arrival Info")}</strong></p>
                                <ul className="list_transf">
                                    <li>{moment(item.arrivalActivityDateTime).format("MMMM DD, YYYY h:mm a")} </li>
                                    <li>{this.t("Airport")}: {item.arrivalairport}</li>
                                    <li>{this.t("Airline")}: {item.arrivalairline}</li>
                                    <li>{this.t("Flight#")}: {item.arrivalflight}</li>
                                </ul>
                                 </div>
                                :''
                              }

                              {item.triptype=='round' || item.triptype=='departure' ?
                                <div>
                                <p className="marg-0 blue_text"><strong><i className="icon-plane planev blue_text"></i> {this.t("Departure Info")}</strong></p>
                                <ul className="list_transf">
                                    <li>{moment(item.departureActivityDateTime).format("MMMM DD, YYYY h:mm a")}</li>
                                    <li>{this.t("Airport")}: {item.departureairport}</li>
                                    <li>{this.t("Airline")}: {item.departureairline}</li>
                                    <li>{this.t("Flight#")}: {item.departureflight}</li>
                                </ul>
                                </div>
                                :''
                              }
                              
                            </div>
                        </div>


                  <div className="col-lg-4">
                    <div className="btn_edit_t">

                    <button
                        className="edit_btn cart_ed"
                        onClick={this.pushnavigate.bind(this,'/transfers/prices/'+item.transferid+'/'+item.transferpriceid+'/'+item.arrivalairportid+'/'+item.departureairportid+'/'+item.triptype+'/'+index)}
                      >
                        {this.t("Edit")} <i className="icon-edit" />
                      </button>
                     
                       
                 
                      <button
                        className="remove_btn"
                        onClick={this.deleteitem.bind(this,index)}
                      >
                        {this.t("Remove")} <i className="icon-trash" />{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="details_tour">

                {this.reloadcart(item)?
                 <NewTransferPricesTable match={transfermatch} onlytable={true} />
                 :
                 <TransferPricesTable type={item.triptype} data={item} />
                }


                </div>

                <div className="row">

                    {item.isthreextwo == -1 && this.config().threextwo?null:
                    <div className="col-md-12">
                         <div className="details_tour det_cart">
                           <span><i className="icon-ok-circle"></i>{cancelationtext}</span>
                        </div>
                    </div>}  

                   </div>
   
                   {this.config().bookingoptions?
                  <div>
                  <div className="row p-3">
                   {this.config().adminid?
                   <div className="col-md-12">
                       <div className="form-group lead_ag">
                           <input className="form-control" value={item.leadname} type="text" placeholder={this.t("Lead Name on Reservation")}  onChange={this.leadnameinput.bind(this,index)} />
                        </div>
                     </div>
                     :''}

                  { item.total > 0 && this.config().adminid?
                    <div className="col-md-6">

                        <div className="form-group lead_ag_check">
                            <div className="custom-control custom-checkbox">
                             <input type='checkbox'  id={"customCheck2"+index} disabled={item.total==0?true:false} checked={item.commisionzero==-1?true:false} className={'custom-control-input commisionzero-'+index} onChange={this.commisionzero.bind(this,index)} />
                             <label className="custom-control-label receive_em" htmlFor={"customCheck2"+index}>Commission Zero?</label>
                            </div>
                        </div>
                  </div>:'' }

                  { item.total > 0 && this.config().adminid?
                    <div className="col-md-6">
                        <div className="form-group lead_ag_check">
                            <div className="custom-control custom-checkbox">
                           
                             <input  id={"customCheck3"+index} type='checkbox' disabled={item.total==0?true:false} checked={item.commisiondiscount==-1?true:false} className={'custom-control-input commisiondiscount-'+index} onChange={this.commisiondiscount.bind(this,index)} />
                
                              <label className="custom-control-label receive_em" htmlFor={"customCheck3"+index}>Convert Commision into Discount?</label>
                            </div>
                            <p><span className="blue_text"><strong>Savings from Agent (10% OFF)</strong></span>
                                <br />
                                 10% discount of the total will be applied after processing the payment
                            </p>
                        </div>

                  </div>:'' }  

                

               </div>

               {this.config().adminid?
               <div className="row p-3">
               <div className="col-md-8">
                      
                      
                         <label className="">Reason for the Discount (Discount not applicable to Extras)</label>
                         <input className="form-control" defaultValue={item.customreasondiscount} type="text" placeholder={this.t("Reason discount")}  onBlur={this.customreasondiscount.bind(this,index)} />
                        
                        
                  </div>

                  <div className="col-md-4 ">

                        
                         <label className="">Monto del descuento</label>
                         <input className="form-control" defaultValue={item.customdiscountamount}  type="text" placeholder={this.t("Discount amount")}  onBlur={this.customdiscountamount.bind(this,index)} />
                        
                  </div>

                  </div>:''}

                  </div>:''}

                  

            

              </div>
            );
          }


          }.bind(this)
        )} 


      </div>

    );


    return null;
  }
}

export default withRouter(cartlist)
