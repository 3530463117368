import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
//import 'moment/locale/es';
//import 'moment/locale/fr'
//if (typeof window === "undefined")  var window={}
const location = window.location;
const $ = window.$;

class Language extends Global {

    constructor(props) {
        super(props);

        this.handle = this.handle.bind(this);

        this.state = {
            header: null,
            languages: null,
            defaultlanguage:1
        };
    }

    componentDidMount() {

        if(!this.getglobaldata('Id_Language')) this.setglobaldata('Id_Language',this.state.defaultlanguage);

        var options = {
            arraydata: 'languages',
            method: 'GetLanguages',
            query:{
                languageids:this.config().languagesdropdown
            },
            callback: function (data) {
                this.setState({
                    languages: data,
                    header: this.props.header === undefined ? true : this.props.header
                });
            }.bind(this)
        };
        this.all(options);
    }

    handle(event) {
        var languageid = event.target.value;
        this.setglobaldata('Id_Language',languageid);
        this.setglobaldata('reloadcart',true);

       
       // window.onbeforeunload = function() { } // Remove Prevent before unload
       window.history.pushState({page:1}, 'Title', location.pathname+'?languageid='+languageid);
       this.reloadPage();
       // this.forceUpdate();

    }

    
    render() {

        var languageid = this.getglobaldata('Id_Language');

        return (
            this.state.languages !== null ? (
                <select name="select" value={languageid} className={this.state.header ? 'form-control select_lang' : (this.props.class || '')} onChange={this.handle}>
                    {
                        this.state.languages.map((language, index) => {
                            return (
                                <option key={`languageOption-${index}`} value={language.Id_Language}>
                                    {
                                        language.Id_Language === '1' ? language.Name_Eng : (language.Id_Language === '2' ? language.Name_Esp : language.Name_Fra)
                                    }
                                </option>
                            )
                        })
                    }
                </select>
            ) : null
        )
    }
}

export default withRouter(Language)