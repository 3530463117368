import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import Language from "../components/component.language";
import Currency from "../components/component.currency";
import Menu from "../components/component.menu";
import CartHeader from "../components/component.cart.header";
import DestinationSelector from "../components/component.destination.selector";
import Weather from "../components/component.weather";
import Search from "../components/component.search";
import Global from '../globals/global';
//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;

class header extends Global{

    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);

        this.state = {};
    }

    openModal() {
        $.magnificPopup.open({
            items: {
                src: '#search_p'
            },
            type: 'inline',
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-zoom-in',
        });
    }


    render() {
        var disablepages = this.config().noheaderpages || [];
        const disable = disablepages.find(page => {
            return location.pathname.search(page) != -1;
        })

        console.log('disablepages',disablepages);
        console.log('disable',disable);

        if (disable) return null;

        var agentmoduleclass = '';
        if(this.config().enableagentsearch) agentmoduleclass = 'nav_editor';

        



        return (
            <header className={`back_blueh ${agentmoduleclass}`}>
                <div id="top_line">
                    <div className="container">
                        <div className="row">

                            <div className="col-6 icon_changec">
                             
                                {this.config().destinations?
                                <DestinationSelector locationNotification={true}/>
                                :''}
                               

                            </div>

                            <div className="col-6">
                                <ul id="top_links" >
                                    
                                 
                                    {this.config().myaccount?
                                        <li> <NavLink to="/myaccount" >{this.getglobaldata('user') ? <span><i className="far fa-user-circle"></i> {this.getglobaldata('user').FirstName}</span> : this.t('My Account')}</NavLink></li>
                                    :''}
                                  

                                    
                                    {this.getglobaldata('user') && this.config().myaccount? <li><NavLink to="/" onClick={this.logout.bind(this)} >{this.t('Logout')}</NavLink></li> : ''}
                                    

                                   
                                    {this.config().languages?
                                        <li  ><Language /></li>
                                    :''}
                                   

                                   
                                    {this.config().currencies?
                                        <li  ><Currency /></li>
                                    :''}
                                

                                    {this.config().search?
                                        <li>
                                        <a onClick={this.openModal} className="search_pop i_search"><i className="icon_search"></i></a>
                                    </li>
                                    :''}
                                 

                                </ul>
                            </div>
                        </div>{/*---- End row --- */}
                    </div>{/*---- End container--- */}
                </div>{/*---- End top line--- */}

                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            {this.config().logo != ''?
                            <div id="logo_home">
                                <h1><NavLink to="/home" title={this.config().brandname} >{this.config().brandname}</NavLink></h1>
                            </div>
                            :''}
                        </div>
                        <nav className="col-10">
                            <a className="cmn-toggle-switch cmn-toggle-switch__htx open_close" href="" onClick={this.eventpreventdefault.bind(this)}><span>{this.t('Menu mobile')}</span></a>
                            <Menu />

                            <ul id="top_tools">
                                <li className="cart_home">
                                    <CartHeader />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>{/*---- container --- */}



                {this.whatsappChat()}

                



                {/*  chat icon --*/}
                <a href="#" className="chat_icon" onClick={this.openchat.bind(this)}>
                    <img src={this.image("img/chat_icon.png")} />
                </a>
                
            </header>
        );
    }
}

export default header;