import React, { Component } from "react";

import { NavLink, withRouter } from "react-router-dom";
import NotificationsAlerts from "../components/component.notifications.alerts";
import Profile from "../components/component.profile";
import PurchaseHistoryDetailTour from "../components/component.purchase.history.detail.tour";
import PurchaseHistoryDetailTransfer from "../components/component.purchase.history.detail.transfer";
import Global from "../globals/global";
const $ = window.$;

class PurchaseHistoryDetail extends Global {
  constructor(props) {
    super(props);

    this.logoutPopup = this.logoutPopup.bind(this);
    var payment = false;
    if (this.props.match) {
      if (this.props.match.params) {
        if (this.props.match.params.payment)
          payment = this.props.match.params.payment;
      }
    }

    this.state = {
      user: null,
      details: null,
      isbuyresult: false,
    };
  }

  componentDidMount() {
    this.setState({
      user: this.getglobaldata("user"),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user !== this.state.user) {
      new window.CBPFWTabs(document.getElementById("tabs"));

      /*    $('.nav_bottom li a').each(function () {
                $(this).addClass('active');
            });*/
      if (this.props.history.action === "POP") {
        this.state.isbuyresult = true;
      }

      var options = {
      callback: this.getCartPurchases.bind(this),
      clear: true,
      duration: 5, //seg
    };
    this.delay(options);

     
  }
   }

  getCartPurchases(){
     // const Id_UserTransaction = this.props.match.params.idtransaction;
      let options = {
        method: "GetCartPurchase",
        query: {
          UserAccessToken: this.state.user.UserAccessToken,
          DeviceAccessToken: this.state.user.DeviceAccessToken,
        },
        callback: function (data, result) {

          this.setState({
            details: result.data,
          });
        }.bind(this),
        error:()=>{
          alert('No se pudo cargar la informacion, refresca la pagina por favor')
        }
      };
      this.all(options);
    }

 
  logoutPopup() {
    $.magnificPopup.open({
      items: {
        src: "#close_ses",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
    });
  }

  purchasesData() {

    console.log('this.state.details',this.state.details)
    if (this.state.details) {

      var data = [];
      this.state.details.map((detail) => {
        console.log('detail.Id_TransTyp',detail.Id_TransType);
        if (Number(detail.Id_TransType) === 1)
          data.push(<PurchaseHistoryDetailTour data={detail} fromPyamentConfirm={true} />);
        if (Number(detail.Id_TransType) === 5)
          data.push(<PurchaseHistoryDetailTransfer data={detail} fromPyamentConfirm={true} />);

      });
      return data;
    } else {
      return <div> {this.spinner(
                              this.t(
                                "We are processing your request, please wait..."
                              )
                            )}</div>;
    }
  }

  render() {
    var user = this.getglobaldata("user");
    if (this.state.payment) {
      this.isbuyresult = true;
      this.state.isbuyresult = true;
    }
    return this.state.user !== null ? (
      <div>
        <section
          className="parallax-window"
          data-parallax="scroll"
          style={{
            background: "url(" + this.image("img/account_back.jpg") + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div className="parallax-content-1">
            <div className="animated fadeInDown">
              <h1>{this.t("My Account")}</h1>
              <p>{`${this.t(
                "Hello"
              )} ${this.state.user.FirstName.toUpperCase()} ${this.state.user.LastName.toUpperCase()}!`}</p>
            </div>
          </div>
        </section>
        {/* End section */}
        <main>
          <div id="position">
            <div className="container">
              <ul>
                <li>
                  <NavLink to="/">{this.t("Home")}</NavLink>
                </li>
                <li>{this.t("My Account")}</li>
              </ul>
            </div>
          </div>
          {/* End Position */}

          <div className="margin_60 container">
            <div className="row">
              <div className="col-lg-9">
                <h6 className="blue_text">
                  <strong>
                    {this.state.user.FirstName} {this.state.user.LastName}
                  </strong>
                </h6>
              </div>

              <div className="col-lg-3">
                <a
                  onClick={this.logoutPopup}
                  className="close_session close_bt"
                >
                  {this.t("Close Session")} <i className="icon-off" />
                </a>
              </div>
            </div>
            <div id="tabs" className="tabs">
              <nav>
                <ul>
                  <li>
                    <a href="#section-1" className="icon-booking">
                      <span>{this.t("Purchase History")}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-2" className="icon-bell-alt">
                      <span>{this.t("Notifications & Alerts")}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-3" className="icon-profile">
                      <span>{this.t("Profile")}</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="content">
                
                  <div className="row">
                    <div className="col-lg-8 col-12 mx-auto">
                      <h4 className="title_detc">
                        {this.t(
                          "Felicidades estas a punto de vivir una gran experiencia"
                        )}
                      </h4>
                    </div>
                  </div>
                

                <section id="section-1">{this.purchasesData()}</section>
                {/* End section 1 */}
                <section id="section-2">
                  <NotificationsAlerts />
                </section>
                {/* End section 2 */}
                <section id="section-3">
                  <Profile />
                </section>
                {/* End section 3 */}
              </div>
              {/* End content */}
            </div>
            {/* End tabs */}
          </div>
          {/* end container */}
        </main>
        {/* End main */}
      </div>
    ) : null;
  }
}

export default PurchaseHistoryDetail;
