import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class TransferPricesTable extends Global{

    constructor(props) {
        super(props);

        this.state = {
            type: null,
            data: null,
        };
    }

    componentDidMount() {
        this.setState({
            type: this.props.type,
            data: this.props.data,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    render() {
        
        return (
            this.state.data !== null ? (
                this.state.data.PaymentBy === '1' ? (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tour_rates">
                                <h2><strong>{this.t('Prices')} ({this.state.data.currency}) </strong></h2>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <p># {this.t('Adults')}</p>
                        </div>

                        <div className="col-md-4">
                            <p className="text-center price_c">{this.state.data.adults.qty} @ ${this.state.data.adults.price}</p>
                        </div>

                        <div className="col-md-4">
                            <p className="price_r" > ${this.state.data.adults.total}</p>
                        </div>
                        {/** CHILDREN */}
                        {
                            this.state.data.children.qty > 0 ? (
                                <div className="col-md-4">
                                    <p># {this.t('Children')}</p>
                                </div>
                            ) : null
                        }
                        {
                            this.state.data.children.qty > 0 ? (
                                <div className="col-md-4">
                                    <p className="text-center price_c">{this.state.data.children.qty} @ ${this.state.data.children.price}</p>
                                </div>
                            ) : null
                        }
                        {
                            this.state.data.children.qty > 0 ? (
                                <div className="col-md-4">
                                    <p className="price_r" >${this.state.data.children.total}</p>
                                </div>
                            ) : null
                        }

                        {/** CHILDREN FREE */}
                        {
                            this.state.data.childrenfree.qty > 0 ? (
                                <div className="col-md-4">
                                    <p># {this.t('Children Free')}</p>
                                </div>
                            ) : null
                        }
                        {
                            this.state.data.childrenfree.qty > 0 ? (
                                <div className="col-md-4">
                                    <p className="text-center price_c">{this.state.data.childrenfree.qty} @ ${this.state.data.childrenfree.price}</p>
                                </div>
                            ) : null
                        }
                        {
                            this.state.data.childrenfree.qty > 0 ? (
                                <div className="col-md-4">
                                    <p className="price_r" >${this.state.data.childrenfree.total}</p>
                                </div>
                            ) : null
                        }

                        {/** COUPON */}
                        {
                            this.state.data.coupon.Id_Coupon > 0 ? (
                                <div className="col-md-4">
                                    <p>{this.t('Coupon')}</p>
                                </div>
                            ) : null
                        }
                        {
                            this.state.data.coupon.Id_Coupon > 0 ? (
                                <div className="col-md-4 text-warning">
                                    <p className="text-center price_c">{this.state.data.coupon.name}</p>
                                </div>
                            ) : null
                        }
                        {
                            this.state.data.coupon.Id_Coupon > 0 ? (
                                <div className="col-md-4">
                                    <p className="price_r" >-${this.state.data.coupon.discount}</p>
                                </div>
                            ) : null
                        }

                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>

                         <div className="col-md-12">
                          <div className="row">
                        <div className="col-md-12">
                            <p className="price_r" ><strong>{this.t('TOTAL')} {this.state.data.currency} ${this.state.data.total}</strong></p>
                        </div>
                         </div>
                          </div>

                      
                    </div>
                ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tour_rates">
                                    <h2><strong>{this.t('Prices')}</strong></h2>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <p># {this.t('Adults')}</p>
                            </div>

                            <div className="col-md-6">
                                <p className="price_r">{this.state.data.adults.qty}</p>
                            </div>
                            {/** CHILDREN */}
                            {
                                this.state.data.children.qty > 0 ? (
                                    <div className="col-md-6">
                                        <p># {this.t('Children')}</p>
                                    </div>
                                ) : null
                            }
                            {
                                this.state.data.children.qty > 0 ? (
                                    <div className="col-md-6">
                                        <p className="price_r">{this.state.data.children.qty}</p>
                                    </div>
                                ) : null
                            }

                            {/** CHILDREN FREE */}
                            {
                                this.state.data.childrenfree.qty > 0 ? (
                                    <div className="col-md-6">
                                        <p># {this.t('Children Free')}</p>
                                    </div>
                                ) : null
                            }
                            {
                                this.state.data.childrenfree.qty > 0 ? (
                                    <div className="col-md-6">
                                        <p className="price_r">{this.state.data.childrenfree.qty}</p>
                                    </div>
                                ) : null
                            }

                            {/** COUPON */}
                            {
                                this.state.data.coupon.Id_Coupon > 0 ? (
                                    <div className="col-md-4">
                                        <p>{this.t('Coupon')}</p>
                                    </div>
                                ) : null
                            }
                            {
                                this.state.data.coupon.Id_Coupon > 0 ? (
                                    <div className="col-md-4 text-warning">
                                        <p className="text-center price_c">{this.state.data.coupon.name}</p>
                                    </div>
                                ) : null
                            }
                            {
                                this.state.data.coupon.Id_Coupon > 0 ? (
                                    <div className="col-md-4">
                                        <p className="price_r" >-${this.state.data.coupon.discount}</p>
                                    </div>
                                ) : null
                            }

                            <div className="col-md-4"></div>
                            <div className="col-md-4"></div>


                             <div className="col-md-12">
                          <div className="row">
                        <div className="col-md-12">
                            <p className="price_r" ><strong>{this.t('TOTAL')} {this.state.data.currency} ${this.state.data.total}</strong></p>
                        </div>
                         </div>
                          </div>

                        </div>
                    )
            ) : null
        )
    }
}

export default withRouter(TransferPricesTable)