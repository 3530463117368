import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import StripesProcessor from "../stripes/App";
import Section from "../components/component.section";
import Global from '../globals/global';
import CreditCards from "../components/component.credit.cards";
import ErrorMessages from "../components/component.error.messages";
import moment from "moment";
const $ = window.$;

class Payment extends Global {

    constructor(props) {
        super(props);

        this.state = {
            stripepaymentparams: {Id_StripeAPIPayment:props.match.params.Id_APIPayment},
            bank: props.match.params.bank,
            Id_APIPayment:props.match.params.Id_APIPayment,
            bbvaUrl:'',
            concept:'',
            errorMessage:'',

            worldpaypaymentparams:null,
            worldpaypaymentSuccess:false,
            messagesWorldPay:[],
            worldPaySuccessMessage:"",
            worldPayErrorMessage:"",
            wordlPaytotalstr:"",
            wordlPaytotal:0,
            worldPayIdBooking:null,
            processing:false
        }
    }

    componentDidMount() {

        if(this.state.bank == 'bbva') {
          this.setState({bbvaUrl:this.config().apiurl +'doBancomerPayment?Id_BancomerAPIPayment='+this.state.Id_APIPayment},function(){
          this.createbancomeriframe()
        })
          
        }

        if(this.state.bank == 'bbva2') this.getBBAVAPaymentLik()

        if(this.state.bank == 'worldpay') this.getWorldPayPaymentInfo()
    }

    getBBAVAPaymentLik(){

      var options = {
      method: "doBancomerPaymentV2",
      query: {Id_BancomerAPIPayment:this.state.Id_APIPayment},
      callback: function (jsonresponse) {
       
        if(jsonresponse.url){
        this.setState({
          bbvaUrl: jsonresponse.url,
          concept:jsonresponse.params.concept,
        },function(){
          this.createbancomeriframe()
        })
        }else{

          this.setState({
           errorMessage: 'Order not found',
          })

        }
        

      }.bind(this),
      error: function (jsonresponse) {
        
        this.setState({
          errorMessage: jsonresponse.errorMessage,
        })
        
      }.bind(this),
    };

    this.single(options)

    }

  ////////////////////////////////////////////////////////////worlds pay
    getWorldPayPaymentInfo(){
      //obtener el pago
      var options = {
        method: "getWorldPayPayment",
        query: {Id_WorldPayApiPayment:this.state.Id_APIPayment},
        callback: function (jsonresponse) {
          console.log("WORLD PAY GET",jsonresponse);
          if(jsonresponse.result == true){
            
            

            
            if(jsonresponse.all_data.isPaid == -1){
              var worldPaySuccessMessage = this.t("This reservation has been processed");

              this.setState({              
                worldpaypaymentSuccess:true,
                worldPaySuccessMessage:worldPaySuccessMessage,              
              },()=>{            
                
              })

            }else{
              jsonresponse.request_data.CC_Name = "";
              this.setState({
                worldpaypaymentparams:jsonresponse.request_data,
                worldpaypaymentSuccess:false,
                worldPaySuccessMessage:worldPaySuccessMessage,
                wordlPaytotalstr:jsonresponse.all_data.Ds_Amount+" "+jsonresponse.all_data.Ds_Currency,
                wordlPaytotal:jsonresponse.all_data.Ds_Amount,
                worldPayIdBooking:jsonresponse.Id_Booking
              },()=>{            
                this.validations();
              }) 
            }

          }else{
            this.setState({
              worldPayErrorMessage: this.t("Error has been detected, try again or contact for help")+" (wp-e1)",
            })
          }
                 
  
        }.bind(this),
        error: function (jsonresponse) {
          
          this.setState({
            worldPayErrorMessage: this.t("Error has been detected, try again or contact for help")+" (wp-e)",
          })
          
        }.bind(this),
      };
  
      this.single(options)
      //setear la moneda
      //setear el total
    }

    creditCardChange(index, data){
      console.log("Creditcard datos", data);

        
      if (data.name == "number") {
        data.name = `worldpaypaymentparams.CC_Number`;
        data.value = data.value.split(" ").join("");  
        
      }
  
      if (data.name == "expiry") {
        let expirySplit = data.value.split("/");
  
        let month = expirySplit[0];
        let year = -1;
  
        if (expirySplit[1]) year = expirySplit[1];
  
        data.name = `worldpaypaymentparams.CC_ExpirationMonth`;
        data.value = month;
  
        this.changeinputform({}, data);
  
        data.name = `worldpaypaymentparams.CC_ExpirationYear`;
        data.value = year;
      }
  
      if (data.name == "cvc") data.name = `worldpaypaymentparams.CC_CCV`;
      if (data.name == "zipcode") data.name = `worldpaypaymentparams.CC_ZipCode`;
      if (data.name == "name") data.name = `worldpaypaymentparams.CC_Name`;
  
      this.changeinputform({}, data);
    }

    setCreditCardIssuer(index, issuer){
      // console.log("issuer checkpot", issuer);
      // console.log("index", index);

      
      let creditcardtypeoptions = {
        name: `worldpaypaymentparams.Id_CreditCardType`,
        value: -1,
      };

      if (issuer.name == "visa") {
        creditcardtypeoptions.value = 1;
      }

      if (issuer.name == "amex") {
        creditcardtypeoptions.value = 3;
      }

      if (issuer.name == "mastercard") {
        creditcardtypeoptions.value = 2;
      }

      if (issuer.name == "discover") {
        creditcardtypeoptions.value = 4;
      }

      this.changeinputform({}, creditcardtypeoptions);
    }

    submitWoldPaypayment(){
      
      //si no esta creada la reservacion, es decir no es 24 horas
      if(this.state.worldPayIdBooking == null || this.state.worldPayIdBooking == undefined || this.state.worldPayIdBooking == ""){
      
          this.setState({
            processing:true
          });


          var params = this.state.worldpaypaymentparams;
          params.Id_WorldPayApiPayment = this.state.Id_APIPayment;
          params.worldPayPaymentUrl = window.location.origin+"/"+window.location.pathname;
          

          var options = {
            method: "doBookingCartPayment",
            body: params,
            type:'POST',
            callback: function (jsonresponse) {

              if(jsonresponse.result == true){
                var worldPaySuccessMessage = this.t("The payment has been success");
                this.setState({
                  worldPaySuccessMessage:worldPaySuccessMessage,
                  worldpaypaymentSuccess:true,
                  processing:false
                });
              }else{
                this.setState({
                  worldPayErrorMessage: this.t("Error has been detected, try again or contact for help")+" (wp-e2)",
                });
              }

            }.bind(this),
            error: function (jsonresponse) {
              this.state.messagesWorldPay.push(this.t("An error has been detected, try again or contact for help")+" (wp-e3)"); 
              this.state.processing = false;         
              this.forceUpdate();
            }.bind(this),
          };

          this.create(options);

      }else{

        //si YA esta creada la reservacion, es decir ES 24 horas
        this.setState({
          processing:true
        });
       
        var params = {
          payment:this.state.worldpaypaymentparams,
          Id_Booking:this.state.worldPayIdBooking,
          total:this.state.wordlPaytotal          
        };

       
        var options = {
          method: "doPaymentWorldPay",
          body: params,
          type:'POST',
          callback: function (jsonresponse) {

            if(jsonresponse.result == true){
              var worldPaySuccessMessage = this.t("The payment has been success");
              this.setState({
                worldPaySuccessMessage:worldPaySuccessMessage,
                worldpaypaymentSuccess:true,
                processing:false
              });
            }else{
              this.setState({
                worldPayErrorMessage: this.t("Error has been detected, try again or contact for help")+" (wp-e5)",
              });
            }

          }.bind(this),
          error: function (jsonresponse) {
            this.state.messagesWorldPay.push(this.t("An error has been detected, try again or contact for help")+" (wp-e4)"); 
            this.state.processing = false;         
            this.forceUpdate();
          }.bind(this),
        };

        this.create(options);
      }
    }

    changeinputform(event, name) {
      this.changeinput(event, name);
  
      var options = {
        callback: this.validations.bind(this),
        clear: true,
        duration: 1, //seg
      };
      this.delay(options);
      //this.validations();
    }

    validations() {
      this.messages();
      this.forceUpdate();
    }

    messages() {
      var messages = [];
      messages = messages.concat(this.paymentinfovalidations());
      
      this.state.messagesWorldPay = messages;
    }

    paymentinfovalidations() {
      
      var messages = [];
      
  
      if (this.state.worldpaypaymentparams.CC_Name == "")
        messages.push(this.t("Enter Name as it appears on the Credit Card"));     
  
      if (
        !/^[0-9]{16,16}$/i.test(this.state.worldpaypaymentparams.CC_Number)
      ) {
        messages.push(this.t("Enter credit card number  - 16 Digits"));
      }

      if (!this.creditCardValidateDate({
        expirationmonth:this.state.worldpaypaymentparams.CC_ExpirationMonth,
        expirationyear:this.state.worldpaypaymentparams.CC_ExpirationYear
      })) {
        messages.push(this.t("Invalid expiration date"));
        
      }      
  
      if (this.state.worldpaypaymentparams.CC_ZipCode == "") messages.push(this.t("Enter Zip Code"));
  
      return messages;
    }

    creditCardValidateDate(paymentinfo) {
      var currentmonth = Number(moment().month() + 1);
      var currentyear = String(moment().year());
      var expirationmonth = Number(paymentinfo.expirationmonth);
      var expirationyear = Number(paymentinfo.expirationyear);
  
      currentyear = Number(currentyear.substring(currentyear.length - 2));
  
      if (
        expirationmonth == -1 ||
        paymentinfo.expirationyear == -1 ||
        expirationmonth < 1 ||
        expirationmonth > 12 ||
        expirationyear < currentyear ||
        (expirationyear == currentyear && expirationmonth < currentmonth)
      )
        return false;
  
      return true;
    }
    ///////////////////////////////////////////worlds pay


    createbancomeriframe() {
  

    var src = this.state.bbvaUrl;
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", src);
    ifrm.setAttribute("allowfullscreen", true);
    ifrm.setAttribute("frameborder", "0");
    ifrm.setAttribute("scrolling", "no");

    $("#bancomer-iframe").empty();
    document.getElementById("bancomer-iframe").appendChild(ifrm);

    $("#bancomer-iframe iframe").on(
      "load",
      function () {
      
        this.scrolltop(".scrollhere")

        if (this.ismobile()) {
          //$("#payment_bancomer").attr("style", "height:1850px");
        
            //$("#bancomer-iframe iframe").attr("style", "height:1800px;top: -45px;position: relative;")
            $("#bancomer-iframe iframe").attr("style", "height:1800px;");
          
        } else {
          //$("#payment_bancomer").attr("style", "height:1550px");
         
           // $("#bancomer-iframe iframe").attr("style", "height:1800px;top: -45px;position: relative;")
            $("#bancomer-iframe iframe").attr("style", "height:1490px;");;
        }

      }.bind(this)
    );
  }




    aprovedpayment(){
       // alert('aprobado');
    }
    render() {


        if(this.state.bank == 'bbva'){

           return (
           
                <main>
                   <Section origin="payment" />
                   <div className="d-flex justify-content-center mb-5 scrollhere">

                      <div className="mb-5 col-md-6">
                      <h2>{this.state.errorMessage}</h2>
                      <h5 style={{width:'100%',position:'absolute',top:'0px',height:'230px',background:'#f9f9f9',padding:6}}>{this.state.concept}</h5>
                      <div id="bancomer-iframe"></div>
                       </div>
                   </div>
                </main>
        )
        
        }else if(this.state.bank == 'bbva2'){

           return (
           
                <main>
                   <Section origin="payment" />
                   <div className="d-flex justify-content-center mb-5 scrollhere">

                      <div className="mb-5 col-md-6">
                      <h2>{this.state.errorMessage}</h2>
                      <h5 style={{width:'100%',position:'absolute',top:'30px',background:'white',padding:6}}>{this.state.concept}</h5>
                      <div id="bancomer-iframe"></div>
                       </div>
                   </div>
                </main>
        )
        
        }

        
       else if(this.state.bank == 'stripe') 
        {
          return (
           
                <main>
                   <Section origin="payment" />
                   <div className="d-flex justify-content-center mb-5">
                      <div className="mb-5 col-md-6">
                         <StripesProcessor
                            Amount={false}
                            translate={this.t.bind(this)}
                            Currency={''}
                            params={this.state.stripepaymentparams}
                            aprovedpayment={this.aprovedpayment.bind(this)}
                            this={this}
                            />
                      </div>
                   </div>
                </main>
        )

      }else if(this.state.bank == 'worldpay'){
        
          return(<div class="d-block">
            <Section origin="payment" />
            
            {this.state.worldpaypaymentSuccess?
            <div className="d-block justify-content-center text-center mb-5 p-5">
              <h3>{this.state.worldPaySuccessMessage}</h3>
            </div>
            :
            <div>
              {this.state.worldPayErrorMessage != ""?
              <div className="d-block justify-content-center text-center mb-5 p-5">
                <h3>{this.state.worldPayErrorMessage}</h3>
              </div>
              :
              <div className="d-block justify-content-center mb-5 p-5">
                <h3>Total: <strong>{this.state.wordlPaytotalstr}</strong></h3>
                <CreditCards
                  translate={this.t.bind(this)}
                  onChange={this.creditCardChange.bind(this, 0)}
                  issuer={this.setCreditCardIssuer.bind(this, 0)}
                  disableFields={[]}
                />
                <br/>
                    {this.state.processing == true?
                    <div>
                            <h5>
                              {" "}
                              {this.spinner(
                                this.t(
                                  "We are processing your request, please wait..."
                                )
                              )}
                            </h5>
                    </div>
                    :<button
                      disabled={this.state.messagesWorldPay.length > 0?true:false}
                      style={                
                        { display: "block" }                
                      }
                      className="btn checkout_popc"
                      onClick={this.submitWoldPaypayment.bind(this)}
                    >
                      {" "}
                      {/*paymentprocessorid == 3 ? this.t("Continue"):this.t("Submit Payment")*/}
                      {this.t("Submit Payment")}
                    </button>}
                <br/>
                <ErrorMessages messages={this.state.messagesWorldPay} />  
              </div>
              }
            </div>
            }
            <br/>        
          </div>);
        
      }else{

        return (<h5>
                            {" "}
                            {this.spinner(
                              this.t(
                                "We are processing your request, please wait..."
                              )
                            )}
                          </h5>
                          )
      }
    }
}

export default Payment