import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
//if (typeof window === "undefined")  var window={}
const $ = window.$;
class paymentprocessor extends Global {

    constructor(props) {
        super(props);


        this.state = {
            url:this.props.url
        };
    }

    componentDidMount() {

       
        
    }

    componentWillReceiveProps(nextProps) {

        if(this.ismobile()) $('#payment_bancomer').attr('style','height:1850px');
        else $('#payment_bancomer').attr('style','height:1550px');

        console.log('nextProps.url',nextProps.url)
        console.log('this.props.url',this.props.url)

        
        if(nextProps.url != this.props.url) this.setState({url:nextProps.url})
    }

    onload(){


     this.scrolltop(".mfp-container")

    

    }

   
    render() {

        if(!this.state.url) return null;

        console.log('this.state.url',this.state.url);

        return (

            <iframe 
               onLoad={this.onload.bind(this)}
               src={this.state.url}
               frameBorder="0" 
               scrolling="no"
               seamless="seamless" 
               className="embed-responsive_"
               />


        )
    }
}

export default withRouter(paymentprocessor)