import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Global from '../globals/global';
import ReactResizeDetector from 'react-resize-detector';


import BancomerEndPoint from '../pages/page.end.point.bancomer';
import MitEndPoint from '../pages/page.end.point.mit';

import TravelAgentMessage from '../pages/page.travel.agent.message';

import Payment from '../pages/page.payment';

import Home from "../pages/page.home";
import Tours from "../pages/page.tours";
import ToursAvailability from "../pages/page.tours.availability";
import Cart from "../pages/page.cart";
import Contact from "../pages/page.contact";
import Error404 from "../pages/page.404";
import Header from "../partials/partial.header";
import Footer from "../partials/partial.footer.ids";

import Transfers from '../pages/page.transfers';

import Checkout from "../pages/page.checkout";

import TransferPrices from '../pages/page.transfers.prices';
import PurchaseHistoryDetail from '../pages/page.purchase.history.detail';
import PurchaseHistoryDetailCart from '../pages/page.purchase.history.detail.cart';
import Specials from '../pages/page.specials';
import TrendingReviews from '../pages/page.trending.reviews';
import AboutUs from '../pages/page.about.us';
import Privacy from '../pages/page.privacy';
import TourDetails from '../pages/page.tour.details';
import TermsConditions from '../pages/page.terms';

import Cookies from '../pages/page.cookies';

/*Embajador Toorzy*/
import Ambassador from '../pages/page.ambassador';

import HomeLanding from '../pages/page.homelanding';

import WishList from '../pages/page.wishlist';
import Dining from '../pages/page.dining';
import Shopping from '../pages/page.shopping';

import Login from '../pages/page.login';

import MyAccount from '../pages/page.my.account';
import ShopDetails from '../pages/page.shop.details';

const $ = window.$;

class Routes extends Global {

  redirect(Page_) {
    return (<Page_ />); //Cookies.get('loggedin') == 'true'? (<Page_ />) : (<Error /> );  // <Redirect to="/dashboard"/>
  }

  constructor(props) {
    super(props);

    this.state ={
        tranlation:false
      }


  }

  componentWillMount(){

    this.initialization(); //set default language, currency, destination, location
    
  }

  
  componentDidMount() {

    this.initializationdidmount();

  }

  componentWillReceiveProps(nextProps) { 

   
   //este funciona igual que this.props.history.listen

  }  


  toursredirect(Page_){
    return  (<Redirect to='/tours/1' /> );  
  }

 

  loginredirect(Page_) {
    return this.getglobaldata('user') ? (<Page_ />) : (<Redirect to='/login' />);
  }

  homeredirect(Page_) {
    return !this.getglobaldata('user') ? (<Page_ />) : (<Redirect to='/' />);
  }

  
  tourdetailsredirect(){
    return (<Redirect to="/tourdetails/:tourname/:tourid" />)
  }

  testting_(){

    return {test:'1'}
    console.log('testting_.............')
  }

   onResize(height){

     this.iframecontrol({height:height});

     return(<div></div>)
  }


  router(){

    var Home_ = this.config().brand == 'toorzy'?Ambassador:Home;

    if(this.config().enablehome2) Home_ = HomeLanding;

    return (

      <Switch >
          <Route path="/" exact component={Home_}/>
          <Route path="/home" exact component={Home_}/>

          <Route path="/tours/availability/:tourid/:action/:itemindex" component={ToursAvailability} />
          <Route path="/tours/availability/:tourid/:action" component={ToursAvailability} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />


           <Route path="/search/transfers/:destinationid/:hotelid" component={Transfers} />
          <Route path="/transfers/prices/:id_transfer/:id_transfer_prices/:id_airport_arrival/:id_airport_departure/:type/:itemindex" component={TransferPrices} />
          <Route path="/transfers/prices/:id_transfer/:id_transfer_prices/:id_airport_arrival/:id_airport_departure/:type" component={TransferPrices} />

          <Route path="/transfers" component={Transfers} />
          
          {/********************************************* */}
          <Route path="/tours/:destination/:destinationid/:locationid/:category/:page" component={Tours} />
          <Route path="/tours/:destination/:destinationid/:category/:page" component={Tours} />
          {/********************************************* */}

          <Route path="/tours/:destination/:destinationid/:locationid/:page" component={Tours} />
          <Route path="/tours/:destination/:destinationid/:locationid/:page" component={Tours} />          
          <Route path="/tours/:destination/:destinationid/:page" component={Tours} />
          
          <Route path="/tours/:destination/:destinationid" component={Tours} />
          <Route path="/tours/:page" render={() => (this.toursdestinationredirect())} />

          <Route path="/trendingrev" component={TrendingReviews} />

          <Route path="/specials" component={Specials} />
          
          <Route path="/:hashtag/tourdetails/:tourname/:tourid" component={TourDetails} />
          <Route path="/tourdetails/:tourname/:tourid" component={TourDetails} />
          
          <Route path="/tours" component={Tours} />
          <Route path="/contact" component={Contact} />
          <Route path="/wishlist" component={WishList} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={TermsConditions} />

          <Route path="/ambassador/:type" component={Ambassador} />
          <Route path="/ambassador" component={Ambassador} />
          
          <Route path="/homelanding" component={HomeLanding} />

          <Route path="/cookies" component={Cookies} />

          <Route path="/payment/:bank/:Id_APIPayment" component={Payment} />
          
          <Route path="/login" render={() => (this.homeredirect(Login))} />
          <Route path="/myaccount/:payment" component={MyAccount} />
          <Route path="/myaccount" render={() => (this.loginredirect(MyAccount))} />
          <Route path="/purchase/detail/cart/payment" component={PurchaseHistoryDetailCart} />
          <Route path="/purchase/detail/:idtransaction" component={PurchaseHistoryDetail} />
          
          <Route path="/mitmessage/:result" component={MitEndPoint} />
          <Route path="/bancomermessage/:result" component={BancomerEndPoint} />
          <Route path="/travelagentmessage" component={TravelAgentMessage} />
        

          <Route component={Error404} />
        </Switch>

    )
  }

 
 

  render() {

    if(!this.state.tranlation) return null;

    return (
      <div>

       {this.config().header?<Header />:''}

         {this.router()}
 
        
         {this.config().footer?<Footer />:''}

         <ReactResizeDetector handleWidth handleHeight>
            {({ width, height }) => this.onResize(height)}
           </ReactResizeDetector>
       
        
      </div>
    );
  }
};


export default withRouter(Routes)

