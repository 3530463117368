import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class traficprivacy extends Global {
    render() {
       if(this.getglobaldata('Id_Language') == 2)
  return ( 
      

<div className="row">
            <div className="col-lg-12">

              <h3 className="blue_text"><strong>AVISO DE PRIVACIDAD INTEGRAL</strong> </h3>                         
            </div>



          <div className="col-lg-12">
                          
<p>La persona jur&iacute;dica <strong>Trafictours de M&eacute;xico SA de CV</strong>, comercialmente conocida como TRAFICTOURS, con domicilio en Avenida Acanceh&nbsp; lote 3 manzana 2 local 203 Supermanzana 11, Municipio de Benito Ju&aacute;rez, Canc&uacute;n, Quintana Roo, M&eacute;xico, CP 77504, , sitio web: <a href="http://www.trafictours.com">www.trafictours.com</a> es responsable del uso y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>

<h2 className="terms_title mt-15">1. ¿Para qué fines utilizaremos sus datos personales?</h2>

<p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades primarias que son necesarias para el servicio que solicita:</p>

  <p><strong>a) En su calidad de cliente, proveedor o empleado de TraficTours de M&eacute;xico, S.A. de C.V.:</strong></p>


<ul>
<li>Para su identificaci&oacute;n, verificaci&oacute;n y contacto.</li>
<li>Para su seguridad durante su permanencia en las instalaciones de TraficTours, establecemos medidas de seguridad y vigilancia de las instalaciones, entre ellas el monitoreo, videoc&aacute;maras, control y vigilancia de acceso f&iacute;sico y l&oacute;gico.</li>
<li>Para su atenci&oacute;n en caso de incidentes de salud o de seguridad.</li>
<li>Contrataci&oacute;n, administraci&oacute;n, operaci&oacute;n y reservaci&oacute;n de servicios contratados con nosotros que pueden referirse a la comercializaci&oacute;n de servicios de hospedaje en plan europeo o todo incluido, paquetes tur&iacute;sticos en territorio nacional o en el extranjero, spa, alimentos y bebidas, excursiones, transporte terrestre de turistas y su coordinaci&oacute;n, organizaci&oacute;n de bodas, congresos o convenciones y otras amenidades o servicios tur&iacute;sticos complementarios.</li>
<li>Gestionar la relaci&oacute;n de proveedor con usted y administrar o procesar las cuentas por cobrar o por pagar que de tiempo en tiempo se generen.</li>
<li>Cualquier otra forma de tratamiento de datos derivado de su calidad cliente en nuestras p&aacute;ginas web, tales como facturaci&oacute;n, procesamiento de pedidos e integraci&oacute;n de expedientes administrativos como soporte documental o de otra &iacute;ndole respecto de la relaci&oacute;n comercial existente con usted.</li>
</ul>


<p><strong>b) Particularmente en su calidad de <u>empleado o candidato</u> a obtener un empleo con TraficTours de M&eacute;xico, S.A. de C.V., aplicar&aacute;n adem&aacute;s las siguientes finalidades primarias:</strong></p>


<ul>
<li>Integraci&oacute;n de su expediente de empleado.</li>
<li>Para procesos de contrataci&oacute;n y/o promoci&oacute;n laboral.</li>
<li>Para la realizaci&oacute;n de estudios socioecon&oacute;micos y psicom&eacute;tricos.</li>
<li>Para el otorgamiento de prestaciones laborales.</li>
<li>Para realizar encuestas y/o estudios que permitan conocer el clima laboral en el centro de trabajo, el nivel de satisfacci&oacute;n del personal, as&iacute; como identificar con fines de prevenci&oacute;n, los posibles factores de riesgo psicosocial.</li>
<li>Para la realizaci&oacute;n de ofertas de empleo y oportunidades laborales en los establecimientos a los que la empresa presta sus servicios.</li>
<li>Para el pago de los salarios y dem&aacute;s prestaciones establecidas en la Ley Federal del Trabajo y contratos individuales o colectivos de trabajo.</li>
<li>Para el tr&aacute;mite de las altas, modificaciones, entero de descuentos o bajas correspondientes en el Instituto Mexicano de Seguridad Social, Instituto del Fondo Nacional para el Consumo de los Trabajadores, Instituto del Fondo Nacional para la Vivienda de los Trabajadores y contrataci&oacute;n de seguros de gastos m&eacute;dicos particulares a favor de sus empleados.</li>
<li>Dar aviso a sus familiares y contactos en caso de emergencia y para atender adecuadamente la misma con la intervenci&oacute;n de profesionales de la salud o personal de primeros auxilios.</li>
<li>Tr&aacute;mite y solicitud de tarjetas de d&eacute;bito o cr&eacute;dito ante instituciones bancarias, para el pago de n&oacute;mina o para el pago de gastos de viaje y oficina.</li>
<li>Establecimiento, administraci&oacute;n y operaci&oacute;n de cajas de ahorro donde participen los empleados de Trafictours de M&eacute;xico, S.A. de C.V.</li>
</ul>


<p><strong>c) Particularmente, en su calidad de Proveedor de Trafictours de M&eacute;xico, S.A. de C.V., aplicar&aacute;n adem&aacute;s las siguientes finalidades primarias:</strong></p>


<ul>
<li>Para la elaboraci&oacute;n de contratos de prestaci&oacute;n de servicios.</li>
<li>Para el cumplimiento de obligaciones contractuales.</li>
<li>Para los procesos de pago, recepci&oacute;n y/o devoluci&oacute;n de bienes y servicios adquiridos.</li>
<li>Para el procesamiento de pedidos e integraci&oacute;n de expedientes administrativos como soporte documental o de otra &iacute;ndole respecto de la relaci&oacute;n comercial existente con usted.</li>
</ul>


<p>De manera adicional, utilizaremos su informaci&oacute;n personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atenci&oacute;n:</p>
<ul>
<li>Mercadotecnia o publicitaria.</li>
<li>Prospecci&oacute;n comercial.</li>
<li>Control de calidad.</li>
<li>Definici&oacute;n de comportamientos de consumo.</li>
<li>Realizaci&oacute;n de campa&ntilde;as de retroalimentaci&oacute;n y actualizaci&oacute;n de datos.</li>
</ul>
<p><strong>En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, le pedimos que nos lo haga saber utilizando la opci&oacute;n que resulte aplicable:</strong></p>
<ul>
<li>En caso de que proporcione sus datos de forma directa, h&aacute;galo saber en ese mismo momento a nuestro colaborador y solicite que le proporcione el formato correspondiente para documentar su negativa.</li>
<li>En caso de que proporcione sus datos utilizando nuestra p&aacute;gina de internet o una aplicaci&oacute;n m&oacute;vil, deber&aacute; enviar un mensaje de correo electr&oacute;nico a la direcci&oacute;n&nbsp;<a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>.</li>
</ul>


<p>La negativa para el uso de sus datos personales para estas finalidades secundarias <strong>no </strong>podr&aacute; ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>

<h2 className="terms_title mt-15">2. ¿Qu&eacute; datos personales utilizaremos para estos fines?</h2>

<p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>

<ol type="a">
<li><b>Datos de identificación:</b> Nombre, apellidos, firma, identificación con fotografía, Clave Única del Registro de Población (CURP). </li>
<li><b>Datos generales:</b> Domicilio completo, sea particular o de trabajo, dirección de correo electrónico personal o de trabajo; número telefónico particular o del trabajo; número de teléfono celular, ID Nextel (en su caso) y número de fax; fecha de nacimiento; edad, estado civil y nacionalidad.</li>
<li><b>Datos fiscales:</b> Clave de Registro Federal de Contribuyentes (RFC), así como domicilio fiscal y régimen fiscal (en su caso).</li>
<li><b>Datos patrimoniales:</b> vinculados con cuentas bancarias, tarjetas de crédito y/o débito, nombre del titular de la cuenta, número de cuenta y banco de origen, CLABE (en su caso), número de la tarjeta; fecha de vencimiento de la tarjeta, tipo de tarjeta y código de seguridad. Todos estos datos se almacenarán con su consentimiento expreso y en caso de que nos haga saber su oposición podríamos vernos impedidos para proveer los servicios contratados en virtud de que podría no ser posible procesar el pago correspondiente.</li>
<li><b>Datos personales sensibles:</b> salvo para los servicios médicos y expedientes laborales, Trafictours de México, S.A. de C.V., no recolecta ni trata datos personales sensibles. </li>
Sin embargo, debe tener en cuenta que algunos proveedores de tours o productos y algunos de nuestros afiliados y distribuidores podrían necesitar datos personales adicionales u otro tipo de información (como información sobre la actividad física o la salud) para determinar si puede participar en una actividad o no. En dado caso, ésta información queda sujeta al Aviso de Privacidad del proveedor o distribuidor, y no es recopilada por TraficTours de México, S.A. de C.V.
<li><b>Datos personales de fuentes de acceso público y recibido vía transferencias consentidas:</b> provenientes de registros públicos, directorios, redes sociales y otras fuentes lícitas disponibles en el mercado, así como datos personales que nos hayan sido compartidos por terceros a los cuales hubiese autorizado previamente.</li>
<li><b>Datos personales provenientes de redes sociales:</b> TRAFICTOURS podrá recolectar y procesar datos personales e información que haya compartido en las redes sociales pertenecientes a TRAFICTOURS (tales como Facebook®, Instagram ®, Pinterest ®, Foursquare ®, Google ® y Twitter®, entre otras), particularmente aquella que hayas compartido en el perfil de TRAFICTOURS en dichas redes sociales o en el perfil de otras empresas relacionadas a TRAFICTOURS, incluyendo la información que hayas compartido con carácter de “público/a”, según lo dispuesto por los términos y condiciones de uso y las políticas y declaraciones de los mismos.</li>
<li><b>Datos personales provenientes de agencias de viajes:</b> Trafictours de México, S.A. de C.V., podría recibir sus datos personales cuando usted realice su reservación de excursiones, traslados terrestres, así como la contratación de otros servicios complementarios que de tiempo en tiempo contrate por nuestro conducto; ya sea a través de alguna agencia de viajes minorista o mayorista con domicilio en territorio nacional o en el extranjero, como también otras plataformas que comercializan los servicios de Trafictours, (páginas de internet, programas vacacionales de aerolíneas, club de viajes, programas de beneficios y recompensas, y otras similares conocidas o que se conozcan).</li>
<li><b>Datos personales provenientes de empresas filiales o subsidiarias</b> con las que Usted ya tenga una relación contractual y a la cual haya manifestado su consentimiento para que fuesen transferidas a Trafictours de México, S.A. de C.V.</li>
<li><b>Datos personales provenientes de distribuidores:</b>  aquellos que gestionan el alojamiento, la compra y el pago de los productos y servicios de nuestros proveedores. </li>
<li><b>Información de ubicación y otros datos de los dispositivos:</b> Cuando utilice el sitio web de trafictours.com, mediante un dispositivo móvil, recopilaremos y utilizaremos la información sobre usted, en general, de la misma manera y para los mismos propósitos que están activos en su navegador web. Además, si contamos con su consentimiento, podemos recopilar información sobre su ubicación si ha configurado su dispositivo para enviar dicha información al sitio web de trafictours.com, mediante los ajustes de privacidad del dispositivo, o si ha subido fotos etiquetadas con información de ubicación. Por ejemplo, podremos utilizar su ubicación para mostrarle tours o excursiones que se organicen cerca de usted cuando viaje. Usted puede cambiar los ajustes de privacidad de su dispositivo en cualquier momento con el fin de desactivar la funcionalidad para compartir información de ubicación con la aplicación y/o la funcionalidad de etiquetar sus fotos con información de ubicación. Si tiene alguna duda acerca de la configuración de privacidad de su dispositivo, le sugerimos que se ponga en contacto con el fabricante del Dispositivo o con su proveedor de servicios móviles para obtener ayuda.</li>
<li><b>Recopilación automática de información:</b> Recopilamos automáticamente cierta información de su ordenador o dispositivo móvil cuando visita trafictours.com, o cualquiera de las páginas web de nuestros afiliados alojadas por nosotros. Por ejemplo, recopilamos su dirección IP, el software de su navegador web y el sitio web de referencia. Para ayudarnos a analizar el uso de nuestros sitios web y servicios relacionados, y para enviarle comunicaciones más pertinentes, también recopilamos y mantenemos un seguimiento de su actividad en línea, tales como el contenido que consulta, las páginas que visita y las búsquedas y reservas que le proporcionamos y realiza. Para conocer más, lo invitamos a consultar nuestra <NavLink to="/cookies">Política de Cookies.</NavLink></li>

</ol>

<p>Los datos personales descritos en los incisos precitados del presente apartado, se recolectan mediante: i) el uso de formatos o documentos impresos; ii) el uso de correos electr&oacute;nicos y/o iii) el suministro voluntario de informaci&oacute;n y datos personales cuando usted se hospeda en nuestras instalaciones, cuando se comunica v&iacute;a telef&oacute;nica con nosotros o con nuestros colaboradores (incluyendo el &aacute;rea de atenci&oacute;n a clientes); iv) v&iacute;a nuestro sitio Web trafictours.com y servicios de chat, as&iacute; como v) la utilizaci&oacute;n de fuentes de acceso p&uacute;blico y de otras fuentes disponibles en el mercado, vi) v&iacute;a nuestros clientes afiliados o distribuidores, y vii) los datos que usted haya compartido en los perfiles de redes sociales de TraficTours.</p>

<h2 className="terms_title mt-15">3. <strong>&iquest;C&oacute;mo protegemos los datos personales?</strong></h2>

<p>Los datos personales recolectados por TRAFICTOURS se encuentran protegidos por medidas de seguridad, f&iacute;sicas, administrativas y t&eacute;cnicas adecuadas contra el da&ntilde;o, p&eacute;rdida, alteraci&oacute;n, destrucci&oacute;n, uso, acceso o tratamiento no autorizado, de conformidad con lo dispuesto en la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de Particulares (&ldquo;LFPDPPP&rdquo;) y su Reglamento. Para conocer m&aacute;s sobre nuestras medidas de seguridad le pedimos nos contacte a <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>.</p>
<p>Nos esforzamos por almacenar sus datos personales en entornos operativos seguros que no est&aacute;n accesibles al p&uacute;blico en general, y por aplicar medidas de seguridad en nuestras instalaciones f&iacute;sicas para proteger contra la p&eacute;rdida, el uso indebido o la alteraci&oacute;n de sus datos personales por parte de nuestros empleados o de terceros.</p>
<p>Sin embargo, no es posible garantizar al 100&nbsp;% la seguridad de ninguna transmisi&oacute;n de datos a trav&eacute;s de Internet ni de ning&uacute;n entorno de almacenamiento de datos, de manera que no podemos asegurarle en t&eacute;rminos absolutos que los datos personales que nos proporciona est&eacute;n protegidos en todo momento. Trafictours de M&eacute;xico, S.A. de C.V., conf&iacute;a en que nos informe si experimenta cualquier hecho inusual que pueda indicar una vulneraci&oacute;n de la seguridad de su informaci&oacute;n, del mismo modo, usted ser&aacute; notificado en caso de alg&uacute;n incidente o vulnerabilidad detectado por Trafictours, en los plazos y medios establecidos, que la normatividad aplicable designe. En ese caso, nos esforzaremos por investigar si este hecho estaba relacionado con la transmisi&oacute;n de datos cuyo origen o destino sea trafictours.com, y le informaremos sobre las medidas que puede adoptar y que se han adoptado para ayudar a corregir el problema.</p>

<h2 className="terms_title mt-15">4. <strong>Informaci&oacute;n relativa a menores de edad:</strong></h2>

<p>Trafictours.com, son sitios para el p&uacute;blico en general, y no ofrece servicios dirigidos a menores.</p>
<p>A excepci&oacute;n de los convenios acad&eacute;micos celebrados con instituciones de nivel medio superior y superior, as&iacute; como aquellos programas de becarios, impulsados por la autoridad competente, con el objetivo de brindar la oportunidad de que los alumnos efect&uacute;en pr&aacute;cticas profesionales en nuestra empresa, Trafictours de M&eacute;xico, S.A. de C.V. no ofrece servicios dirigidos a menores.</p>
<p>En caso de que Trafictours de M&eacute;xico, S.A. de C.V., sea informado, de que una persona menor de 13 a&ntilde;os nos ha enviado informaci&oacute;n personal, eliminaremos o destruiremos dicha informaci&oacute;n tan pronto como sea razonablemente posible.</p>
<p>Si usted conoce a un menor que est&aacute; inscrito al sitio web de trafictours.com, por favor inf&oacute;rmenos v&iacute;a correo electr&oacute;nico a <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>, a fin de eliminar su informaci&oacute;n.</p>


<h2 className="terms_title mt-15">5. <strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></h2>


<p>Le informamos que sus datos personales pueden ser transferidos a terceros dentro y fuera del territorio nacional en caso de que sea necesario para llevar a cabo las finalidades consignadas en el presente aviso de privacidad.</p>
<p>Tambi&eacute;n le informamos que Trafictours de Mexico, S.A. de C.V., es parte de un grupo empresarial conocido comercialmente como &ldquo;TRAFICTOURS&rdquo; y puede que transfiera sus datos personales con otras compa&ntilde;&iacute;as nacionales e internacionales, del mismo grupo, en t&eacute;rminos de lo establecido en el Art&iacute;culo 37 de la LFPDPPP respecto a las partes relacionadas. Las compa&ntilde;&iacute;as que integran el grupo son:</p>
<ul>
<li>Trafictours Canada, Inc.</li>
<li>DMC Leisure, S.A. de C.V.</li>
<li>TTDR Travel Company, SAS</li>
<li>Turissimo Caribe Excursiones Dominican Republic, SA</li>
<li>CTI Logistics, Inc.</li>
<li>Sun Excursions Caribbean, Inc.</li>
<li>Turissimo Jamaica Limited.</li>
</ul>
<p>Tambi&eacute;n compartiremos sus datos personales con terceros independientes con los que requerimos interactuar para llevar a cabo las finalidades aqu&iacute; establecidas, por ejemplo:</p>
<ul>
<li><strong>Proveedores de servicios:</strong> Algunos de los datos personales que recopilamos se comparten con nuestros proveedores de servicios (por ejemplo, operadores de tours o servicios de transportaci&oacute;n), quienes est&aacute;n implicados directamente en llevar a cabo las compras que realiza a trav&eacute;s de nosotros. Solo le proporcionamos a nuestros proveedores de servicios la informaci&oacute;n necesaria (por ejemplo, el nombre del hotel para que le recojan o le lleven, su n&uacute;mero de tel&eacute;fono local, su n&uacute;mero de tel&eacute;fono m&oacute;vil, su direcci&oacute;n de correo electr&oacute;nico, fecha de llegada y fecha de salida). Le recomendamos que lea la pol&iacute;tica de privacidad del proveedor de servicios para determinar c&oacute;mo este podr&aacute; usar o divulgar sus datos personales.</li>
<li><strong>Sitios web remitentes:</strong> Si se le remiti&oacute; a trafictours.com, desde otro sitio web, podemos compartir con dicho sitio web remitente su informaci&oacute;n de registro, como por ejemplo su nombre, su direcci&oacute;n de correo electr&oacute;nico, su direcci&oacute;n postal, su n&uacute;mero de tel&eacute;fono y sus preferencias de viaje. No hemos limitado el uso que los sitios web remitentes hacen de sus datos personales, y le recomendamos que revise las pol&iacute;ticas de privacidad de cualquier sitio web que le haya remitido a trafictours.com</li>
<li><strong>Proveedores terceros de marketing y publicidad:</strong> Tambi&eacute;n compartimos sus datos personales con proveedores terceros que ofrecen servicios o funciones en nuestro nombre, como por ejemplo el an&aacute;lisis comercial, el procesamiento de pagos, el servicio de atenci&oacute;n al cliente, el marketing, las relaciones p&uacute;blicas, la distribuci&oacute;n de encuestas o programas de sorteos y la prevenci&oacute;n del fraude. Adem&aacute;s, es posible que autoricemos a proveedores terceros a recopilar datos personales en nuestro nombre, lo que incluye, por ejemplo, aquellos que sean necesarios para manejar las funciones de nuestros sitios web o Aplicaciones, o para proporcionar la distribuci&oacute;n de publicidad en l&iacute;nea adaptada a sus intereses. Los proveedores terceros tienen acceso y pueden recopilar datos personales &uacute;nicamente cuando sea necesario para llevar a cabo sus funciones, y no pueden compartir o usar datos personales con ning&uacute;n otro prop&oacute;sito. Actualmente, contratamos a diferentes socios terceros online para que nos ayuden a gestionar y mejorar nuestras comunicaciones y nuestra actividad de Internet. Utilizamos los servicios de empresas de marketing terceras para ayudarnos a medir la eficacia de nuestra publicidad y saber c&oacute;mo utilizan nuestros visitantes nuestro sitio web.</li>
<li><strong>Terceros independientes: </strong>especialmente incluyen instituciones bancarias que procesan sus pagos, as&iacute; como a los proveedores finales de los servicios o bienes que usted adquiere mediante la intermediaci&oacute;n tur&iacute;stica que realiza TRAFIC TOURS.&nbsp; &Eacute;sta transferencia siempre ser&aacute; opcional y usted podr&aacute; oponerse en cualquier momento, sin embargo, esto podr&iacute;a impedir que TRAFIC TOURS act&uacute;e como intermediario y gestione la reservaci&oacute;n necesaria para el otorgamiento de los bienes o servicios que usted adquiera. Algunas de esas transferencias son opcionales, le pedimos indique con cu&aacute;les no desea que compartamos su informaci&oacute;n:</li>
</ul>

                                     <div className="table-responsive">
                                            <table className="table table-striped">
                                            <thead className="table_privacy_title">
                                                <tr>
                                                    <th scope="col">Tercero</th>
                                                    <th scope="col">País</th>
                                                    <th scope="col">Finalidad</th>
                                                    <th scope="col">Carácter</th>
                                                    <th scope="col" className="text-left">Tipo de Transferencia</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">Instituciones bancarias</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Procesamiento de Pagos</td>
                                                    <td className="align-middle">Opcional</td>
                                                    <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td className="align-middle">Terceros Privados</td>
                                                    <td className="align-middle">México o en el extranjero.</td>
                                                    <td className="align-middle">Proporcionar los servicios que usted contrate mediante la intermediación turística que realiza TRAFIC TOURS.</td>
                                                    <td>
                                                        Obligatoria
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Proveedores de Tours y excursiones</td>
                                                    <td className="align-middle">Internacional</td>
                                                    <td className="align-middle">Proveedores de Tours y excursiones</td>
                                                    <td>
                                                    Voluntaria 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Proveedores de servicios de Traslados terrestres</td>
                                                    <td className="align-middle">Internacional</td>
                                                    <td className="align-middle">Proveedores de servicios de Traslados terrestres</td>
                                                    <td>
                                                    Voluntaria 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Empresas encargadas de administración de cajas de ahorro y préstamos sobre nómina</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Apertura de cajas de ahorro y otorgamiento de créditos y préstamos sobre nómina.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Empresas encargadas del reclutamiento y selección de personal.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Coadyuvar con terceros mediante la recomendación de candidatos para vacantes.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>
    
                                                <tr>
                                                    <td className="align-middle">Empresas prestadoras de servicios especializados o compartidos.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Prestación de servicios especializados.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td className="align-middle">Empresas de análisis y estudios laborales.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Para efectuar  estudios relacionados con beneficios para el personal.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Instituciones de fondo de pago electrónico (Vales de despensa)..</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Para el pago de los salarios y demás prestaciones establecidas en la Ley Federal del Trabajo y contratos individuales o colectivos de trabajo</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>




                                            </tbody>
                                            </table>
                                        </div>

                      <p>De igual manera le informamos que a fin de dar cumplimiento a la legislación vigente, Trafictours de México, S.A. de C.V., debe compartir sus datos personales a terceros independientes y dichas transferencias de datos NO son opcionales. Dichas transferencias son:</p>

                      <div className="table-responsive">
                                            <table className="table table-striped">
                                            <thead className="table_privacy_title">
                                                <tr>
                                                    <th scope="col">Tercero</th>
                                                    <th scope="col">País</th>
                                                    <th scope="col">Finalidad</th>
                                                    <th scope="col">Carácter</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">SAT y autoridades fiscales a nivel estatal</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Cumplimiento de disposiciones fiscales vigentes mediante la presentación de declaraciones periódicas</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Procuraduría Federal del Consumidor</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">La atención de procedimientos previstos en la Ley Federal de Protección al Consumidor</td>
                                                    <td className="align-middle">Obligatoria</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">INFONAVIT</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Afiliación al Instituto y demás acciones relacionadas con sus financiamientos</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">INFONACOT</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Afiliación al Instituto y demás acciones relacionadas con sus financiamientos</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">IMSS</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Cumplimiento de las obligaciones de previsión social establecidas en la Ley.</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>


                                                <tr>
                                                    <td className="align-middle">Autoridades en materia de trabajo y previsión social a nivel federal o estatal.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Cumplimiento de las obligaciones resultantes de la relación obrero-patronal.</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Autoridades Judiciales </td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Atención y cumplimiento a requerimientos por parte de las autoridades Judiciales</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>



                                            </tbody>
                                            </table>
                                        </div>

<p>Finalmente se&ntilde;alamos que transferiremos sus datos nacional o internacionalmente, en los supuestos contemplados por el <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LFPDPPP.pdf">Art&iacute;culo 37 de la </a><a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LFPDPPP.pdf">Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares</a>, vigente en M&eacute;xico, que establece expresamente la posibilidad de compartir sus datos con autoridades competentes y sociedades controladoras, subsidiarias o afiliadas bajo el control com&uacute;n del responsable, o a una sociedad matriz o a cualquier sociedad del mismo, en los siguientes supuestos:</p>
<ol type="I">
<li>Cuando la transferencia est&eacute; prevista en una Ley o Tratado en los que M&eacute;xico sea parte;</li>
<li>Cuando la transferencia sea necesaria para la prevenci&oacute;n o el diagn&oacute;stico m&eacute;dico, la prestaci&oacute;n de asistencia sanitaria, tratamiento m&eacute;dico o la gesti&oacute;n de servicios sanitarios;</li>
<li>Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control com&uacute;n del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo del responsable que opere bajo los mismos procesos y pol&iacute;ticas internas; IV. Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en inter&eacute;s del titular, por el responsable y un tercero;</li>
<li>Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un inter&eacute;s p&uacute;blico, o para la procuraci&oacute;n o administraci&oacute;n de justicia;</li>
<li>Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y</li>
<li>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relaci&oacute;n jur&iacute;dica entre el responsable y el titular</li>
</ol>


<h2 className="terms_title mt-15">6. <strong>Plazos de conservaci&oacute;n:</strong></h2>


<p>Trafictours de M&eacute;xico, S.A. de C.V., conservar&aacute; los datos personales por el tiempo que sea necesario para cumplir con los fines por los que haya recopilado la informaci&oacute;n, incluidos las finalidades para satisfacer cualquier requisito legal, contable y de prestaciones de informes, de acuerdo a lo establecido en el Art&iacute;culo 37, del Reglamento de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares, vigente en M&eacute;xico.</p>
<p>Con el prop&oacute;sito de determinar el per&iacute;odo de retenci&oacute;n apropiado, tenemos en cuenta la cantidad, la naturaleza y el grado de confidencialidad de los datos personales, el riesgo potencial de da&ntilde;os originados por el uso o divulgaci&oacute;n no autorizados de sus datos personales, los fines por los que procesamos dichos datos y si podemos alcanzar dichos fines mediante otros medios, as&iacute; como las disposiciones legales vigentes.</p>

<h2 className="terms_title mt-15">7. <strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?&nbsp;</strong></h2>

<p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n), o bien, revocar su consentimiento al uso o divulgaci&oacute;n de sus datos personales (Revocaci&oacute;n). Estos derechos se conocen como derechos ARCO.<br /> <br /> <strong>Con relaci&oacute;n al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:</strong></p>



<p><strong>a) &iquest;Qu&eacute; informaci&oacute;n, documentaci&oacute;n y requisitos deber&aacute; contener la solicitud?</strong></p>

<p>En cuanto a los requisitos generales que debe cubrir la solicitud, est&aacute;n los siguientes:</p>
<ul>
<li>Nombre del titular de los datos personales.</li>
<li>Documentos que acrediten la identidad del titular (pasaporte, credencial para votar expedida por el Instituto Nacional Electoral, c&eacute;dula profesional estatal o federal o licencia de manejo).</li>
<li>En su caso, nombre del representante del titular y documentos para acreditar su identidad y personalidad. Trat&aacute;ndose de apoderados de personas f&iacute;sicas, la representaci&oacute;n se acreditar&aacute; mediante las siguientes 3 opciones: a) instrumento p&uacute;blico (documento suscrito por parte de un Notario P&uacute;blico), b) carta poder firmada ante dos testigos (anexando copia simple de sus identificaciones oficiales), c) acudiendo usted y su representante a declarar en comparecencia del responsable presentando su identificaci&oacute;n oficial (pasaporte, credencial para votar expedida por el Instituto Nacional Electoral, c&eacute;dula profesional estatal o federal o licencia de manejo).</li>
<li>Domicilio o cualquier medio para recibir notificaciones.</li>
<li>Descripci&oacute;n clara y precisa de los datos personales y del derecho que se quiere ejercer o de lo que se solicita.</li>
<li>Los documentos o informaci&oacute;n que faciliten identificar la informaci&oacute;n materia de la solicitud (por ejemplo, n&uacute;mero de cup&oacute;n o reservaci&oacute;n, nombre completo, correo electr&oacute;nico registrado en sistema).</li>
</ul>

<p>As&iacute; mismo, en relaci&oacute;n al tipo de solicitud, requerimos por favor nos indique la siguiente informaci&oacute;n adicional, a fin de que su solicitud pueda ser atendida de forma m&aacute;s eficiente:</p>


<ul>
<li><strong>Solicitud de Acceso: </strong>Indicar la modalidad en la que usted prefiere que se reproduzcan los datos personales solicitados.</li>
<li><strong>Solicitud de Rectificaci&oacute;n: </strong>Especificar las modificaciones que se solicitan a los datos personales, as&iacute; como aportar los documentos que sustenten la solicitud.</li>
<li><strong>Solicitud de Cancelaci&oacute;n: </strong>Se&ntilde;alar las causas que motivan la petici&oacute;n de que se eliminen los datos de los archivos, registros o bases de datos del responsable.</li>
<li><strong>Solicitud de Oposici&oacute;n: </strong>Manifestar las causas o la situaci&oacute;n que llevan al titular a solicitar que concluya el tratamiento de sus datos personales, as&iacute; como el da&ntilde;o que le causar&iacute;a que dicho tratamiento contin&uacute;e. En el caso de que la solicitud se refiera a un tratamiento en lo particular, se deben indicar las finalidades espec&iacute;ficas respecto de las cuales se solicita el ejercicio del derecho.</li>
<li><strong>Revocaci&oacute;n del consentimiento:</strong> Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que, para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</li>
</ul>



<p><strong>b) &iquest;A trav&eacute;s de qu&eacute; medios pueden acreditar su identidad el titular y, en su caso, su representante, as&iacute; como la personalidad este &uacute;ltimo?</strong></p>

<p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; presentar la solicitud respectiva a trav&eacute;s del siguiente medio: Por correo electr&oacute;nico a la direcci&oacute;n privacidad@trafictours.com o tambi&eacute;n podr&aacute; contactar a nuestro Departamento de Datos Personales para que le d&eacute; tr&aacute;mite a tu solicitud, le proporcione orientaci&oacute;n y le facilite el formato correspondiente. El domicilio de nuestro Departamento de Datos Personales es Avenida Acanceh&nbsp; lote 3 manzana 2 local 203 Supermanzana 11, Municipio de Benito Ju&aacute;rez, Canc&uacute;n, Quintana Roo, M&eacute;xico, CP 77504.</p>
<p>El formato de atenci&oacute;n a solicitudes ARCO oficial para TraficTours puede solicitarlo en la direcci&oacute;n <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>, o descargarlo en el siguiente link.[//insertar]</p>


<p><strong>c) &iquest;En cu&aacute;ntos d&iacute;as le daremos respuesta a su solicitud?</strong></p>


<p>20 d&iacute;as h&aacute;biles (todos los d&iacute;as del a&ntilde;o, a excepci&oacute;n de los s&aacute;bados, domingos e inh&aacute;biles por disposici&oacute;n de Ley).</p>



<p><strong>d) &iquest;Por qu&eacute; medio le comunicaremos la respuesta a su solicitud?<br /> </strong>Por correo electr&oacute;nico o en los datos de contacto que usted nos proporcione en su solicitud.</p>




<p><strong>e) &iquest;En qu&eacute; medios se pueden reproducir los datos personales que, en su caso, solicite?</strong><br /> En la misma forma que hayan sido proporcionados por el cliente.</p>




<p><strong>f) Ponemos a sus &oacute;rdenes los siguientes formularios o sistemas para facilitar el ejercicio de derechos ARCO:</strong></p>


<p>Los formularios o sistemas correspondientes y orientaci&oacute;n sobre el ejercicio de los derechos ARCO se pueden obtener contactando a nuestro Departamento de Datos Personales en la direcci&oacute;n de correo electr&oacute;nico privacidad@marivalgroup.com y con domicilio f&iacute;sico en Paseo de los Cocoteros s/n, hotel Marival Resort &amp; Suites, fraccionamiento Nuevo Vallarta, municipio de Bah&iacute;a de Banderas, Nayarit, c&oacute;digo postal 63732, n&uacute;mero de tel&eacute;fono 322-2268200 (extensi&oacute;n 4493).</p>


<p><strong>g) &iquest;Se aplica alg&uacute;n costo por el ejercicio de mis derechos ARCO?</strong></p>


<p>El ejercicio de los derechos ARCO es gratuito. Sin embargo, usted puede ser requerido a cubrir &uacute;nicamente los gastos justificados de env&iacute;o o el costo de reproducci&oacute;n en copias u otros formatos. Del mismo modo, si la misma persona reitera su solicitud en un periodo menor a doce meses, los costos no ser&aacute;n mayores a tres d&iacute;as de Salario M&iacute;nimo General Vigente en el Distrito Federal, a menos que existan modificaciones sustanciales al aviso de privacidad que motiven nuevas consultas.</p>


<p><strong>h) &iquest;Qu&eacute; pasa si una solicitud es incompleta o no se anexan todos los datos que incluye el procedimiento?.</strong></p>


<p>En caso de que su solicitud no cuente con la informaci&oacute;n descrita en el apartado 4 Requisitos (incisos a y b); TRAFICTOURS podr&aacute; solicitar al titular que proporcione la informaci&oacute;n faltante, por medio de un escrito denominado &ldquo;prevenci&oacute;n&rdquo;, el cual se deber&aacute; emitir en un plazo de m&aacute;ximo 5 d&iacute;as h&aacute;biles contados a partir del d&iacute;a siguiente de la presentaci&oacute;n de la solicitud.</p>
<p>El titular contar&aacute; con 10 d&iacute;as h&aacute;biles, despu&eacute;s de recibir la &ldquo;prevenci&oacute;n&rdquo;, para proporcionar la informaci&oacute;n requerida, pues de lo contrario se tendr&aacute; como no presentada su solicitud.</p>
<p><strong>Para mayor informaci&oacute;n sobre el procedimiento, ponemos a disposici&oacute;n los siguientes medios: al correo electr&oacute;nico: </strong><a href="mailto:privacidad@trafictours.com"><strong>privacidad@trafictours.com</strong></a></p>
<p><strong>Los datos de contacto de la persona o departamento de datos personales, que est&aacute; a cargo de dar tr&aacute;mite a las solicitudes de derechos ARCO, son los siguientes:</strong><br /> <br /> </p>
<ul>
<li>Correo electr&oacute;nico: <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a></li>
<li>Contactando a nuestro departamento de protecci&oacute;n de datos, que est&aacute; a cargo de dar tr&aacute;mite a las solicitudes de derechos ARCO:
<ul>
<li>Nombre de la persona o departamento de datos personales: DEPARTAMENTO DE DATOS PERSONALES TRAFICTOURS.</li>
<li>Domicilio: Avenida Acanceh lote 3 manzana 2 local 203 Supermanzana 11, Municipio de Benito Ju&aacute;rez, Canc&uacute;n, Quintana Roo, M&eacute;xico, CP 77504</li>
<li>Correo electr&oacute;nico:&nbsp;<a href="mailto:PRIVACIDAD@MARIVALGROUP.COM">PRIVACIDAD@TRAFICTOURS.COM</a></li>
<li>N&uacute;mero telef&oacute;nico: 3222268200</li>
</ul>
</li>
</ul>



<h2 className="terms_title mt-15">8. <strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></h2>


<p>Con objeto de que usted pueda limitar el uso y divulgaci&oacute;n de su informaci&oacute;n personal, le ofrecemos los siguientes medios: el cliente puede limitar el uso o divulgaci&oacute;n de sus datos personales enviando un correo donde haga el planteamiento concreto a la direcci&oacute;n de correo electr&oacute;nico&nbsp;<a href="mailto:privacidad@marivalgroup.com">privacidad@trafictours.com</a></p>
<p>De manera adicional, le informamos que contamos con los siguientes listados de exclusi&oacute;n, en los cuales podr&aacute; registrarse o bien, des inscribirse en nuestras plataformas de env&iacute;os, para que sus datos personales no sean tratados para ciertos fines (principalmente secundarios):</p>
<p>De manera adicional, le informamos que contamos con los siguientes listados de exclusi&oacute;n, en los cuales podr&aacute; registrarse o bien, des inscribirse en nuestras plataformas de env&iacute;os, para que sus datos personales no sean tratados para ciertos fines (principalmente secundarios):</p>


                <div className="table-responsive">
                                      <table className="table table-striped">
                                            <thead className="table_privacy_title">
                                                <tr>
                                                    <th scope="col">Nombre del listado</th>
                                                    <th scope="col">Finalidad para las que aplica</th>
                                                    <th scope="col">Medio para obtener mayor información</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">MARKETING</td>
                                                    <td className="align-middle">MERCADOTECNIA O PUBLICIDAD</td>
                                                    <td className="align-middle">PROMOS@MARIVALGROUP.COM</td>
                                                </tr>




                                            </tbody>
                                            </table>
                 </div>

                                        <h2 className="terms_title mt-15">9. <strong>Sobre el uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></h2>


<p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina. Los datos personales que recabamos a trav&eacute;s de estas tecnolog&iacute;as, los utilizaremos para los siguientes fines:</p>
<p>Para monitorear y prevenir el uso indebido de las plataformas digitales; para mejorar nuestras herramientas digitales y actualizarlas conforme evoluciona la tecnolog&iacute;a; y para ofrecer contenido personalizado.</p>
<p>Los datos personales que obtenemos de estas tecnolog&iacute;as de rastreo son los siguientes:</p>
  <ul>
   <li>Identificadores, nombre de usuario y contrase&ntilde;as de una sesi&oacute;n.</li>
   <li>Idioma preferido por el usuario.</li>
   <li>Regi&oacute;n en la que se encuentra el usuario.</li>
   <li>Tipo de navegador del usuario.</li>
   <li>Tipo de sistema operativo del usuario.</li>
   <li>P&aacute;ginas web visitadas por un usuario.</li>
   <li>B&uacute;squedas realizadas por un usuario.</li>
   <li>Publicidad revisada por un usuario.</li>
  </ul>

<p>Para m&aacute;s informaci&oacute;n se sugiere consultar nuestra&nbsp;<NavLink to="/cookies">Pol&iacute;tica de Cookies</NavLink>:&nbsp;</p>
                                       
<h2 className="terms_title mt-15">10. <strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></h2>
                                       
<p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
<p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s de: env&iacute;o de correo electr&oacute;nico y en la p&aacute;gina de internet.</p>
<p>El procedimiento a trav&eacute;s del cual se llevar&aacute;n a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:</p>
<p>Los cambios o actualizaciones al aviso de privacidad se dar&aacute;n a conocer por los medios que se describen en esta pol&iacute;tica y surtir&aacute;n efectos 1 semana despu&eacute;s. En caso de dudas o comentarios los clientes deber&aacute;n dirigirse a <a href="mailto:PRIVACIDAD@MARIVALGROUP.COM">privacidad@trafictours.com</a></p>

<h2 className="terms_title mt-15">11. <strong>Consentimiento: Otorgo mi consentimiento para el uso de mis datos personales en conformidad con el Aviso de Privacidad Integral de Trafictours de M&eacute;xico, S.A. de C.V.:</strong></h2>

                <div className="table-responsive">
                                      <table className="table table-striped">

                                            <tbody>

                                                <tr>
                                                    <td className="align-middle">&nbsp;</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Nombre y firma del titular</td>
                                                </tr>




                                            </tbody>
                                            </table>
                 </div>

                 <h2 className="terms_title mt-15">12. Fecha de actualización:  15/07/2021</h2>


          </div>
       </div>

);

////////////////////////////////////////////SPANISH
else return (

<div className="row">
            <div className="col-lg-12">

              <h3 className="blue_text"><strong>AVISO DE PRIVACIDAD INTEGRAL</strong> </h3>                         
            </div>



          <div className="col-lg-12">
                          
<p>La persona jur&iacute;dica <strong>Trafictours de M&eacute;xico SA de CV</strong>, comercialmente conocida como TRAFICTOURS, con domicilio en Avenida Acanceh&nbsp; lote 3 manzana 2 local 203 Supermanzana 11, Municipio de Benito Ju&aacute;rez, Canc&uacute;n, Quintana Roo, M&eacute;xico, CP 77504, , sitio web: <a href="http://www.trafictours.com">www.trafictours.com</a> es responsable del uso y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:</p>

<h2 className="terms_title mt-15">1. ¿Para qué fines utilizaremos sus datos personales?</h2>

<p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades primarias que son necesarias para el servicio que solicita:</p>

  <p><strong>a) En su calidad de cliente, proveedor o empleado de TraficTours de M&eacute;xico, S.A. de C.V.:</strong></p>


<ul>
<li>Para su identificaci&oacute;n, verificaci&oacute;n y contacto.</li>
<li>Para su seguridad durante su permanencia en las instalaciones de TraficTours, establecemos medidas de seguridad y vigilancia de las instalaciones, entre ellas el monitoreo, videoc&aacute;maras, control y vigilancia de acceso f&iacute;sico y l&oacute;gico.</li>
<li>Para su atenci&oacute;n en caso de incidentes de salud o de seguridad.</li>
<li>Contrataci&oacute;n, administraci&oacute;n, operaci&oacute;n y reservaci&oacute;n de servicios contratados con nosotros que pueden referirse a la comercializaci&oacute;n de servicios de hospedaje en plan europeo o todo incluido, paquetes tur&iacute;sticos en territorio nacional o en el extranjero, spa, alimentos y bebidas, excursiones, transporte terrestre de turistas y su coordinaci&oacute;n, organizaci&oacute;n de bodas, congresos o convenciones y otras amenidades o servicios tur&iacute;sticos complementarios.</li>
<li>Gestionar la relaci&oacute;n de proveedor con usted y administrar o procesar las cuentas por cobrar o por pagar que de tiempo en tiempo se generen.</li>
<li>Cualquier otra forma de tratamiento de datos derivado de su calidad cliente en nuestras p&aacute;ginas web, tales como facturaci&oacute;n, procesamiento de pedidos e integraci&oacute;n de expedientes administrativos como soporte documental o de otra &iacute;ndole respecto de la relaci&oacute;n comercial existente con usted.</li>
</ul>


<p><strong>b) Particularmente en su calidad de <u>empleado o candidato</u> a obtener un empleo con TraficTours de M&eacute;xico, S.A. de C.V., aplicar&aacute;n adem&aacute;s las siguientes finalidades primarias:</strong></p>


<ul>
<li>Integraci&oacute;n de su expediente de empleado.</li>
<li>Para procesos de contrataci&oacute;n y/o promoci&oacute;n laboral.</li>
<li>Para la realizaci&oacute;n de estudios socioecon&oacute;micos y psicom&eacute;tricos.</li>
<li>Para el otorgamiento de prestaciones laborales.</li>
<li>Para realizar encuestas y/o estudios que permitan conocer el clima laboral en el centro de trabajo, el nivel de satisfacci&oacute;n del personal, as&iacute; como identificar con fines de prevenci&oacute;n, los posibles factores de riesgo psicosocial.</li>
<li>Para la realizaci&oacute;n de ofertas de empleo y oportunidades laborales en los establecimientos a los que la empresa presta sus servicios.</li>
<li>Para el pago de los salarios y dem&aacute;s prestaciones establecidas en la Ley Federal del Trabajo y contratos individuales o colectivos de trabajo.</li>
<li>Para el tr&aacute;mite de las altas, modificaciones, entero de descuentos o bajas correspondientes en el Instituto Mexicano de Seguridad Social, Instituto del Fondo Nacional para el Consumo de los Trabajadores, Instituto del Fondo Nacional para la Vivienda de los Trabajadores y contrataci&oacute;n de seguros de gastos m&eacute;dicos particulares a favor de sus empleados.</li>
<li>Dar aviso a sus familiares y contactos en caso de emergencia y para atender adecuadamente la misma con la intervenci&oacute;n de profesionales de la salud o personal de primeros auxilios.</li>
<li>Tr&aacute;mite y solicitud de tarjetas de d&eacute;bito o cr&eacute;dito ante instituciones bancarias, para el pago de n&oacute;mina o para el pago de gastos de viaje y oficina.</li>
<li>Establecimiento, administraci&oacute;n y operaci&oacute;n de cajas de ahorro donde participen los empleados de Trafictours de M&eacute;xico, S.A. de C.V.</li>
</ul>


<p><strong>c) Particularmente, en su calidad de Proveedor de Trafictours de M&eacute;xico, S.A. de C.V., aplicar&aacute;n adem&aacute;s las siguientes finalidades primarias:</strong></p>


<ul>
<li>Para la elaboraci&oacute;n de contratos de prestaci&oacute;n de servicios.</li>
<li>Para el cumplimiento de obligaciones contractuales.</li>
<li>Para los procesos de pago, recepci&oacute;n y/o devoluci&oacute;n de bienes y servicios adquiridos.</li>
<li>Para el procesamiento de pedidos e integraci&oacute;n de expedientes administrativos como soporte documental o de otra &iacute;ndole respecto de la relaci&oacute;n comercial existente con usted.</li>
</ul>


<p>De manera adicional, utilizaremos su informaci&oacute;n personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atenci&oacute;n:</p>
<ul>
<li>Mercadotecnia o publicitaria.</li>
<li>Prospecci&oacute;n comercial.</li>
<li>Control de calidad.</li>
<li>Definici&oacute;n de comportamientos de consumo.</li>
<li>Realizaci&oacute;n de campa&ntilde;as de retroalimentaci&oacute;n y actualizaci&oacute;n de datos.</li>
</ul>
<p><strong>En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, le pedimos que nos lo haga saber utilizando la opci&oacute;n que resulte aplicable:</strong></p>
<ul>
<li>En caso de que proporcione sus datos de forma directa, h&aacute;galo saber en ese mismo momento a nuestro colaborador y solicite que le proporcione el formato correspondiente para documentar su negativa.</li>
<li>En caso de que proporcione sus datos utilizando nuestra p&aacute;gina de internet o una aplicaci&oacute;n m&oacute;vil, deber&aacute; enviar un mensaje de correo electr&oacute;nico a la direcci&oacute;n&nbsp;<a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>.</li>
</ul>


<p>La negativa para el uso de sus datos personales para estas finalidades secundarias <strong>no </strong>podr&aacute; ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>

<h2 className="terms_title mt-15">2. ¿Qu&eacute; datos personales utilizaremos para estos fines?</h2>

<p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>

<ol type="a">
<li><b>Datos de identificación:</b> Nombre, apellidos, firma, identificación con fotografía, Clave Única del Registro de Población (CURP). </li>
<li><b>Datos generales:</b> Domicilio completo, sea particular o de trabajo, dirección de correo electrónico personal o de trabajo; número telefónico particular o del trabajo; número de teléfono celular, ID Nextel (en su caso) y número de fax; fecha de nacimiento; edad, estado civil y nacionalidad.</li>
<li><b>Datos fiscales:</b> Clave de Registro Federal de Contribuyentes (RFC), así como domicilio fiscal y régimen fiscal (en su caso).</li>
<li><b>Datos patrimoniales:</b> vinculados con cuentas bancarias, tarjetas de crédito y/o débito, nombre del titular de la cuenta, número de cuenta y banco de origen, CLABE (en su caso), número de la tarjeta; fecha de vencimiento de la tarjeta, tipo de tarjeta y código de seguridad. Todos estos datos se almacenarán con su consentimiento expreso y en caso de que nos haga saber su oposición podríamos vernos impedidos para proveer los servicios contratados en virtud de que podría no ser posible procesar el pago correspondiente.</li>
<li><b>Datos personales sensibles:</b> salvo para los servicios médicos y expedientes laborales, Trafictours de México, S.A. de C.V., no recolecta ni trata datos personales sensibles. </li>
Sin embargo, debe tener en cuenta que algunos proveedores de tours o productos y algunos de nuestros afiliados y distribuidores podrían necesitar datos personales adicionales u otro tipo de información (como información sobre la actividad física o la salud) para determinar si puede participar en una actividad o no. En dado caso, ésta información queda sujeta al Aviso de Privacidad del proveedor o distribuidor, y no es recopilada por TraficTours de México, S.A. de C.V.
<li><b>Datos personales de fuentes de acceso público y recibido vía transferencias consentidas:</b> provenientes de registros públicos, directorios, redes sociales y otras fuentes lícitas disponibles en el mercado, así como datos personales que nos hayan sido compartidos por terceros a los cuales hubiese autorizado previamente.</li>
<li><b>Datos personales provenientes de redes sociales:</b> TRAFICTOURS podrá recolectar y procesar datos personales e información que haya compartido en las redes sociales pertenecientes a TRAFICTOURS (tales como Facebook®, Instagram ®, Pinterest ®, Foursquare ®, Google ® y Twitter®, entre otras), particularmente aquella que hayas compartido en el perfil de TRAFICTOURS en dichas redes sociales o en el perfil de otras empresas relacionadas a TRAFICTOURS, incluyendo la información que hayas compartido con carácter de “público/a”, según lo dispuesto por los términos y condiciones de uso y las políticas y declaraciones de los mismos.</li>
<li><b>Datos personales provenientes de agencias de viajes:</b> Trafictours de México, S.A. de C.V., podría recibir sus datos personales cuando usted realice su reservación de excursiones, traslados terrestres, así como la contratación de otros servicios complementarios que de tiempo en tiempo contrate por nuestro conducto; ya sea a través de alguna agencia de viajes minorista o mayorista con domicilio en territorio nacional o en el extranjero, como también otras plataformas que comercializan los servicios de Trafictours, (páginas de internet, programas vacacionales de aerolíneas, club de viajes, programas de beneficios y recompensas, y otras similares conocidas o que se conozcan).</li>
<li><b>Datos personales provenientes de empresas filiales o subsidiarias</b> con las que Usted ya tenga una relación contractual y a la cual haya manifestado su consentimiento para que fuesen transferidas a Trafictours de México, S.A. de C.V.</li>
<li><b>Datos personales provenientes de distribuidores:</b>  aquellos que gestionan el alojamiento, la compra y el pago de los productos y servicios de nuestros proveedores. </li>
<li><b>Información de ubicación y otros datos de los dispositivos:</b> Cuando utilice el sitio web de trafictours.com, mediante un dispositivo móvil, recopilaremos y utilizaremos la información sobre usted, en general, de la misma manera y para los mismos propósitos que están activos en su navegador web. Además, si contamos con su consentimiento, podemos recopilar información sobre su ubicación si ha configurado su dispositivo para enviar dicha información al sitio web de trafictours.com, mediante los ajustes de privacidad del dispositivo, o si ha subido fotos etiquetadas con información de ubicación. Por ejemplo, podremos utilizar su ubicación para mostrarle tours o excursiones que se organicen cerca de usted cuando viaje. Usted puede cambiar los ajustes de privacidad de su dispositivo en cualquier momento con el fin de desactivar la funcionalidad para compartir información de ubicación con la aplicación y/o la funcionalidad de etiquetar sus fotos con información de ubicación. Si tiene alguna duda acerca de la configuración de privacidad de su dispositivo, le sugerimos que se ponga en contacto con el fabricante del Dispositivo o con su proveedor de servicios móviles para obtener ayuda.</li>
<li><b>Recopilación automática de información:</b> Recopilamos automáticamente cierta información de su ordenador o dispositivo móvil cuando visita trafictours.com, o cualquiera de las páginas web de nuestros afiliados alojadas por nosotros. Por ejemplo, recopilamos su dirección IP, el software de su navegador web y el sitio web de referencia. Para ayudarnos a analizar el uso de nuestros sitios web y servicios relacionados, y para enviarle comunicaciones más pertinentes, también recopilamos y mantenemos un seguimiento de su actividad en línea, tales como el contenido que consulta, las páginas que visita y las búsquedas y reservas que le proporcionamos y realiza. Para conocer más, lo invitamos a consultar nuestra <NavLink to="/cookies">Política de Cookies.</NavLink></li>

</ol>

<p>Los datos personales descritos en los incisos precitados del presente apartado, se recolectan mediante: i) el uso de formatos o documentos impresos; ii) el uso de correos electr&oacute;nicos y/o iii) el suministro voluntario de informaci&oacute;n y datos personales cuando usted se hospeda en nuestras instalaciones, cuando se comunica v&iacute;a telef&oacute;nica con nosotros o con nuestros colaboradores (incluyendo el &aacute;rea de atenci&oacute;n a clientes); iv) v&iacute;a nuestro sitio Web trafictours.com y servicios de chat, as&iacute; como v) la utilizaci&oacute;n de fuentes de acceso p&uacute;blico y de otras fuentes disponibles en el mercado, vi) v&iacute;a nuestros clientes afiliados o distribuidores, y vii) los datos que usted haya compartido en los perfiles de redes sociales de TraficTours.</p>

<h2 className="terms_title mt-15">3. <strong>&iquest;C&oacute;mo protegemos los datos personales?</strong></h2>

<p>Los datos personales recolectados por TRAFICTOURS se encuentran protegidos por medidas de seguridad, f&iacute;sicas, administrativas y t&eacute;cnicas adecuadas contra el da&ntilde;o, p&eacute;rdida, alteraci&oacute;n, destrucci&oacute;n, uso, acceso o tratamiento no autorizado, de conformidad con lo dispuesto en la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de Particulares (&ldquo;LFPDPPP&rdquo;) y su Reglamento. Para conocer m&aacute;s sobre nuestras medidas de seguridad le pedimos nos contacte a <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>.</p>
<p>Nos esforzamos por almacenar sus datos personales en entornos operativos seguros que no est&aacute;n accesibles al p&uacute;blico en general, y por aplicar medidas de seguridad en nuestras instalaciones f&iacute;sicas para proteger contra la p&eacute;rdida, el uso indebido o la alteraci&oacute;n de sus datos personales por parte de nuestros empleados o de terceros.</p>
<p>Sin embargo, no es posible garantizar al 100&nbsp;% la seguridad de ninguna transmisi&oacute;n de datos a trav&eacute;s de Internet ni de ning&uacute;n entorno de almacenamiento de datos, de manera que no podemos asegurarle en t&eacute;rminos absolutos que los datos personales que nos proporciona est&eacute;n protegidos en todo momento. Trafictours de M&eacute;xico, S.A. de C.V., conf&iacute;a en que nos informe si experimenta cualquier hecho inusual que pueda indicar una vulneraci&oacute;n de la seguridad de su informaci&oacute;n, del mismo modo, usted ser&aacute; notificado en caso de alg&uacute;n incidente o vulnerabilidad detectado por Trafictours, en los plazos y medios establecidos, que la normatividad aplicable designe. En ese caso, nos esforzaremos por investigar si este hecho estaba relacionado con la transmisi&oacute;n de datos cuyo origen o destino sea trafictours.com, y le informaremos sobre las medidas que puede adoptar y que se han adoptado para ayudar a corregir el problema.</p>

<h2 className="terms_title mt-15">4. <strong>Informaci&oacute;n relativa a menores de edad:</strong></h2>

<p>Trafictours.com, son sitios para el p&uacute;blico en general, y no ofrece servicios dirigidos a menores.</p>
<p>A excepci&oacute;n de los convenios acad&eacute;micos celebrados con instituciones de nivel medio superior y superior, as&iacute; como aquellos programas de becarios, impulsados por la autoridad competente, con el objetivo de brindar la oportunidad de que los alumnos efect&uacute;en pr&aacute;cticas profesionales en nuestra empresa, Trafictours de M&eacute;xico, S.A. de C.V. no ofrece servicios dirigidos a menores.</p>
<p>En caso de que Trafictours de M&eacute;xico, S.A. de C.V., sea informado, de que una persona menor de 13 a&ntilde;os nos ha enviado informaci&oacute;n personal, eliminaremos o destruiremos dicha informaci&oacute;n tan pronto como sea razonablemente posible.</p>
<p>Si usted conoce a un menor que est&aacute; inscrito al sitio web de trafictours.com, por favor inf&oacute;rmenos v&iacute;a correo electr&oacute;nico a <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>, a fin de eliminar su informaci&oacute;n.</p>


<h2 className="terms_title mt-15">5. <strong>&iquest;Con qui&eacute;n compartimos su informaci&oacute;n personal y para qu&eacute; fines?</strong></h2>


<p>Le informamos que sus datos personales pueden ser transferidos a terceros dentro y fuera del territorio nacional en caso de que sea necesario para llevar a cabo las finalidades consignadas en el presente aviso de privacidad.</p>
<p>Tambi&eacute;n le informamos que Trafictours de Mexico, S.A. de C.V., es parte de un grupo empresarial conocido comercialmente como &ldquo;TRAFICTOURS&rdquo; y puede que transfiera sus datos personales con otras compa&ntilde;&iacute;as nacionales e internacionales, del mismo grupo, en t&eacute;rminos de lo establecido en el Art&iacute;culo 37 de la LFPDPPP respecto a las partes relacionadas. Las compa&ntilde;&iacute;as que integran el grupo son:</p>
<ul>
<li>Trafictours Canada, Inc.</li>
<li>DMC Leisure, S.A. de C.V.</li>
<li>TTDR Travel Company, SAS</li>
<li>Turissimo Caribe Excursiones Dominican Republic, SA</li>
<li>CTI Logistics, Inc.</li>
<li>Sun Excursions Caribbean, Inc.</li>
<li>Turissimo Jamaica Limited.</li>
</ul>
<p>Tambi&eacute;n compartiremos sus datos personales con terceros independientes con los que requerimos interactuar para llevar a cabo las finalidades aqu&iacute; establecidas, por ejemplo:</p>
<ul>
<li><strong>Proveedores de servicios:</strong> Algunos de los datos personales que recopilamos se comparten con nuestros proveedores de servicios (por ejemplo, operadores de tours o servicios de transportaci&oacute;n), quienes est&aacute;n implicados directamente en llevar a cabo las compras que realiza a trav&eacute;s de nosotros. Solo le proporcionamos a nuestros proveedores de servicios la informaci&oacute;n necesaria (por ejemplo, el nombre del hotel para que le recojan o le lleven, su n&uacute;mero de tel&eacute;fono local, su n&uacute;mero de tel&eacute;fono m&oacute;vil, su direcci&oacute;n de correo electr&oacute;nico, fecha de llegada y fecha de salida). Le recomendamos que lea la pol&iacute;tica de privacidad del proveedor de servicios para determinar c&oacute;mo este podr&aacute; usar o divulgar sus datos personales.</li>
<li><strong>Sitios web remitentes:</strong> Si se le remiti&oacute; a trafictours.com, desde otro sitio web, podemos compartir con dicho sitio web remitente su informaci&oacute;n de registro, como por ejemplo su nombre, su direcci&oacute;n de correo electr&oacute;nico, su direcci&oacute;n postal, su n&uacute;mero de tel&eacute;fono y sus preferencias de viaje. No hemos limitado el uso que los sitios web remitentes hacen de sus datos personales, y le recomendamos que revise las pol&iacute;ticas de privacidad de cualquier sitio web que le haya remitido a trafictours.com</li>
<li><strong>Proveedores terceros de marketing y publicidad:</strong> Tambi&eacute;n compartimos sus datos personales con proveedores terceros que ofrecen servicios o funciones en nuestro nombre, como por ejemplo el an&aacute;lisis comercial, el procesamiento de pagos, el servicio de atenci&oacute;n al cliente, el marketing, las relaciones p&uacute;blicas, la distribuci&oacute;n de encuestas o programas de sorteos y la prevenci&oacute;n del fraude. Adem&aacute;s, es posible que autoricemos a proveedores terceros a recopilar datos personales en nuestro nombre, lo que incluye, por ejemplo, aquellos que sean necesarios para manejar las funciones de nuestros sitios web o Aplicaciones, o para proporcionar la distribuci&oacute;n de publicidad en l&iacute;nea adaptada a sus intereses. Los proveedores terceros tienen acceso y pueden recopilar datos personales &uacute;nicamente cuando sea necesario para llevar a cabo sus funciones, y no pueden compartir o usar datos personales con ning&uacute;n otro prop&oacute;sito. Actualmente, contratamos a diferentes socios terceros online para que nos ayuden a gestionar y mejorar nuestras comunicaciones y nuestra actividad de Internet. Utilizamos los servicios de empresas de marketing terceras para ayudarnos a medir la eficacia de nuestra publicidad y saber c&oacute;mo utilizan nuestros visitantes nuestro sitio web.</li>
<li><strong>Terceros independientes: </strong>especialmente incluyen instituciones bancarias que procesan sus pagos, as&iacute; como a los proveedores finales de los servicios o bienes que usted adquiere mediante la intermediaci&oacute;n tur&iacute;stica que realiza TRAFIC TOURS.&nbsp; &Eacute;sta transferencia siempre ser&aacute; opcional y usted podr&aacute; oponerse en cualquier momento, sin embargo, esto podr&iacute;a impedir que TRAFIC TOURS act&uacute;e como intermediario y gestione la reservaci&oacute;n necesaria para el otorgamiento de los bienes o servicios que usted adquiera. Algunas de esas transferencias son opcionales, le pedimos indique con cu&aacute;les no desea que compartamos su informaci&oacute;n:</li>
</ul>

                                     <div className="table-responsive">
                                            <table className="table table-striped">
                                            <thead className="table_privacy_title">
                                                <tr>
                                                    <th scope="col">Tercero</th>
                                                    <th scope="col">País</th>
                                                    <th scope="col">Finalidad</th>
                                                    <th scope="col">Carácter</th>
                                                    <th scope="col" className="text-left">Tipo de Transferencia</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">Instituciones bancarias</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Procesamiento de Pagos</td>
                                                    <td className="align-middle">Opcional</td>
                                                    <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td className="align-middle">Terceros Privados</td>
                                                    <td className="align-middle">México o en el extranjero.</td>
                                                    <td className="align-middle">Proporcionar los servicios que usted contrate mediante la intermediación turística que realiza TRAFIC TOURS.</td>
                                                    <td>
                                                        Obligatoria
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Proveedores de Tours y excursiones</td>
                                                    <td className="align-middle">Internacional</td>
                                                    <td className="align-middle">Proveedores de Tours y excursiones</td>
                                                    <td>
                                                    Voluntaria 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Proveedores de servicios de Traslados terrestres</td>
                                                    <td className="align-middle">Internacional</td>
                                                    <td className="align-middle">Proveedores de servicios de Traslados terrestres</td>
                                                    <td>
                                                    Voluntaria 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Empresas encargadas de administración de cajas de ahorro y préstamos sobre nómina</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Apertura de cajas de ahorro y otorgamiento de créditos y préstamos sobre nómina.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Empresas encargadas del reclutamiento y selección de personal.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Coadyuvar con terceros mediante la recomendación de candidatos para vacantes.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>
    
                                                <tr>
                                                    <td className="align-middle">Empresas prestadoras de servicios especializados o compartidos.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Prestación de servicios especializados.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td className="align-middle">Empresas de análisis y estudios laborales.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Para efectuar  estudios relacionados con beneficios para el personal.</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Instituciones de fondo de pago electrónico (Vales de despensa)..</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Para el pago de los salarios y demás prestaciones establecidas en la Ley Federal del Trabajo y contratos individuales o colectivos de trabajo</td>
                                                    <td>
                                                    Opcional 
                                                    </td>
                                                   <td>

                                                        Autorizo
                                                        <br />
                                                        Si___ <br />
                                                        No___

                                                    </td>
                                                </tr>




                                            </tbody>
                                            </table>
                                        </div>

                      <p>De igual manera le informamos que a fin de dar cumplimiento a la legislación vigente, Trafictours de México, S.A. de C.V., debe compartir sus datos personales a terceros independientes y dichas transferencias de datos NO son opcionales. Dichas transferencias son:</p>

                      <div className="table-responsive">
                                            <table className="table table-striped">
                                            <thead className="table_privacy_title">
                                                <tr>
                                                    <th scope="col">Tercero</th>
                                                    <th scope="col">País</th>
                                                    <th scope="col">Finalidad</th>
                                                    <th scope="col">Carácter</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">SAT y autoridades fiscales a nivel estatal</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Cumplimiento de disposiciones fiscales vigentes mediante la presentación de declaraciones periódicas</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Procuraduría Federal del Consumidor</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">La atención de procedimientos previstos en la Ley Federal de Protección al Consumidor</td>
                                                    <td className="align-middle">Obligatoria</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">INFONAVIT</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Afiliación al Instituto y demás acciones relacionadas con sus financiamientos</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">INFONACOT</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Afiliación al Instituto y demás acciones relacionadas con sus financiamientos</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">IMSS</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Cumplimiento de las obligaciones de previsión social establecidas en la Ley.</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>


                                                <tr>
                                                    <td className="align-middle">Autoridades en materia de trabajo y previsión social a nivel federal o estatal.</td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Cumplimiento de las obligaciones resultantes de la relación obrero-patronal.</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Autoridades Judiciales </td>
                                                    <td className="align-middle">México</td>
                                                    <td className="align-middle">Atención y cumplimiento a requerimientos por parte de las autoridades Judiciales</td>
                                                    <td className="align-middle">Obligatorio</td>
                                                </tr>



                                            </tbody>
                                            </table>
                                        </div>

<p>Finalmente se&ntilde;alamos que transferiremos sus datos nacional o internacionalmente, en los supuestos contemplados por el <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LFPDPPP.pdf">Art&iacute;culo 37 de la </a><a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LFPDPPP.pdf">Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares</a>, vigente en M&eacute;xico, que establece expresamente la posibilidad de compartir sus datos con autoridades competentes y sociedades controladoras, subsidiarias o afiliadas bajo el control com&uacute;n del responsable, o a una sociedad matriz o a cualquier sociedad del mismo, en los siguientes supuestos:</p>
<ol type="I">
<li>Cuando la transferencia est&eacute; prevista en una Ley o Tratado en los que M&eacute;xico sea parte;</li>
<li>Cuando la transferencia sea necesaria para la prevenci&oacute;n o el diagn&oacute;stico m&eacute;dico, la prestaci&oacute;n de asistencia sanitaria, tratamiento m&eacute;dico o la gesti&oacute;n de servicios sanitarios;</li>
<li>Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control com&uacute;n del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo del responsable que opere bajo los mismos procesos y pol&iacute;ticas internas; IV. Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en inter&eacute;s del titular, por el responsable y un tercero;</li>
<li>Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un inter&eacute;s p&uacute;blico, o para la procuraci&oacute;n o administraci&oacute;n de justicia;</li>
<li>Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y</li>
<li>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relaci&oacute;n jur&iacute;dica entre el responsable y el titular</li>
</ol>


<h2 className="terms_title mt-15">6. <strong>Plazos de conservaci&oacute;n:</strong></h2>


<p>Trafictours de M&eacute;xico, S.A. de C.V., conservar&aacute; los datos personales por el tiempo que sea necesario para cumplir con los fines por los que haya recopilado la informaci&oacute;n, incluidos las finalidades para satisfacer cualquier requisito legal, contable y de prestaciones de informes, de acuerdo a lo establecido en el Art&iacute;culo 37, del Reglamento de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares, vigente en M&eacute;xico.</p>
<p>Con el prop&oacute;sito de determinar el per&iacute;odo de retenci&oacute;n apropiado, tenemos en cuenta la cantidad, la naturaleza y el grado de confidencialidad de los datos personales, el riesgo potencial de da&ntilde;os originados por el uso o divulgaci&oacute;n no autorizados de sus datos personales, los fines por los que procesamos dichos datos y si podemos alcanzar dichos fines mediante otros medios, as&iacute; como las disposiciones legales vigentes.</p>

<h2 className="terms_title mt-15">7. <strong>&iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?&nbsp;</strong></h2>

<p>Usted tiene derecho a conocer qu&eacute; datos personales tenemos de usted, para qu&eacute; los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que est&eacute; desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no est&aacute; siendo utilizada adecuadamente (Cancelaci&oacute;n); as&iacute; como oponerse al uso de sus datos personales para fines espec&iacute;ficos (Oposici&oacute;n), o bien, revocar su consentimiento al uso o divulgaci&oacute;n de sus datos personales (Revocaci&oacute;n). Estos derechos se conocen como derechos ARCO.<br /> <br /> <strong>Con relaci&oacute;n al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:</strong></p>



<p><strong>a) &iquest;Qu&eacute; informaci&oacute;n, documentaci&oacute;n y requisitos deber&aacute; contener la solicitud?</strong></p>

<p>En cuanto a los requisitos generales que debe cubrir la solicitud, est&aacute;n los siguientes:</p>
<ul>
<li>Nombre del titular de los datos personales.</li>
<li>Documentos que acrediten la identidad del titular (pasaporte, credencial para votar expedida por el Instituto Nacional Electoral, c&eacute;dula profesional estatal o federal o licencia de manejo).</li>
<li>En su caso, nombre del representante del titular y documentos para acreditar su identidad y personalidad. Trat&aacute;ndose de apoderados de personas f&iacute;sicas, la representaci&oacute;n se acreditar&aacute; mediante las siguientes 3 opciones: a) instrumento p&uacute;blico (documento suscrito por parte de un Notario P&uacute;blico), b) carta poder firmada ante dos testigos (anexando copia simple de sus identificaciones oficiales), c) acudiendo usted y su representante a declarar en comparecencia del responsable presentando su identificaci&oacute;n oficial (pasaporte, credencial para votar expedida por el Instituto Nacional Electoral, c&eacute;dula profesional estatal o federal o licencia de manejo).</li>
<li>Domicilio o cualquier medio para recibir notificaciones.</li>
<li>Descripci&oacute;n clara y precisa de los datos personales y del derecho que se quiere ejercer o de lo que se solicita.</li>
<li>Los documentos o informaci&oacute;n que faciliten identificar la informaci&oacute;n materia de la solicitud (por ejemplo, n&uacute;mero de cup&oacute;n o reservaci&oacute;n, nombre completo, correo electr&oacute;nico registrado en sistema).</li>
</ul>

<p>As&iacute; mismo, en relaci&oacute;n al tipo de solicitud, requerimos por favor nos indique la siguiente informaci&oacute;n adicional, a fin de que su solicitud pueda ser atendida de forma m&aacute;s eficiente:</p>


<ul>
<li><strong>Solicitud de Acceso: </strong>Indicar la modalidad en la que usted prefiere que se reproduzcan los datos personales solicitados.</li>
<li><strong>Solicitud de Rectificaci&oacute;n: </strong>Especificar las modificaciones que se solicitan a los datos personales, as&iacute; como aportar los documentos que sustenten la solicitud.</li>
<li><strong>Solicitud de Cancelaci&oacute;n: </strong>Se&ntilde;alar las causas que motivan la petici&oacute;n de que se eliminen los datos de los archivos, registros o bases de datos del responsable.</li>
<li><strong>Solicitud de Oposici&oacute;n: </strong>Manifestar las causas o la situaci&oacute;n que llevan al titular a solicitar que concluya el tratamiento de sus datos personales, as&iacute; como el da&ntilde;o que le causar&iacute;a que dicho tratamiento contin&uacute;e. En el caso de que la solicitud se refiera a un tratamiento en lo particular, se deben indicar las finalidades espec&iacute;ficas respecto de las cuales se solicita el ejercicio del derecho.</li>
<li><strong>Revocaci&oacute;n del consentimiento:</strong> Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que, para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</li>
</ul>



<p><strong>b) &iquest;A trav&eacute;s de qu&eacute; medios pueden acreditar su identidad el titular y, en su caso, su representante, as&iacute; como la personalidad este &uacute;ltimo?</strong></p>

<p>Para el ejercicio de cualquiera de los derechos ARCO, usted deber&aacute; presentar la solicitud respectiva a trav&eacute;s del siguiente medio: Por correo electr&oacute;nico a la direcci&oacute;n privacidad@trafictours.com o tambi&eacute;n podr&aacute; contactar a nuestro Departamento de Datos Personales para que le d&eacute; tr&aacute;mite a tu solicitud, le proporcione orientaci&oacute;n y le facilite el formato correspondiente. El domicilio de nuestro Departamento de Datos Personales es Avenida Acanceh&nbsp; lote 3 manzana 2 local 203 Supermanzana 11, Municipio de Benito Ju&aacute;rez, Canc&uacute;n, Quintana Roo, M&eacute;xico, CP 77504.</p>
<p>El formato de atenci&oacute;n a solicitudes ARCO oficial para TraficTours puede solicitarlo en la direcci&oacute;n <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a>, o descargarlo en el siguiente link.[//insertar]</p>


<p><strong>c) &iquest;En cu&aacute;ntos d&iacute;as le daremos respuesta a su solicitud?</strong></p>


<p>20 d&iacute;as h&aacute;biles (todos los d&iacute;as del a&ntilde;o, a excepci&oacute;n de los s&aacute;bados, domingos e inh&aacute;biles por disposici&oacute;n de Ley).</p>



<p><strong>d) &iquest;Por qu&eacute; medio le comunicaremos la respuesta a su solicitud?<br /> </strong>Por correo electr&oacute;nico o en los datos de contacto que usted nos proporcione en su solicitud.</p>




<p><strong>e) &iquest;En qu&eacute; medios se pueden reproducir los datos personales que, en su caso, solicite?</strong><br /> En la misma forma que hayan sido proporcionados por el cliente.</p>




<p><strong>f) Ponemos a sus &oacute;rdenes los siguientes formularios o sistemas para facilitar el ejercicio de derechos ARCO:</strong></p>


<p>Los formularios o sistemas correspondientes y orientaci&oacute;n sobre el ejercicio de los derechos ARCO se pueden obtener contactando a nuestro Departamento de Datos Personales en la direcci&oacute;n de correo electr&oacute;nico privacidad@marivalgroup.com y con domicilio f&iacute;sico en Paseo de los Cocoteros s/n, hotel Marival Resort &amp; Suites, fraccionamiento Nuevo Vallarta, municipio de Bah&iacute;a de Banderas, Nayarit, c&oacute;digo postal 63732, n&uacute;mero de tel&eacute;fono 322-2268200 (extensi&oacute;n 4493).</p>


<p><strong>g) &iquest;Se aplica alg&uacute;n costo por el ejercicio de mis derechos ARCO?</strong></p>


<p>El ejercicio de los derechos ARCO es gratuito. Sin embargo, usted puede ser requerido a cubrir &uacute;nicamente los gastos justificados de env&iacute;o o el costo de reproducci&oacute;n en copias u otros formatos. Del mismo modo, si la misma persona reitera su solicitud en un periodo menor a doce meses, los costos no ser&aacute;n mayores a tres d&iacute;as de Salario M&iacute;nimo General Vigente en el Distrito Federal, a menos que existan modificaciones sustanciales al aviso de privacidad que motiven nuevas consultas.</p>


<p><strong>h) &iquest;Qu&eacute; pasa si una solicitud es incompleta o no se anexan todos los datos que incluye el procedimiento?.</strong></p>


<p>En caso de que su solicitud no cuente con la informaci&oacute;n descrita en el apartado 4 Requisitos (incisos a y b); TRAFICTOURS podr&aacute; solicitar al titular que proporcione la informaci&oacute;n faltante, por medio de un escrito denominado &ldquo;prevenci&oacute;n&rdquo;, el cual se deber&aacute; emitir en un plazo de m&aacute;ximo 5 d&iacute;as h&aacute;biles contados a partir del d&iacute;a siguiente de la presentaci&oacute;n de la solicitud.</p>
<p>El titular contar&aacute; con 10 d&iacute;as h&aacute;biles, despu&eacute;s de recibir la &ldquo;prevenci&oacute;n&rdquo;, para proporcionar la informaci&oacute;n requerida, pues de lo contrario se tendr&aacute; como no presentada su solicitud.</p>
<p><strong>Para mayor informaci&oacute;n sobre el procedimiento, ponemos a disposici&oacute;n los siguientes medios: al correo electr&oacute;nico: </strong><a href="mailto:privacidad@trafictours.com"><strong>privacidad@trafictours.com</strong></a></p>
<p><strong>Los datos de contacto de la persona o departamento de datos personales, que est&aacute; a cargo de dar tr&aacute;mite a las solicitudes de derechos ARCO, son los siguientes:</strong><br /> <br /> </p>
<ul>
<li>Correo electr&oacute;nico: <a href="mailto:privacidad@trafictours.com">privacidad@trafictours.com</a></li>
<li>Contactando a nuestro departamento de protecci&oacute;n de datos, que est&aacute; a cargo de dar tr&aacute;mite a las solicitudes de derechos ARCO:
<ul>
<li>Nombre de la persona o departamento de datos personales: DEPARTAMENTO DE DATOS PERSONALES TRAFICTOURS.</li>
<li>Domicilio: Avenida Acanceh lote 3 manzana 2 local 203 Supermanzana 11, Municipio de Benito Ju&aacute;rez, Canc&uacute;n, Quintana Roo, M&eacute;xico, CP 77504</li>
<li>Correo electr&oacute;nico:&nbsp;<a href="mailto:PRIVACIDAD@MARIVALGROUP.COM">PRIVACIDAD@TRAFICTOURS.COM</a></li>
<li>N&uacute;mero telef&oacute;nico: 3222268200</li>
</ul>
</li>
</ul>



<h2 className="terms_title mt-15">8. <strong>&iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal?</strong></h2>


<p>Con objeto de que usted pueda limitar el uso y divulgaci&oacute;n de su informaci&oacute;n personal, le ofrecemos los siguientes medios: el cliente puede limitar el uso o divulgaci&oacute;n de sus datos personales enviando un correo donde haga el planteamiento concreto a la direcci&oacute;n de correo electr&oacute;nico&nbsp;<a href="mailto:privacidad@marivalgroup.com">privacidad@trafictours.com</a></p>
<p>De manera adicional, le informamos que contamos con los siguientes listados de exclusi&oacute;n, en los cuales podr&aacute; registrarse o bien, des inscribirse en nuestras plataformas de env&iacute;os, para que sus datos personales no sean tratados para ciertos fines (principalmente secundarios):</p>
<p>De manera adicional, le informamos que contamos con los siguientes listados de exclusi&oacute;n, en los cuales podr&aacute; registrarse o bien, des inscribirse en nuestras plataformas de env&iacute;os, para que sus datos personales no sean tratados para ciertos fines (principalmente secundarios):</p>


                <div className="table-responsive">
                                      <table className="table table-striped">
                                            <thead className="table_privacy_title">
                                                <tr>
                                                    <th scope="col">Nombre del listado</th>
                                                    <th scope="col">Finalidad para las que aplica</th>
                                                    <th scope="col">Medio para obtener mayor información</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">MARKETING</td>
                                                    <td className="align-middle">MERCADOTECNIA O PUBLICIDAD</td>
                                                    <td className="align-middle">PROMOS@MARIVALGROUP.COM</td>
                                                </tr>




                                            </tbody>
                                            </table>
                 </div>

                                        <h2 className="terms_title mt-15">9. <strong>Sobre el uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></h2>


<p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies, u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina. Los datos personales que recabamos a trav&eacute;s de estas tecnolog&iacute;as, los utilizaremos para los siguientes fines:</p>
<p>Para monitorear y prevenir el uso indebido de las plataformas digitales; para mejorar nuestras herramientas digitales y actualizarlas conforme evoluciona la tecnolog&iacute;a; y para ofrecer contenido personalizado.</p>
<p>Los datos personales que obtenemos de estas tecnolog&iacute;as de rastreo son los siguientes:</p>
  <ul>
   <li>Identificadores, nombre de usuario y contrase&ntilde;as de una sesi&oacute;n.</li>
   <li>Idioma preferido por el usuario.</li>
   <li>Regi&oacute;n en la que se encuentra el usuario.</li>
   <li>Tipo de navegador del usuario.</li>
   <li>Tipo de sistema operativo del usuario.</li>
   <li>P&aacute;ginas web visitadas por un usuario.</li>
   <li>B&uacute;squedas realizadas por un usuario.</li>
   <li>Publicidad revisada por un usuario.</li>
  </ul>

<p>Para m&aacute;s informaci&oacute;n se sugiere consultar nuestra&nbsp;<NavLink to="/cookies">Política de Cookies</NavLink>:&nbsp;</p>
                                       
<h2 className="terms_title mt-15">10. <strong>&iquest;C&oacute;mo puede conocer los cambios en este aviso de privacidad?</strong></h2>
                                       
<p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
<p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s de: env&iacute;o de correo electr&oacute;nico y en la p&aacute;gina de internet.</p>
<p>El procedimiento a trav&eacute;s del cual se llevar&aacute;n a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:</p>
<p>Los cambios o actualizaciones al aviso de privacidad se dar&aacute;n a conocer por los medios que se describen en esta pol&iacute;tica y surtir&aacute;n efectos 1 semana despu&eacute;s. En caso de dudas o comentarios los clientes deber&aacute;n dirigirse a <a href="mailto:PRIVACIDAD@MARIVALGROUP.COM">privacidad@trafictours.com</a></p>

<h2 className="terms_title mt-15">11. <strong>Consentimiento: Otorgo mi consentimiento para el uso de mis datos personales en conformidad con el Aviso de Privacidad Integral de Trafictours de M&eacute;xico, S.A. de C.V.:</strong></h2>

                <div className="table-responsive">
                                      <table className="table table-striped">

                                            <tbody>

                                                <tr>
                                                    <td className="align-middle">&nbsp;</td>
                                                </tr>

                                                <tr>
                                                    <td className="align-middle">Nombre y firma del titular</td>
                                                </tr>




                                            </tbody>
                                            </table>
                 </div>

                 <h2 className="terms_title mt-15">12. Fecha de actualización:  15/07/2021</h2>


          </div>
       </div>



);
    }
}

export default withRouter(traficprivacy)