import React from "react";
import Card from "react-credit-cards";
import Global from "../globals/global";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
  isBBVACreditCard
} from "./component.credit.card.validator";

import "react-credit-cards/es/styles-compiled.css";

export default class App extends Global {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
  };

  handleCallback = ({ issuer }, isValid) => {
   

    if(this.props.issuer) this.props.issuer({name:issuer,isValid:isValid})

    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {

    let isBBVA = isBBVACreditCard(target.value);
    console.log('isBBVA',isBBVA);
    
    this.setState({
      focused: target.name,
    },()=>{
      if(this.props.onChange) this.props.onChange({issuer:this.state.issuer,name:target.name,value:target.value, isBBVA:isBBVA })
    });
  };

  handleInputChange = ({ target }) => {
    let isBBVA = isBBVACreditCard(target.value);

    console.log('isBBVA 1',isBBVA);

    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value },()=>{
      if(this.props.onChange) this.props.onChange({issuer:this.state.issuer,name:target.name,value:target.value, isBBVA:isBBVA })
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    this.form.reset();
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    return (
      <div className="row">


        
        <div className="col-lg-6 pt-3 pb-3">
          <div >
            <div className="form-group">
            <label>{this.t("Credit Card Number")}</label>
              <input
                type="tel"
                name="number"
                className="form-control"
                placeholder={this.t("Credit Card Number")}
                pattern="[\d| ]{16,22}"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            
            </div>
            <div className="form-group">
            <label>{this.t("Name as it appears on the Credit Card")}</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder={this.t("Name as it appears on the Credit Card")}
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </div>
            <div className="row">
              <div className="col-4">
              <label>{this.t("Valid Thru")}</label>
                <input
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="MM/YY"
                  pattern="\d\d/\d\d"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>

              {this.props.disableFields.indexOf('cvc') == -1?
              <div className="col-3">
               <label>CVV</label>
                <input
                  type="tel"
                  name="cvc"
                  className="form-control"
                  placeholder="CVV"
                  pattern="\d{3,4}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              :''}

             {this.props.disableFields.indexOf('zipcode') == -1?
              <div className="col-5">
               <label>{this.t("Zip Code")}</label>
                <input
                  type="tel"
                  name="zipcode"
                  className="form-control"
                  placeholder={this.t("Zip Code")}
                  pattern="\d{5,5}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              :''}

            </div>
            
            
          </div>
        </div>

        <div className="col-lg-6 pt-5">
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
            placeholders={{name: this.t('YOUR NAME HERE')}}
            locale={{ valid: this.t('Valid Thru')}}
          />
        </div>

      </div>
    );
  }
}
